import React from 'react';

// import { useApi } from 'api/useApi';
// import tableApi from 'api/table/tableApi';

// import { Spinner } from 'components';

import AdminGrids from './AdminGrids';

const AdminGridsContainer = (props) => {
  // const [args] = useState([]);
  // const [fetchedGrids, pendingGrids] = useApi(tableApi.getAll, args);
  // const [grids, setGrids] = useState([]);

  // const getGrids = useCallback(() => {
  //   if (fetchedGrids) {
  //     setGrids(fetchedGrids.tables);
  //   }
  // }, [fetchedGrids]);

  // if (pendingGrids) return <Spinner />;

  // return <AdminGrids grids={grids} getGrids={getGrids} />;
  return <AdminGrids {...props} />;
};

export default AdminGridsContainer;
