import _ from 'lodash';
import { object } from 'prop-types';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AdminRoute } from 'components';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import routesAdmin from './routesAdmin';

const renderPageSegment = () => (
  <TilesPanel routes={_.filter(routesAdmin, { type: 'admin' })} />
);

const Admin = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match?.path} render={renderPageSegment} />
      {_.map(routesAdmin, ({ exact, path, component: Component }, i) => (
        <AdminRoute
          key={i}
          exact={exact}
          path={path}
          render={() => <Component />}
        />
      ))}
    </Switch>
  );
};

Admin.propTypes = {
  match: object.isRequired,
};

export default Admin;
