import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, Module } from 'core/model';

const moduleApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ modules: Module[] }>('module', params),
  //getNew: () => BaseApi.get(`module/new`),
  get: (key: string, params: BaseApiParams) =>
    BaseApi.get<{ module: Module }>(`module/${key}`, params),
  post: (module: Module, params: BaseApiParams) =>
    BaseApi.post(`module`, module, params),
  putAS: (autoSaveInfo: AutoSaveInfo<Module, string>, params: BaseApiParams) =>
    BaseApi.put(`module/AS`, { autoSaveInfo }, params),
  delete: (Key: string, Areas: string, params: BaseApiParams) =>
    BaseApi.delete(`module/${Key}/${Areas}`, params),
};

export default moduleApi;
