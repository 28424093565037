import { bool, InferProps, node, string } from 'prop-types';
import { MouseEventHandler } from 'react';

import { ctor } from '../helper';
import { contentPT, interactivePT, sizePT, stylePT } from './common';

const fwItemPT = {
  ...contentPT,
  ...interactivePT,
  ...sizePT,
  ...stylePT,
  center: bool,
  children: node,
  itemKey: string,
  path: string,
  value: string,
};

interface FwItemPT extends InferProps<typeof fwItemPT> {
  onClick?: MouseEventHandler;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwItemProps extends FwItemPT {}
class FwItemProps implements FwItemPT {
  constructor(props?: FwItemPT) {
    ctor(this, props);
  }
}

export { fwItemPT, FwItemProps };
