import { dtoCtor, Dto } from '../helper';
import { LanguageContent } from './languageContent';

class Language implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Language, 'dtoName'>) {
    dtoCtor(this, 'Language', props);
  }

  key?: string;
  name?: string;
  languageContent?: LanguageContent;
}

export { Language };
