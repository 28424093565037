import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import inputApi from 'api/input/inputApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, Input as I } from 'core/model';

const Input: FC<Props> = ({ input, handleSetKey }) => {
  const { t } = useTranslation();

  const newInput = {
    ...input,
    additionalData: JSON.stringify(input.additionalData),
  };

  const castI: I = Object.assign(new I(), input);

  // get entityDoc for update name, key, type, placeholder and additionalData
  const inputs = getAdminInputs(castI, null, t);
  const entityDoc = getEntityDoc(newInput, inputs);

  return (
    <>
      {input && (
        <EntityInfo
          autosave
          api={inputApi}
          entityDoc={entityDoc}
          keyName={'fieldID'}
          entityKey={input.fieldID}
          type={'Input'}
          title={'Input'}
          onSetKey={handleSetKey}
        />
      )}
    </>
  );
};

const propTypes = {
  input: any.isRequired,
  handleSetKey: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

Input.propTypes = propTypes;

export default withTranslation()(Input);
