import React from 'react';

// since Storybook upgrade from v6 to v7, babel was also upgraded
// and react-table useAsyncDebounce was giving errors (more details below)
// temporary extract code from react-table to FasterWeb
// until merged fix #3962 is published / included in a v7 release
// issue #3962 -> https://github.com/TanStack/table/issues/3962
// merged PR #3984 -> https://github.com/TanStack/table/pull/3984

function useGetLatest(obj: any) {
  const ref = React.useRef<any>();
  ref.current = obj;

  return React.useCallback(() => ref.current, []);
}

export function useAsyncDebounce(defaultFn: any, defaultWait = 0) {
  const debounceRef = React.useRef<any>({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return React.useCallback(
    async (...args) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve(await getDefaultFn()(...args));
        } catch (err) {
          debounceRef.current.reject(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait]
  );
}
