import { FIELD_TYPE } from 'core/utils/constant';

const { date, datetime, decimal, multidates, number, text, textarea, time } =
  FIELD_TYPE;

const getInputType = (type: string) => {
  let typeHtml = 'text';

  switch (type) {
    case multidates:
    case date:
      typeHtml = 'date';
      break;
    case decimal:
    case number:
      typeHtml = 'number';
      break;
    case text:
    case textarea:
      typeHtml = 'text';
      break;
    case time:
      typeHtml = 'time';
      break;
    case datetime:
      typeHtml = 'datetime-local';
      break;
    default:
      typeHtml = type;
      break;
  }

  return typeHtml;
};

export { getInputType };
