import _ from 'lodash';
import React, { useState, useEffect, useRef, FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import errorApi from 'api/error/errorApi';
import { useApi } from 'api/useApi';

import Error from './Error';

const ErrorContainer = () => {
  const { t } = useTranslation();

  const readyRef = useRef(false);
  const decryptedRef = useRef(t('No match found'));
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    id: undefined,
    key: undefined,
    value: undefined,
  });
  const [decrypted, setDecrypted] = useState<string>();

  const [args, setArgs] = useState([]);
  const { response: editedError, pending: pendingEditError } = useApi(
    _.isEmpty(args) ? undefined : errorApi.post,
    args
  );

  useEffect(() => {
    if (pendingEditError) {
      readyRef.current = true;
      setLoading(true);
    } else {
      if (readyRef.current) {
        if (editedError?.data?.decrypted) {
          setDecrypted(editedError?.data?.decrypted);
        } else {
          setDecrypted(decryptedRef.current);
        }
        setLoading(false);
      }
    }
  }, [editedError, pendingEditError]);

  const handleSubmit: FormEventHandler = async (e) => {
    // prevent page refresh
    e.preventDefault();

    // trigger api call
    setArgs([form]);
  };

  const handleChange = (field: string, value: string) => {
    setDecrypted('');
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <Error
      t={t}
      id={form.id}
      debugKey={form.key}
      loading={loading || pendingEditError}
      value={form.value}
      result={decrypted}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
    />
  );
};

export default ErrorContainer;
