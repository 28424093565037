import { GridItem, Skeleton, SkeletonProps } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, elementType, InferProps } from 'prop-types';
import React, { FC } from 'react';

import { NoSpanGrid, NoSpanItem, Spannable } from './FwGrid.helpers';
import NestedGrid from './FwGrid.NestedGrid';

// only used for debugging (when not specifying a render item component in grid)
const FwSkeleton = (props: SkeletonProps) => <Skeleton {...props} />;

const NestedItem: FC<Props> = <T extends Spannable = Spannable>({
  item,
  itemComponent: ItemComponent = FwSkeleton,
  responsive,
  ...props
}) => {
  const { column, columnSpan, row, rows, rowSpan, visible } = item || {};
  const processedItem = _.cloneDeep(item);

  const itemIsNoSpanGrid = item?.rows ? true : false;

  if (item && !itemIsNoSpanGrid) {
    // remove positionning and visible properties from item
    _.forOwn(
      { column, columnSpan, row, rows, rowSpan, visible },
      (_value, key) => delete processedItem[key]
    );
  }

  return (
    <GridItem
      data-role="item"
      colSpan={columnSpan}
      visibility={
        // hide item but reserve space
        visible === false ? 'hidden' : undefined
      }
    >
      {itemIsNoSpanGrid ? (
        // when it has rows, it is a nested no span grid
        <NestedGrid
          grid={item as NoSpanGrid<T>}
          itemComponent={ItemComponent}
          responsive={responsive}
          {...props}
        />
      ) : (
        // otherwise, it is an item
        <ItemComponent {...processedItem} />
      )}
    </GridItem>
  );
};

const propTypes = {
  itemComponent: elementType,
  responsive: bool,
};

interface Props<T extends Spannable = Spannable>
  extends InferProps<typeof propTypes> {
  item: NoSpanItem<T>;
}

NestedItem.propTypes = propTypes;

export default NestedItem;
