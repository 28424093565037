import { CheckboxProps } from '@chakra-ui/react';

import utils from 'core/utils/utils';

import { FwControllableStateBuilder } from './useFwControllableState.helper';

// // no display values for checkboxes
// const defaultDisplayToDto = undefined;

// convert content values (e.g. Chakra UI checkbox props) to dto values (e.g. 'true'/'false'/'')
const defaultContentToDto = (contentValue: Partial<CheckboxProps>) => {
  const checked = contentValue?.isChecked;
  const indeterminate = contentValue?.isIndeterminate;

  return indeterminate ? '' : checked ? 'true' : 'false';
};

// convert dto values (e.g. 'true'/'false'/'') to default content values (e.g. Chakra UI checkbox props)
const defaultDtoToContent = (
  value: 'true' | 'false' | ''
): Partial<CheckboxProps> => {
  return value
    ? { isChecked: utils.getBoxChecked(value), isIndeterminate: false }
    : { isIndeterminate: true };
};

// // no display values for checkboxes
// const defaultContentToDisplay = undefined;

// // no display values for checkboxes
// const defaultDisplayToContent = undefined;

// no forced content for checkboxes?
const buildStateFromForcedContent: FwControllableStateBuilder<
  Partial<CheckboxProps>
> = undefined;

const buildStateFromContent: FwControllableStateBuilder<
  Partial<CheckboxProps>
> = ({ value }) => {
  return {
    dtoValue: defaultContentToDto(value),
    contentValue: value,
    shouldNotify: true,
  };
};

// no display values for checkboxes
const buildStateFromDisplay: FwControllableStateBuilder<
  Partial<CheckboxProps>,
  string
> = undefined;

const buildStateFromDto: FwControllableStateBuilder<
  Partial<CheckboxProps>,
  'true' | 'false' | ''
> = ({
  value,
  //oldState,
  params: { allowIndeterminate },
}) => {
  const newDtoValue = allowIndeterminate ? value || '' : value || 'false';

  return {
    dtoValue: newDtoValue,
    contentValue: defaultDtoToContent(newDtoValue),
    shouldNotify: false,
  };
};

export {
  buildStateFromForcedContent,
  buildStateFromContent,
  buildStateFromDisplay,
  buildStateFromDto,
};
