import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';

import baseApi, { CacheRoute, validateStatusHelper } from 'api/baseApi';

const cacheFileName = 'cache.json';

const swHeaderPrefix = 'X-Fwsw';
const swStoreHeader = `${swHeaderPrefix}-Store`;
const swUrlHeader = `${swHeaderPrefix}-Url`;

const swStoreValue = 'cache';

const cacheRouteToFetchParams = (
  cacheRoute: CacheRoute,
  headers?: HeadersInit
): { endpoint: string; params: RequestInit } => {
  const isString = typeof cacheRoute === 'string';

  // build endpoint path
  const endpoint = isString ? cacheRoute : cacheRoute.origin;

  // build params
  let params = isString
    ? undefined
    : cacheRoute.target
    ? {
        ...cacheRoute.params,
        headers: {
          [swUrlHeader]: cacheRoute.target,
          ...cacheRoute.params?.headers,
        },
      }
    : cacheRoute.params;

  // pass additional headers
  params = {
    ...params,
    headers: {
      ...headers,
      ...params?.headers,
    },
  };

  return { endpoint, params };
};

const fetchCacheRoutes = async (
  authedfetch: boolean,
  cacheRoutes: CacheRoute[],
  headers?: HeadersInit
) => {
  let success = true;

  // todo refactor with executeScript or remove axios altogether (see #978)
  const fetchEngine = authedfetch ? baseApi.get : fetch;

  for (let i = 0; i < cacheRoutes.length; i++) {
    const route = cacheRoutes[i];
    const { endpoint, params } = cacheRouteToFetchParams(route, headers);
    const response = await fetchEngine(endpoint, params);

    if (!validateStatusHelper(response?.status)) {
      // request failed
      success = false;
      break;
    }
  }

  return success;
};

const afterFetchResponse = (
  response: Response | AxiosResponse,
  onSuccess?: () => void,
  onFail?: () => void
) => {
  if (validateStatusHelper(response?.status)) {
    onSuccess?.();
  } else {
    onFail?.();
  }
};

const showErrorModalState = (
  t: TFunction,
  message: string,
  onCancel: () => void
) => ({
  showModal: true,
  modalOptions: {
    content: t(message),
    cancelName: t('Ok'),
    onCancel,
  },
});

export {
  afterFetchResponse,
  cacheFileName,
  cacheRouteToFetchParams,
  fetchCacheRoutes,
  showErrorModalState,
  swStoreHeader,
  swUrlHeader,
  swStoreValue,
};
