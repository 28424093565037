import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { dateFormats, jsDateFromString } from 'core/utils/date';

import { dtoSeparator } from '../select/useFwControllableState.helper';

const PERIOD_TYPE = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',
};
const { date, datetime, time } = FIELD_TYPE;

const getIsoDateFormat = (type: string) => {
  return type === date
    ? dateFormats.isoDate
    : type === time
    ? dateFormats.isoTime
    : dateFormats.isoShort;
};

const getDateFormat = (type: string) => {
  return type === date
    ? dateFormats.date
    : type === time
    ? dateFormats.time
    : dateFormats.datetime;
};

const isValidDateString = (dateString: string, type: string) => {
  let isValid = false;
  let validatedDates: Date[];

  if (dateString && _.includes([date, datetime, time], type)) {
    const dateFormat = getIsoDateFormat(type);
    const dateEntries = dateString.split(dtoSeparator);
    validatedDates = _.map(dateEntries, (dateEntry) =>
      jsDateFromString(dateEntry, dateFormat)
    );
    isValid = !_.some(validatedDates, (dateEntry) => !dateEntry);
  }

  return { isValid, validatedDates };
};

export { PERIOD_TYPE, getIsoDateFormat, getDateFormat, isValidDateString };
