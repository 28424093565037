import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';

import { readSettings } from 'core/utils/storage';

import { BaseApiParams } from './baseApi';

const isValidFunc = <R, D>(
  func: (...args: Array<D | BaseApiParams>) => AxiosPromise<R>,
  data: Array<D>
) => func && typeof func === 'function' && func.length === data.length + 1;

function useApi<R, D>(
  func: (...args: Array<D | BaseApiParams>) => AxiosPromise<R>,
  data: Array<D>,
  defaultValue = undefined,
  xhrParams?: BaseApiParams
) {
  // mark xhr params as local when settings prevent network access
  const settingsRef = useRef(readSettings());
  const newXhrParams = useMemo(
    () =>
      settingsRef.current?.localMode
        ? { ...xhrParams, localMode: settingsRef.current?.localMode }
        : xhrParams,
    [xhrParams]
  );

  // todo use generic instead of any
  const [state, setState] = useState<{
    fetched?: R;
    pending: boolean;
    response?: Partial<AxiosResponse>;
  }>({
    response: defaultValue ? { data: defaultValue } : undefined,
    pending: isValidFunc(func, data) ? true : false,
  });
  const { fetched, response, pending } = state;

  useEffect(() => {
    let cancelled = false;
    const source = axios.CancelToken.source();

    if (isValidFunc(func, data)) {
      // console.log(func, data, 'call');
      setState({ pending: true });
      const promise = data
        ? func(...data, { cancelToken: source.token, ...newXhrParams })
        : func({ cancelToken: source.token, ...newXhrParams });

      promise.then((promiseResponse) => {
        if (!cancelled) {
          if (promiseResponse) {
            // success
            setState({
              pending: false,
              fetched:
                promiseResponse?.status === 200
                  ? promiseResponse.data
                  : undefined,
              response: promiseResponse,
            });
          } else {
            // error
            setState({ pending: false });
          }
        }
      });
    }

    return () => {
      cancelled = true;
      source.cancel('');
    };
  }, [func, data, newXhrParams]);

  return { fetched, pending, response };
}

export { useApi };
