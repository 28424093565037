import { Area, AreaContent } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const areaAS: AdminStructure<Area & AreaContent> = [
  // area
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  // areaContent
  {
    key: 'areaContent.logo',
    name: 'Logo',
    type: FIELD_TYPE.photo,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'areaContent.background',
    name: 'Background',
    type: FIELD_TYPE.photo,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

export { areaAS };
