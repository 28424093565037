import { bool, InferProps, node } from 'prop-types';

import { ctor } from '../helper';
import { stylePT } from './common';

const fwLabelPT = {
  ...stylePT,
  basic: bool,
  children: node,
  error: bool,
  primary: bool,
  rounded: bool,
  tooltip: node,
};

type FwLabelPT = InferProps<typeof fwLabelPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwLabelProps extends FwLabelPT {}
class FwLabelProps implements FwLabelPT {
  constructor(props?: FwLabelPT) {
    ctor(this, props);
  }
}

export { fwLabelPT, FwLabelProps };
