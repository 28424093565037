import React, { useState, useEffect, FC } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';

import areaApi from 'api/area/areaApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Area } from 'core/model';
import { createFilename } from 'core/utils/form/getValueChange';

import AreaLayout from './AreaLayout';

const AreaLayoutContainer: FC<RouteChildrenProps> = ({ match }) => {
  const existingArea =
    match && match.params && (match.params as { name: string }).name;

  const [getArgs] = useState([existingArea]);
  const { fetched: fetchedArea, pending: pendingArea } = useApi(
    existingArea ? areaApi.get : undefined,
    getArgs
  );

  const [area, setArea] = useState(
    existingArea
      ? undefined
      : new Area({
          key: '',
          areaContent: {
            logo: '',
            background: '',
          },
        })
  );

  const handleSetKey = (key: string) => {
    const newArea = { ...area, key: key };
    // set in state
    setArea(newArea);
  };

  const jsonStringImage = (file: string) =>
    JSON.stringify({ filename: createFilename(file), path: file });

  useEffect(() => {
    if (!pendingArea && fetchedArea) {
      const areaContent = fetchedArea.area.areaContent;
      if (areaContent.background) {
        areaContent.background = jsonStringImage(areaContent.background);
      }
      if (areaContent.logo) {
        areaContent.logo = jsonStringImage(areaContent.logo);
      }

      setArea(fetchedArea.area);
    }
  }, [fetchedArea, pendingArea]);

  // render
  return area ? (
    <AreaLayout area={area} handleSetKey={handleSetKey} />
  ) : (
    <FwSpinner />
  );
};

export default withRouter(AreaLayoutContainer);
