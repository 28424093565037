import _ from 'lodash';
import { bool, InferProps, node } from 'prop-types';
import React, { FC, useEffect, useRef, useState } from 'react';

import { useApi } from 'api';
import batchTemplateApi from 'api/batch-template/batchTemplateApi';
import templateApi from 'api/template/templateApi';
import { FwSpinner } from 'components/base/elements';
import { BatchTemplate, Template } from 'core/model';
import {
  readProcesses,
  readTemplates,
  storeProcesses,
  storeTemplates,
} from 'core/utils/storage';

import { FwTemplatesContext } from './FwTemplatesContext';

const FwTemplatesProvider: FC<Props> = ({ children, lazy }) => {
  const processesRef = useRef(readProcesses());
  const templatesRef = useRef(readTemplates());

  const [processes, setProcesses] = useState<BatchTemplate[]>(
    processesRef.current
  );
  const [templates, setTemplates] = useState<Template[]>(templatesRef.current);

  const batchTemplateArgs = useRef([]);
  const { fetched: fetchedBatchTemplates, pending: pendingBatchTemplates } =
    useApi(
      !lazy && _.isEmpty(processesRef.current) ? batchTemplateApi.getAll : null,
      batchTemplateArgs.current
    );

  const templatesArgs = useRef([]);
  const { fetched: fetchedTemplates, pending: pendingTemplates } = useApi(
    !lazy && _.isEmpty(templatesRef.current) ? templateApi.getAll : null,
    templatesArgs.current
  );

  useEffect(() => {
    if (
      !pendingBatchTemplates &&
      fetchedBatchTemplates &&
      !pendingTemplates &&
      fetchedTemplates
    ) {
      storeProcesses(fetchedBatchTemplates.batchTemplates);
      setProcesses(fetchedBatchTemplates.batchTemplates);

      storeTemplates(fetchedTemplates.templates);
      setTemplates(fetchedTemplates.templates);
    }
  }, [
    fetchedBatchTemplates,
    fetchedTemplates,
    pendingBatchTemplates,
    pendingTemplates,
  ]);

  return pendingBatchTemplates ||
    (!lazy && !processes) ||
    pendingTemplates ||
    (!lazy && !templates) ? (
    <FwSpinner />
  ) : (
    <FwTemplatesContext.Provider value={{ processes, templates }}>
      {children}
    </FwTemplatesContext.Provider>
  );
};

const propTypes = { children: node, lazy: bool };

type Props = InferProps<typeof propTypes>;

FwTemplatesProvider.propTypes = propTypes;

export { FwTemplatesProvider };
