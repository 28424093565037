import { PositionProps, chakra } from '@chakra-ui/system';
import { Label, graphlib, layout } from '@dagrejs/dagre';
import _ from 'lodash';
import React, {
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FwArticle, FwSegment, FwTile } from 'components/base';
import { fwFlowPT, FwFlowProps, Node } from 'core/model';

import FwButton from '../button/FwButton';
import {
  computeNextScale,
  getNodeWidth,
  nodeHeight,
  nodeWidth,
} from './FwFlow.helpers';

const FwFlow = ({ nodes, edges }: FwFlowProps) => {
  const { t } = useTranslation();

  // memo
  const graph = useMemo(() => {
    // initialize graph
    const newGraph = new graphlib.Graph();
    newGraph.setGraph({});
    newGraph.setDefaultEdgeLabel(function () {
      return {};
    });

    // add nodes
    _.forEach(nodes, (node) => {
      const nodeObj = typeof node === 'string' ? new Node({ id: node }) : node;

      newGraph.setNode(nodeObj.id, {
        ...nodeObj,
        label: nodeObj.title,
        width: nodeHeight,
        height: getNodeWidth(nodeObj),
      });
    });

    // add edges
    _.forEach(edges, (edge) => {
      newGraph.setEdge(edge[0], edge[1]);
    });

    // build graph
    if (nodes?.length) {
      layout(newGraph);
    }

    return newGraph;
  }, [edges, nodes]);

  // refs
  const isDraggingRef = useRef(false);
  const pageXYREf = useRef({ x: 0, y: 0 });

  // state
  const [state, setState] = useState({ scale: 1, translation: { x: 0, y: 0 } });

  useEffect(() => {
    setState({ scale: 1, translation: { x: 0, y: 0 } });
  }, [edges, nodes]);

  // callbacks
  const Print = useCallback(() => {
    window.print();
  }, []);

  const ZoomIn = useCallback(() => {
    setState((s) => ({ ...s, scale: computeNextScale(s?.scale, true) }));
  }, []);

  const ZoomOut = useCallback(() => {
    setState((s) => ({ ...s, scale: computeNextScale(s?.scale, false) }));
  }, []);

  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      isDraggingRef.current = true;
      pageXYREf.current = {
        x: e.pageX - state.translation.x * state.scale,
        y: e.pageY - state.translation.y * state.scale,
      };
    },
    [state]
  );

  const onMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (isDraggingRef.current) {
      setState((s) => ({
        ...s,
        translation: {
          x: (e.pageX - pageXYREf.current.x) / s.scale,
          y: (e.pageY - pageXYREf.current.y) / s.scale,
        },
      }));
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  const onMouseUp = useCallback(() => {
    isDraggingRef.current = false;
  }, []);

  // render
  const { scale, translation } = state;

  const containerStyle = {
    position: 'relative' as PositionProps['position'],
    background: 'blue',
    h: '100%',
    w: '100%',
    minHeight: 'calc(100vh - 150px)',
    overflow: 'hidden',
  };

  const whiteboardStyle = {
    position: 'absolute' as PositionProps['position'],
    background: 'fw-accent.100',
    h: `calc((1 / ${scale}) * 100%)`,
    w: `calc((1 / ${scale}) * 100%)`,
    left: '0',
    top: '0',
    transform: `scale(${scale})`,
    transformOrigin: '0 0',
    cursor: 'grab',
  };

  const panelStyle = {
    position: 'absolute' as PositionProps['position'],
    minHeight: `100%`,
    minWidth: `100%`,
    left: '0',
    top: '0',
    transform: `translate(${translation.x}px, ${translation.y}px)`,
    sx: {
      '& p': {
        fontSize: '8px !important',
      },
    },
  };

  const panelInteractions = {
    onMouseDown,
    onMouseMove,
    onMouseLeave,
    onMouseUp,
  };

  return (
    <chakra.div {...containerStyle}>
      <chakra.div {...whiteboardStyle} {...panelInteractions}>
        <chakra.svg
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          left="0"
          onMouseDown={(e) => e.preventDefault()}
        >
          <defs>
            <marker
              id="dot"
              viewBox="0 0 10 10"
              refX="7"
              refY="5"
              markerWidth="5"
              markerHeight="5"
            >
              <circle cx="5" cy="5" r="5" fill="fw-accent.700" />
            </marker>
          </defs>
          {graph.edges().map((edgeKey, idx) => {
            const edge = graph.edge(edgeKey);
            const points = _.filter(
              edge.points,
              (point) => !_.isNaN(point.x) && !_.isNaN(point.y)
            );
            const lines = [];

            for (let i = 1; i < points.length; i++) {
              lines.push(
                <chakra.line
                  key={i}
                  x1={points[i - 1].y + nodeWidth / 2 + translation.x}
                  y1={points[i - 1].x + nodeHeight / 2 + translation.y}
                  x2={points[i].y + nodeWidth / 2 + translation.x}
                  y2={points[i].x + nodeHeight / 2 + translation.y}
                  stroke="fw-accent.700"
                  strokeWidth="1"
                  onMouseDown={(e) => e.preventDefault()}
                  markerEnd={i === points.length - 1 ? 'url(#dot)' : undefined}
                />
              );
            }

            return points.length >= 2 ? (
              <Fragment key={idx}>{lines}</Fragment>
            ) : null;
          })}
        </chakra.svg>
        <chakra.div {...panelStyle} onMouseDown={(e) => e.preventDefault()}>
          {graph.nodes().map((nodeKey, idx) => {
            const node = graph.node(nodeKey) as unknown as Node & Label;
            const ndWidth = getNodeWidth(node);
            const tile = (
              <FwTile small leftIcon={node.icon}>
                {!_.isNil(node.title) ? t(node.title) : node.id}
              </FwTile>
            );

            return (
              <chakra.div
                key={idx}
                h={`${nodeHeight}`}
                maxW={`${ndWidth}`}
                sx={{ '& > a > div': { maxW: `${ndWidth}` } }}
                position="absolute"
                left={`calc(${node.y}px + ${(nodeWidth - ndWidth) / 2}px)`}
                top={`calc(${node.x}px)`}
                onMouseDown={(e) => e.preventDefault()}
              >
                {tile}
                {node.labels?.some((lbl) => lbl) ? (
                  <FwSegment frosted>
                    <FwArticle fluid truncated content={node.labels} />
                  </FwSegment>
                ) : null}
              </chakra.div>
            );
          })}
        </chakra.div>
      </chakra.div>
      <chakra.div position="absolute" right="0" top="0">
        <FwButton leftIcon="RiZoomInLine" onClick={ZoomIn} />
        <FwButton leftIcon="RiZoomOutLine" onClick={ZoomOut} />
      </chakra.div>
      <chakra.div position="absolute" right="0" bottom="0">
        <FwButton leftIcon="RiPrinterFill" onClick={Print} />
      </chakra.div>
    </chakra.div>
  );
};

FwFlow.propTypes = fwFlowPT;

export default FwFlow;
