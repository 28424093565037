import { Box } from '@chakra-ui/react';
import { func, InferProps } from 'prop-types';
import React, { FC } from 'react';

const FwDimmer: FC<Props> = ({ onClick, ...props }) => {
  const dimmerStyle = {
    top: '0',
    left: '0',
    w: '100%',
    h: '100%',
    bg: 'blackAlpha.600',
  };

  return (
    <Box {...dimmerStyle} position="absolute" onClick={onClick} {...props} />
  );
};

const propTypes = { onClick: func };

export type Props = InferProps<typeof propTypes>;

FwDimmer.propTypes = propTypes;

export default FwDimmer;
