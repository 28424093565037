//
// file should be split and each function be moved to a new core/logic structure?
//
import _ from 'lodash';

import { Edit } from 'core/model';

//
// visible
// visible = true -> visible
// visible = false -> "visibility: hidden"
// visible = null -> "display: none"
// default -> visible
//

type Displayable = { visible?: boolean };

// check if item display is "none" (visible = null)
const isNoRenderItem = <T extends Displayable>(element: T) =>
  _.isNull(element.visible);

// get all items where display is "none" (visible = null)
const getNoRenderItems = <T extends Displayable>(elements: T[]) =>
  _.filter(elements, isNoRenderItem);

// get all items where display is not "none" (i.e. where item is visible or has "visibility: hidden")
const getRenderedItems = <T extends Displayable>(elements: T[]) =>
  _.filter(elements, (e) => !isNoRenderItem(e));

// update existing array by removing items where display is "none" (visible = null)
const removeNoRenderItems = <T extends Displayable>(elements: T[]) =>
  _.remove(elements, isNoRenderItem);

// force the returned value to be either an array or nil (i.e. null or undefined)
function toArray<T>(value: T | T[]): T[];
function toArray<T>(value: T | T[] | T[][]): T[] | T[][] {
  return _.isNil(value) || Array.isArray(value) ? value : [value];
}

// map array to object utility for Edit dto
const arrayToObject = <T extends Edit>(array: T[]) => {
  return _.reduce(
    array,
    (accumulator, item) => {
      if (item) {
        accumulator[item.key] = item.value;
      }

      return accumulator;
    },
    {}
  );
};

export {
  arrayToObject,
  getNoRenderItems,
  getRenderedItems,
  isNoRenderItem,
  removeNoRenderItems,
  toArray,
};
