import _ from 'lodash';

export type QueryCriteria = {
  indexPage: number;
  searchAllByValue: string;
  searchByInput: object;
  orderByField: string;
  orderByFieldDesc: string;
};

export const getQueryString = (criteria: QueryCriteria) => {
  const query = ['?'];

  if (!_.isEmpty(criteria) && !_.isEmpty(criteria.searchByInput)) {
    _.forOwn(criteria.searchByInput, (value: any, key) => {
      if (!_.isNil(key) && !_.isNil(value)) {
        let qVal = '';

        if (Array.isArray(value)) {
          qVal = `&values=${encodeURIComponent(value.join('|'))}`;
        } else {
          qVal = `&values=${encodeURIComponent(value)}`;
        }

        const qID = `&fields=${encodeURIComponent(key)}`;
        const queryString = `${qID}${qVal}`;

        !_.isNil(criteria.searchByInput[key]) && query.push(queryString);
      }
    });
  }

  criteria.orderByField &&
    query.push('&orderBy=', encodeURIComponent(criteria.orderByField));
  criteria.orderByFieldDesc &&
    query.push('&orderByDesc=', encodeURIComponent(criteria.orderByFieldDesc));
  criteria.searchAllByValue &&
    query.push('&searchAll=', encodeURIComponent(criteria.searchAllByValue));
  query.push('&indexPage=', `${criteria.indexPage || 1}`);

  return `${query.join('')}`;
};

export const getFileNameBuildKeysQuery = (downloadFileName: string[]) =>
  downloadFileName && downloadFileName.length > 0
    ? downloadFileName
        .map((n) => `&fileNameBuildKeys=${encodeURIComponent(n)}`)
        .join('')
    : '';

export const getLimitPageByCollectionRowQuery = (collectionKey: string) =>
  collectionKey
    ? `&limitPageByCollectionRow=${encodeURIComponent(collectionKey)}`
    : '';
