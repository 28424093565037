import _ from 'lodash';

import { Column, FwInputProps, Option } from 'core/model';

import { removeProperties } from './helper';

const unmappedColumnProps = [
  'additionalData',
  'dropdownID',
  'operatorNK',
  'position',
] as const;

const mapColumnToInput = (
  column: Column,
  directInputProps?: FwInputProps
): FwInputProps => {
  const { columnID, key, name, dropdown, ...rest } =
    removeProperties(column, unmappedColumnProps) || {};

  return {
    // mapped props
    key: columnID || key || key,
    label: name,
    name: key,
    options: dropdown && _.map(dropdown.options, (o) => new Option(o)),
    // unchanged props
    ...rest,
    ...directInputProps,
  };
};

export { mapColumnToInput };
