import React, { useEffect, useRef, useState } from 'react';

import {
  getAllTableCache,
  readTemplates,
  readProcesses,
  resetAllTableCache,
  resetProcesses,
  resetTemplates,
} from 'core/utils/storage';

import Settings from './Settings';

const SettingsContainer = () => {
  const tableCacheRef = useRef<string>();
  const templatesCacheRef = useRef<string>();

  const [loadingCache, setLoadingCache] = useState(true);

  useEffect(() => {
    if (loadingCache) {
      tableCacheRef.current = JSON.stringify(getAllTableCache());
      templatesCacheRef.current = JSON.stringify({
        templates: readTemplates()?.length,
        processes: readProcesses()?.length,
      });
      setLoadingCache(false);
    }
  }, [loadingCache]);

  // const handleChangeBgPos = (e, { value }) => {
  //   switch (value) {
  //     case 'top':
  //       topBg();
  //       break;
  //     case 'bottom':
  //       bottomBg();
  //       break;
  //     case 'left':
  //       leftBg();
  //       break;
  //     case 'right':
  //       rightBg();
  //       break;
  //     default:
  //       centerBg();
  //   }
  // };

  // const toggleRouteHelper = (isHidden, path) => {
  //   if (isHidden) {
  //     settingsActionCreators.showRoute(path);
  //   } else {
  //     settingsActionCreators.hideRoute(path);
  //   }
  // };

  // const toggleRoute = (route) =>
  //   toggleRouteHelper(
  //     settings.hiddenRoutes.some((hr) => hr === route.path),
  //     route.path
  //   );

  // const toggleBackground = () =>
  //   background.customBackground ? removeBg() : addBg();

  const handleEraseTableCache = () => {
    resetAllTableCache();
    setLoadingCache(true);
  };

  const handleEraseTemplatesCache = () => {
    resetProcesses();
    resetTemplates();
    setLoadingCache(true);
  };

  return (
    <Settings
      eraseTableCache={handleEraseTableCache}
      eraseTemplatesCache={handleEraseTemplatesCache}
      loadingCache={loadingCache}
      tableCache={tableCacheRef.current}
      templatesCache={templatesCacheRef.current}
    />
  );
};

export default SettingsContainer;
