import _ from 'lodash';
import { InferProps } from 'prop-types';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FwFlow, FwInput } from 'components/base';
import {
  BatchTemplate,
  FwFlowProps,
  Input,
  Option,
  Rule,
  Template,
} from 'core/model';
import { readProcesses, readTemplates } from 'core/utils/storage';
import utils from 'core/utils/utils';

import {
  SelectableFlow,
  mergeFlowCharts,
  parseBatchTemplateToFlowChart,
  parseRuleToFlowChart,
  Ts,
} from './FlowParser.helpers';

declare const window: Window & {
  ts: Ts;
};

const FwFlowParser = () => {
  const { t } = useTranslation();

  // initialisation
  const [ready, setReady] = useState(!!window.ts);
  const onScriptLoad = useCallback(() => setReady(true), []);

  useEffect(() => {
    if (!ready && !window.ts) {
      const script = document.createElement('script');
      script.onload = onScriptLoad;
      script.type = 'text/javascript';
      script.src = 'static/js/typescript.min.js';

      document.head.appendChild(script);
    }
  }, [ready, onScriptLoad]);

  // memo
  const templates = useMemo<Template[]>(() => {
    return readTemplates();
  }, []);
  const fields = useMemo<Input[]>(() => {
    return utils.getFieldsFromTemplates(templates);
  }, [templates]);
  const rules = useMemo<Rule[]>(() => {
    return utils.getFlatSubArray(templates, [(t: Template) => t.rules]);
  }, [templates]);
  const batchTemplates = useMemo(() => {
    return readProcesses();
  }, []);
  const options = useMemo(() => {
    return [
      new Option({
        key: 'batchTemplates',
        value: 'batchTemplates',
        text: t('batchTemplates'),
        items: batchTemplates.map(
          ({ batchTemplateId, name }) =>
            new Option({
              key: batchTemplateId,
              value: batchTemplateId,
              text: name,
            })
        ),
      }),
      new Option({
        key: 'rules',
        value: 'rules',
        text: t('functions'),
        items: _.uniqBy(
          rules.map(
            ({ event }) =>
              new Option({
                key: `${event?.eventID}`,
                value: `${event?.eventID}`,
                text: `${event?.action} - ${event?.type} - ${event?.key}`,
              })
          ),
          (tt) => tt.text
        ),
      }),
    ];
  }, [batchTemplates, rules, t]);
  const flows: SelectableFlow[] = useMemo(() => {
    const ruleFlowProps: SelectableFlow[] = [];
    const execFlowProps: SelectableFlow[] = [];

    if (ready) {
      rules.forEach((rule: Rule) => {
        ruleFlowProps.push({
          key: `${rule?.event.eventID}`,
          source: rule,
          flowProps: parseRuleToFlowChart(window.ts, rule, fields),
        });
      });
      batchTemplates.forEach((batchTemplate: BatchTemplate) => {
        execFlowProps.push({
          key: batchTemplate?.batchTemplateId,
          source: batchTemplate,
          flowProps: parseBatchTemplateToFlowChart(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            window.ts,
            batchTemplate
          ),
        });
      });
    }

    return [...ruleFlowProps, ...execFlowProps];
  }, [batchTemplates, fields, ready, rules]);

  // state
  const [state, setState] = useState<FwFlowProps>(
    mergeFlowCharts(flows?.map((flow) => flow.flowProps))
  );

  // callbacks
  const handleChange = useCallback(
    (
      _e: ChangeEvent,
      data: { name: string; value: string | object; fillData?: object }
    ) => {
      const selectedProcess = data?.value as string;
      const selectedFlows = flows.filter(
        (flow) => !selectedProcess || flow.key === selectedProcess
      );

      console.log(selectedFlows?.map((flow) => flow.source));

      setState(mergeFlowCharts(selectedFlows?.map((flow) => flow.flowProps)));
    },
    [flows]
  );

  // render
  return ready ? (
    <>
      <FwInput
        clearable
        type="SELECT"
        options={options}
        onChange={handleChange}
      />
      <FwFlow {...state} />
    </>
  ) : null;
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwFlowParser.propTypes = propTypes;

export default FwFlowParser;
