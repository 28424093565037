import _ from 'lodash';

import { FwCategoryProps, FwItemProps, Option } from 'core/model';

const slideUp = {
  enter: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      duration: 0.15,
      ease: [0, 0, 0.2, 1],
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.05,
      ease: [0.4, 0, 1, 1],
      transitionEnd: {
        x: 0,
        y: 8,
      },
    },
  },
};

const getOptions = (options: Option[]) => {
  return options && Array.isArray(options) ? options : [];
};

const searchItems = (searchQuery: string, items: Option[]) => {
  return _.filter(items, (i) => {
    return _.deburr(i.text.toLowerCase()).includes(
      _.deburr(searchQuery.toLowerCase())
    );
  });
};

const searchOptions = (searchQuery: string, options: Option[]) => {
  const result = [];

  // if search is valid
  if (searchQuery && searchQuery.length > 0) {
    const catOptions = _.filter(options, (o) => o.items && o.items.length > 0);
    const noCatOptions = _.filter(
      options,
      (o) => !(o.items && o.items.length > 0)
    );

    // for each category
    _.forEach(catOptions, (item) => {
      const { items } = item;

      // search options
      const searchedOptions = searchItems(searchQuery, items);

      // add to result of search
      if (searchedOptions && searchedOptions.length > 0) {
        result.push({ ...item, items: searchedOptions });
      }
    });

    // no categories, search options directly
    result.push(...searchItems(searchQuery, noCatOptions));
  }
  // else return all options
  else {
    result.push(...options);
  }

  return result;
};

const getSearchedOptions = (searchQuery: string, items: Option[]) => {
  return getOptions(searchOptions(searchQuery, items));
};

// const showClearAction = (
//   clearable: boolean,
//   dtoValue: string,
//   searchable = false,
//   editable = false,
//   open = false,
//   allowOpenClear = true
// ) => {
//   return (editable || searchable) && open
//     ? false
//     : !allowOpenClear && open
//     ? false
//     : clearable && dtoValue;
// };

const passActiveAndOnClickProps = (
  dropdownItems: (FwCategoryProps | FwItemProps)[],
  selectedItemKeys: string[],
  handleClickItem: (e: React.MouseEvent, data: FwItemProps) => void
) => {
  _.forEach(dropdownItems, (di) => {
    if (di instanceof FwCategoryProps) {
      passActiveAndOnClickProps(di.items, selectedItemKeys, handleClickItem);
    } else {
      di.active = _.includes(selectedItemKeys, di.itemKey) ? true : undefined;
      di.onClick = (e: React.MouseEvent) => handleClickItem(e, di);
    }
  });
};

export {
  slideUp,
  getOptions,
  getSearchedOptions,
  passActiveAndOnClickProps,
  // showClearAction,
};
