import _ from 'lodash';

import { FwStore } from 'components/base';
import { Rule } from 'core/model';
import { ACTION, CONTENT_TYPE, SUB_CONTENT_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

import { applyScript } from '../../form/components/template/helpers/executeScript';

const { onChange, show, readonly } = ACTION;
const { category } = SUB_CONTENT_TYPE;
const { field, section } = CONTENT_TYPE;
const VISIBLE_PROP = 'visible';
const READONLY_PROP = 'isReadOnly';

const ruleHandler = (updatedElements) => (rule: Rule, docData, value, key) => {
  const updatedElementsByAction = updatedElements[rule.script.action];
  const { fieldKey = undefined, catKey = undefined } =
    rule.script.type === category ? utils.splitKeyAutoHideForCategory(key) : {};

  const old = updatedElementsByAction[rule.script.type] || [];
  const newArray = [{ key: catKey || key, value }];
  const union = _.union(fieldKey ? old[fieldKey] : old, newArray);

  updatedElementsByAction[rule.script.type] = fieldKey
    ? { ...old, [fieldKey]: union }
    : union;
};

const setProp = (prop, array, updatedElementsByType) => {
  _.forEach(
    _.filter(array, (i) => _.some(updatedElementsByType, { key: i.key })),
    (item) => {
      const { value } = _.find(updatedElementsByType, { key: item.key });
      item[prop] = value;
    }
  );
};

// const setSubArrayVisible = (
//   contentType,
//   contentProperty,
//   array,
//   updatedElements
// ) => {
//   _.forEach(array, (item) => {
//     setProp(
//       vISIBLE_PROP,
//       item[contentProperty],
//       updatedElements[contentType]
//     );
//   });

//   return array;
// };

const updateVisibleCategories = (inputs, updatedCats) => {
  _.forEach(inputs, (i) => {
    if (
      updatedCats &&
      updatedCats[i.key] &&
      i.dropdown &&
      _.some(i.dropdown.options, (o) => o.items)
    ) {
      setProp(VISIBLE_PROP, i.dropdown.options, updatedCats[i.key]);
    }
  });
};

const getVisibleElements = (
  doc,
  docData,
  store: FwStore,
  updatedKeys?,
  currentVisibleElements?,
  indexItemChange?
) => {
  const { rules, steps, modals, clickables } = doc.template;
  const updatedSteps = (currentVisibleElements || {}).steps || steps;
  const updatedModalInputs =
    (currentVisibleElements || {}).modalInputs ||
    _.union(
      utils.getFlatSubArray(modals, [(m) => m.inputs], true),
      utils.getFlatSubArray(
        clickables,
        [(c) => (c.modal ? c.modal.inputs : undefined)],
        true
      )
    );

  const updatedElements = { [show]: {}, [readonly]: {} };

  const autoShowHideReadOnlyRules = rules.filter(
    (r) =>
      _.includes([show, readonly], r.script.action) &&
      (updatedKeys ? r.event.action === onChange : true) &&
      (updatedKeys ? _.includes(updatedKeys, r.event.key) : true)
  );

  applyScript(autoShowHideReadOnlyRules, {
    doc,
    data: docData,
    handler: ruleHandler(updatedElements),
    indexItemChange,
    store,
  });

  _.forEach(updatedSteps, (step) => {
    // update sections
    setProp(VISIBLE_PROP, step.contents, updatedElements[show][section]);

    // update inputs in sections
    _.forEach(step.contents, (content) => {
      updateInputs(content.fields, updatedElements);
    });
  });

  // update inputs in modals
  updateInputs(updatedModalInputs, updatedElements);

  return currentVisibleElements || { steps, modalInputs: updatedModalInputs };
};

const updateInputs = (inputs, updatedElements) => {
  updateInputProps(inputs, updatedElements);

  // update collection sub-fields
  _.forEach(
    inputs.filter((f) => f.subInputs.length > 0),
    (f) => {
      updateInputProps(f.subInputs, updatedElements);
    }
  );
};

const updateInputProps = (inputs, updatedElements) => {
  // update visible and isReadOnly props
  setProp(VISIBLE_PROP, inputs, updatedElements[show][field]);
  setProp(READONLY_PROP, inputs, updatedElements[readonly][field]);

  // update dropdown categories visibility
  updateVisibleCategories(inputs, updatedElements[show][category]);
};

export default getVisibleElements;
