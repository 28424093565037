import _ from 'lodash';
import { InferProps, number } from 'prop-types';

import { ctor } from 'core/model/helper';

const CompressImagePropTypes = {
  quality: number,
  factor: number,
  maxDimension: number,
  minDimension: number,
};

export type CompressImageProps = InferProps<typeof CompressImagePropTypes>;

export interface CompressionOptions {
  allowCompression?: boolean;
  props?: CompressImageProps;
}
export class CompressionOptions implements CompressionOptions {
  constructor(props?: CompressionOptions) {
    ctor(this, props);
  }
}

export const DEFAULT_COMPRESSION_OPTIONS: CompressImageProps = {
  quality: 0.99,
  factor: 0.8,
  maxDimension: 3000,
  minDimension: 500,
};

export const compressImage = (
  callback: (data: string) => void,
  imageData: string,
  compressionOptions?: CompressImageProps
) => {
  if (!imageData) {
    // cannot compress empty value, just return it
    callback(imageData);
  } else {
    // otherwise, compress and return compressed value
    const { quality, factor, maxDimension, minDimension } = {
      // fall back to default compression options
      ...DEFAULT_COMPRESSION_OPTIONS,
      // override with valid options from compressionOptions
      ..._.pickBy(compressionOptions, _.identity),
    };

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();

    // define behaviour for when image is loaded
    image.onload = () => {
      let width = image.width;
      let height = image.height;

      const isSmall = width < minDimension || height < minDimension;

      // don't resize small images
      if (!isSmall) {
        width *= factor;
        height *= factor;

        // get orientation of image to determine larger dimension
        const isLandscape = width > height;

        // cap image size if resized image is too big
        if ((isLandscape ? width : height) > maxDimension) {
          const cappedWidth = isLandscape
            ? maxDimension
            : width * (maxDimension / height);
          const cappedHeight = isLandscape
            ? height * (maxDimension / width)
            : maxDimension;
          width = cappedWidth;
          height = cappedHeight;
        }
      }
      // resize canvas and draw image
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // convert to jpeg to compress
      // small images are not further compressed
      const compressedImageDataUrl = canvas.toDataURL(
        'image/jpeg',
        isSmall ? 1 : quality
      );

      // call callback function with base64 string
      callback(compressedImageDataUrl);
    };

    // trigger image load
    image.src = imageData;
  }
};
