import { any, element, InferProps, node, oneOf, string } from 'prop-types';

import { MODAL_TYPES } from 'core/utils/constants';

import { ctor } from '../helper';

const fwNotifierPT = {
  anchorRef: any,
  button: element,
  children: node,
  message: string,
  timestamp: string,
  type: oneOf(Object.values(MODAL_TYPES)),
};

type FwNotifierPT = InferProps<typeof fwNotifierPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwNotifierProps extends FwNotifierPT {}
class FwNotifierProps implements FwNotifierPT {
  constructor(props?: FwNotifierPT) {
    ctor(this, props);
  }
}

export { fwNotifierPT, FwNotifierProps };
