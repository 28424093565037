import { Center, IconButton, Spinner } from '@chakra-ui/react';
import { InferProps } from 'prop-types';
import React, { FC } from 'react';

const FwSpinner: FC<Props> = () => {
  return (
    <Center h="100%">
      <IconButton
        disabled
        aria-label="loading..."
        icon={<Spinner size="lg" />}
      />
    </Center>
  );
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwSpinner.propTypes = propTypes;

export default FwSpinner;
