import React, { FC, useEffect, useRef, useState } from 'react';
import { RouteChildrenProps, useHistory, withRouter } from 'react-router-dom';

import inputApi from 'api/input/inputApi';
import pageContentApi from 'api/page-content/pageContentApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { PageContentManagement } from 'core/model';
import { pushUrl, setTableStruct } from 'scenes/dashboard/helpers/admin';

import inputTableStructure from '../../../../helpers/inputTableStructure';
import PageContent from './PageContent';

const PageContentContainer: FC<RouteChildrenProps> = ({ location, match }) => {
  const history = useHistory();
  const { pageId } = (location?.state as { pageId: string }) || {};
  const { pageContentId } = match.params as {
    pageContentId: string;
  };

  const argsPageContent = useRef(pageContentId ? [pageContentId] : []);

  const [argsDelete, setArgsDelete] = useState([]);
  const [pageContent, setPageContent] = useState<PageContentManagement>();

  const { fetched: fetchedPageContent, pending: pendingPageContent } = useApi(
    pageContentApi.getById,
    argsPageContent.current
  );

  const {} = useApi(inputApi.delete, argsDelete);

  // receive pageContent from backend and set in state
  useEffect(() => {
    if (!pendingPageContent && !pageContentId) {
      setPageContent(
        new PageContentManagement({ pageID: pageId, tableKey: '' })
      );
    } else {
      if (!pendingPageContent && fetchedPageContent) {
        setPageContent(fetchedPageContent.pageContent);
      }
    }
  }, [fetchedPageContent, pageContentId, pageId, pendingPageContent]);

  const handleSetID = (id: string) => {
    // set in state
    setPageContent({ ...pageContent, pageContentID: id });
  };

  const handleAddRow = () => {
    pushUrl(history, 'newInput', { pageContentId: pageContent.pageContentID });
  };

  const handleDeleteRow = (_rowNumber: number, fieldId: string) => {
    // delete field_viewContent
    setArgsDelete([fieldId, pageContent.pageContentID]);
  };

  // set field_viewContent on page contents table
  const tableStruct = setTableStruct(
    'ViewContentID',
    pageContent?.pageContentID,
    inputTableStructure
  );

  // render
  return !pendingPageContent && pageContent ? (
    <PageContent
      pageContent={pageContent}
      tableStruct={tableStruct}
      handleAddRow={handleAddRow}
      handleDeleteRow={handleDeleteRow}
      handleSetKey={handleSetID}
    />
  ) : (
    <FwSpinner />
  );
};

export default withRouter(PageContentContainer);
