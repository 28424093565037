import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import languageApi from 'api/language/languageApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, Language } from 'core/model';

const LanguageLayout: FC<Props> = ({ language, handleSetKey }) => {
  const { t } = useTranslation();

  const castA: Language = Object.assign(new Language(), language);
  const inputs = getAdminInputs(castA, null, t);

  const entityDoc = getEntityDoc(language, inputs);

  return (
    <>
      {language && (
        <EntityInfo
          autosave
          api={languageApi}
          entityDoc={entityDoc}
          keyName={'key'}
          entityKey={language.key}
          type={'language'}
          title={'Language'}
          onSetKey={handleSetKey}
        />
      )}
    </>
  );
};

const propTypes = {
  language: any.isRequired,
  handleSetKey: func,
};

type Props = InferProps<typeof propTypes>;

LanguageLayout.propTypes = propTypes;

export default withTranslation()(LanguageLayout);
