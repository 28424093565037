import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FwPanel, FwTile } from 'components/base';
import { mapDesignToStyle } from 'core/mapper';
import { Route } from 'core/model';

const TilesPanel = ({
  routes,
  small,
  scroll,
}: {
  routes?: Route[];
  small?: boolean;
  scroll?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <FwPanel small={small} scroll={scroll}>
      {_.map(routes, (r, i) => {
        const style = mapDesignToStyle(r?.design);

        return (
          <FwTile
            key={i}
            {...style}
            active={
              !_.isNil(r.active)
                ? r.active
                : _.includes(
                    [
                      r.path,
                      `/${_.join(_.compact(_.split(r.path, '/')), '/')}`,
                    ],
                    location.pathname
                  )
            }
            to={r.path}
            onClick={r.handleChangeTile}
          >
            {t(`${r.name}`, { ns: ['common', 'custom'] })}
          </FwTile>
        );
      })}
    </FwPanel>
  );
};

export default React.memo(TilesPanel);
