import React, { FC, lazy, useEffect } from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';

import { PrivateRoute /*, FasterMap*/ } from 'components';
import { FwSpinner, useFwArea } from 'components/base';
import ShareRoute from 'components/router/ShareRoute';
import { areaRoutesPT, AreaRoutesProps } from 'core/model';

const Dashboard = lazy(() => import('./scenes/dashboard/dashboardContainer'));
const Public = lazy(() => import('./scenes/public/Public'));
const Zoom = lazy(() => import('./scenes/public/components/zoomContainer'));
const FilterDoc = lazy(() => import('./components/doc/FilterDoc'));
const PublicDoc = lazy(() => import('./components/doc/PublicDoc'));

const AreaRoutes: FC<AreaRoutesProps> = ({ areaKey, basePath }) => {
  const { area, setAreaKey } = useFwArea();

  useEffect(() => {
    setAreaKey(areaKey);
  }, [areaKey, setAreaKey]);

  return !area ? (
    <FwSpinner />
  ) : (
    <Switch>
      {/* share routes */}
      <Route
        exact
        path={`${basePath}public/:module/:id([A-Za-z0-9-]{36})`}
        component={(props: RouteChildrenProps) => (
          // todo maybe generalize ShareRoute to use for different routes
          // and create 'share/' urls (instead of 'public/' here) for them
          <ShareRoute {...props} component={PublicDoc} />
        )}
      />
      {/* public routes */}
      <Route
        path={`${basePath}public`}
        render={() => <Public loginContent={area.areaContent} />}
      />
      {/* private routes */}
      {/* <PrivateRoute
        exact
        path={`${basePath}dispatch/map`}
        component={FasterMap}
      /> */}
      <PrivateRoute
        exact
        path={`${basePath}filterselection`}
        component={FilterDoc}
      />
      <PrivateRoute
        exact
        path={`${basePath}zoom/:module/:id([A-Za-z0-9-]{36})`}
        component={Zoom}
      />
      <PrivateRoute path={basePath} component={Dashboard} />
    </Switch>
  );
};

AreaRoutes.propTypes = areaRoutesPT;

export default AreaRoutes;
