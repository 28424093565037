import { InferProps } from 'prop-types';
import React, { FC } from 'react';

import { FwMarkdown } from 'components/base';

import { CommonInputProps } from '../FwInput';

const FwMarkdownInput: FC<Props & CommonInputProps> = ({
  // common input props
  defaultValue,
  value,
}) => {
  const defaultText = value || defaultValue;

  return <FwMarkdown>{defaultText}</FwMarkdown>;
};

const propTypes = {};

export type Props = InferProps<typeof propTypes>;

FwMarkdownInput.propTypes = propTypes;

export default FwMarkdownInput;
