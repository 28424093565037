import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { Identity, UserFilter } from 'core/model';

type UserFilters = {
  fixedFilters: Array<UserFilter>;
  editableFilters: Array<UserFilter>;
};

type Auth = {
  user: Identity;
  userFilters: UserFilters;
  setUserFilters: Dispatch<SetStateAction<UserFilters>>;
  checkUserAccess: () => void;
  logout: (refreshRedirect?: boolean) => void;
};

const FwAuthContext: Context<Auth> = createContext<Auth>(undefined);

export { Auth, FwAuthContext, UserFilters };
