import _ from 'lodash';
import { func, InferProps, string } from 'prop-types';
import React, { useMemo } from 'react';

import { FwField, FwGrid, useFwTemplates } from 'components/base';
import { mapInputToField } from 'core/mapper';
import { FwFieldProps } from 'core/model';
import utils from 'core/utils/utils';

const FieldPageContent = ({ name, label, type, value, onChange }: Props) => {
  const { templates } = useFwTemplates();

  const fieldFromTemplate = useMemo(() => {
    let field: FwFieldProps;

    const fields = utils.getFieldsFromTemplates(templates);
    const input = _(fields).find({ key: name });

    if (
      (input?.additionalData as { allowPageFilter: boolean })?.allowPageFilter
    ) {
      // disable required property impact
      input.required = false;
      field = mapInputToField(input);
    }

    return field;
  }, [name, templates]);

  const basicProps = useMemo(
    () => ({
      name,
      label,
      type,
      value,
      onChange,
    }),
    [label, name, onChange, type, value]
  );

  return fieldFromTemplate ? (
    <FwGrid
      items={[_.merge(fieldFromTemplate, basicProps)]}
      itemComponent={FwField}
    />
  ) : (
    <FwField {...basicProps} />
  );
};

const propTypes = {
  name: string,
  label: string,
  type: string,
  value: string,
  onChange: func,
};

type Props = InferProps<typeof propTypes>;

FieldPageContent.propTypes = propTypes;

export default FieldPageContent;
