import { HStack } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwField } from 'components/base';
import { FwFilterProps, Option, Input, fwFilterPT } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { GetOperatorOptionsByType } from './FwFilter.helper';

const { searchselect, select } = FIELD_TYPE;

const INPUT_NAME = {
  key: 'key',
  operator: 'operator',
  value: 'value',
};

const FwFilter: FC<FwFilterProps> = ({
  filterData: {
    key,
    operator,
    value,
    type,
    searchType: originalSearchType,
    input: { row, required, searchType },
  },
  allTemplateInputs,
  inputOptions,
  disableChanges,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const [originalKey] = useState<string>(key);
  const [operatorOptions, setOperatorOptions] = useState<Option[]>(() =>
    GetOperatorOptionsByType(t, type)
  );
  const [disableType] = useState<string>(() =>
    _.find(disableChanges, { key })?.type?.toLowerCase()
  );

  const onChangeKey = (
    e: ChangeEvent,
    data: { name: string; value: string | object; fillData?: object }
  ) => {
    const { name, value } = data;

    // only trigger change if key has value
    if (value) {
      // find input then update in store
      const input = _.find(allTemplateInputs, {
        key: value,
      }) as Input;

      const { type } = input;
      // keep position and required state by batchFilter
      input.required = required;
      input.row = row;

      // update fillData
      data.fillData = {
        [name]: value,
        type,
        input,
      };

      // update state
      setOperatorOptions(GetOperatorOptionsByType(t, type));

      // trigger change
      onChange(e, data);
    }
  };

  return (
    <HStack>
      <FwField
        type={searchselect}
        value={key}
        name={INPUT_NAME.key}
        readOnly={disableType ? true : false}
        onChange={onChangeKey}
        options={inputOptions}
      />
      <FwField
        type={select}
        value={operator}
        name={INPUT_NAME.operator}
        readOnly={_.includes(
          [INPUT_NAME.operator, INPUT_NAME.value],
          disableType
        )}
        onChange={(e, data) => {
          // only trigger change if operator has value
          if (data.value) {
            onChange(e, data);
          }
        }}
        options={operatorOptions}
      />
      <FwField
        // invalid is pass in props so atm only this input is highlight when invalid
        {...props}
        noLabel
        type={originalKey === key ? originalSearchType : searchType}
        value={value}
        name={INPUT_NAME.value}
        readOnly={_.includes([INPUT_NAME.value], disableType)}
        onChange={onChange}
      />
    </HStack>
  );
};

FwFilter.propTypes = fwFilterPT;

export default FwFilter;
