import React from 'react';

import { FwImage, useFwArea } from 'components/base';

const BackgroundContainer = () => {
  const { area } = useFwArea();

  return !area ? null : (
    <FwImage background src={area.areaContent.background} />
  );
};

export default BackgroundContainer;
