import { useEffect, useState } from 'react';

import { IDXDDB_STORES, getFromIdxdDB } from 'core/utils/idxdDB';

// for now only supports document as entity
type UseIdxdDBEntityState = {
  pending: boolean;
  entity: unknown;
};

// refactored state updater to ensure 'pending' is always set to false
const stateUpdater =
  (newState?: Partial<UseIdxdDBEntityState>) =>
  (prevState: UseIdxdDBEntityState) => {
    return {
      ...prevState,
      ...newState,
      pending: false,
    };
  };

// for now only supports document as entity
const useIdxdDBEntity = (key: string) => {
  const [state, setState] = useState<UseIdxdDBEntityState>({
    pending: true,
    entity: undefined,
  });

  useEffect(() => {
    if (key) {
      // try get from indexedDb
      getFromIdxdDB(IDXDDB_STORES.document, key, (data) => {
        let newPartialState: Partial<UseIdxdDBEntityState> = undefined;

        if (data) {
          newPartialState = { entity: data };
        }

        setState(stateUpdater(newPartialState));
      });
    } else {
      // update state
      setState(stateUpdater());
    }
  }, [key]);

  return [state.pending, state.entity];
};

export default useIdxdDBEntity;
