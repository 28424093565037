import _ from 'lodash';

const sortByStepRowColumnKey = (array) => {
  return _.sortBy(array, [
    (i) => i.step,
    (i) => i.sectionRow,
    (i) => i.sectionColumn,
    (i) => i.row,
    (i) => i.column,
    (i) => i.position,
    (i) => i.key,
  ]);
};

const reduceCore = (array, propGetter) => {
  return _.uniq(_.map(array, propGetter));
};

const reduceToRows = (sortedArray) => {
  return reduceCore(sortedArray, (i) => i.row);
};

const reduceToColumns = (sortedArray) => {
  return reduceCore(sortedArray, (i) => i.column);
};

export { reduceToRows, reduceToColumns, sortByStepRowColumnKey };
