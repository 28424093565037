import { FIELD_TYPE } from 'core/utils/constant';

const email = {
  key: 'NormalizedUserName',
  name: 'Email',
  position: 1,
  type: FIELD_TYPE.text,
};

const table = {
  columns: [email],
  rowCount: 10,
  sortByColumnKey: 'NormalizedUserName',
  ascendingSort: true,
  additionalData: { disableViewed: true, disableCache: true },
};

export default table;
