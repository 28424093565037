import _ from 'lodash';
import { any, func, InferProps } from 'prop-types';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import { EntityInfo } from 'components';
import { useFwAuth } from 'components/base';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { AppPreference, getAdminInputs, Input } from 'core/model';
import { PERFORM_TYPES } from 'core/utils/constants';
import { readSettings } from 'core/utils/storage';
import utils from 'core/utils/utils';

import PreferencesOffline from './Preferences.Offline';

const Preferences: FC<Props> = ({
  options,
  handleSetKey,
  handleEntityChange,
  handleSetLocalMode,
}) => {
  const { t } = useTranslation();

  const { user } = useFwAuth();
  const settings = readSettings();

  const stateRef = useRef(
    new AppPreference({
      ...settings,
      appPreferenceID: user.appPreference.appPreferenceID,
    })
  );

  // todo consider moving this to EntityInfo
  const inputs = getAdminInputs(stateRef.current, null, t);

  // apply dropdown options in inputs
  inputs[0].dropdown = { dropdownID: 1, options };

  const inputsRef = useRef(inputs);

  const entityDoc = getEntityDoc(stateRef.current, inputs);

  const onDataChange = useCallback(
    (data) => {
      // todo wip #585 refactor value type mapping
      // turn data (string values) into newData (typed values)
      // based on input types and field keys
      const newData = _.reduce(
        data,
        (result, value, key) => {
          const inputType = (_.find(inputsRef.current, { key }) as Input)?.type;
          result[key] =
            inputType === 'CHECKBOX'
              ? value === 'true'
              : value; /* map value here */
          return result;
        },
        {}
      );

      stateRef.current = new AppPreference(_.assign(stateRef.current, newData));
      handleEntityChange(newData);
    },
    [handleEntityChange]
  );

  return (
    <>
      {!settings.localMode && (
        <EntityInfo
          autosave
          entityDoc={entityDoc}
          icon="RiSoundModuleFill"
          label={t('common|Preferences')}
          api={appPreferenceApi}
          entityKey={stateRef.current.appPreferenceID}
          keyName={'appPreferenceID'}
          type={'appPreference'}
          onEntityChange={onDataChange}
          onSetKey={handleSetKey}
        />
      )}
      {utils.canPerform(user, PERFORM_TYPES.local) && (
        <PreferencesOffline
          settings={settings}
          handleSetLocalMode={handleSetLocalMode}
        />
      )}
    </>
  );
};

const propTypes = {
  options: any.isRequired,
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
  handleSetLocalMode: func,
};

type Props = InferProps<typeof propTypes>;

Preferences.propTypes = propTypes;

export default Preferences;
