import { InferProps, node } from 'prop-types';
import React, { FC } from 'react';

import { FwParagraph } from 'components/base';

const FwTitle: FC<Props> = ({ children, align }) => {
  return children ? (
    <FwParagraph heading truncated align={align}>
      {children}
    </FwParagraph>
  ) : null;
};

const propTypes = {
  children: node,
};

// todo wip#585 improve and refactor
export interface Props extends InferProps<typeof propTypes> {
  align?: 'left' | 'center' | 'right' | 'justify';
}

FwTitle.propTypes = propTypes;

export default FwTitle;
