import _ from 'lodash';

import { PageContentManagement } from 'core/model';
import { FIELD_TYPE, CONTENT_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const optionContentType = [];
_.forEach(CONTENT_TYPE, function (value) {
  optionContentType.push({
    key: value,
    value: value,
    text: value,
  });
});

const pageContentManagement0AS: AdminStructure<PageContentManagement> = [
  {
    key: 'name',
    name: 'Name',
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'type',
    name: 'Type',
    required: true,
    type: FIELD_TYPE.select,
    dropdown: { dropdownID: 1, options: optionContentType },
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'areaOnly',
    name: 'Only display in an area',
    type: FIELD_TYPE.checkbox,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'additionalData',
    name: 'Data',
    type: FIELD_TYPE.textarea,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const pageContentManagement1AS: AdminStructure<PageContentManagement> = [
  {
    key: 'tableKey',
    name: 'Table',
    type: FIELD_TYPE.select,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const pageContentManagement1Rules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'tableKey',
    },
    script: {
      scriptID: 1,
      description: 'Get options from table api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`option/grid`).then(res => res?.data?.options && callback({ options: {'tableKey': res.data.options.map(o => ({ key: o.key, value: o.key, text: o.key })) } }))",
    },
  },
];

export {
  pageContentManagement0AS,
  pageContentManagement1AS,
  pageContentManagement1Rules,
};
