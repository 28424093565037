import { Box, PopoverContent, PopoverContentProps } from '@chakra-ui/react';
import React, { FC } from 'react';

import { defaultSpacing } from 'config/theme/constants';

const PopContent: FC<PopoverContentProps> = ({ children, ...props }) => {
  // fix popper positioning since change Chakra UI made in
  // https://github.com/chakra-ui/chakra-ui/pull/5969
  const popperStyle = {
    sx: { '& .chakra-popover__popper': { minWidth: 'max-content' } },
  };

  const style = {
    cursor: 'default',
    minW: 'max-content',
    w: 'full',
    boxShadow: 'none !important',
    maxHeight: '21rem',
    p: defaultSpacing,
    // {
    //   base: '6rem',
    //   sm: '10rem',
    //   lg: '16rem',
    //   xl: '21rem',
    // },
  };

  return (
    <Box {...popperStyle}>
      <PopoverContent overflowX={'auto'} {...style} {...props}>
        {children}
      </PopoverContent>
    </Box>
  );
};

export default PopContent;
