import { InferProps, node } from 'prop-types';
import React, { useState } from 'react';

import { Page } from 'core/model';

import { FwPageContext } from './FwPageContext';

const FwPageProvider = ({ children, initPage }: Props) => {
  const [page, setPage] = useState<Page>(initPage);

  return (
    <FwPageContext.Provider value={[page, setPage]}>
      {children}
    </FwPageContext.Provider>
  );
};

const propTypes = {
  children: node,
};

interface Props extends InferProps<typeof propTypes> {
  initPage?: Page;
}

FwPageProvider.propTypes = propTypes;

export { FwPageProvider };
