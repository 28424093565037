import { dtoCtor, Dto } from '../helper';
import { Event } from './event';
import { Script } from './script';

class Rule implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Rule, 'dtoName'>) {
    dtoCtor(this, 'Rule', props);
  }

  event?: Event;
  script?: Script;
}

export { Rule };
