import { any, func, InferProps } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwButton, FwField, FwGrid, FwModal, FwSegment } from 'components/base';
import FwLocalModeStatus from 'components/base/utility/lightStatus/FwLocalModeStatus';
import { FwModalProps } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { MODAL_TYPES } from 'core/utils/constants';
import { readSettings } from 'core/utils/storage';

const PreferencesOffline = ({ handleSetLocalMode }: Props) => {
  const { t } = useTranslation();

  const { localMode } = readSettings();

  const [modalProps, setModalProps] = useState<FwModalProps>({
    cancelName: 'Cancel',
    name: 'Confirm',
    open: false,
    submitName: 'Ok',
    type: MODAL_TYPES.info,
  });

  const onToggleLocalMode = () => {
    setModalProps((prevProps) => ({
      ...prevProps,
      warning: t(
        `${
          localMode ? 'Disabling' : 'Enabling'
        } offline mode may take time, do not lose network connection until complete`
      ),
      open: true,
      onSubmit: () => {
        setModalProps((modalProps) => ({ ...modalProps, open: false }));
        handleSetLocalMode(!localMode);
      },
      onCancel: () =>
        setModalProps((modalProps) => ({ ...modalProps, open: false })),
    }));
  };

  return (
    <FwSegment
      as={FwGrid}
      leftIcon="RiGlobalLine"
      name={t('common|Connectivity')}
    >
      <div>
        <FwField label={t('common|Cloud sync')} type={FIELD_TYPE.empty} />
        <span style={{ marginRight: '5px' }}>
          <FwLocalModeStatus />
        </span>
        {t(`common|${localMode ? 'Offline' : 'Online'}`)}
      </div>
      <div>
        <FwButton
          key="0"
          primary
          basic={!localMode}
          leftIcon={localMode ? 'RiUploadCloud2Fill' : 'RiCloudOffLine'}
          onClick={onToggleLocalMode}
        >
          {t(`common|${localMode ? 'Enable' : 'Disable'} cloud sync`)}
        </FwButton>
      </div>
      <FwModal {...modalProps} />
    </FwSegment>
  );
};

const propTypes = {
  handleSetLocalMode: func,
  settings: any,
};

type Props = InferProps<typeof propTypes>;

PreferencesOffline.propTypes = propTypes;

export default PreferencesOffline;
