import { dtoCtor, Dto } from '../helper';
import { AppUser } from './appUser';
import { AutoSaveEdit } from './autoSaveEdit';

class AutoSaveInfo<T, TKey> implements Dto {
  dtoName?: string;

  constructor(props?: Omit<AutoSaveInfo<T, TKey>, 'dtoName'>) {
    dtoCtor(this, 'AutoSaveInfo', props);
  }

  id?: TKey;
  autoSaveEdits?: AutoSaveEdit[];
  appUser?: AppUser;
}

export { AutoSaveInfo };
