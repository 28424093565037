import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';

const {
  checkbox,
  select,
  editselect,
  multiselect,
  searchselect,
  searcheditselect,
  collection,
  reference,
} = FIELD_TYPE;

const getDefaultValueByType = (type, dropdown) =>
  _.includes(type, checkbox)
    ? `${false}`
    : _.includes(
        [select, editselect, searchselect, searcheditselect, multiselect],
        type
      ) &&
      dropdown &&
      dropdown.selectedOptionValue
    ? dropdown.selectedOptionValue
    : _.includes(type, collection)
    ? []
    : _.includes(type, reference)
    ? {}
    : '';

export default getDefaultValueByType;
