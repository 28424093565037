import { Design, StylePT } from 'core/model';

const mapDesignToStyle = (design: Design): StylePT => {
  const { backgroundColor, borderColor, color, icon, iconColor } = design || {};

  return design
    ? {
        backgroundColor,
        borderColor,
        color,
        leftIcon: icon,
        leftIconColor: iconColor,
      }
    : {};
};

export { mapDesignToStyle };
