import { dtoCtor, Dto } from '../helper';

class AppPreference implements Dto {
  dtoName?: string;

  constructor(props?: Omit<AppPreference, 'dtoName'>) {
    dtoCtor(this, 'AppPreference', props);
  }

  appPreferenceID: number;
  language?: string;
  theme?: string;
  color?: string;
  smallMenu?: boolean;
  defaultModule?: string;
  data?: object;
}

export { AppPreference };
