import _ from 'lodash';

import { getAreaBasePath } from 'components/base';
import { AppUser, Area, Module, Route } from 'core/model';
import Utils from 'core/utils/utils';

const getKeyPath = (area: Area, key: string) =>
  `${getAreaBasePath(area)}${key.toLowerCase()}`;

const getModulesAccessible = (
  moduleRoutes: (Module & Route)[],
  area: Area,
  user: AppUser,
  isAll: boolean
) =>
  _.filter(
    moduleRoutes,
    ({ areaOnly, areas, key, system }) =>
      !system &&
      (isAll || Utils.canAccess(user, key)) &&
      Utils.showInArea({ areaOnly, areas }, area)
  ).map((m) => ({ ...m, path: getKeyPath(area, m.key) }));

export default getModulesAccessible;
