import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import { InferProps, string } from 'prop-types';
import React, { FC, ReactNode } from 'react';

import {
  FwIcon,
  FwImage,
  FwLabel,
  FwLink,
  FwParagraph,
  FwTooltip,
} from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import { dateFormats, jsDateFromString, jsDateToString } from 'core/utils/date';
import utils from 'core/utils/utils';

import { getIsoDateFormat } from '../input/datetime/FwInput.Datetime.helpers';

const FwDataItem: FC<Props> = ({ type, value }) => {
  let result: ReactNode = null;

  if (value) {
    if (type === FIELD_TYPE.checkbox) {
      const isChecked = utils.getBoxChecked(value);
      result = isChecked ? <FwIcon primary name="RiCheckboxFill" /> : null;
    } else if (type === FIELD_TYPE.multiselect) {
      // multiple values
      const dataArr = _.includes(value, '|') ? _.split(value, '|') : [value];
      result = (
        <FwParagraph>
          {_.map(dataArr, (item, index) => (
            <FwLabel key={index}>{item}</FwLabel>
          ))}
        </FwParagraph>
      );
    } else if (type === FIELD_TYPE.icon) {
      result = <FwIcon name={value} />;
    } else if (
      _.includes([FIELD_TYPE.photo, FIELD_TYPE.sign, FIELD_TYPE.file], type) &&
      utils.tryParse(value)
    ) {
      const { path, filename } = JSON.parse(value);

      if (path && filename) {
        let resultComponent: ReactNode;

        const isValidImg =
          /\.(png|jpe?g)$/i.test(filename) &&
          (type === FIELD_TYPE.photo || type === FIELD_TYPE.sign);

        let name = undefined;
        if (type === FIELD_TYPE.photo || type === FIELD_TYPE.file) {
          try {
            name = utils.getOriginalFilename(value);
          } catch (e) {
            name = filename;
          }
        }

        if (isValidImg) {
          resultComponent = (
            <FwTooltip text={name} placement="bottom">
              <Box
                cursor="pointer"
                w="100px"
                maxH="100px"
                overflow="hidden"
                onClick={() => utils.openInNewTab(path)}
              >
                <FwImage src={path} alt={filename} />
              </Box>
            </FwTooltip>
          );
        } else if (type === FIELD_TYPE.file) {
          resultComponent = (
            <FwLink onClick={() => utils.openInNewTab(path)}>{name}</FwLink>
          );
        } else {
          resultComponent = value;
        }
        result = resultComponent;
      } else {
        result = value;
      }
    } else if (type === FIELD_TYPE.link) {
      result = <FwLink to={value}>{value}</FwLink>;
    } else if (
      _.includes([FIELD_TYPE.datetime, FIELD_TYPE.date, FIELD_TYPE.time], type)
    ) {
      const dateFormat =
        type === FIELD_TYPE.datetime
          ? dateFormats.datetime
          : type === FIELD_TYPE.date
          ? dateFormats.date
          : /* type === FIELD_TYPE.time */
            dateFormats.time;

      const date = jsDateToString(
        jsDateFromString(value, getIsoDateFormat(type)),
        dateFormat
      );

      result = date ?? value;
    } else {
      result = value;
    }
  }

  return result ? (
    // convert ReactNode into ReactElement
    <>{result}</>
  ) : null;
};

const propTypes = {
  type: string,
  value: string,
};

export type Props = InferProps<typeof propTypes>;

FwDataItem.propTypes = propTypes;

export default FwDataItem;
