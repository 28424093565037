import { Stack } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FwIcon, FwLink, FwSegment } from 'components/base';
import { FwTileProps, fwTilePT } from 'core/model';

import { FwParagraph } from '..';

const FwTile: FC<FwTileProps> = ({
  active,
  backgroundColor,
  borderColor,
  children,
  color,
  leftIcon,
  leftIconColor,
  // rightIcon,
  // rightIconColor,
  small,
  to,
  onClick,
}) => {
  const stackStyle = {
    alignItems: 'center',
    h: '100%',
  };

  const segmentStyle = { backgroundColor, borderColor, color };

  return (
    <FwLink basic to={to}>
      <FwSegment
        {...segmentStyle}
        compact
        tile
        active={active}
        small={small}
        onClick={onClick}
      >
        <Stack {...stackStyle} direction={small ? 'row' : 'column'}>
          {leftIcon && (
            <FwIcon
              color={leftIconColor}
              name={leftIcon}
              size={small ? undefined : '80px'}
            />
          )}
          <FwParagraph
            fluid
            truncated
            align={small ? undefined : 'center'}
            small={small}
          >
            {children}
          </FwParagraph>
        </Stack>
      </FwSegment>
    </FwLink>
  );
};

FwTile.propTypes = fwTilePT;

export default FwTile;
