import {
  Tag,
  TagLeftIcon,
  TagRightIcon,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { FC, ForwardedRef, forwardRef } from 'react';

import { FwIcon, FwTooltip, useFwCssStyle, useFwTheme } from 'components/base';
import { fwAccent } from 'config/theme/constants';
import { FwLabelProps, fwLabelPT } from 'core/model';

const FwLabel: FC<FwLabelProps> = forwardRef(
  (
    {
      backgroundColor: backgroundColorProp,
      basic,
      borderColor: borderColorProp,
      color: colorProp,
      children,
      error,
      leftIcon,
      leftIconColor,
      primary,
      rightIcon,
      rightIconColor,
      rounded,
      tooltip,
    }: FwLabelProps,
    ref: ForwardedRef<HTMLLabelElement>
  ) => {
    const { backgroundColor, borderColor, color } = useFwCssStyle({
      backgroundColor: backgroundColorProp,
      borderColor: borderColorProp,
      color: colorProp,
    });
    const {
      color: defaultLblColor,
      _disabled: {
        _hover: { bg: defaultLblBg },
      },
    } = useFwTheme();
    const errorColor = useColorModeValue(
      'var(--chakra-colors-red-500)',
      'var(--chakra-colors-red-200)'
    );

    const colors = primary
      ? {
          colorScheme: fwAccent,
        }
      : error
      ? { colorScheme: error ? 'red' : fwAccent, color: errorColor }
      : {
          background: backgroundColor || defaultLblBg,
          color: color || defaultLblColor,
        };

    const tagStyle = {
      variant: basic ? 'outline' : primary ? 'solid' : undefined,
      ...colors,
      borderColor,
      borderRadius: rounded ? 'full' : undefined,
    };

    return (
      <FwTooltip text={tooltip}>
        <Tag ref={ref} {...tagStyle}>
          {leftIcon && (
            <TagLeftIcon
              as={FwIcon}
              boxSize="1em"
              name={leftIcon}
              color={leftIconColor}
            />
          )}
          <TagLabel>{children}</TagLabel>
          {rightIcon && (
            <TagRightIcon
              as={FwIcon}
              boxSize="1em"
              name={rightIcon}
              color={rightIconColor}
            />
          )}
        </Tag>
      </FwTooltip>
    );
  }
);

FwLabel.propTypes = fwLabelPT;
FwLabel.displayName = 'FwLabel';

export default FwLabel;
