import { AspectRatio, Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwLink, FwParagraph, FwSegment } from 'components/base';
import { StylePT, FwIframeProps, fwIframePT } from 'core/model';
import Utils from 'core/utils/utils';

const FwIframe: FC<FwIframeProps> = ({
  backgroundColor,
  borderColor,
  color,
  compact,
  height,
  leftIcon,
  leftIconColor,
  rightIcon,
  rightIconColor,
  url,
}) => {
  const { t } = useTranslation();

  const segmentStyle: StylePT = {
    backgroundColor,
    borderColor,
    color,
    leftIcon,
    leftIconColor,
    rightIcon,
    rightIconColor,
  };
  const iframeStyle = {
    height: height && height > 0 ? height : compact ? '450px' : '500px',
    marginTop: '-0.5em',
    marginLeft: '-0.5em',
    overflow: 'hidden',
    width: 'calc(100% + 1em)',
  };

  return (
    <FwSegment {...segmentStyle} noOverflow>
      <AspectRatio {...iframeStyle}>
        <iframe title={url} src={url} />
      </AspectRatio>
      <Box m={2}>
        <FwParagraph small>
          <FwLink
            leftIcon={'RiExternalLinkFill'}
            onClick={() => Utils.openInNewTab(url)}
          >
            {t('See full screen')}
          </FwLink>
        </FwParagraph>
      </Box>
    </FwSegment>
  );
};

FwIframe.propTypes = fwIframePT;

export default FwIframe;
