import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';

const { checkbox, file, text } = FIELD_TYPE;

const typeMapper = (htmlType) => {
  switch (htmlType) {
    case 'checkbox':
      return checkbox;
    case 'file':
      return file;
    case 'text':
      return text;
    default: {
      return htmlType;
    }
  }
};

const mapInputToProps = (
  {
    fieldID,
    key,
    name,
    type,
    required,
    isReadOnly,
    pattern,
    dropdown,
    visible,
    subInputs,
    suggestions,
    additionalData,
  },
  docData,
  invalidInputKey,
  loadingInputKeys
) => {
  return {
    // unchanged props
    fieldID,
    name,
    type,
    required,
    pattern,
    dropdown,
    visible,
    subInputs,
    suggestions,
    additionalData,
    // mapped props
    key: fieldID,
    inputKey: key,
    readOnly: isReadOnly,
    // computed props
    value: docData ? docData[key] : undefined,
    invalid: invalidInputKey === key,
    loading: loadingInputKeys && _.includes(loadingInputKeys, key),
  };
};

export { mapInputToProps, typeMapper };
