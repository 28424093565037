import { Input, FwFilterProps, FwGridItemProps } from 'core/model';

import { inputMapConfig, mapInputToField } from './mapInputToField';

const mapInputToFilter = (
  input: Input,
  otherProps: FwFilterProps & FwGridItemProps & inputMapConfig
): FwFilterProps => {
  const field = mapInputToField(input, { ...(otherProps || {}) }) || {};
  return field;
};

export { mapInputToFilter };
