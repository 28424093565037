import _ from 'lodash';
import React, { useMemo } from 'react';

import { mapDesignToStyle } from 'core/mapper';
import { BUTTON_TYPE, DRAFT_STATUS } from 'core/utils/constant';
import { readSettings } from 'core/utils/storage';
import { getModalOptions } from 'core/utils/utilsModal';

import DocumentButton from '../../button/DocumentButton';
import DownloadButton from '../../button/downloadButtonContainer';
// import PrintButton from '../../button/PrintButton';
import { applyButtonEffect } from '../helpers';
import { getEffect } from '../helpers/applyButtonEffect';

const { download, link, print, reset, save } = BUTTON_TYPE;

const DocumentButtonContainer = ({
  publicly,
  actionable,
  viewersWarning,
  clickable: { key, name, type, additionalData, toStatus, design, modal },
  onButtonClick,
}: any) => {
  const settings = useMemo(() => {
    return readSettings();
  }, []);

  const skipValidation =
    _.includes([download, link, print, reset, save], type) ||
    toStatus === DRAFT_STATUS;

  const warning = viewersWarning
    ? 'Other users are viewing this document, continuing may disrupt their operations'
    : undefined;

  const modalOptions = modal
    ? getModalOptions({ ...modal, warning })
    : undefined;

  const style = mapDesignToStyle(design);

  const btnProps = {
    ...style,
    disabled: !actionable,
    name: name,
    primary: true,
    type: 'button',
    additionalData,
    onClick: (params) =>
      onButtonClick(
        !skipValidation,
        modalOptions,
        applyButtonEffect(getEffect(type)(params))
      ),
  };

  // render
  return type === print ? null : type === download ? (
    settings.localMode ? (
      <></>
    ) : (
      <DownloadButton {...btnProps} />
    )
  ) : (
    <DocumentButton
      {...btnProps}
      btnKey={key}
      toStatus={toStatus}
      effectType={type}
      publicly={publicly}
    />
  );
};

// documentButtonContainer.propTypes = {
//   clickable: object.isRequired,
//   onButtonClick: func.isRequired,
// };

export default DocumentButtonContainer;
