import _ from 'lodash';

// this functions basically performs the same operation as lodash omit
// but returns the correct type for typescript linting
// usage requires to mark array of props ('arr') as const
// e.g. const myUnmappedProps = ['prop1'] as const;
const removeProperties = <
  TDto extends object,
  TPropsArray extends readonly (keyof TDto)[],
  TUnmapped extends TPropsArray[number]
>(
  dto: TDto,
  arr: TPropsArray
) => {
  return /* first remove props from object at runtime */ _.omit(
    dto,
    arr
  ) /* then remove them from type, for typescript linting */ as Omit<
    TDto,
    TUnmapped
  >;
};

export { removeProperties };
