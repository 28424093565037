import { bool, InferProps, node, string } from 'prop-types';

import { ctor } from '../helper';

const fwSettingsProviderPT = {
  accent: string /* replaces color from AppPreference */,
  children: node,
  countryCode: string,
  defaultModule: string,
  direction: string,
  isProd: bool /* toggle for advanced debugging/testing features */,
  language: string,
  noCache: bool /* toggle for table (sort/filters) caching */,
  localMode: bool,
  smallMenu: bool,
  theme: string,
};

type FwSettingsProviderPT = InferProps<typeof fwSettingsProviderPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwSettingsProviderProps extends FwSettingsProviderPT {}
class FwSettingsProviderProps implements FwSettingsProviderPT {
  constructor(props?: FwSettingsProviderPT) {
    ctor(this, props);
  }
}

export { fwSettingsProviderPT, FwSettingsProviderProps };
