import { func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';

import { Table } from 'components';

import tableStructure from './helpers/tableStructure';

const AdminUsers: FC<Props> = ({ handleAddRow }) => {
  return (
    <Table
      allowAddRow
      allowDoubleClick
      table={tableStructure}
      dataType="User"
      dataKey="NormalizedUserName"
      handleAddRow={handleAddRow}
    />
  );
};

const propTypes = {
  handleAddRow: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

AdminUsers.propTypes = propTypes;

export default withTranslation()(AdminUsers);
