import _ from 'lodash';
import { any, bool, func, object, string } from 'prop-types';
import React, {
  MutableRefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useApi } from 'api/useApi';
import { useInputForm } from 'components';
import {
  FwButton,
  FwField,
  FwForm,
  FwGrid,
  FwSegment,
  FwToast,
  useFwStore,
} from 'components/base';
import {
  getVisibleElements,
  setInvalidInputKey,
} from 'components/doc//helpers';
import {
  DataProps,
  DocState,
  fieldTimeout,
  getAutoSaveInfo,
  getSubmitData,
  isSaved,
  isValidResponse,
  reducer,
} from 'components/doc/function/document';
import DocModal from 'components/doc/modal/DocModal';
import { mapInputToField } from 'core/mapper';
import { Input } from 'core/model';
import Utils from 'core/utils/utils';
import { defaultModal, isValidDocument } from 'core/utils/utilsModal';

import { getEntityExtended } from './helpers';

const EntityInfoContainer = ({
  autosave,
  icon,
  entityDoc,
  label,
  title,
  api,
  entityKey,
  keyName,
  type,
  onSetKey,
  onEntityChange,
}) => {
  const { t } = useTranslation();
  const store = useFwStore();

  // refs, do not replace useState by useRef https://github.com/facebook/react/issues/14490
  const previousTimeouts = useRef({});
  const unmountingRef = useRef(false);
  const entityDocRef = useRef(entityDoc);
  const invalidInputKeyRef = useRef();

  const [entityDocDataExtendedRef] = useState(() => ({
    current: getEntityExtended(_.cloneDeep(entityDocRef.current), store),
  }));

  const [entityDocDataRef] = useState(() => ({
    current: _.cloneDeep(entityDocDataExtendedRef.current),
  }));

  const [visibleElementsRef] = useState(() => ({
    current: getVisibleElements(
      entityDocRef.current,
      entityDocDataRef.current,
      store
    ),
  }));

  // state
  const [args, setArgs] = useState([]);

  const [
    {
      docData,
      exists,
      invalidInputKey,
      loading,
      loadingInputKeys,
      modal: { isOpen, options },
      prevDocData,
      requiredAreFill,
      visibleElements,
    },
    dispatch,
  ] = useReducer(reducer, {
    docData: entityDocDataRef.current,
    exists: entityKey ? true : false,
    loading: false,
    modal: defaultModal,
    prevDocData: _.cloneDeep(entityDocDataRef.current),
    requiredAreFill: false,
    visibleElements: visibleElementsRef.current,
  } as Partial<DocState>);

  // unmount management
  useEffect(() => {
    return () => {
      unmountingRef.current = true;
    };
  }, []);

  const safeDispatch = (newState: Partial<DocState>) => {
    if (!unmountingRef.current) {
      dispatch(newState);
    }
  };

  const getEntityFactorised = (entity: DataProps) => {
    const newEntity = {};

    // group values ​​that have the same keys before the dot
    _.forEach(entity, function (value, key) {
      const [firstKey, secondKey] = key.split('.');

      if (secondKey) {
        if (_.isUndefined(newEntity[firstKey])) {
          newEntity[firstKey] = {};
        }

        newEntity[firstKey][secondKey] = value;
      } else {
        newEntity[key] = value;
      }
    });

    return newEntity;
  };

  // send api request
  const triggerApiCall = (formSubmit: DataProps, fields?: string[]) => {
    const fieldsPascalCase =
      fields && fields.length > 0
        ? _.map(fields, Utils.toPascalCase)
        : undefined;

    // check if all required inputs are fill
    const visibleInputs = Utils.getVisibleInputsFromSteps(
      visibleElementsRef.current.steps,
      true
    );

    const invalidInputKey = isValidDocument(t, visibleInputs, formSubmit);

    // set loading and requiredAreFill state
    safeDispatch({
      loading: true,
      requiredAreFill: !invalidInputKey,
    });

    // update form to have correct additionalData
    let newFormSubmit = _.cloneDeep(formSubmit);

    if ('additionalData' in formSubmit && formSubmit.additionalData) {
      newFormSubmit = {
        ...formSubmit,
        additionalData: JSON.parse(
          (formSubmit as { additionalData: string }).additionalData
        ),
      };
    }

    // update form to have correct value type based to the data of the entityDocRef
    _.forEach(newFormSubmit, function (formValue, formKey) {
      const [firstKey, secondKey] = formKey.split('.');

      const originalData = JSON.parse(entityDocRef.current.data);
      const originalValue = secondKey
        ? originalData[firstKey][secondKey]
        : originalData[firstKey];

      if (_.isString(formValue)) {
        if (_.isArray(originalValue)) {
          // update form to get array from string
          const formValueSplit = formValue
            ? _.split(formValue, '|').sort()
            : [];

          newFormSubmit[formKey] = _.map(formValueSplit, (fvs) =>
            Utils.tryParse(fvs) ? JSON.parse(fvs) : fvs
          );
        } else if (_.isObject(originalValue)) {
          // update form to get object from string
          newFormSubmit[formKey] = JSON.parse(formValue);
        }
      }
    });

    if (!invalidInputKey) {
      // compute api arguments
      const newArgs =
        exists && !_.isUndefined(fieldsPascalCase)
          ? [getAutoSaveInfo(newFormSubmit, fields, keyName)]
          : [{ [type]: getEntityFactorised(newFormSubmit) }];

      // trigger useApi hook
      setArgs(newArgs);
    } else {
      // remove loading state
      safeDispatch({
        ...setInvalidInputKey(invalidInputKey, invalidInputKeyRef),
        loading: false,
      });
    }
  };

  // define api call (either put or post of the entity)
  const { response, pending } = useApi(
    requiredAreFill
      ? exists
        ? autosave
          ? api.putAS
          : api.put
        : api.post
      : undefined,
    args
  );

  // receive response from api call
  useEffect(() => {
    if (isValidResponse(response)) {
      // prevent further api call by clearing args
      setArgs([]);

      // update state
      safeDispatch({
        loading: false,
        exists: true,
        prevDocData: _.cloneDeep(docData),
      });

      // when api request was a post
      if (response.status === 201 && !exists) {
        if (onSetKey) {
          // set key
          const { id, key, username } = response.data;
          const newKey = id ? id : key ? key : username ? username : undefined;
          onSetKey(newKey);
        }

        if (!autosave) {
          FwToast.success(`${t('You have successfully saved the data')}`);
        }

        // make next call a put
      } else if (response.status === 204 && !autosave) {
        FwToast.success(`${t('You have successfully saved changes')}`);
      }
    }
  }, [response, autosave, exists, onSetKey]);

  // update form after api post request
  useEffect(() => {
    if (
      autosave &&
      keyName &&
      entityKey &&
      docData &&
      docData[keyName] !== entityKey
    ) {
      docData[keyName] = entityKey;
      safeDispatch({
        prevDocData: _.cloneDeep(docData),
      });
    }
  }, [docData, entityKey, autosave, keyName]);

  // notify parent component of change
  const notifyChange = (info: { [x: string]: string | object | object[] }) => {
    if (onEntityChange) {
      onEntityChange(info);
    }
  };

  // when a value changes in form
  const handleChange = (
    field: string,
    value: string | object | object[],
    dataRef: MutableRefObject<DataProps>
  ) => {
    // if autosave
    if (autosave) {
      const formSubmit = _.cloneDeep(dataRef.current);
      const submitKeys = Object.keys(getSubmitData(prevDocData, formSubmit));

      fieldTimeout(
        previousTimeouts.current,
        field,
        submitKeys,
        formSubmit,
        triggerApiCall
      );

      // notify change
      notifyChange({ [field]: value });
    }
  };

  const onModalCancel = () => {
    hideModal();
  };

  // on modal ok button click
  const onModalSubmit = async (
    modalInputs: Input[],
    buttonEffect: (arg0: object) => void
  ) => {
    // validate data inside popup
    const invalidInputKey = await isValidDocument(
      t,
      modalInputs,
      entityDocDataRef.current
    );

    if (invalidInputKey) {
      safeDispatch(
        setInvalidInputKey(invalidInputKey, false, invalidInputKeyRef)
      );
    } else {
      // modal was triggered by a button
      if (buttonEffect) {
        buttonEffect(options);
      }

      hideModal();
    }
  };

  // when form is submitted via submit button
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // call api
    triggerApiCall(docData);

    // notify change
    notifyChange(docData);
  };

  // update state and get visual autosave indicator
  const {
    docData: newDocData,
    invalidInputKey: newInvalidInputKey,
    modal: newModal,
    visibleElements: newVisibleElements,
    hideModal,
    onInputFormChange,
    onInputFormBlur,
  } = useInputForm(
    autosave,
    exists,
    isSaved(response, pending),
    entityDocRef.current,
    entityDocDataRef.current,
    entityDocDataExtendedRef.current,
    visibleElementsRef.current,
    invalidInputKeyRef.current,
    prevDocData,
    handleChange
  );

  useEffect(() => {
    visibleElementsRef.current = newVisibleElements;

    safeDispatch({
      docData: newDocData,
      invalidInputKey: newInvalidInputKey,
      modal: newModal,
      visibleElements: newVisibleElements,
    });
  }, [
    newDocData,
    newVisibleElements,
    newModal,
    newInvalidInputKey,
    visibleElementsRef,
  ]);

  // get input for render
  const visibleInputs = Utils.getVisibleInputsFromSteps(
    visibleElements.steps,
    true
  );

  const inputProps = {
    docData,
    onChange: onInputFormChange,
    onBlur: onInputFormBlur,
  };

  return (
    <FwSegment as={FwGrid} leftIcon={icon} name={label}>
      {entityDocDataRef.current && (
        <FwForm
          title={title ? t(`common|${title}`) : undefined}
          onSubmit={handleSubmit}
        >
          <FwGrid
            items={_.map(visibleInputs, (input) =>
              mapInputToField(input, inputProps)
            )}
            itemComponent={FwField}
          />
          {!autosave && (
            <FwButton
              responsive
              disabled={loading}
              leftIcon="RiSave3Fill"
              loading={loading}
              type="submit"
            >
              {t('common|Save')}
            </FwButton>
          )}
          <DocModal
            {...options}
            noDimmerClick
            data={docData}
            invalidInputKey={invalidInputKey}
            loadingInputKeys={loadingInputKeys}
            open={isOpen}
            visibleElements={visibleElements}
            onCancel={onModalCancel}
            onChangeData={onInputFormChange}
            onSubmit={onModalSubmit}
          />
        </FwForm>
      )}
    </FwSegment>
  );
};

EntityInfoContainer.propTypes = {
  autosave: bool,
  entityDoc: any,
  icon: string,
  label: string,
  title: string,
  api: object,
  entityKey: any,
  keyName: string,
  type: string,
  onSetKey: func,
  onEntityChange: func,
};

export default EntityInfoContainer;
