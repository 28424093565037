import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Template } from 'core/model';

const templateApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ templates: Template[] }>(`template`, params),
  get: (id: string, params: BaseApiParams) =>
    BaseApi.get<{ template: Template }>(`template/${id}`, params),
};

export default templateApi;
