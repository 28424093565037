import { bool, func, InferProps, element, string } from 'prop-types';

import { ctor } from '../helper';

const fwImagePT = {
  alt: string,
  background: bool,
  fallback: element,
  padding: string,
  src: string,
  onClick: func,
};

type FwImagePT = InferProps<typeof fwImagePT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwImageProps extends FwImagePT {}
class FwImageProps implements FwImagePT {
  constructor(props?: FwImagePT) {
    ctor(this, props);
  }
}

export { fwImagePT, FwImageProps };
