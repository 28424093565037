import { TFunction } from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  FwGrid,
  FwPageStoreProvider,
  useFwArea,
  useFwStore,
} from 'components/base';
import {
  DndItemProps,
  MaskRowProps,
  SetDndItemsProps,
} from 'components/base/containers/mask/FwMask.helpers';
import {
  DndProps,
  api,
  executeScript,
} from 'components/form/components/template/helpers/executeScript';
import { CONTENT_TYPE } from 'core/utils/constant';
import scrollToAnchor from 'core/utils/scrollToAnchor';
import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';

// import CustomPageGrid from '../../custom-page/components/CustomPageGrid';
import CustomPageContent from '../../custom-page/components/CustomPageContent';
import { DataProps } from '../function/document';
// import BaseGrid from '../../grid/BaseGrid';

const CustomPage = ({
  pageContents,
  invalidInputKey,
  invalidTrigger,
  handleDocValidation,
  ...props
}: any) => {
  const { area } = useFwArea();
  const { pageStore, pageContentStore, moduleStore } = useFwStore();

  const dragRef = useRef<DndProps['drag']>(null);
  const hoverRef = useRef(null);

  // list containing pageContentID and corresponding page content state setter
  const listenerRef = useRef<
    { contentID: string; setDndItems: SetDndItemsProps }[]
  >([]);

  // function called in page contents to retrieve pageContentID and state setter
  const onListen = useCallback(
    (contentID: string, setDndItems: SetDndItemsProps) => {
      const dndListeners = listenerRef.current;

      if (!_.some(dndListeners, { contentID })) {
        dndListeners.push({ contentID, setDndItems });
      }
    },
    []
  );

  // keeps drag information until drag begins
  const drag = useCallback(
    (
      maskRow: MaskRowProps,
      dragItems: DndItemProps[],
      contentID: string,
      index: number
    ) => {
      dragRef.current = {
        index,
        maskRow,
        contentID,
        items: dragItems,
      };
    },
    []
  );

  // keeps hover information whenever drag is over a possible drop
  const hover = useCallback(
    (
      hoverIndex: number,
      contentID: string,
      script: string,
      setLoading: (isLoading: boolean) => void,
      items: DndItemProps[]
    ) => {
      hoverRef.current = {
        hoverIndex,
        contentID,
        script,
        setLoading,
        items,
      };
    },
    []
  );

  const drop = useCallback(() => {
    // check if the drag element has been moved over a possible drop
    if (hoverRef.current) {
      const { hoverIndex, contentID, script, setLoading, items } =
        hoverRef.current;
      const { maskRow } = dragRef.current;

      // uses setViewContent to update viewContents at drop time
      _.forEach(listenerRef.current, ({ contentID: id, setDndItems }) => {
        setDndItems((prevs) => {
          // check if drag and drop in the same viewContent
          return id === contentID
            ? /* reset opacity */
              _.map(prevs, (prev) =>
                prev.opacity ? { ...prev, opacity: false } : prev
              )
            : /* remove the dragged element from the viewContent */
              _.filter(prevs, (prev) => prev.key !== maskRow.key);
        });
      });

      // execute script at drop time
      if (script) {
        executeScript(script, {
          store: { pageStore, pageContentStore, moduleStore },
          dnd: {
            drag: dragRef.current,
            drop: { contentID, index: hoverIndex, items },
            setLoading,
          },
          api,
        });
      }

      // empty dragRef and hoverRef
      dragRef.current = null;
      hoverRef.current = null;
    }
  }, [pageStore, pageContentStore, moduleStore]);

  // scroll to invalid input when invalidInputKey changes
  useEffect(() => {
    if (invalidInputKey && invalidTrigger?.timeStamp) {
      scrollToAnchor(`[name="${invalidInputKey}"]`);
      invalidTrigger.timeStamp = undefined;
    }
  }, [invalidInputKey, invalidTrigger]);

  const handleDocValidationScroll = useCallback(
    (t: TFunction, docData: DataProps) => {
      const inputKey = handleDocValidation(t, docData);

      if (inputKey) {
        scrollToAnchor(`[name="${inputKey}"]`);
      }

      return inputKey;
    },
    [handleDocValidation]
  );

  const visibleContents = _.map(
    sortByStepRowColumnKey(
      _.filter(
        pageContents,
        ({ areaOnly, areas, type }) =>
          utils.showInArea({ areaOnly, areas }, area) ||
          type === CONTENT_TYPE.section
      )
    ),
    ({ row, rowSpan, column, columnSpan, ...pc }) => ({
      key: pc.pageContentID,
      row,
      rowSpan,
      column,
      columnSpan,
      invalidInputKey,
      visible: pc?.visible,
      pc,
      dragAndDrop: {
        onListen,
        drag,
        drop,
        hover,
        contentID: pc.pageContentID,
      },
      handleDocValidationScroll: handleDocValidationScroll,
      ...props,
    })
  );

  // const contentKeyGetter = useCallback((input) => input.pageContentID, []);

  // const contentRenderer = useCallback(
  //   (pc) => (
  //     <CustomPageContent
  //       {...props}
  //       key={pc.pageContentID}
  //       area={area}
  //       pc={pc}
  //     />
  //   ),
  //   [area, props]
  // );

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <FwPageStoreProvider>
        <FwGrid itemComponent={CustomPageContent} items={visibleContents} />
      </FwPageStoreProvider>
    </DndProvider>
  );
};

export default CustomPage;
