import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Table } from 'core/model';

const tableApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ tables: Table[] }>('table', params),
  getNew: (params: BaseApiParams) =>
    BaseApi.get<{ table: Table }>('table/new', params),
  getTable: (tableKey: string, params: BaseApiParams) =>
    BaseApi.get<{ table: Table }>(`table/${tableKey}`, params),
  postTable: (table: Table, params: BaseApiParams) =>
    BaseApi.post('table', { table }, params),
  // putTable: (table: Table, params: BaseApiParams) =>
  //   baseApi.put(
  //     'table',
  //     { table },
  //     { ...params, validErrors: [400, 401, 404, 409, 500] }
  //   ),
};

export default tableApi;
