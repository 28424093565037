import { Stack } from '@chakra-ui/react';
import { func, InferProps, node, string } from 'prop-types';
import React, { FC } from 'react';

import { FwTitle } from 'components/base';

const FwForm: FC<Props> = ({ children, title, onSubmit }) => {
  return (
    <Stack as="form" direction="column" w="100%" onSubmit={onSubmit}>
      <FwTitle>{title}</FwTitle>
      {children}
    </Stack>
  );
};

const propTypes = { children: node, title: string, onSubmit: func };

export type Props = InferProps<typeof propTypes>;

FwForm.propTypes = propTypes;

export default FwForm;
