import { dtoCtor, Dto } from '../helper';
import { Row } from './row';

class TableData implements Dto {
  dtoName?: string;

  constructor(props?: Omit<TableData, 'dtoName'>) {
    dtoCtor(this, 'TableData', props);
  }

  rows?: Row[];
  currentPage: number;
  totalPage: number;
  rowPerPage: number;
  totalRow: number;
  allowSearchAll?: boolean;
}

export { TableData };
