import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import {
  FwModuleProvider,
  FwPageProvider,
  FwTemplatesProvider,
  useFwAuth,
} from 'components/base';
import { getToken } from 'core/utils/storage';

const PrivateRoute: FC<RouteProps> = ({
  component: Component,
  exact,
  path,
}) => {
  const token = getToken();
  const { user, checkUserAccess } = useFwAuth();

  if (user && token) {
    checkUserAccess();
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={({ history, location, match }) =>
        user && token ? (
          <FwTemplatesProvider>
            <FwModuleProvider>
              <FwPageProvider>
                <Component
                  history={history}
                  location={location}
                  match={match}
                />
              </FwPageProvider>
            </FwModuleProvider>
          </FwTemplatesProvider>
        ) : (
          <Redirect
            to={{
              pathname: path + 'public/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
