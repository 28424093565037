import _ from 'lodash';

import { UserFilter } from 'core/model';
import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';

import { AdminStructure } from '..';

const operatorOptions = _.map(OPERATOR, (value) => {
  return {
    key: value,
    value: value,
    text: value,
  };
});

const userFilterAS: AdminStructure<UserFilter> = [
  {
    key: 'editable',
    name: 'Editable',
    isReadOnly: true,
    type: FIELD_TYPE.checkbox,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    type: FIELD_TYPE.searchselect,
    isReadOnly: true,
    required: true,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'operator',
    name: 'Operator',
    type: FIELD_TYPE.select,
    dropdown: { dropdownID: 1, options: operatorOptions },
    isReadOnly: true,
    required: true,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'value',
    name: 'Value',
    type: FIELD_TYPE.text,
    isReadOnly: true,
    required: true,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const userFilterRules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'key',
    },
    script: {
      scriptID: 1,
      description: 'Get options from input api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`inputManagement`).then(res => res && res.data.inputs && callback({ options: {'key': res.data.inputs.map(i => ({ key: i.key, value: i.key, text: i.key, items: null })) } }))",
    },
  },
];

export { userFilterAS, userFilterRules };
