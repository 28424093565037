import { TFunction } from 'i18next';
import React, { FormEventHandler } from 'react';

import { useInputFormContainer } from 'components';
import {
  FwButton,
  FwField,
  FwForm,
  FwGrid,
  FwMessage,
  FwSegment,
} from 'components/base';

const Error = ({
  debugKey,
  id,
  loading,
  result,
  t,
  value,
  handleChange,
  handleSubmit,
}: {
  debugKey: string;
  id: string;
  loading: boolean;
  result: string;
  t: TFunction;
  value: string;
  handleChange: (fieldKey: string, value: string) => void;
  handleSubmit: FormEventHandler;
}) => {
  const inputs = [
    {
      key: 'id',
      name: t('Debug ID'),
      row: 1,
      column: 1,
      isDisabled: loading,
      subInputs: [],
      visible: true,
    },
    {
      key: 'key',
      name: t('Debug Key'),
      row: 2,
      column: 1,
      isDisabled: loading,
      subInputs: [],
      visible: true,
    },
    {
      key: 'value',
      name: t('Value'),
      row: 3,
      column: 1,
      isDisabled: loading,
      required: true,
      subInputs: [],
      visible: true,
    },
  ];

  const { fields } = useInputFormContainer(
    false,
    undefined,
    { id, key: debugKey, value },
    inputs,
    handleChange
  );

  const buttonProps = {
    leftIcon: 'RiKey2Fill',
    loading: loading,
    primary: true,
    responsive: true,
    small: true,
  };

  return (
    <FwSegment>
      <FwForm onSubmit={handleSubmit}>
        <FwGrid items={fields} itemComponent={FwField} />
        <FwButton {...buttonProps} type="submit">
          {t('common|Decode')}
        </FwButton>
      </FwForm>
      {result && <FwMessage>{result}</FwMessage>}
    </FwSegment>
  );
};

Error.propTypes = {};

export default Error;
