import _ from 'lodash';

const keyIsProtected = (protectedKeys: any, key: any) => {
  return protectedKeys.includes(key);
};

// prevent table filtering on calendar start and end columns
const filterAgendaTableColumns = (table: any, maskData: any) => {
  // find column keys
  const {
    document: { start, end },
  } = maskData;
  const protectedKeys = [start, end];

  // add preventSort and preventFilter properties
  const protectedColumns = _.map(
    _.filter(table.columns, (c) => keyIsProtected(protectedKeys, c.key)),
    (pc) => ({ ...pc, preventSort: true, preventFilter: true })
  );
  const otherColumns = _.filter(
    table.columns,
    (c) => !keyIsProtected(protectedKeys, c.key)
  );

  // rebuild columns
  const newColumns = [...otherColumns, ...protectedColumns];

  // sort and return
  return _.sortBy(newColumns, (c) => c.position);
};

export { filterAgendaTableColumns };
