import { RXInputMask } from 'incr-regex-package';

const defaultValue = '';
const defaultPattern = /.*/;
const missingChar = '＿';
const nextChar = '⋯';
const optionalMatch = '◑';

const getValueMatch = (
  pattern,
  previousValidatedInput,
  input,
  caretSelectionEnd
) => {
  let matchedValue = input;
  let newCaretPosition = caretSelectionEnd;

  // if pattern is valid
  if (pattern && pattern.length > 0) {
    try {
      // build default mask for pattern
      const mask = new RXInputMask({
        pattern: pattern || defaultPattern,
        value: defaultValue,
      });

      // convert strings to arrays
      const emptyValCharArray = [...mask.emptyValue];
      const inputCharArray = [...input];

      // ignore already-processed input chars
      const ignoreIndexArray = [-1];

      // validated text from input
      let text = defaultValue;

      // apply previously validated input
      let startIndex =
        previousValidatedInput && input.startsWith(previousValidatedInput)
          ? previousValidatedInput.length
          : 0;

      if (startIndex > 0) {
        text = previousValidatedInput;
        ignoreIndexArray.push(startIndex - 1);

        if (
          mask.emptyValue.endsWith(optionalMatch) ||
          mask.emptyValue.endsWith(nextChar)
        ) {
          startIndex = 0;
        }
      }

      // iterate on mask chars
      for (let i = startIndex; i < emptyValCharArray.length; i++) {
        // non mandatory char
        if (
          [missingChar, optionalMatch, nextChar].includes(emptyValCharArray[i])
        ) {
          // if optional char, allow next char test
          if ([optionalMatch].includes(emptyValCharArray[i])) {
            i--;
          }

          // iterate on input chars
          for (
            let j =
              ignoreIndexArray.length > 1
                ? Math.max(...ignoreIndexArray) + 1
                : 0;
            j < inputCharArray.length;
            j++
          ) {
            // test string with 1 more char
            const test = `${text}${inputCharArray[j]}`;

            const testMask = new RXInputMask({
              pattern: pattern || defaultPattern,
              value: test,
            });

            const maskVal =
              testMask.value === testMask.emptyValue
                ? undefined
                : testMask.value;
            const validatedTest =
              maskVal && maskVal.length >= test.length
                ? maskVal.substring(0, test.length)
                : maskVal
                ? maskVal
                : undefined;

            if (validatedTest) {
              // write validated test to text
              text = validatedTest;
              ignoreIndexArray.push(j);
              break;
            } else {
              // ignore invalid char now
              ignoreIndexArray.push(j);

              if (test.length >= newCaretPosition) {
                // cut text to caret position
                newCaretPosition -= 1;
                break;
              }
            }
          }
        }
        // mandatory char
        else if (inputCharArray.length > 0) {
          if (
            i < inputCharArray.length &&
            emptyValCharArray[i] === inputCharArray[i]
          ) {
            // ignore as it already exists in input
            ignoreIndexArray.push(i);
          } else {
            // allow 1 additional char for autoadded char
            newCaretPosition += 1;
          }

          // add mandatory char to validated text
          text = `${text}${emptyValCharArray[i]}`;
        }
        // input is empty
        else {
          break;
        }

        if (
          !(Math.max(...ignoreIndexArray) < inputCharArray.length - 1) ||
          text.length >= newCaretPosition
        ) {
          // all input has been processed or cut text to caret position
          break;
        }
      }

      // write validated text to value
      matchedValue = text;
    } catch (e) {
      // do nothing
    }
  }

  return matchedValue;
};

export default getValueMatch;
