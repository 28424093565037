import { InferProps, string } from 'prop-types';
import React, { ChangeEventHandler, FC, useState } from 'react';

import utils from 'core/utils/utils';

import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';

const FwPasswordInput: FC<Props & CommonInputProps> = ({
  // direct props
  placeholder,
  // common input props
  defaultValue,
  value,
  onChange,
  ...props
}) => {
  const placeholderText = value || defaultValue ? '••••••' : placeholder;

  // useState
  const [text, setText] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setText(e.target.value);
    onChange && onChange(e, utils.getNameValueFromEData(e));
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Base
      rightIcon={
        text ? (showPassword ? 'RiEyeOffFill' : 'RiEyeFill') : undefined
      }
      placeholder={placeholderText}
      type={showPassword ? 'text' : 'password'}
      value={text}
      onChange={handleInputChange}
      onRightIconClick={handleToggleShowPassword}
      {...props}
    />
  );
};

const propTypes = { placeholder: string };

export type Props = InferProps<typeof propTypes>;

FwPasswordInput.propTypes = propTypes;

export default FwPasswordInput;
