import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { Module, Route } from 'core/model';

type ModuleContext = {
  module: Module;
  moduleRoutes: Route[];
  allModuleRoutes: Route[];
  setModule: Dispatch<SetStateAction<Module>>;
};

const FwModuleContext: Context<ModuleContext> = createContext(undefined);

export { FwModuleContext };
