import { TFunction } from 'i18next';
import _ from 'lodash';

import { Card, MaskRowProps, getMaskRowText } from '../FwMask.helpers';
import { MaskStructure } from '../FwMask.structures';

// todo wip#125 refactor
const formToCard = (
  maskStructure: MaskStructure,
  maskRow: MaskRowProps,
  t: TFunction
): Card => {
  // convert a maskRow into a card
  const { key, data: rowData, color } = maskRow || {};
  const title = getMaskRowText(maskStructure, rowData, t);

  return {
    title,
    key,
    color,
    row: maskRow,
  };
};

const filterCards = (cards: Card[], searchText: string) =>
  searchText && _.isArray(cards)
    ? /* filter the cards that have searchText in their title property*/ _.filter(
        cards,
        (card) =>
          // check without considering case sensitivity
          _.includes(card.title?.toUpperCase(), searchText.toUpperCase())
      )
    : cards;

const updateCards = (cards: Card[], hoverIndex: number, cardToInsert: Card) => {
  let newCards = cards;

  if (cardToInsert && _.isArray(cards)) {
    // remove the card to insert
    newCards = cards.filter((card) => card.key !== cardToInsert?.key);
    // add the card to be inserted at the hoverIndex position
    newCards.splice(hoverIndex, 0, { ...cardToInsert, opacity: true });
  }

  return newCards;
};

export { filterCards, formToCard, updateCards };
