import { InferProps, node } from 'prop-types';
import React, { FC, useState } from 'react';
// import utils from 'core/utils/utils';

import { useFwAuth } from '../auth';
import { ModuleStore, FwModuleStoreContext } from './FwModuleStoreContext';

const FwModuleStoreProvider: FC<Props> = ({ children }) => {
  const { user } = useFwAuth();

  const [moduleStore, setModuleStore] = useState<ModuleStore>({ user });

  return (
    <FwModuleStoreContext.Provider
      value={{
        moduleStore,
        setModuleStore,
      }}
    >
      {children}
    </FwModuleStoreContext.Provider>
  );
};

const propTypes = { children: node };

type Props = InferProps<typeof propTypes>;

FwModuleStoreProvider.propTypes = propTypes;

export { FwModuleStoreProvider };
