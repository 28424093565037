import _ from 'lodash';

import { TemplateManagement } from 'core/model';
import { FIELD_TYPE, FORM_LAYOUT_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const optionFormLayoutType = [];
_.forEach(FORM_LAYOUT_TYPE, function (value) {
  optionFormLayoutType.push({
    key: value,
    value: value,
    text: value,
  });
});

const templateManagementAS: AdminStructure<TemplateManagement> = [
  {
    key: 'name',
    name: 'Name',
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'type',
    name: 'Type',
    type: FIELD_TYPE.select,
    required: true,
    dropdown: { dropdownID: 1, options: optionFormLayoutType },
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'position',
    name: 'Position',
    type: FIELD_TYPE.number,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'additionalData',
    name: 'Data',
    type: FIELD_TYPE.textarea,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

export { templateManagementAS };
