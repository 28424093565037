import { GridItem } from '@chakra-ui/react';
import React, { FC } from 'react';

import { fwGridItemPT, FwGridItemProps } from 'core/model';

const FwGridItem: FC<FwGridItemProps> = ({
  children,
  column,
  columnSpan,
  row,
  rowSpan,
}) => {
  return (
    <GridItem
      colStart={column}
      colSpan={columnSpan || 1}
      rowStart={row}
      rowSpan={rowSpan || 1}
    >
      {children}
    </GridItem>
  );
};

FwGridItem.propTypes = fwGridItemPT;

export default FwGridItem;
