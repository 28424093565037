import { dtoCtor } from '../helper';
import { EvaluationDesign } from './evaluationDesign';
import { Viewable } from './viewable';

class Row extends Viewable {
  dtoName?: string;

  constructor(props?: Omit<Row, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'Row', props);
  }

  rowID?: string;
  number: number;
  key?: string;
  type?: string;
  creator?: string;
  status?: string;
  formLayoutName?: string;
  formLayoutId?: string;
  data?: string;
  metaData?: object;
  evaluationDesigns?: EvaluationDesign[];
  from?: string;
  to?: string;
}

export { Row };
