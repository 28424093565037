import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import _ from 'lodash';

import { getToken } from 'core/utils/storage';

export type CloseHandler = (error?: Error) => void;

export type HubCallback = (...args: unknown[]) => void;

export type HubHandler = {
  name: string;
  callback: HubCallback;
};

const buildConnection = (hubName: string, channel = undefined) => {
  // seems useless when using in browser as auth is done via cookie
  const token = getToken();

  return new HubConnectionBuilder()
    .withUrl(`/api/${hubName}${channel ? `?channel=${channel}` : ''}`, {
      accessTokenFactory: () => token,
    })
    .build();
};

const registerHandlers = (
  connection: HubConnection,
  handlers: HubHandler[]
) => {
  _.forEach(handlers, (handler) => {
    connection.on(handler.name, handler.callback);
  });
};

const configureConnection = (
  hubConnection: HubConnection,
  onCloseHandler: (error?: Error) => void
) => {
  hubConnection.serverTimeoutInMilliseconds = 90000;
  hubConnection.onclose(onCloseHandler);
};

class BaseHub {
  constructor(
    hubName: string,
    onCloseHandler: (error?: Error) => void,
    channel = undefined
  ) {
    this.restartCount = 0;

    // build connection
    this.connection = buildConnection(hubName, channel);

    // define lifetime handlers
    configureConnection(this.connection, onCloseHandler);

    // build event handlers array
    const handlers: HubHandler[] = [];

    this.addHandler = (name: string, callback: HubCallback) => {
      handlers.push({ name, callback });
    };

    // link handlers to connection
    this.subscribe = () => {
      registerHandlers(this.connection, handlers);
    };

    // start connection
    this.startConnection = async () => {
      this.startTime = performance.now();

      await this.connection
        .start()
        .then(() => (this.restartCount = 0))
        .catch(() => {
          console.error('Error while establishing connection');
          setTimeout(() => {
            this.restartTime = performance.now();
            this.restartCount += 1;

            this.startConnection();
          }, 5000);
        });
    };

    // terminate connection
    this.stopConnection = () => {
      if (this.connection) {
        this.connection.stop();
      }
    };
  }

  connection: HubConnection;
  restartCount: number;
  restartTime: number;
  startTime: number;
  addHandler: (arg0: string, arg1: HubCallback) => void;
  addHandlers: (arg0: Record<string, HubCallback>) => void;
  startConnection: () => Promise<void>;
  stopConnection: () => void;
  subscribe: () => void;
}

export type Hub = new (
  onCloseHandler: (error?: Error) => void,
  channel?: string
) => BaseHub;

export default BaseHub;
