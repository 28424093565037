import { dtoCtor, Dto } from '../helper';
import { EvalValue } from './evalValue';
import { Filter } from './filter';
import { Process } from './process';

class BatchTemplate implements Dto {
  dtoName?: string;

  constructor(props?: Omit<BatchTemplate, 'dtoName'>) {
    dtoCtor(this, 'BatchTemplate', props);
  }

  batchTemplateId?: string;
  key?: string;
  name?: string;
  process?: Process;
  additionalData?: object;
  filters?: Filter[];
  edits?: EvalValue[];
}

export { BatchTemplate };
