import {
  Box,
  HTMLChakraProps,
  Popover,
  PopoverAnchor,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/react';
import _ from 'lodash';
import { arrayOf, bool, InferProps } from 'prop-types';
import React, { FC, RefObject } from 'react';

import { commonPT } from 'core/model';

import { PopContent } from './components';

const FwPop: FC<Props & Omit<PopoverProps, 'children'>> & {
  Anchor: FC<HTMLChakraProps<typeof PopoverAnchor>>;
  Content: FC<PopoverContentProps>;
  Trigger: FC<HTMLChakraProps<typeof PopoverTrigger>>;
} = ({ children, inline, popRef, open, ...props }: Props & PopoverProps) => {
  return (
    <Box ref={popRef} display={inline ? 'inline-block' : undefined}>
      <Popover
        {...props}
        isLazy
        matchWidth
        gutter={-1}
        isOpen={open}
        placement="bottom-start"
      >
        {children}
      </Popover>
    </Box>
  );
};

const propTypes = {
  ...commonPT,
  children: arrayOf((prop, index, componentName) => {
    let error: Error;
    const childrenCount = React.Children.count(prop);

    if (childrenCount !== 2) {
      error = new Error(`${componentName} only supports having two children`);
    } else {
      // make sure children are composed of, first, 1 Anchor then 1 Content
      const expectedTypes = {
        0: [PopoverAnchor.name, PopoverTrigger.name],
        1: [PopContent.name],
      };
      const idx = parseInt(index);

      if (!_.includes(expectedTypes[idx], prop[index].type.name)) {
        error = new Error(
          `${componentName} ${
            idx === 0 ? 'first' : 'second'
          } child component must be of type ${expectedTypes[idx]}`
        );
      }
    }

    return error;
  }).isRequired,
  open: bool,
  inline: bool,
};

export interface Props extends InferProps<typeof propTypes> {
  popRef?: RefObject<HTMLDivElement>;
}

FwPop.propTypes = propTypes;

FwPop.Anchor = PopoverAnchor;
FwPop.Content = PopContent;
FwPop.Trigger = PopoverTrigger;

export default FwPop;
