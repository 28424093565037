import { useColorMode } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';

import { FwSettingsProviderProps } from 'core/model';

import { FwSettingsContext } from './FwSettingsContext';

const useFwSettings = () => {
  const { dispatchSettings: overload, ...settingsContext } =
    useContext(FwSettingsContext);
  const { toggleColorMode } = useColorMode();

  const dispatchSettings = useCallback(
    (settings: FwSettingsProviderProps) => {
      overload(settings, toggleColorMode);
    },
    [overload, toggleColorMode]
  );

  return { ...settingsContext, dispatchSettings, toggleColorMode };
};

export { useFwSettings };
