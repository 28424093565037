import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, Input } from 'core/model';

const inputApi = {
  getById: (id: string, params: BaseApiParams) =>
    BaseApi.get<{ input: Input }>(`input/${id}`, params),
  post: (input: Input, params: BaseApiParams) =>
    BaseApi.post('input', input, params),
  putAS: (autoSaveInfo: AutoSaveInfo<Input, string>, params: BaseApiParams) =>
    BaseApi.put('input/AS', { autoSaveInfo }, params),
  delete: (FieldId: string, PageContentId: string, params: BaseApiParams) =>
    BaseApi.delete(`input/${FieldId}/${PageContentId}`, params),
};

export default inputApi;
