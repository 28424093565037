import _ from 'lodash';
import { Dispatch, ReducerState } from 'react';

import { FwInputProps, Option } from 'core/model';
// import { getDateFromISO } from 'core/utils/date';

// import {
//   buildValueString,
//   getDateFromISOType,
//   toggleDateInArray,
// } from '../datetime/FwInput.Datetime.helpers';

type UseFwControllableState<CT> = [
  FwControllableState<CT>,
  {
    setContentValue: (contentValue: CT) => void;
    setDisplayValue: (displayValue: string) => void;
    forceContentValue: (contentValue: unknown) => void;
    setShouldNotify: (shouldNotify: boolean) => void;
  }
];

type UseReducerInitializer = <CT>(
  arg: string
) => ReducerState<
  (
    oldState: FwControllableState<CT>,
    action: FwAction<CT>
  ) => FwControllableState<CT>
>;

type ActionApplier<CT> =
  | ((
      c: FwAction<CT>
    ) => FwControllableState<CT>) /* only in initialisation, function must return state */
  | Dispatch<FwAction<CT>> /* in all other cases, function will return void */;

const initValue = '';
const initState = {
  dtoValue: initValue,
  displayValue: initValue,
  contentValue: undefined,
};

const reducer = <CT>(
  oldState: FwControllableState<CT>,
  action: FwAction<CT>
): FwControllableState<CT> => {
  const newState = action(oldState);

  // todo: use defaultShouldUpdateState?
  return !_.isEqual(oldState, newState)
    ? { ...oldState, ...newState }
    : oldState;
};

// prepare state change  procedure from new value & launch it
const triggerAction = <CT, V extends string | CT>(
  applyAction: ActionApplier<CT>,
  stateBuilder?: FwControllableStateBuilder<CT, V>,
  value?: V,
  params?: FwControllableParams,
  shouldNotify?: boolean
) => {
  // define action for reducer
  const action = buildAction(stateBuilder, value, params, shouldNotify);

  // dispatch action to tell reducer how to update state
  return applyAction(action);
};

const defaultShouldUpdateState = <CT>(
  prevState: FwControllableState<CT>,
  nextState: FwControllableState<CT>
) => {
  // find if any state value changed
  return !_.isMatch(prevState, nextState);
};

// the options that inform the hook's behavior
interface FwControllableParams<
  M extends boolean | undefined = boolean | undefined
> extends FwInputProps {
  defaultValue: string;
  editable?: boolean;
  value: string;
  multiple?: M /* indicates that many values are stored in the valueString */;
  options?: Option[];
  // properties for Datetime...
  type?: string;
  range?: boolean;
  // etc.
}

// the customizable callbacks that will run in the hook
interface FwControllableStateCallbacks<CT> {
  // the name parameter of onChange
  name?: string;
  // the callback fired when the value changes
  onChange?: (
    e: React.ChangeEvent<Element>,
    data: {
      name: string;
      value: string;
    }
  ) => void;
  // the function that determines if the state value should be updated
  shouldUpdate?: (
    prev: FwControllableState<CT>,
    next: FwControllableState<CT>
  ) => boolean;
  // informs the reducer on how to build the displayValue
  displayStringBuilder?: (values: string | string[]) => string;
  // informs the reducer on what constitutes a valid stringValue
  valueStringValidator?: (valueString: string) => boolean;
  // informs the reducer on how to build the contentValue
  contentValueBuilder?: () => unknown;
}

// the different stateful values that are tracked
interface FwControllableState<CT> {
  // value formatted as a valid dto FasterWeb string
  dtoValue?: string;
  // value destined to be displayed to user
  displayValue?: string;
  // interfaceable value with components exposing typed-value prop
  contentValue?: CT;
  //
  shouldNotify?: boolean;
}

type FwAction<CT> = (
  oldState: FwControllableState<CT>
) => FwControllableState<CT>;

const dtoSeparator = '|';
interface FwControllableStateBuilderParams<CT, V extends string | CT> {
  value?: V;
  oldState?: FwControllableState<CT>;
  params?: FwControllableParams;
  shouldNotify?: boolean;
}

type FwControllableStateBuilder<CT, V extends string | CT = CT> = (
  args: FwControllableStateBuilderParams<CT, V>
) => FwControllableState<CT>;

const buildAction = <CT, V extends string | CT>(
  buildStateFromValue: FwControllableStateBuilder<CT, V>,
  value?: V,
  params?: FwControllableParams,
  shouldNotify?: boolean
): FwAction<CT> => {
  return (oldState?: FwControllableState<CT>) => {
    return buildStateFromValue({ value, oldState, params, shouldNotify });
  };
};

type FwControllableStateBuilders<CT> = {
  buildStateFromContent: FwControllableStateBuilder<CT>;
  buildStateFromDisplay: FwControllableStateBuilder<CT, string>;
  buildStateFromDto: FwControllableStateBuilder<CT, string>;
  buildStateFromForcedContent: FwControllableStateBuilder<CT>;
};

export {
  // constants
  dtoSeparator,
  // objects
  initState,
  initValue,
  // types
  ActionApplier,
  FwAction,
  FwControllableStateBuilder,
  FwControllableStateBuilders,
  FwControllableStateCallbacks,
  FwControllableParams,
  FwControllableState,
  UseFwControllableState,
  UseReducerInitializer,
  // functions
  buildAction,
  defaultShouldUpdateState,
  reducer,
  triggerAction,
};
