import { dtoCtor, Dto } from '../helper';
import { Clickable } from './clickable';
import { Design } from './design';
import { Modal } from './modal';
import { Rule } from './rule';
import { Step } from './step';

class Template implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Template, 'dtoName'>) {
    dtoCtor(this, 'Template', props);
  }

  templateId?: string;
  key?: string;
  name?: string;
  position: number;
  type?: string;
  additionalData?: object;
  design?: Design;
  steps?: Step[];
  rules?: Rule[];
  clickables?: Clickable[];
  modals?: Modal[];
  permission?: string;
}

export { Template };
