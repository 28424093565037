import { Context, createContext } from 'react';

import { BatchTemplate, Template } from 'core/model';

type FwTemplates = {
  processes: BatchTemplate[];
  templates: Template[];
};

const FwTemplatesContext: Context<FwTemplates> = createContext(undefined);

export { FwTemplatesContext };
