import _ from 'lodash';
import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';

import templateManagementApi from 'api/template/templateManagementApi';
import { EntityInfo, Table } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import {
  Design as D,
  getAdminInputs,
  TemplateManagement as T,
} from 'core/model';

const Template: FC<Props> = ({
  t,
  template,
  tableStruct,
  handleAddRow,
  handleSetKey,
  handleEntityChange,
}) => {
  const newTemplate = {
    ...template,
    additionalData: JSON.stringify(template.additionalData),
  };

  // set new design if it doesn't exist in template
  if (newTemplate && _.isNil(newTemplate['design'])) {
    newTemplate['design'] = new D();
  }

  const castT: T = Object.assign(new T(), newTemplate);
  const inputs = getAdminInputs(castT, null, t);
  const templateEntityDoc = getEntityDoc(newTemplate, inputs);

  const castD: D = Object.assign(new D(), newTemplate.design);
  const designInputs = getAdminInputs(castD, null, t);
  const designEntityDoc = getEntityDoc(newTemplate, designInputs);

  return (
    <>
      {template && (
        <EntityInfo
          autosave
          api={templateManagementApi}
          entityDoc={templateEntityDoc}
          keyName={'templateId'}
          entityKey={template.templateId}
          type={'template'}
          title={'Template'}
          onSetKey={handleSetKey}
          onEntityChange={handleEntityChange}
        />
      )}
      {template.templateId && (
        <>
          <EntityInfo
            autosave
            api={templateManagementApi}
            entityDoc={designEntityDoc}
            keyName={'key'}
            entityKey={template.templateId}
            type={'template'}
            title={'Design'}
          />
          <Table
            allowAddRow
            allowDoubleClick
            table={tableStruct}
            module={template}
            dataType="View"
            dataKey="Key"
            handleAddRow={handleAddRow}
          />
        </>
      )}
    </>
  );
};

const propTypes = {
  t: func.isRequired,
  template: any,
  tableStruct: any,
  handleAddRow: func.isRequired,
  handleSetKey: func.isRequired,
  handleEntityChange: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

Template.propTypes = propTypes;

export default withTranslation()(Template);
