import { ButtonGroup } from '@chakra-ui/react';
import { bool, func, InferProps, string } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwButton } from 'components/base';

const ModalButton: FC<ModalButtonProps> = ({
  disabled,
  submitName,
  cancelName,
  overrideOnSubmit,
  overrideOnCancel,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup>
      {submitName && (
        <FwButton primary small disabled={disabled} onClick={overrideOnSubmit}>
          {t(`common|${submitName}`)}
        </FwButton>
      )}
      {cancelName && (
        <FwButton small disabled={disabled} onClick={overrideOnCancel}>
          {t(`common|${cancelName}`)}
        </FwButton>
      )}
    </ButtonGroup>
  );
};

const propTypes = {
  disabled: bool,
  submitName: string,
  cancelName: string,
  overrideOnCancel: func,
  overrideOnSubmit: func,
};

export type ModalButtonProps = InferProps<typeof propTypes>;

ModalButton.propTypes = propTypes;

export default ModalButton;
