import _ from 'lodash';
import { MutableRefObject } from 'react';

import { FwStore } from 'components/base';
import { Document } from 'core/model';
import utils, { DataProps } from 'core/utils/utils';

import { DocState, VisibleElements } from '../function/document';

import { getDocData, getVisibleElements, showModal, Suggestions } from '.';

const updateState = (
  newDocData: DataProps,
  documentRef: MutableRefObject<Document>,
  docDataRef: MutableRefObject<DataProps>,
  visibleElementsRef: MutableRefObject<VisibleElements>,
  invalidInputKeyRef: MutableRefObject<string>,
  dispatch: (arg0: Partial<DocState>) => void,
  store: FwStore,
  inputKey: string /* consider sending to executeScript */
) => {
  const updatedKeys = _.map(
    Object.keys(newDocData) || [],
    // remove index of row in subFieldKey
    (k) =>
      utils.isSubFieldDataKey(k) ? utils.removeIndexFromSubInputKey(k) : k
  );

  // todo #585 make sure the first is also the only one
  // find the first collection item which trigger change
  const itemKeyChange = Object.keys(newDocData).find((k) =>
    utils.isSubFieldDataKey(k)
  );
  // if found then get its index and its collection key
  const indexItemChange = itemKeyChange
    ? utils.getIndexFromSubInputKey(itemKeyChange)
    : undefined;
  const collectionKeyChange = itemKeyChange
    ? itemKeyChange.substring(0, itemKeyChange.indexOf('|'))
    : undefined;

  // update data
  _.forOwn(newDocData, (value, key) => {
    if (!utils.isSubFieldDataKey(key)) {
      docDataRef.current[key] = value;
    }
  });

  // apply autofill
  docDataRef.current = getDocData(
    documentRef.current,
    store,
    docDataRef.current,
    updatedKeys,
    indexItemChange
  );

  // apply autohide
  visibleElementsRef.current = getVisibleElements(
    documentRef.current,
    docDataRef.current,
    store,
    updatedKeys,
    visibleElementsRef.current,
    indexItemChange
  );

  // show modal
  const modal = showModal(
    documentRef.current,
    docDataRef.current,
    updatedKeys,
    false,
    store,
    indexItemChange
  );

  // fetch suggestions
  Suggestions.getSuggestions(
    dispatch,
    documentRef.current,
    docDataRef.current,
    collectionKeyChange
      ? /* filter out collection key in updatedKeys */
        updatedKeys.filter((k) => k !== collectionKeyChange)
      : updatedKeys,
    visibleElementsRef.current,
    store,
    indexItemChange,
    inputKey
  );

  // trigger render
  dispatch({
    docData: docDataRef.current,
    visibleElements: visibleElementsRef.current,
    modal,
    invalidInputKey: _.includes(
      [...updatedKeys, itemKeyChange],
      invalidInputKeyRef.current
    )
      ? null
      : undefined,
  });
};

export default updateState;
