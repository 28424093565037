import { HTMLChakraProps, Input, InputGroup, Textarea } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, func, InferProps, string } from 'prop-types';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { useFwTheme } from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import utils from 'core/utils/utils';

import { CommonInputProps } from '../FwInput';
import { getShadowByState } from '../FwInput.helpers';
import { BaseInputIcon } from './components';
import { getInputType } from './FwInput.Base.helpers';

const Base = forwardRef(
  (
    {
      // direct props
      leftIcon,
      loading,
      pattern,
      placeholder,
      rightIcon,
      rounded,
      onLeftIconClick,
      onRightIconClick,
      // common input props
      clearable,
      defaultValue,
      disabled,
      saved,
      testId,
      type = FIELD_TYPE.text,
      unchanged = true,
      value,
      onChange,
      ...props
    }: Props & CommonInputProps,
    ref
  ) => {
    const defaultText = value || defaultValue || '';
    const isUpdatable = onChange || !value;

    const isTxtarea = type === FIELD_TYPE.textarea;
    const { bg } = useFwTheme();
    const shadow = getShadowByState(saved, unchanged);
    const [text, setText] = useState(defaultText);

    useEffect(() => {
      setText(defaultText);
    }, [defaultText]);

    // functions
    const onClear = () => {
      const newValue = '';

      setText(newValue);

      const event = { target: { value: newValue } } as ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement
      >;
      onChangeData(event);
    };

    const onChangeData: ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
      if (onChange) {
        onChange(e, utils.getNameValueFromEData(e));
      }
    };

    const onInputChange: ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
      if (isUpdatable) {
        let newValue = e.target.value;

        if (!_.isNil(pattern)) {
          const sEn = e.target.selectionEnd;
          newValue = getValueMatch(pattern, text, e.target.value, sEn);
          e.target.value = newValue;
        }

        setText(newValue);
        onChangeData(e);
      }
    };

    // styles
    const inputStyle = {
      ps: leftIcon ? '10' : undefined,
      pe: rightIcon ? '10' : undefined,
      bg,
      borderRadius: rounded ? '2em' : undefined,
      sx: {
        ['&, &:focus, &[data-focus], &[readonly], &[data-readonly], &[aria-readonly=true]']:
          { boxShadow: shadow },
      },
    };

    const iconStyle = {
      sx: {
        ['input:not(focus) ~ &, textarea:not(focus) ~ &']: {
          color: 'gray.300',
        },
        ['input:focus ~ &, textarea:focus ~ &']: { color: 'inherit' },
      },
    };

    // render
    const clearIcon =
      clearable && text ? (
        <BaseInputIcon
          right
          disabled={disabled || loading}
          loading={loading}
          name={'RiCloseFill'}
          onClick={onClear}
          {...iconStyle}
        />
      ) : undefined;

    const element = isTxtarea ? (
      <Textarea
        disabled={disabled}
        placeholder={placeholder}
        ref={ref as ForwardedRef<HTMLTextAreaElement>}
        value={text}
        onChange={onInputChange}
        {...inputStyle}
        {...props}
      />
    ) : (
      <Input
        data-testid={testId}
        disabled={disabled}
        placeholder={placeholder}
        ref={ref as ForwardedRef<HTMLInputElement>}
        type={getInputType(type)}
        value={text}
        onChange={onInputChange}
        {...inputStyle}
        {...props}
      />
    );

    return (
      <InputGroup>
        {element}
        {leftIcon && (
          <BaseInputIcon
            left
            disabled={disabled || loading}
            name={leftIcon}
            onClick={onLeftIconClick}
            {...iconStyle}
          />
        )}
        {clearIcon ||
          (rightIcon && (
            <BaseInputIcon
              right
              disabled={disabled || loading}
              loading={loading}
              name={rightIcon}
              onClick={onRightIconClick}
              {...iconStyle}
            />
          ))}
      </InputGroup>
    );
  }
);

const propTypes = {
  defaultValue: string,
  leftIcon: string,
  loading: bool,
  pattern: string,
  placeholder: string,
  rightIcon: string,
  rounded: bool,
  onLeftIconClick: func,
  onRightIconClick: func,
};

type PartialProps = InferProps<typeof propTypes>;
type Omitted = 'rounded';
export type Props = PartialProps & Omit<HTMLChakraProps<typeof Input>, Omitted>;

Base.propTypes = propTypes;
Base.displayName = 'Base';

export default Base;
