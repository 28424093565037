import BaseApi, { BaseApiParams } from 'api/baseApi';

type ExternalQuery = {
  method: string;
  url: string;
  body: string;
};

const externalApi = {
  post: (query: ExternalQuery, params?: BaseApiParams) =>
    BaseApi.post<ExternalQuery, unknown>('external', query, params),
};

export default externalApi;
