import React, { FC, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RouteChildrenProps, useHistory, withRouter } from 'react-router-dom';

import templateManagementApi from 'api/template/templateManagementApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { TemplateManagement } from 'core/model';
import { pushUrl, setTableStruct } from 'scenes/dashboard/helpers/admin';

import tableStructure from '../../helpers/tableStructure';
import Template from './Template';

const TemplateContainer: FC<RouteChildrenProps> = ({ match: { params } }) => {
  const { templateKey } = params as { templateKey: string };
  const history = useHistory();

  const argsManagement = useRef(templateKey ? [templateKey] : []);

  const [currentKey, setCurrentKey] = useState('');

  const { fetched, pending } = useApi(
    templateManagementApi.getByKey,
    argsManagement.current
  );

  // set template and initialize areas to empty array
  const [template, setTemplate] = useState(
    templateKey ? undefined : new TemplateManagement()
  );

  // receive template from backend and set in state
  useEffect(() => {
    if (!pending && fetched) {
      setTemplate(fetched.template);
    }
  }, [fetched, pending]);

  const handleSetKey = (id) => {
    // set in state
    setTemplate({ ...template, templateId: id });

    // change url when a new template was create
    pushUrl(history, currentKey);
  };

  const handleEntityChange = (data) => {
    data?.key && setCurrentKey(data?.key);
  };

  // set filter on step contents table
  const tableStruct = setTableStruct(
    'FormLayoutID',
    template?.templateId,
    tableStructure
  );

  const tempalteProps = {
    template,
    tableStruct,
    handleAddRow: () => pushUrl(history, 'newPage'),
    handleSetKey,
    handleEntityChange,
  };

  // render
  return !pending && template ? <Template {...tempalteProps} /> : <FwSpinner />;
};

export default withRouter(withTranslation()(TemplateContainer));
