import { TFunction } from 'i18next';
import L from 'leaflet';
import _ from 'lodash';
import { useMapEvent } from 'react-leaflet';

import { defaultAccent } from 'config/theme/constants';
import { mapStyleToLeaflet } from 'core/mapper';

import { getMaskRowText } from '../FwMask.helpers';
import { MaskStructure, MapView } from '../FwMask.structures';

// todo wip#125 refactor
const DblClickComponent = () => {
  const map = useMapEvent('dblclick', (e: any) => {
    const zoom = map.getZoom();
    map.setView(e.latlng, zoom + 2);
  });

  return null;
};

// todo wip#125 refactor
const RightClickComponent = () => {
  const map = useMapEvent('contextmenu', () => {
    const zoom = map.getZoom();
    map.setZoom(zoom - 2);
  });

  return null;
};

//// compute both start and end of event
//const getCoordinatesXY = (data, { defaultX, defaultY }) => {
//  const coordinatesXY = {
//    coordinateX: data.CoordinateX,
//    coordinateY: data.CoordinateY,
//  };

//  if (!coordinatesXY.coordinateX && coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//  } else if (coordinatesXY.coordinateX && !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateY = defaultY;
//  }

//  // data is missing -> fallback to default cooordinate of map
//  if (!coordinatesXY.coordinateX || !coordinatesXY.coordinateY) {
//    coordinatesXY.coordinateX = defaultX;
//    coordinatesXY.coordinateX = defaultY;
//  }

//  return coordinatesXY;
//};

const getLabelSize = (label: string) => {
  let labelFontSize = 0.8;

  if (label?.length <= 3) {
    labelFontSize = 0.8;
  } else if (label?.length <= 4) {
    labelFontSize = 0.6;
  } else {
    labelFontSize = 0.5;
  }

  return labelFontSize;
};

const updateGeoObject = (
  geoObject: any,
  features: any,
  markerProps: any = {}
) => {
  _.forEach(features, function (feature) {
    const { type, coordinates } = feature.geometry;

    if (coordinates) {
      if (type == 'Polygon' || type == 'LineString') {
        geoObject.geojson.features.push(feature);
      } else if (type == 'Point') {
        const { key, tooltip, label } = markerProps;
        geoObject.markers.push({
          key,
          lat: coordinates[0],
          lng: coordinates[1],
          tooltip,
          label,
          leafletStyle: mapStyleToLeaflet(feature.properties),
        });
      }
    }
  });
};

const newGeoObject = {
  markers: [],
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
};

const maskRowToGeoObject = (
  maskStructure: MaskStructure,
  maskRows: any,
  t: TFunction
) => {
  const {
    document: { geo, longitude, latitude, markerText },
    view: { geo: geoView },
  } = maskStructure;
  const geoObject = _.cloneDeep(newGeoObject);

  _.forEach(maskRows, function ({ key, data: rowData, color }) {
    // get marker text
    const label = {
      text:
        (rowData[markerText]?.length > 4
          ? rowData[markerText].substring(0, 4) + '<br>...'
          : rowData[markerText]) || '',
      additionalStyle:
        rowData[markerText]?.length > 4
          ? `font-size: ${getLabelSize(
              rowData[markerText]
            )}rem;overflow: hidden; line-height: 0.8;`
          : `font-size: ${getLabelSize(rowData[markerText])}rem;`,
    };

    // define tooltip
    const tooltip = getMaskRowText(maskStructure, rowData, t);

    // define markerProps
    const markerProps = { key, label, tooltip };

    // marker from form based on longitude and latitude propertis of document
    if (longitude && latitude) {
      // get coordinates X and Y
      const coordinateX = rowData[longitude];
      const coordinateY = rowData[latitude];

      if (coordinateX && coordinateY) {
        geoObject.markers.push({
          key,
          lat: coordinateX,
          lng: coordinateY,
          tooltip,
          label,
          leafletStyle: { fillColor: color },
        });
      }
    }

    // marker and geojson from form based on geo property of document
    _.forEach(geo, (geoItem) => {
      let features: Array<JSON> = undefined;
      const geoJson = rowData[geoItem];

      if (geoJson) {
        try {
          const parsed = JSON.parse(geoJson);
          // retrieve all features from GeoJSON (if it's a FeatureCollection, it will be an array of features)
          if (parsed.type === 'FeatureCollection') {
            features = parsed.features;
          } else if (parsed.type === 'Feature') {
            features = [parsed];
          }
        } catch (e) {
          // parsing failed, notify in console and resume execution
          console.error(e);
        }
      }

      features && updateGeoObject(geoObject, features, markerProps);
    });
  });

  // marker and geojson based on geo property of view
  const featuresView = geoView?.['features'];
  featuresView && updateGeoObject(geoObject, featuresView);

  return geoObject;
};

const onEachFeature = (feature: any, layer: any) => {
  const { fillColor, color } = mapStyleToLeaflet(feature.properties);
  // opacity is managed by the color (RGBA) of fillColor
  layer.options['fillOpacity'] = 0.7;

  fillColor && (layer.options['fillColor'] = fillColor);
  layer.options['color'] = color ? color : defaultAccent;
};

// todo wip#125 refactor
const validateViewport = (view: MapView) => {
  let isValid = false;

  if (view) {
    const { center, zoom } = view;

    if (
      center &&
      Array.isArray(center) &&
      center.length === 2 &&
      Number.isFinite(center[0]) &&
      Number.isFinite(center[1]) &&
      zoom &&
      Number.isFinite(zoom)
    ) {
      isValid = true;
    }
  }

  return isValid;
};

// todo wip#125 refactor
const markerHtmlStyles = (fillColor: string, color: string) => `
  background-color: ${fillColor ? fillColor : defaultAccent};
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -0.7rem;
  top: -1.72rem;
  position: relative;
  border-radius: 3rem 3rem 0;
  transform: rotate(45deg);
  border: 3px solid ${color ? color : '#FFFFFF'};
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;  
`;

const textHtmlStyles = (additionalStyle: string, labelColor: string) =>
  `
  position: relative;
  font-weight: semi-bold;
  color: ${labelColor ? labelColor : '#FFFFFF'};
  transform: rotate(-45deg);
  ${additionalStyle}
`;

const AltZoomComponent = () => {
  const map = useMapEvent('mousemove', (e: any) => {
    if (!e.originalEvent.altKey) {
      map.scrollWheelZoom.disable();
    } else {
      e.originalEvent.preventDefault();
      map.scrollWheelZoom.enable();
    }
  });

  return null;
};

//// todo wip#125 refactor
//const getMarkerIcon = (color: string) =>
//  l.divIcon({
//    className: 'my-custom-pin',
//    iconAnchor: [0, 0],
//    //labelAnchor: [0, 0],
//    tooltipAnchor: [0, -9.8],
//    popupAnchor: [0, -19.6],
//    html: `<span style="${markerHtmlStyles(color)}"></span>`,
//  });

const getMarkerProp = (marker: any) => {
  const {
    lat,
    lng,
    label,
    leafletStyle: { fillColor, color, labelColor },
  } = marker;

  const htmlLabel = !_.isNil(label)
    ? `<span style="${textHtmlStyles(label.additionalStyle, labelColor)}" > ${
        label.text
      } </span></div>`
    : '';

  const labelledMarkerIcon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [3, 8],
    labelAnchor: [0, 0],
    tooltipAnchor: [0, -9.8],
    popupAnchor: [0, -19.6],
    html: `<div style="${markerHtmlStyles(fillColor, color)}">` + htmlLabel,
  });

  return {
    position: [lng, lat],
    icon: labelledMarkerIcon,
  };
};

export {
  AltZoomComponent,
  DblClickComponent,
  getMarkerProp,
  maskRowToGeoObject,
  newGeoObject,
  onEachFeature,
  RightClickComponent,
  validateViewport,
};
