import { Document, Input, Modal, Rule, Step } from 'core/model';
import { DataProps } from 'core/utils/utils';

const getEntityDoc = (
  entity: DataProps | unknown,
  inputs: Input[],
  rules?: Rule[],
  modals?: Modal[]
) => {
  const contents = [
    new Step({
      contents: [{ column: 1, fields: inputs, row: 1, visible: true }],
      position: 1,
    }),
  ];

  const entityDoc = new Document({
    data: entity ? JSON.stringify(entity) : undefined,
    lastEdit: undefined,
    number: -1,
    template: {
      modals: modals ?? [],
      position: 1,
      rules: rules ?? [],
      steps: contents,
    },
  });

  return entityDoc;
};

export default getEntityDoc;
