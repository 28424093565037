import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import { InferProps, string } from 'prop-types';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwHighlight,
  FwIcon,
  FwInput,
  FwItem,
  FwLabel,
  FwList,
  FwParagraph,
} from 'components/base';
import { Activity, Operation } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { TRANSACTION_TYPES } from 'core/utils/constants';
import { dateFormats, jsDateFromString, jsDateToString } from 'core/utils/date';

// activity Text component
const FwActivityText: FC<FwActivityTextProps> = ({
  text,
  activityValue,
  searchText,
}) => (
  <>
    {text}{' '}
    <FwLabel>
      <FwHighlight text={activityValue} highlight={searchText} />
    </FwLabel>{' '}
  </>
);

const FwActivityTextProps = {
  text: string,
  activityValue: string,
  searchText: string,
};

type FwActivityTextProps = InferProps<typeof FwActivityTextProps>;

FwActivityText.propTypes = FwActivityTextProps;

// fwHistory component
const FwHistory: FC<FwHistoryProps> = ({ operations }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [filterOperations, setFilterOperations] = useState(undefined);

  const getFilterOperations = (operations: Operation[]) => {
    const filterOperations = [];

    _.forEach(operations, function (operation) {
      const operationTemp = filterOperation(operation);
      operationTemp && filterOperations.push(operationTemp);
    });

    return filterOperations;
  };

  const filterOperation = (operation: Operation) => {
    const filteredOperation = searchText
      ? _.filter(operation.activities, function (activity) {
          return (
            (activity.fieldName &&
              _.includes(
                String(activity.valueAfter).toUpperCase(),
                searchText.toUpperCase()
              )) ||
            _.includes(
              String(activity.valueBefore).toUpperCase(),
              searchText.toUpperCase()
            ) ||
            _.includes(
              String(activity.fieldName).toUpperCase(),
              searchText.toUpperCase()
            )
          );
        })
      : operation.activities;

    const dateTime = jsDateToString(
      jsDateFromString(operation.dateTime.split('.')[0], dateFormats.isoShort),
      dateFormats.datetime
    );

    return filteredOperation.length != 0
      ? ({
          editor: operation.editor,
          dateTime: dateTime,
          activities: filteredOperation,
          description: operation.description,
        } as Operation)
      : undefined;
  };

  const singularPluralSelector = (
    length: number,
    singular: string,
    plural: string
  ) => {
    return (
      (length > 1 && length + ' ' + plural) ||
      (length == 1 && length + ' ' + singular) ||
      ''
    );
  };

  const operationText = (operation: Operation) => {
    const emptyString = '';
    const isCreate = operation.description === TRANSACTION_TYPES.create;

    let chgLength = 0;
    let initLength = 0;
    let delLength = 0;

    _.map(operation.activities, (activity) => {
      if (activity.fieldName) {
        activity.valueBefore && activity.valueAfter
          ? chgLength++
          : activity.valueAfter
          ? initLength++
          : activity.valueBefore
          ? delLength++
          : undefined;
      }
    });

    const chgNotEmpty = chgLength != 0;
    const initNotEmpty = initLength != 0;
    const delNotEmpty = delLength != 0;

    return [
      [
        <FwIcon
          key="0"
          inline
          name={isCreate ? 'RiFileAddLine' : 'RiSave3Fill'}
        />,
        (isCreate ? t('Creation') : t('Save')) +
          (chgNotEmpty || initNotEmpty || delNotEmpty ? ' (' : emptyString) +
          singularPluralSelector(chgLength, t('change'), t('changes')) +
          (chgNotEmpty && initNotEmpty ? ', ' : emptyString) +
          singularPluralSelector(initLength, t('addition'), t('additions')) +
          ((chgNotEmpty || initNotEmpty) && delNotEmpty ? ', ' : emptyString) +
          singularPluralSelector(delLength, t('deletion'), t('deletions')) +
          (chgNotEmpty || initNotEmpty || delNotEmpty ? ')' : emptyString),
      ],
    ];
  };

  const activitiesList = (activities: Activity[]) => (
    <FwList fluid>
      {_.map(activities, (activity: Activity, index: string) => {
        if (
          activity.fieldName &&
          (activity.valueBefore || activity.valueAfter)
        ) {
          let nameText = '';
          let beforeText = '';
          let afterText = '';
          let icone = '';
          if (activity.valueBefore && activity.valueAfter) {
            nameText = t('Changed');
            beforeText = t('from');
            afterText = t('to');
            icone = 'RiPencilLine';
          } else if (activity.valueAfter) {
            nameText = t('Initialized');
            afterText = t('with value');
            icone = 'RiAddLine';
          } else if (activity.valueBefore) {
            nameText = t('Deleted from');
            beforeText = t('previous value');
            icone = 'RiSubtractLine';
          }
          return (
            <FwItem key={index}>
              <FwIcon inline name={icone} />
              <FwActivityText
                text={nameText}
                activityValue={t(`custom|${activity.fieldName}`)}
                searchText={searchText}
              />
              {beforeText && (
                <FwActivityText
                  text={beforeText}
                  activityValue={activity.valueBefore}
                  searchText={searchText}
                />
              )}
              {afterText && (
                <FwActivityText
                  text={afterText}
                  activityValue={activity.valueAfter}
                  searchText={searchText}
                />
              )}
            </FwItem>
          );
        }
      })}
    </FwList>
  );

  useEffect(() => {
    setFilterOperations(getFilterOperations(operations));
  }, [operations, searchText]);

  return (
    <>
      <Grid alignItems="center" templateColumns="repeat(3, 1fr)" mb={2}>
        <GridItem>
          <FwInput
            search
            placeholder={t('common|Search...')}
            rightIcon={'RiSearchLine'}
            value={searchText}
            onChange={(_e, { value }) => setSearchText(value as string)}
            type={FIELD_TYPE.text}
            mb={2}
          />
        </GridItem>
      </Grid>
      <Accordion allowMultiple>
        {_.map(filterOperations, (operation: Operation, index: string) => (
          <AccordionItem key={index}>
            <AccordionButton flexDirection="column" alignItems="start">
              <Flex alignItems="baseline">
                <Text as="b" fontSize="sm" textTransform="uppercase">
                  {operation.editor}
                </Text>
                <Badge variant="outline" boxShadow="none">
                  {operation.dateTime}
                </Badge>
              </Flex>
              <FwParagraph small>{operationText(operation)}</FwParagraph>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {activitiesList(operation.activities)}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

const propTypes = {};

interface FwPartialHistoryProps extends InferProps<typeof propTypes> {
  operations?: Operation[];
}

export type FwHistoryProps = FwPartialHistoryProps & FwActivityTextProps;

FwHistory.propTypes = propTypes;

export default FwHistory;
