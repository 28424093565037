import { Document } from 'core/model';

export enum IDXDDB_STORES {
  document = 'document',
  request = 'request',
}

export enum IDXDDB_OPERATIONS {
  add = 'ADD',
  clear = 'CLEAR',
  delete = 'DELETE',
  get = 'GET',
  getAll = 'GETALL',
  put = 'PUT',
}

type Request = { id?: string; method?: string; url?: string; data?: unknown };

export type IdxdDBEntityType<T extends `${IDXDDB_STORES}`> =
  T extends IDXDDB_STORES.document ? Document : Request;

export type RequestCallback<
  TStore extends `${IDXDDB_STORES}`,
  TOp extends `${IDXDDB_OPERATIONS}`
> = (
  result: TOp extends IDXDDB_OPERATIONS.get
    ? IdxdDBEntityType<TStore>
    : TOp extends IDXDDB_OPERATIONS.getAll
    ? IdxdDBEntityType<TStore>[]
    : unknown,
  error?: IDBRequest['error']
) => void;

// define indexedDB tables (stores)
type Store<T extends `${IDXDDB_STORES}`> = {
  name: T;
  options: {
    keyPath: keyof IdxdDBEntityType<T>;
  } & Omit<IDBObjectStoreParameters, 'keyPath'>;
};

const documentStore: Store<IDXDDB_STORES.document> = {
  name: IDXDDB_STORES.document,
  options: { keyPath: 'documentID' },
};

const requestStore: Store<IDXDDB_STORES.request> = {
  name: IDXDDB_STORES.request,
  options: { keyPath: 'id', autoIncrement: true },
};

export const stores = [documentStore, requestStore];

// find store key path
const getStoreKeyPath = <T extends `${IDXDDB_STORES}`>(storeName: T) => {
  return stores.find((store) => store.name === storeName).options.keyPath;
};

// return key of given entity corresponding to given store key path
export const getEntityKey = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  entity: IdxdDBEntityType<T>
): IDBValidKey => entity?.[getStoreKeyPath(storeName)];

// common logic for all indexedDB operations
export const performOnIdxddb = <
  TStore extends `${IDXDDB_STORES}`,
  TOp extends `${IDXDDB_OPERATIONS}`
>(
  db: IDBDatabase,
  storeName: TStore,
  operation: TOp,
  payload?: IdxdDBEntityType<TStore> | IDBValidKey | IDBKeyRange | null,
  callback?: RequestCallback<TStore, TOp>,
  handleSuccess?: (result: IdxdDBEntityType<TStore>) => void
) => {
  const objectStore = db
    .transaction(
      [storeName],
      [IDXDDB_OPERATIONS.get, IDXDDB_OPERATIONS.getAll].includes(
        operation as IDXDDB_OPERATIONS
      )
        ? 'readonly'
        : 'readwrite'
    )
    .objectStore(storeName);

  let request: IDBRequest;
  let errorMessage: string;

  switch (operation) {
    case IDXDDB_OPERATIONS.add:
      request = objectStore.add(payload as IdxdDBEntityType<TStore>);
      errorMessage = '[IndexedDB] Error adding data';
      break;
    case IDXDDB_OPERATIONS.clear:
      request = objectStore.clear();
      errorMessage = '[IndexedDB] Error clearing data';
      break;
    case IDXDDB_OPERATIONS.delete:
      request = objectStore.delete(payload as IDBValidKey | IDBKeyRange);
      errorMessage = '[IndexedDB] Error deleting data';
      break;
    case IDXDDB_OPERATIONS.get:
      request = objectStore.get(payload as IDBValidKey | IDBKeyRange);
      errorMessage = '[IndexedDB] Error reading data';
      break;
    case IDXDDB_OPERATIONS.getAll:
      request = objectStore.getAll();
      errorMessage = '[IndexedDB] Error reading all data';
      break;
    case IDXDDB_OPERATIONS.put:
      request = objectStore.put(payload as IdxdDBEntityType<TStore>);
      errorMessage = '[IndexedDB] Error updating data';
      break;
  }

  request.onsuccess = (event) => {
    const result = (event.target as IDBRequest).result;

    if (handleSuccess) {
      handleSuccess(result);
    } else {
      callback?.(result);
    }
  };

  request.onerror = (event) => {
    const request = event.target as IDBRequest;
    console.error(errorMessage, request.error);
    callback?.(request.result, request.error);
  };
};
