import { arrayOf, InferProps, instanceOf, string, shape } from 'prop-types';

import { ExecutionFilter, Input, Option } from 'core/model';

import { ctor } from '../helper';
import { FwFieldProps } from './FwField.props';

const fwFilterSharedPT = {
  allTemplateInputs: arrayOf(instanceOf(Input)),
  inputOptions: arrayOf(instanceOf(Option)),
  disableChanges: arrayOf(
    shape({
      key: string,
      type: string,
    })
  ),
};

type FwFilterSharedPT = InferProps<typeof fwFilterSharedPT>;

const fwFilterPT = {
  filterData: instanceOf(ExecutionFilter),
  operator: string,
  operatorOptions: arrayOf(string),
};

type FwFilterPT = InferProps<typeof fwFilterPT> &
  FwFilterSharedPT &
  FwFieldProps;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwFilterProps extends FwFilterPT {}
class FwFilterProps implements FwFilterPT {
  constructor(props?: FwFilterPT) {
    ctor(this, props);
  }
}

export { fwFilterPT, FwFilterProps, FwFilterSharedPT };
