import _ from 'lodash';

import { BatchTemplate, FwFlowProps, Input, Node, Rule } from 'core/model';
import { ACTION, BUTTON_TYPE } from 'core/utils/constant';

import type {
  createSourceFile,
  getLeadingCommentRanges,
  getTrailingCommentRanges,
  Identifier,
  IfStatement,
  Node as TsNode,
  ScriptTarget,
  SourceFile,
  SyntaxKind,
} from './tsserverlibrary.d.ts';

const parseMarker = '/* # */';

export type Ts = {
  createSourceFile: typeof createSourceFile;
  getLeadingCommentRanges: typeof getLeadingCommentRanges;
  getTrailingCommentRanges: typeof getTrailingCommentRanges;
  Identifier: Identifier;
  IfStatement: IfStatement;
  Node: TsNode;
  ScriptTarget: typeof ScriptTarget;
  SourceFile: SourceFile;
  SyntaxKind: typeof SyntaxKind;
};

type SelectableFlow = { key: string; source: unknown; flowProps: FwFlowProps };

interface Block {
  edges: string[][];
  nodes: Node[];
  startNode: Node;
  endNodes: Node[];
}

const getIconByType = (type: string) => {
  let icon: string;

  switch (type) {
    case ACTION.fill:
      icon = 'RiFileListLine';
      break;
    case ACTION.focusIn:
      icon = 'RiContractRightLine';
      break;
    case ACTION.focusOut:
      icon = 'RiShareForwardBoxLine';
      break;
    case ACTION.onChange:
      icon = 'RiEditLine';
      break;
    case ACTION.onLoad:
      icon = 'RiLoader2Line';
      break;
    case ACTION.readonly:
      icon = 'RiRotateLockLine';
      break;
    case ACTION.search:
      icon = 'RiUploadCloud2Line';
      break;
    case ACTION.show:
      icon = 'RiEyeLine';
      break;
    case BUTTON_TYPE.download:
      icon = 'RiDownload2Line';
      break;
    case BUTTON_TYPE.edit:
      icon = 'RiEditBoxFill';
      break;
    case BUTTON_TYPE.email:
      icon = 'RiMailSendLine';
      break;
    case BUTTON_TYPE.filter:
      icon = 'RiFilter2Line';
      break;
    case BUTTON_TYPE.link:
      icon = 'RiShareBoxLine';
      break;
    case BUTTON_TYPE.popup:
      icon = 'RiAiGenerate';
      break;
    case BUTTON_TYPE.preview:
      icon = 'RiNewspaperLine';
      break;
    case BUTTON_TYPE.print:
      icon = 'RiPrinterLine';
      break;
    case BUTTON_TYPE.process:
      icon = 'RiFlashlightLine';
      break;
    case BUTTON_TYPE.reset:
      icon = 'RiRestartLine';
      break;
    case BUTTON_TYPE.save:
      icon = 'RiSave3Line';
      break;
    case BUTTON_TYPE.submit:
      icon = 'RiSendPlaneFill';
      break;
    case BUTTON_TYPE.tableEdit:
      icon = 'RiGridLine';
      break;
  }

  return icon;
};

const mergeFlowCharts = (flowCharts: FwFlowProps[]) => {
  const nodes = [];
  const edges = [];

  flowCharts.forEach((flowChart) => {
    nodes.push(...flowChart.nodes);
    edges.push(...flowChart.edges);
  });

  return new FwFlowProps({ nodes, edges });
};

const mergeBlocks = (blocks: Block[]) => {
  let mergedBlock: Block;

  if (blocks?.length && blocks[0]) {
    mergedBlock = {
      startNode: blocks[0].startNode,
      endNodes: blocks[blocks.length - 1].endNodes,
      nodes: [],
      edges: [],
    };

    for (let i = 0; i < blocks.length || 0; i++) {
      const block = blocks[i];

      mergedBlock.edges.push(...block.edges);
      mergedBlock.nodes.push(...block.nodes);
    }
  }

  return mergedBlock;
};

const findIdentifierText = (ts: Ts, cbNode: Ts['Node']) => {
  let text: string;

  if (cbNode.kind === ts.SyntaxKind.Identifier) {
    text = (cbNode as Ts['Identifier']).escapedText as string;
  } else {
    const children = cbNode?.getChildren();
    const childrenPosTexts = children
      ?.map((c) => ({
        pos: c.pos,
        text: findIdentifierText(ts, c),
      }))
      .filter((cpt) => cpt.text);

    // -----------------------------------------------------------------------------------
    // if (childrenPosTexts?.length > 1) {
    //   console.log({ childrenPosTexts });
    // }

    if (childrenPosTexts?.length) {
      text = childrenPosTexts.sort((cpt) => cpt.pos)[0].text;
    }
  }

  return text;
};

const getComment = (
  ts: Ts,
  sourceCode: string,
  cbNode: Ts['Node'],
  withTrail = false
) => {
  const leadingComments =
    ts
      .getLeadingCommentRanges(sourceCode, cbNode.getFullStart())
      ?.map((c) => 'l' + sourceCode.substring(c.pos, c.end)) || [];
  const otherComments =
    ts
      .getTrailingCommentRanges(sourceCode, cbNode.getFullStart())
      ?.map((c) => 'ts' + sourceCode.substring(c.pos, c.end)) || [];
  const trailingComments = withTrail
    ? ts
        .getTrailingCommentRanges(sourceCode, cbNode.getEnd())
        ?.map((c) => 'te' + sourceCode.substring(c.pos, c.end)) || []
    : [];

  const comments: string[] = [
    ...leadingComments,
    ...otherComments,
    ...trailingComments,
  ];

  return comments?.[0]?.split('*')?.[1]?.trim();
};

const ifToBlock = (
  ts: Ts,
  sourceFile: string,
  childCode: string,
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  const ifStatement = cbNode as Ts['IfStatement'];

  const startNode = new Node({
    id: `${idPrefix || ''}${ifStatement.pos}`,
    title: '',
    icon: 'RiMindMap',
  });

  // if
  const expr = ifStatement.expression;
  const ifComment = getComment(ts, sourceFile, cbNode);
  const ifNode = new Node({
    id: `${idPrefix || ''}${expr.pos}`,
    title: ifComment || findIdentifierText(ts, expr),
  });

  // then
  const thenStatement = ifStatement.thenStatement;
  const thenBlock = childCodeToBlock(
    ts,
    sourceFile,
    childCode?.substring(
      thenStatement.pos - cbNode.pos,
      thenStatement.end - cbNode.pos
    ),
    thenStatement,
    idPrefix
  );

  // else
  const elseStatement = ifStatement.elseStatement;

  const elseEdges = [];
  const elseNodes = [];
  const elseEndNodes = [];

  if (elseStatement) {
    const elseBlock = childCodeToBlock(
      ts,
      sourceFile,
      childCode?.substring(
        elseStatement.pos - cbNode.pos,
        elseStatement.end - cbNode.pos
      ),
      elseStatement,
      idPrefix
    );

    elseEdges.push([startNode.id, elseBlock.startNode.id], ...elseBlock.edges);
    elseNodes.push(...elseBlock.nodes);
    elseEndNodes.push(...elseBlock.endNodes);
  }

  return {
    edges: [
      [startNode.id, ifNode.id],
      [ifNode.id, thenBlock.startNode.id],
      ...elseEdges,
    ],
    nodes: [startNode, ifNode, ...thenBlock.nodes, ...elseNodes],
    startNode: startNode,
    endNodes: [...thenBlock.endNodes, ...elseEndNodes],
  };
};

const whileToBlock = (
  ts: Ts,
  sourceFile: string,
  childCode: string,
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  const comment = getComment(ts, sourceFile, cbNode);
  const startNode = new Node({
    id: `${idPrefix || ''}${cbNode.pos}`,
    title: comment || childCode || `${ts.SyntaxKind[cbNode.kind]}`,
  });

  return {
    edges: [],
    nodes: [startNode],
    startNode: startNode,
    endNodes: [startNode],
  };
};

const expressionToBlock = (
  ts: Ts,
  sourceFile: string,
  childCode: string,
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  const comment = getComment(ts, sourceFile, cbNode, true);
  const startNode = new Node({
    id: `${idPrefix || ''}${cbNode.pos}`,
    title: comment || findIdentifierText(ts, cbNode) || childCode,
  });

  return {
    edges: [],
    nodes: [startNode],
    startNode: startNode,
    endNodes: [startNode],
  };
};

const childrenToBlock = (
  ts: Ts,
  sourceFile: string,
  childCode: string,
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  return mergeBlocks(
    cbNode
      ?.getChildren()
      ?.map((child) =>
        childCodeToBlock(
          ts,
          sourceFile,
          childCode?.substring(child.pos - cbNode.pos, child.end - cbNode.pos),
          child,
          idPrefix
        )
      )
      ?.filter((child) => child)
  );
};

const childCodeToBlock = (
  ts: Ts,
  sourceFile: string,
  childCode: string,
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  let mapper: (
    ts: Ts,
    sourceFile: string,
    childCode: string,
    cbNode: Ts['Node'],
    idPrefix?: number | string
  ) => Block;

  switch (cbNode?.kind) {
    case ts.SyntaxKind.IfStatement:
      mapper = ifToBlock;
      break;
    case ts.SyntaxKind.WhileStatement:
      mapper = whileToBlock;
      break;
    case ts.SyntaxKind.ExpressionStatement:
    case ts.SyntaxKind.ReturnStatement:
      mapper = expressionToBlock;
      break;
    default:
      mapper = childrenToBlock;
      break;
  }

  // console.log({ childCode, cbNode });
  return mapper(ts, sourceFile, childCode, cbNode, idPrefix);
};

const topLevelChildToBlock = (
  ts: Ts,
  sourceFile: Ts['SourceFile'],
  cbNode: Ts['Node'],
  idPrefix?: number | string
): Block => {
  const childCode = sourceFile.text.substring(cbNode.pos, cbNode.end);
  return childCodeToBlock(ts, sourceFile.text, childCode, cbNode, idPrefix);
};

const topLevelChildrenToBlocks = (
  ts: Ts,
  sourceFile: Ts['SourceFile'],
  cbNodes: Ts['Node'][],
  idPrefix?: number | string
): Block[] => {
  return cbNodes?.map((cbNode) =>
    topLevelChildToBlock(ts, sourceFile, cbNode, idPrefix)
  );
};

// create functions to traverse the AST and extract nodes and edges
const fileToFlowChart = (
  ts: Ts,
  sourceFile: Ts['SourceFile'],
  root?: Node,
  idPrefix?: number | string
) => {
  const nodes: Node[] = [];
  const edges: string[][] = [];
  const chartRoot =
    root ||
    new Node({ id: ts.SyntaxKind[sourceFile?.kind || 0], title: 'Start' });

  // get top-level nodes
  const rootNode = sourceFile?.getChildren()?.[0];
  const topLevelChildren = rootNode?.getChildren() || [];

  // convert top-level to blocks
  const blocks =
    topLevelChildrenToBlocks(
      ts,
      sourceFile,
      topLevelChildren,
      idPrefix
    )?.filter((block) => block) || [];

  // add nodes and edges from blocks
  nodes.push(chartRoot);

  for (let i = 0; i < blocks.length; i++) {
    const { nodes: blockNodes, startNode, edges: blockEdges } = blocks[i];

    // add current block nodes to chart
    nodes.push(...blockNodes);

    // create edge links between existing chart and current block
    if (i === 0) {
      edges.push([chartRoot.id, startNode.id]);
    } else {
      const { endNodes: prevEndNodes } = blocks[i - 1];

      for (let j = 0; j < prevEndNodes.length; j++) {
        edges.push([prevEndNodes[j].id, startNode.id]);
      }
    }

    // add block internal edges to chart
    edges.push(...blockEdges);
  }

  return new FwFlowProps({ nodes, edges });
};

const codeToFlowChart = (
  ts: Ts,
  script: string,
  root?: Node,
  idPrefix?: number | string
) => {
  let flowChart: FwFlowProps;

  if (script?.includes(parseMarker)) {
    // parse the code and create an AST
    const sourceFile = ts.createSourceFile(
      'sample.ts',
      // remove new lines
      script
        .replace(parseMarker, '')
        .replace(/[\n\r]/g, '')
        .trim(),
      ts.ScriptTarget.Latest,
      true
    );

    // call the visitor function starting with the source file
    flowChart = fileToFlowChart(ts, sourceFile, root, idPrefix);
  }

  return flowChart;
};

const parseRuleToFlowChart = (ts: Ts, rule: Rule, fields: Input[]) => {
  const nodes = [];
  const edges = [];

  const { event, script } = rule || new Rule();

  const node1 = new Node({
    id: `event: ${event?.eventID} - ${event?.key}`,
    title: fields?.find((f) => f.key === event?.key)?.name || event?.key,
    icon: getIconByType(event?.action),
  });
  const node2 = new Node({
    id: `script: ${script?.scriptID} - ${script?.description}`,
    title: `${script?.description}`,
    icon: getIconByType(script?.action),
  });

  nodes.push(node1);
  nodes.push(node2);

  edges.push([node1.id, node2.id]);

  if (script?.data) {
    const scriptFlow = codeToFlowChart(
      ts,
      script.data,
      node2,
      `${event.eventID}${script.scriptID}`
    );

    if (scriptFlow?.nodes && scriptFlow?.edges) {
      nodes.push(...scriptFlow.nodes);
      edges.push(...scriptFlow.edges);
    }
  }

  return new FwFlowProps({ nodes, edges });
};

const parseBatchTemplateToFlowChart = (
  ts: Ts,
  batchTemplate: BatchTemplate
) => {
  const nodes = [];
  const edges = [];

  const { name, process } = batchTemplate || new BatchTemplate();

  let prevNode = new Node({
    id: process?.name || name,
    title: process?.name || name,
    icon: getIconByType(process?.type) || 'RiFileUnknowLine',
  });

  nodes.push(prevNode);

  if (process?.executions?.length) {
    process.executions.forEach((execution, idx) => {
      const currentNode = new Node({
        id: `${process.processID} - ${execution.step} - ${idx}`,
        title: `${execution.step}`,
        icon: _.some(execution.filters)
          ? 'RiFilterLine'
          : _.some(execution.edits)
          ? 'RiFileEditLine'
          : execution.script
          ? 'RiUploadCloud2Line'
          : 'RiFlagLine',
        labels: _.some(execution.filters)
          ? execution.filters.map(
              (f) =>
                `${f.input?.name || f.key} ${f.value ? `- ${f.value}` : ''}`
            )
          : _.some(execution.edits)
          ? execution.edits.map(
              (e) =>
                `${e.input?.name || e.key} ${e.value ? `- ${e.value}` : ''}`
            )
          : undefined,
      });

      nodes.push(currentNode);
      edges.push([prevNode.id, currentNode.id]);
      prevNode = currentNode;

      if (execution.script?.data) {
        const scriptFlow = codeToFlowChart(
          ts,
          execution.script.data,
          currentNode,
          `${process?.processID}${execution.script.scriptID}`
        );

        if (scriptFlow?.nodes && scriptFlow?.edges) {
          nodes.push(...scriptFlow.nodes);
          edges.push(...scriptFlow.edges);
        }
      }
    });
  }

  return new FwFlowProps({ nodes, edges });
};

export {
  codeToFlowChart,
  mergeFlowCharts,
  parseRuleToFlowChart,
  parseBatchTemplateToFlowChart,
  SelectableFlow,
};
