import { CheckboxProps } from '@chakra-ui/react';
import _ from 'lodash';
import { useCallback, useEffect, useReducer, useRef } from 'react';

import * as defaultBoolStateBuilders from './defaultBoolStateBuilders';
import * as defaultDateStateBuilders from './defaultDateStateBuilders';
import * as defaultStateBuilders from './defaultStringStateBuilders';
import {
  ActionApplier,
  // buildStateFromForcedContent,
  // defaultBuildStateFromContent,
  // defaultBuildStateFromDisplay,
  // defaultBuildStateFromDto,
  FwAction,
  FwControllableStateBuilders,
  FwControllableStateCallbacks,
  FwControllableParams,
  FwControllableStateBuilder,
  initState,
  initValue,
  reducer,
  triggerAction,
  UseFwControllableState,
  UseReducerInitializer,
  FwControllableState,
} from './useFwControllableState.helper';

type ContentType<T, M extends boolean | undefined> = M extends true
  ? M extends undefined
    ? T
    : T[]
  : T;

const useFwInputBoolState = (
  params: FwControllableParams<undefined>,
  callbacks?: FwControllableStateCallbacks<Partial<CheckboxProps>>
) =>
  useFwControllableState<Partial<CheckboxProps>, undefined>(
    params,
    callbacks,
    defaultBoolStateBuilders
  );
// const useFwAutocompleteState = (
//   params: FwControllableParams<boolean | undefined>,
//   callbacks?: FwControllableStateCallbacks<
//     contentType<string, boolean | undefined>
//   >
// ) => useFwControllableState(params, callbacks, defaultStateBuilders);

const useFwInputState = (
  params: FwControllableParams<boolean | undefined>,
  callbacks?: FwControllableStateCallbacks<
    ContentType<string, boolean | undefined>
  >
) => useFwControllableState(params, callbacks, defaultStateBuilders);

const useFwInputDateState = (
  params: FwControllableParams<boolean | undefined>,
  callbacks?: FwControllableStateCallbacks<
    ContentType<Date, boolean | undefined>
  >
) => useFwControllableState(params, callbacks, defaultDateStateBuilders);

const setShouldNotifyWhenSameState = ({
  value,
  oldState,
  shouldNotify,
  params,
}) => {
  const { dtoValue: oldDtoValue } = oldState || {};
  const { editable, range, searchable } = params || {};

  if (
    (range || (searchable && !editable)) &&
    !oldState.dtoValue &&
    !!oldState.displayValue
  ) {
    oldState.displayValue = '';
    oldState.contentValue = [];
  }

  return value !== undefined
    ? oldState && oldDtoValue === value
      ? {
          ...oldState,
          shouldNotify,
        }
      : oldState
    : { ...oldState, shouldNotify };
};

// inspired by Chakra UI useControllableState hook
const useFwControllableState = <
  T = string,
  M extends boolean | undefined = boolean | undefined
>(
  params: FwControllableParams<M>,
  callbacks?: FwControllableStateCallbacks<ContentType<T, M>>,
  stateBuilders?: FwControllableStateBuilders<ContentType<T, M>>
): UseFwControllableState<ContentType<T, M>> => {
  // initialize
  const { defaultValue, value } = params;
  const { name, onChange: onChangeProp } = callbacks || {};
  const {
    buildStateFromForcedContent,
    buildStateFromContent,
    buildStateFromDisplay,
    buildStateFromDto,
  } = stateBuilders || {};

  // ref and state
  const defaultValueRef = useRef(defaultValue);
  const isControlledRef = useRef(
    // todo consider allowing transistions with state instead of ref
    value !== undefined
  );
  const propsRef = useRef(params);
  const dtoValueRef = useRef(initValue);

  // handle controlled and uncontrolled value props and map to a single value prop (for first render)
  const valueProp =
    (isControlledRef.current ? value : defaultValueRef.current) || '';

  const setState = useCallback(
    <V extends string | ContentType<T, M>>(
        applyAction: ActionApplier<ContentType<T, M>>,
        stateBuilder: FwControllableStateBuilder<ContentType<T, M>, V>,
        shouldNotify?: boolean,
        valueProps?: FwControllableParams
      ) =>
      (newValue?: V) => {
        // update state from value/defaultValue prop (which is a dto value)
        return triggerAction(
          applyAction,
          stateBuilder,
          newValue,
          valueProps,
          shouldNotify
        );
      },
    []
  );

  const setShouldNotify = useCallback(
    (shouldNotify: boolean, state?, valueProps?: FwControllableParams) => {
      setState(
        applyAction,
        setShouldNotifyWhenSameState,
        shouldNotify,
        valueProps || propsRef.current
      )(state?.dtoValue);
    },
    [setState]
  );

  const [state, applyAction] = useReducer(
    reducer,
    valueProp,
    setState(
      (action: FwAction<ContentType<T, M>>) => reducer(initState, action),
      buildStateFromDto,
      false,
      propsRef.current
    ) as UseReducerInitializer
  );

  // store params in ref
  useEffect(() => {
    propsRef.current = params;
  }, [params]);

  // always sync dtoValue with its copy in ref
  useEffect(() => {
    dtoValueRef.current = state.dtoValue;
  }, [state.dtoValue]);

  // sync state with controlled value prop
  useEffect(() => {
    // if controlled value is different from dtoValue copy in ref
    if (isControlledRef.current === true && dtoValueRef.current !== value) {
      setState(applyAction, buildStateFromDto, false, propsRef.current)(value);
    }
  }, [value, buildStateFromDto, setState]);

  // interface internal onChange with input onChange
  const onChange = useCallback(
    (newValue: string) => {
      if (onChangeProp) {
        onChangeProp(null, { name, value: newValue });
      }
    },
    [name, onChangeProp]
  );

  useEffect(() => {
    const stateClone = _.cloneDeep(state);

    if (stateClone.shouldNotify) {
      onChange(stateClone.dtoValue);
      setShouldNotify(false, stateClone, propsRef.current);
    }
  }, [state, onChange, setShouldNotify]);

  const handleValueChange = useCallback(
    <V extends string | ContentType<T, M>>(
      value: V,
      stateBuilder: FwControllableStateBuilder<ContentType<T, M>, V>,
      valueProps: FwControllableParams
    ) => {
      setState(applyAction, stateBuilder, false, valueProps)(value);
    },
    [setState]
  );

  const setContentValue = useCallback(
    (contentValue: ContentType<T, M>) =>
      handleValueChange(contentValue, buildStateFromContent, propsRef.current),
    [buildStateFromContent, handleValueChange]
  );

  const setDisplayValue = useCallback(
    (displayValue: string) =>
      handleValueChange(displayValue, buildStateFromDisplay, propsRef.current),
    [buildStateFromDisplay, handleValueChange]
  );

  const forceContentValue = useCallback(
    (contentValue: ContentType<T, M>) =>
      handleValueChange(
        contentValue,
        buildStateFromForcedContent,
        propsRef.current
      ),
    [buildStateFromForcedContent, handleValueChange]
  );

  return [
    state as FwControllableState<ContentType<T, M>>,
    { setContentValue, setDisplayValue, forceContentValue, setShouldNotify },
  ];
};

export {
  useFwControllableState,
  useFwInputBoolState,
  useFwInputDateState,
  useFwInputState,
  // useFwAutocompleteState,
};
