import { dtoCtor } from '../helper';
import { Filter } from './filter';

class UserFilter extends Filter {
  dtoName?: string;

  constructor(props?: Omit<UserFilter, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'UserFilter', props);
  }

  userID?: string;
  fieldID?: string;
  action?: string;
  editable?: boolean;
}

export { UserFilter };
