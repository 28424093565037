import { FwItemProps, Option } from 'core/model';
import { isNoRenderItem } from 'core/utils/logic';

const mapOptionToItem = (option: Option): FwItemProps => {
  return option && !isNoRenderItem(option)
    ? new FwItemProps({
        itemKey: option.key,
        value: option.value,
        text: option.text,
      })
    : null;
};

export { mapOptionToItem };
