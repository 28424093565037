import { Page } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const pageAS: AdminStructure<Page> = [
  {
    key: 'name',
    name: 'Name',
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'position',
    name: 'Position',
    required: true,
    type: FIELD_TYPE.number,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

export { pageAS };
