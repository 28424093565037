import { ChakraProps } from '@chakra-ui/react';

const green = '#00ff00';
const red = '#ff0000';

const containerStyle: ChakraProps = {
  display: 'inline-block',
  pos: 'relative',
  w: '10px',
  h: '10px',
};

const glowStyle: ChakraProps = {
  display: 'inline-block',
  pos: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  w: '5px',
  h: '5px',
  borderRadius: '50%',
  transition: 'background-color 0.3s ease-in-out',
};

export { containerStyle, glowStyle, green, red };
