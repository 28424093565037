import _ from 'lodash';

import { AppUser, Area, Module, Route } from 'core/model';
import getFilteredRoutes from 'core/modules/helpers/getFilteredRoutes';
import getModulesRoutes from 'core/modules/helpers/getModulesRoutes';
import { setFallbackUrl } from 'core/utils/storage';
import utils from 'core/utils/utils';

// todo improve file structure
const filterModules = (
  modules: Module[],
  isProd: boolean,
  localMode: boolean,
  area: Area,
  currentUser: AppUser
) => {
  // filter routes
  const filteredRoutes: Route[] = getFilteredRoutes(
    currentUser,
    area,
    isProd,
    localMode
  );

  // get can access for user and all module routes
  const moduleRoutes = getModulesRoutes(
    modules,
    filteredRoutes,
    area,
    currentUser,
    false
  );
  const allModuleRoutes = getModulesRoutes(
    modules,
    filteredRoutes,
    area,
    currentUser,
    true
  );

  // load default url
  const { defaultModule } = currentUser.appPreference;
  const defaultUrl = utils.getDefaultUrl(moduleRoutes, defaultModule);

  setFallbackUrl(defaultUrl);

  return {
    activeModule: _.find(moduleRoutes, { path: defaultUrl }),
    moduleRoutes,
    allModuleRoutes,
  };
};

export { filterModules };
