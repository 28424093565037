import _ from 'lodash';

import { Step } from 'core/model';

import documentRoutes from '../../document/documentRoutes';

const getStepIndex = (addIndex: number) =>
  _.indexOf(
    _.split(
      _.find(documentRoutes, (route) => route.name === 'doc-with-step').path,
      '/'
    ),
    ':stepId([A-Za-z0-9-]{36})'
  ) + addIndex;

const isInLocationArray = (locationArray: string[], pathValue: string) =>
  _.some(locationArray, (value) => value === pathValue) ? 1 : 0;

// retrieve the basePath using the structure of the routes in the documentRoutes file
export const getBasePath = (isArea: boolean): string => {
  const currentLocationArray = _.filter(
    _.split(window.location.pathname, '/'),
    (item) => item !== ''
  );

  const addIndex =
    (isArea ? 1 : 0) +
    isInLocationArray(currentLocationArray, 'new') +
    isInLocationArray(currentLocationArray, 'public') +
    isInLocationArray(currentLocationArray, 'tracking');

  const stepIndex = getStepIndex(addIndex);

  return _.join(_.slice(currentLocationArray, 0, stepIndex), '/');
};

// add the step name at the end of the URL (purely visual, no actions)
export const updateURL = (basePath: string, name: string): void => {
  if (basePath !== null && name !== null) {
    window.history.replaceState(null, null, `${basePath}/${name}`);
  }
};

// retrieve the current step from the URL if it exists, or return the first step in the steps array if it isn't empty
export const getInitStepID = (stepID: string, stepsArray: Step[]): string => {
  let initStep: string = null;

  if (stepsArray && stepsArray.length > 0) {
    initStep =
      _.find(stepsArray, (s) => s.stepID === stepID)?.stepID ||
      stepsArray[0].stepID;
  }

  return initStep;
};
