import { dtoCtor } from '../helper';
import { AppUser } from './appUser';
import { ExtendDocument } from './extendDocument';
import { Template } from './template';
import { Viewable } from './viewable';

class Document extends Viewable {
  dtoName?: string;

  constructor(props?: Omit<Document, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'Document', props);
  }

  documentID?: string;
  number: number;
  key?: string;
  active?: boolean;
  status?: string;
  data?: string;
  metaData?: object;
  template?: Template;
  appUser?: AppUser;
  extendDocuments?: ExtendDocument[];
}

export { Document };
