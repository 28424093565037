import { i18n } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { defaultLocale /*, LOCALES*/ } from 'core/utils/constant';

// // available locales
// const locales = [
//   {
//     key: LOCALES.en as string,
//     value: LOCALES.en as string,
//     text: 'English',
//   },
//   {
//     key: LOCALES.fr as string,
//     value: LOCALES.fr as string,
//     text: 'Français',
//   },
// ];

// start i18n instance with common options (to Storybook and FasterWeb)
// warning: do not export/import via index file...
// ...as index already references local i18n (and will override any previous i18n initialisation)
const configureI18n = (
  i18n: i18n,
  options?: Record<string, string | object>
) => {
  i18n
    .use(Backend) /* load translation files from external location */
    .use(initReactI18next) /* make i18next available for all react components */
    .init({
      // no fallback, i18n will show specified dictionary key by default
      fallbackLng: [],

      // all namespaces needed to fetch -> separate files to load
      ns: [
        'batch',
        'calendar',
        'common',
        'custom',
        'glossary',
        'settings',
        'tracking',
      ],

      // namespace char separator
      nsSeparator: '|',

      // default namespace
      defaultNS: 'glossary',

      // dynamically fetching user language
      backend: {
        loadPath: './locales/{{lng}}/{{ns}}.json',
      },

      // used for values like author.name (thus not recommendend with natural language keys)
      keySeparator: false,

      // already performed by react
      interpolation: {
        escapeValue: false,
      },

      // debug mode for development
      debug: false,

      // additional configuration via specified options
      ...options,
    });
};

// configure i18n
const setI18nDefaultLocale = (i18n: i18n, defaultLng?: string) => {
  configureI18n(i18n, { lng: defaultLng || defaultLocale });
};

export { setI18nDefaultLocale };
