import { arrayOf, InferProps, instanceOf, oneOfType, string } from 'prop-types';

import { ctor } from '../helper';

class Node {
  constructor(node: Node) {
    ctor(this, node);
  }

  id: string;
  title?: string;
  labels?: string[];
  icon?: string;
  // type?: string;
}

const fwFlowPT = {
  nodes: arrayOf(oneOfType([string, instanceOf(Node)])),
  edges: arrayOf(arrayOf(string)),
};

type FwFlowPT = InferProps<typeof fwFlowPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwFlowProps extends FwFlowPT {}
class FwFlowProps implements FwFlowPT {
  constructor(props?: FwFlowPT) {
    ctor(this, props);
  }
}

export { fwFlowPT, FwFlowProps, Node };
