import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import templateManagementApi from 'api/template/templateManagementApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';
import { setRoutes } from 'scenes/dashboard/helpers/admin';

const TemplatesContainer = () => {
  const [loading, setLoading] = useState(true);
  const [templatesRoutes, setTemplatesRoutes] = useState([]);
  const [args] = useState([]);

  const { fetched, pending } = useApi(templateManagementApi.getAllKey, args);

  useEffect(() => {
    const routes = setRoutes(
      _.map(fetched?.templateKeys, (key) => ({ key })),
      'admin/template',
      ':templateKey?'
    );

    setTemplatesRoutes(routes);
    setLoading(false);
  }, [fetched, pending]);

  return loading || pending ? (
    <FwSpinner />
  ) : (
    <TilesPanel routes={templatesRoutes} />
  );
};

export default TemplatesContainer;
