import { Checkbox, HStack, RequiredIndicator } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FwButton, useFwTheme } from 'components/base';
import { FwCheckboxProps, fwCheckboxPT } from 'core/model';

import { CommonInputProps } from '../FwInput';
import { getShadowByState } from '../FwInput.helpers';
import { useFwInputBoolState } from '../select/useFwControllableState';

const FwCheckbox: FC<FwCheckboxProps & CommonInputProps> = ({
  // direct props
  allowIndeterminate,
  label,
  // common input props
  clearable,
  defaultValue,
  disabled,
  name,
  readOnly,
  required,
  saved,
  unchanged = true,
  value,
  // common functions
  onBlur,
  onChange,
}) => {
  const { accent, bg } = useFwTheme();
  const [{ contentValue }, { setContentValue }] = useFwInputBoolState(
    { allowIndeterminate, defaultValue, value },
    { name, onChange }
  );

  const handleChange = () => {
    setContentValue({ isChecked: !contentValue.isChecked });
  };

  const clearCheckbox = () => {
    if (!readOnly) {
      setContentValue({
        isChecked: false,
        isIndeterminate: allowIndeterminate ? true : undefined,
      });
      onBlur?.(null);
    }
  };

  const cboxStyle = {
    sx: {
      '> span.chakra-checkbox__control': {
        boxShadow: getShadowByState(saved, unchanged),
        backgroundColor: bg,
      },
      '> span.chakra-checkbox__control[aria-checked=true], > span.chakra-checkbox__control[data-checked], > span.chakra-checkbox__control:indeterminate, > span.chakra-checkbox__control[aria-checked=mixed], > span.chakra-checkbox__control[data-indeterminate]':
        {
          backgroundColor: accent,
          borderColor: accent,
        },
    },
  };

  const showClear =
    clearable &&
    !(allowIndeterminate && contentValue?.isIndeterminate) &&
    !(!allowIndeterminate && contentValue?.isChecked === false);

  return (
    <HStack minH="32px">
      <Checkbox
        name={name}
        {...cboxStyle}
        {...contentValue}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
        onChange={handleChange}
      >
        {label}
        {required && <RequiredIndicator />}
      </Checkbox>
      {!readOnly && showClear && (
        <FwButton
          rounded
          small
          disabled={disabled}
          leftIcon="RiCloseFill"
          onClick={clearCheckbox}
        />
      )}
    </HStack>
  );
};

FwCheckbox.propTypes = fwCheckboxPT;

export default FwCheckbox;
