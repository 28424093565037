import { dtoCtor, Dto } from '../helper';
import { Access } from './access';
import { ActionManagement } from './actionManagement';
import { Design } from './design';
import { Step } from './step';

class TemplateManagement implements Dto {
  dtoName?: string;

  constructor(props?: Omit<TemplateManagement, 'dtoName'>) {
    dtoCtor(this, 'TemplateManagement', props);
  }

  fasterClientID?: string;
  templateId?: string;
  key?: string;
  name?: string;
  position: number;
  type?: string;
  additionalData?: object;
  design?: Design;
  steps?: Step[];
  actions?: ActionManagement[];
  access?: Access;
}

export { TemplateManagement };
