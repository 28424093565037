import { Alert, AlertIcon, AlertStatus, AlertTitle } from '@chakra-ui/react';
import { bool, InferProps, node, string } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const FwMessage: FC<Props> = ({ children, message, small, subtle, type }) => {
  const { t } = useTranslation();

  const alertStyle = {
    bg: subtle ? 'transparent' : undefined,
    variant: subtle ? undefined : 'left-accent',
    py: small ? 2 : undefined,
  };

  const titleStyle = {
    fontSize: small ? undefined : 'xl',
    fontWeight: small ? 'normal' : 'semibold',
  };

  return (
    <Alert {...alertStyle} status={type}>
      {type && <AlertIcon />}
      {message && (
        <AlertTitle {...titleStyle}>
          {t(message, { ns: ['glossary', 'common', 'custom'] })}
        </AlertTitle>
      )}
      {children}
    </Alert>
  );
};

const propTypes = {
  children: node,
  message: string,
  small: bool,
  subtle: bool,
};

export interface Props extends InferProps<typeof propTypes> {
  type?: AlertStatus;
}

FwMessage.propTypes = propTypes;

export default FwMessage;
