import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Area, AutoSaveInfo } from 'core/model';

const areaApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ areas: Area[] }>('area', params),
  get: (key: string, params: BaseApiParams) =>
    BaseApi.get<{ area: Area }>(`area/${key}`, params),
  putAS: (autoSaveInfo: AutoSaveInfo<Area, string>, params: BaseApiParams) =>
    BaseApi.put('area/AS', { autoSaveInfo }, params),
  post: (area: Area, params: BaseApiParams) =>
    BaseApi.post('area', area, params),
};

export default areaApi;
