import _ from 'lodash';

import { Option } from 'core/model';

import { FwControllableStateBuilder } from './useFwControllableState.helper';

const dtoSeparator = '|';
const displaySeparator = ' | ';

// todo maybe move to logic file?
const addOrRemove = (array: string[], value: string) => {
  return _.includes(array, value)
    ? _.filter(array, (v) => v !== value)
    : _.union(array, [value]);
};

const arrayToDisplayValue = (valueArray: string[]) =>
  `(${valueArray.length}) ${_.join(valueArray, displaySeparator)}`;

// todo consider unit tests
// convert dto values (e.g. 'value1' or 'value1|value2') to display values (e.g. 'value1' or '(2) value1 | value2')
const defaultDtoToDisplay = (dtoValue: string) => {
  let displayValue: string;

  if (_.includes(dtoValue, dtoSeparator)) {
    // split "value1|value2" -> ["value1", "value2"]
    const valueArray = _.split(dtoValue, dtoSeparator);

    // join back ["value1", "value2"] -> "(2) value1 | value2"
    displayValue = arrayToDisplayValue(valueArray);
  } else {
    displayValue = dtoValue;
  }

  return displayValue;
};

// convert display values (e.g. 'value1' or '(2) value1 | value2') to dto values (e.g. 'value1' or 'value1|value2')
const defaultDisplayToDto = (displayValue: string) => {
  return displayValue;
};

// convert content values (e.g. '2022-01-01' or ['13:15' , '13:30']) to dto values (e.g. '2022-01-01' or '13:15|13:30')
const defaultContentToDto = <T>(contentValue: T) => {
  let dtoValue: string;

  if (_.isArray(contentValue)) {
    const stringArray: string[] = _.filter(
      contentValue,
      (val) => typeof val === 'string'
    );
    dtoValue = _.join(stringArray, '|');
  } else if (typeof contentValue === 'string') {
    dtoValue = contentValue;
  }

  return dtoValue || '';
};

// convert dto values (e.g. 'value1' or 'value1|value2') to default content values (e.g. 'value1' or ['13:15' , '13:30'])
const defaultDtoToContent = (value: string): string | string[] => {
  return _.includes(value, dtoSeparator) ? _.split(value, dtoSeparator) : value;
};

// convert content values (e.g. 'value1' or ['13:15' , '13:30']) to display values (e.g. 'value1' or '(2) value1 | value2')
const defaultContentToDisplay = <CT>(value: CT): string => {
  return defaultDtoToDisplay(defaultContentToDto(value));
};

// convert display values (e.g. 'value1' or '(2) value1 | value2')to  content values (e.g. 'value1' or ['13:15' , '13:30'])
const defaultDisplayToContent = (value: string) => {
  return defaultDtoToContent(defaultDisplayToDto(value));
};

// todo refactor? this logic might already exist somewhere else
const flattenItems = (items: Option[]) => {
  const flatItems = [];

  _.forEach(items, (i) => {
    if (i.items) {
      flatItems.push(...i.items);
    } else {
      flatItems.push(i);
    }
  });

  return flatItems;
};

// currently, items can be undefined
const getPreDisplayValue = (items: Option[], newDtoValue: string): string => {
  const flatItems = flattenItems(items);

  return flatItems &&
    flatItems.length > 0 &&
    _.findIndex(flatItems, { value: newDtoValue }) >= 0
    ? (_.find(flatItems, { value: newDtoValue }).text as string)
    : newDtoValue;
};

const buildStateFromForcedContent: FwControllableStateBuilder<
  string | string[],
  string | string[]
> = ({ value: newContentValue, params: { multiple, options } }) => {
  let dtoDisplayValue: string;

  if (_.isArray(newContentValue)) {
    dtoDisplayValue = _.join(
      _.map(newContentValue, (v) => getPreDisplayValue(options, v)),
      dtoSeparator
    );
  } else {
    dtoDisplayValue = getPreDisplayValue(options, newContentValue);
  }

  return {
    dtoValue: defaultContentToDto(newContentValue),
    displayValue: defaultContentToDisplay(dtoDisplayValue),
    contentValue: newContentValue,
    shouldNotify: !multiple || newContentValue === undefined,
  };
};

const buildStateFromContent: FwControllableStateBuilder<
  string | string[],
  string
> = ({ value, oldState, params }) => {
  const { multiple } = params;
  let newContentValue: string | string[];

  if (multiple) {
    const prevValue = oldState.dtoValue
      ? (oldState.contentValue as string[])
      : [];

    newContentValue = addOrRemove(prevValue, value);
  } else {
    newContentValue = value;
  }

  return buildStateFromForcedContent({
    value: newContentValue,
    params,
  });
};

// changing display value is not supported when multiple=true yet
// this function is thus independent from oldState and multiple
const buildStateFromDisplay: FwControllableStateBuilder<
  string | string[],
  string
> = ({
  value,
  //oldState,
  params: { editable, options /*, multiple */ },
}) => {
  const flatItems = flattenItems(options);

  const displayValueEquivalentKey =
    // todo refactor? this does not take categories into account
    flatItems &&
    flatItems.length > 0 &&
    _.findIndex(flatItems, { text: value }) >= 0
      ? (_.find(flatItems, { text: value }).key as string)
      : undefined;

  return {
    dtoValue: editable
      ? defaultDisplayToDto(displayValueEquivalentKey || value)
      : '',
    displayValue: value,
    contentValue: defaultDisplayToContent(displayValueEquivalentKey || value),
    shouldNotify: false,
  };
};

const buildStateFromDto: FwControllableStateBuilder<
  string | string[],
  string
> = ({
  value,
  //oldState,
  params: { options, multiple },
}) => {
  const newDtoValue = value;
  let dtoDisplayValue: string;

  if (multiple) {
    // split "a|b" -> ["a", "b"]
    const valueArray = newDtoValue ? _.split(newDtoValue, dtoSeparator) : [];

    // translate to displayValues & join back ["a", "b"] -> "a|b"
    dtoDisplayValue = _.join(
      _.map(valueArray, (v) => getPreDisplayValue(options, v)),
      dtoSeparator
    );
  } else {
    dtoDisplayValue = getPreDisplayValue(options, newDtoValue);
  }

  const newContentValue = defaultDtoToContent(newDtoValue);

  return {
    dtoValue: newDtoValue,
    displayValue: defaultDtoToDisplay(dtoDisplayValue),
    contentValue:
      multiple && !_.isArray(newContentValue)
        ? [newContentValue]
        : newContentValue,
    shouldNotify: false,
  };
};

export {
  addOrRemove,
  arrayToDisplayValue,
  buildStateFromForcedContent,
  buildStateFromContent,
  buildStateFromDisplay,
  buildStateFromDto,
};
