import { useEffect, useRef } from 'react';

// check if a component is mounted
export default function useMountedComponentRef() {
  const mountedRef = useRef(false);

  useEffect(() => {
    // mark component as mounted
    mountedRef.current = true;

    return () => {
      // mark component as unmounted
      mountedRef.current = false;
    };
  }, []);

  return mountedRef;
}
