import React from 'react';

import { Table } from 'components';

import tableStructure from './helpers/tableStructure';

const AdminGrids = ({ ...props }) => {
  return (
    <Table {...props} table={tableStructure} dataType="Grid" dataKey="GridID" />
  );
};

export default AdminGrids;
