import { PopoverContentProps } from '@chakra-ui/react';

// adjust behaviour when in need to support other placements
const placement = 'right-start';

// adjust when in need to support other variants
const behaviour = [
  {
    // prevent flip by forcing placement
    name: 'flip',
    options: {
      fallbackPlacements: [placement],
    },
  },
  {
    // prevent notification from overflowing vertically its parent
    name: 'preventOverflow',
    options: {
      tetherOffset: ({ popper }) => popper.height,
    },
  },
  {
    // give an overlay effect on popper by not overflowing on the right side (with right start placement)
    name: 'offset',
    options: {
      offset: ({ popper }) => [
        // no vertical adjustment
        0,
        // horizontally displaced
        -popper.width,
      ],
    },
  },
];

const notificationStyle: PopoverContentProps = {
  // remove default border style
  border: 'none',
  // rounded corners
  borderRadius: '5%',
  // clip children overflowing rounded corners
  overflow: 'hidden',
};

export { behaviour, notificationStyle, placement };
