import { dtoCtor, Dto } from '../helper';
import { Dropdown } from './dropdown';

class Input implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Input, 'dtoName'>) {
    dtoCtor(this, 'Input', props);
  }

  fieldID?: string;
  fasterClientID?: string;
  pageContentID?: string;
  row: number;
  column: number;
  rowSpan?: number;
  columnSpan?: number;
  key?: string;
  name?: string;
  additionalData?: object;
  pattern?: string;
  placeholder?: string;
  dropdown?: Dropdown;
  type?: string;
  searchType?: string;
  required?: boolean;
  visible?: boolean;
  isReadOnly?: boolean;
  subInputs?: Input[];
}

export { Input };
