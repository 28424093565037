import { Center, Container, Spacer, VStack } from '@chakra-ui/react';
import { InferProps, node } from 'prop-types';
import React, { FC } from 'react';

import { FwPancake, FwSegment } from 'components/base';

import FwLogo from '../common/logo/FwLogo';

const FwSupercenter: FC<Props> = ({ children }) => {
  const spacerStyle = { mb: '5px !important' };

  return (
    <FwPancake>
      <Center h="100%" flex={1}>
        <Container maxW="md">
          <FwSegment padded>
            <VStack spacing="4">
              <FwLogo />
              {children}
              <Spacer {...spacerStyle} />
            </VStack>
          </FwSegment>
        </Container>
      </Center>
    </FwPancake>
  );
};

const propTypes = {
  children: node,
};

export type Props = InferProps<typeof propTypes>;

FwSupercenter.propTypes = propTypes;

export default FwSupercenter;
