import { Progress } from '@chakra-ui/react';
import _ from 'lodash';
import React, { ComponentProps } from 'react';

import { useFwTheme } from 'components/base/utility';
import { fwProgressPT, FwProgressProps } from 'core/model';

const FwProgress = ({ value, type }: FwProgressProps) => {
  const { accent } = useFwTheme();

  // progress bar will display a loading state when value is nil
  const isIndeterminate = _.isNil(value);

  const style: ComponentProps<typeof Progress> = {
    colorScheme:
      // todo refactor with FwMessage, FwToast and FwModal
      type === 'error'
        ? 'red'
        : type === 'info'
        ? 'blue'
        : type === 'success'
        ? 'green'
        : type === 'warning'
        ? 'yellow'
        : undefined,
    hasStripe: true,
    isAnimated: true,
    size: 'xs',
    sx: type
      ? undefined
      : {
          '& > div': {
            backgroundColor: isIndeterminate ? undefined : accent,
            backgroundImage: isIndeterminate
              ? `linear-gradient(to right, transparent 0%, ${accent} 50%, transparent 100%)`
              : undefined,
          },
        },
  };

  return (
    <Progress {...style} isIndeterminate={isIndeterminate} value={value} />
  );
};

FwProgress.propTypes = fwProgressPT;

export default FwProgress;
