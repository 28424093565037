import _ from 'lodash';

import { FwFieldProps, Input, Option } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

import { removeProperties } from './helper';

const { autocomplete, collection, reference } = FIELD_TYPE;

export interface inputMapConfig extends FwFieldProps {
  docData?: object;
  invalidInputKey?: string;
  loadingInputKeys?: Array<string>;
}

const omittedInputProps = [
  'fasterClientID',
  'dtoName',
  'pageContentID',
] as const;

// todo add additionalData props in model?
const omittedAdditionalData = [
  'allowPageFilter',
  'filters',
  'referenceSearchField',
  'referenceFields',
  'linkFields',
  'suggestionCount',
  'required',
];

const getInvalidInputInCollection = (
  subInputs: Input[],
  invalidInputKey: string
) => {
  // invalid subInput key contains index so we need to remove it
  const invalidSubInputKey =
    invalidInputKey && utils.removeIndexFromSubInputKey(invalidInputKey);

  return invalidSubInputKey &&
    subInputs.some(({ key }) => key === invalidSubInputKey)
    ? {
        invalidSubInputKey,
        invalidRow: utils.getIndexFromSubInputKey(invalidInputKey),
      }
    : {};
};

const mapInputToField = (
  input: Input,
  inputMapConfig?: inputMapConfig
): FwFieldProps => {
  let fieldProps: FwFieldProps = null;
  const { docData, invalidInputKey, loadingInputKeys, ...directFieldProps } =
    inputMapConfig || {};

  if (input) {
    const {
      // processed props
      additionalData,
      dropdown,
      fieldID,
      key,
      name,
      type,
      isReadOnly,
      subInputs,
      // rest of props (kept unchanged and passed to field)
      ...props
    } = removeProperties(input, omittedInputProps) || {};

    const additionalDataValues = _.omit(additionalData, omittedAdditionalData);

    const specificProps = _.includes([collection], type)
      ? {
          subInputs,
          collectionValue: (docData ? docData[key] || [] : undefined) || [],
          // keep only loading sub-input keys of this collection
          loadingSubInputKeys:
            loadingInputKeys &&
            loadingInputKeys.filter((k) =>
              subInputs.some(({ key }) => k.startsWith(`${key}|`))
            ),
          ...getInvalidInputInCollection(subInputs, invalidInputKey),
        }
      : _.includes([autocomplete, reference], type)
      ? {
          fieldID,
          referenceValue: _.includes([reference], type)
            ? (docData ? docData[key] || {} : undefined) || {}
            : undefined,
        }
      : undefined;

    fieldProps = {
      // mapped props
      ...additionalDataValues,
      ...specificProps,
      key: fieldID || key || name,
      label: name,
      name: key,
      readOnly: isReadOnly,
      options: dropdown && _.map(dropdown.options, (o) => new Option(o)),
      // computed props
      value: !_.includes([collection, reference], type)
        ? (docData ? `${docData[key] || ''}` : undefined) || ''
        : undefined,
      invalid: invalidInputKey === key || undefined,
      loading:
        (loadingInputKeys && _.includes(loadingInputKeys, key)) || undefined,
      // unchanged props
      type,
      ...props,
      ...directFieldProps,
    };
  }

  return fieldProps || {};
};

export { mapInputToField };
