import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useFwAuth } from 'components/base';
import { ACCESS_TYPES } from 'core/utils/constants';
import Utils from 'core/utils/utils';

const AdminRoute = ({ render: Component, ...rest }: any) => {
  const { user: currentUser } = useFwAuth();

  return Utils.canAccess(currentUser, ACCESS_TYPES.admin) ? (
    <Route {...rest} render={Component} />
  ) : (
    <Redirect to={{ pathname: '/', state: { from: rest.location } }} />
  );
};

export default AdminRoute;
