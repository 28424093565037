import { dtoCtor, Dto } from '../helper';

class Edit implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Edit, 'dtoName'>) {
    dtoCtor(this, 'Edit', props);
  }

  key?: string;
  value?: string;
}

export { Edit };
