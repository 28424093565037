import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, TemplateManagement } from 'core/model';

const templateManagementApi = {
  getAllKey: (params: BaseApiParams) =>
    BaseApi.get<{ templateKeys: string[] }>(`templateManagement`, params),
  getByKey: (key: string, params: BaseApiParams) =>
    BaseApi.get<{ template: TemplateManagement }>(
      `templateManagement/${key}`,
      params
    ),
  post: (template: TemplateManagement, params: BaseApiParams) =>
    BaseApi.post('templateManagement/AS', template, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<TemplateManagement, string>,
    params: BaseApiParams
  ) => BaseApi.put('templateManagement/AS', { autoSaveInfo }, params),
};

export default templateManagementApi;
