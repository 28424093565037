const shadows = {
  xs: {
    _light: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    _dark: '0 0 0 1px rgba(0, 0, 0, 0.45)',
  },
  sm: {
    _light: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    _dark: '0 1px 2px 0 rgba(0, 0, 0, 0.45)',
  },
  base: {
    _light: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    _dark: '0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.44)',
  },
  md: {
    _light:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    _dark:
      '0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 0 3px -1px rgba(0, 0, 0, 0.44)',
  },
  lg: {
    _light:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    _dark:
      '0 10px 15px -3px rgba(0, 0, 0, 0.5), 0 0 6px -2px rgba(0, 0, 0, 0.45)',
  },
  xl: {
    _light:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    _dark:
      '0 20px 25px -5px rgba(0, 0, 0, 0.5), 0 10px 10px -5px rgba(0, 0, 0, 0.44)',
  },
  '2xl': {
    _light: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    _dark: '0 25px 50px -12px rgba(0, 0, 0, 0.9)',
  },
  'dark-lg': {
    _light:
      'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
    _dark:
      'rgba(0, 0, 0, 0.5) 0px 0px 0px 1px, rgba(0, 0, 0, 0.6) 0px 5px 10px, rgba(0, 0, 0, 0.9) 0px 15px 40px',
  },
  outline: {
    _light: '0 0 0 3px rgba(66, 153, 225, 0.6)',
    _dark: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  },
  inner: {
    _light: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    _dark: 'inset 0 2px 4px 0 rgba(0,0,0,0.44)',
  },
};

export { shadows };
