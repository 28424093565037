import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { Identity, TableData } from 'core/model';

type StoreTableDatas = {
  [key: string]: TableData;
};

type ModuleStore = {
  // todo #597 to define
  // processData: ProcessModuleStore;
  tableDatas?: StoreTableDatas;
  user?: Identity;
  // ...other data
};

type ModuleStoreContext = {
  moduleStore: ModuleStore;
  setModuleStore: Dispatch<SetStateAction<ModuleStore>>;
};

const FwModuleStoreContext: Context<ModuleStoreContext> =
  createContext<ModuleStoreContext>(undefined);

export { ModuleStore, FwModuleStoreContext };
