import _ from 'lodash';
import { any, bool, func, InferProps, oneOf, string } from 'prop-types';

import { SharedModalMessagePT } from 'components/base/elements/modal/components/ModalMessage';
import { FwFilterSharedPT } from 'core/model';
import { MODAL_TYPES } from 'core/utils/constants';

import { ctor } from '../helper';

const fwModalSharedPT = {
  cancelName: string,
  name: string,
  open: bool,
  submitName: string,
  onCancel: func,
  onSubmit: func,
};

type FwModalSharedPT = InferProps<typeof fwModalSharedPT> &
  SharedModalMessagePT;

const fwModalPT = {
  additionalData: any,
  blur: bool,
  noDimmerClick: bool,
  type: oneOf(_.values(MODAL_TYPES)),
  warning: string,
};

type FwModalPT = InferProps<typeof fwModalPT> &
  FwModalSharedPT &
  FwFilterSharedPT;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwModalProps extends FwModalPT {}
class FwModalProps implements FwModalPT {
  constructor(props?: FwModalPT) {
    ctor(this, props);
  }
}

export { fwModalPT, FwModalProps, FwModalSharedPT };
