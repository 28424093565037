import React from 'react';
import { useTranslation } from 'react-i18next';

// import { FasterDropdown } from 'components';
import { FwMenu, FwButton } from 'components/base';

const DownloadButton = ({
  disabled,
  loading,
  name,
  downloadOptions,
  handleFetch,
  ...props
}: any) => {
  const { t } = useTranslation();

  return (
    // <Button primary>
    //   <FasterDropdown
    //     dropdownRef={dropdownRef}
    //     className={`icon ${icon ? 'left' : 'right'} labeled button`}
    //     categorize
    //     fluid={false}
    //     disabled={disabled}
    //     loading={loading}
    //     icon={icon || 'caret down'}
    //     iconColor={iconColor}
    //     color={color}
    //     text={name}
    //     options={downloadOptions || []}
    //     onClick={handleFetch}
    //     onChange={handleChange}
    //   />
    // </Button>
    <FwMenu
      arrow
      inline
      loading={loading}
      trigger={
        <FwButton
          {...props}
          primary
          small
          disabled={disabled}
          loading={loading}
          rightIcon="RiArrowDownSFill"
          onClick={handleFetch}
        >
          {t(`custom|${name}`)}
        </FwButton>
      }
      items={downloadOptions || []}
    />
  );
};

export default DownloadButton;
