import _ from 'lodash';
import { InferProps, string } from 'prop-types';
import React, { FC } from 'react';

const FwHighlight: FC<Props> = ({ text, highlight }) => {
  let render: JSX.Element;
  if (highlight && text) {
    const regex = new RegExp(`(${highlight})`, 'gi');
    const filterParts = _.filter(text.split(regex), _.identity);

    render = (
      <>
        {_.map(filterParts, (part, i) => {
          return regex.test(part) ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          );
        })}
      </>
    );
  } else {
    render = <>{text}</>;
  }

  return render;
};

const propTypes = {
  text: string,
  highlight: string,
};

export type Props = InferProps<typeof propTypes>;

FwHighlight.propTypes = propTypes;

export default FwHighlight;
