import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import appPreferenceApi from 'api/appPreference/appPreferenceApi';
import { useApi } from 'api/useApi';
import { useInputFormContainer } from 'components';
import {
  FwField,
  FwGrid,
  FwSegment,
  useFwAuth,
  useFwSettings,
} from 'components/base';
import { isValidResponse, isSaved } from 'components/doc/function/document';
import { AutoSaveEdit, AutoSaveInfo, Option } from 'core/model';
import { THEME, FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

const { select } = FIELD_TYPE;

const ThemeSelector = () => {
  const { t } = useTranslation();
  const { user } = useFwAuth();
  const settings = useFwSettings();

  const themeOptions = Object.values(THEME).map((value) => {
    return new Option({
      key: value,
      value,
      text: t(`common|${utils.toSentenceCase(value)}`),
    });
  });

  const themeInputs = [
    {
      key: 'Hide unreleased features',
      name: 'Hide unreleased features',
      label: t('common|Background'),
      type: select,
      row: 1,
      column: 1,
      options: themeOptions,
      subInputs: [],
      visible: true,
    },
    {
      key: 'Accent',
      name: 'Accent',
      label: t('common|Accent'),
      row: 1,
      column: 2,
      isDisabled: true,
      subInputs: [],
      visible: true,
    },
  ];

  const [appPreferenceID, setAppPreferenceID] = useState(
    user.appPreference.appPreferenceID
  );

  const [args, setArgs] = useState([]);
  const { response, pending } = useApi(
    appPreferenceID == 0 ? appPreferenceApi.post : appPreferenceApi.putAS,
    args
  );

  // send api request
  const triggerApiCall = (value) => {
    // compute api arguments
    const newArgs =
      appPreferenceID == 0
        ? [{ appPreference: { ...settings, theme: value } }]
        : [
            new AutoSaveInfo({
              id: appPreferenceID,
              autoSaveEdits: [
                new AutoSaveEdit({ name: 'Theme', value: value }),
              ],
            }),
          ];

    // trigger useApi hook
    setArgs(newArgs);
  };

  // receive response from api call
  useEffect(() => {
    if (isValidResponse(response)) {
      if (response.status === 201) {
        setAppPreferenceID(response.data.id);
      }

      // prevent further api call by clearing args
      setArgs([]);
    }
  }, [response]);

  const handleChangeTheme = (field, value) => {
    settings.dispatchSettings({ theme: value });
    triggerApiCall(value);
  };

  const { fields } = useInputFormContainer(
    isValidResponse(response),
    isSaved(response, pending),
    { 'Hide unreleased features': settings.theme },
    themeInputs,
    handleChangeTheme
  );

  return (
    <FwSegment as={FwGrid} leftIcon="RiContrastDropFill" name="Theme">
      <FwGrid items={fields} itemComponent={FwField} />
    </FwSegment>
  );
};

export default ThemeSelector;
