import _ from 'lodash';
import React, { FC } from 'react';

import { FwTable } from 'components/base';
import { FwCollectionProps, fwCollectionPT } from 'core/model';
import { sortByStepRowColumnKey } from 'core/utils/sort';

const FwCollection: FC<FwCollectionProps> = ({
  name,
  subInputs,
  collectionValue,
  invalid,
  readOnly,
  disabled,
  invalidSubInputKey,
  invalidRow,
  loadingSubInputKeys,
  responsive,
  onBlur,
  onChange,
}) => {
  // process subInputs to columns
  const columns = subInputs
    ? sortByStepRowColumnKey(
        subInputs.filter(({ visible }) => !_.isNil(visible))
      ).map(({ key, name }) => ({
        Header: name,
        accessor: key,
      }))
    : [];

  const handleAdd = () => {
    // todo init for type dropdown with selectedOptionValue
    // clone and add new item
    updateMainField([...collectionValue, {}]);
  };

  const handleDelete = (idx) => {
    // clone and delete item by index
    const newValues = [...collectionValue];
    newValues.splice(idx, 1);

    // update
    updateMainField(newValues);
  };

  const handleChange = (updateValues, idx) => {
    // clone and update value
    const newValues = [...collectionValue];

    // initialize fillData with value of collection
    const fillData = { [name]: newValues };

    _.forOwn(updateValues, (val, key) => {
      newValues[idx] = {
        ...newValues[idx],
        [key]: val,
      };

      // build fillData by key and row index
      fillData[`${key}|${idx}`] = val;
    });

    // update
    updateMainField(newValues, fillData);
  };

  const updateMainField = (newValues, fillData = undefined) => {
    // update value then callback to onChange
    onChange(null, { name, value: newValues, fillData });
  };

  return (
    <FwTable
      allowAddRow
      allowDeleteRow
      collapse
      hideGlobalFilter
      hideFilters
      hidePagination
      renderDataAsInput
      inputs={subInputs}
      isInvalid={invalid}
      isInputReadOnly={readOnly}
      isInputDisabled={disabled}
      invalidKey={invalidSubInputKey}
      invalidRow={invalidRow}
      loadingKeys={loadingSubInputKeys}
      allowContextMenu={false}
      allowDoubleClick={false}
      allowViewed={false}
      showEmptyRowsMessage={false}
      columns={columns}
      data={collectionValue}
      responsive={responsive}
      handleDataInputChange={handleChange}
      handleDataInputBlur={onBlur}
      handleAddRow={handleAdd}
      handleDeleteRow={handleDelete}
    />
  );
};

FwCollection.propTypes = fwCollectionPT;

export default FwCollection;
