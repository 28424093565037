import { chakra, useColorModeValue } from '@chakra-ui/react';
import { InferProps, number } from 'prop-types';
import React, { FC } from 'react';
import { Id, toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFwTheme } from 'components/base';
import { MODAL_TYPES } from 'core/utils/constants';

import Message from './FwToast.Message';

const { error, info, success, warning } = {
  error: MODAL_TYPES.error,
  info: MODAL_TYPES.info,
  success: MODAL_TYPES.success,
  warning: MODAL_TYPES.warning,
};

const StyledToastContainer = chakra(({ autoClose, ...props }) => (
  <ToastContainer
    autoClose={autoClose || 5500}
    closeButton={false}
    icon={false}
    position={toast.POSITION.TOP_CENTER}
    {...props}
  />
));

const FwToast: FC<Props> & {
  error: (message: string, options?: ToastOptions) => Id;
  info: (message: string, options?: ToastOptions) => Id;
  success: (message: string, options?: ToastOptions) => Id;
  warn: (message: string, options?: ToastOptions) => Id;
} = ({ autoClose }) => {
  // todo wip#125 factor text color value at whole theme level
  const txtColor = useColorModeValue('black', 'white');
  const { bg } = useFwTheme();

  const toastContainerStyle = {
    p: 0,
    sx: {
      '& .Toastify__toast': {
        bg,
        mb: '0.1rem !important' /* spacing between toasts */,
        p: '0 !important',
      },
      '& .Toastify__toast-body': {
        color: txtColor,
        p: '0 !important',
      },
    },
  };

  return (
    <StyledToastContainer {...toastContainerStyle} autoClose={autoClose} />
  );
};

const propTypes = {
  autoClose: number,
};

export type Props = InferProps<typeof propTypes>;

FwToast.propTypes = propTypes;

FwToast.error = (message, options) =>
  toast.error(<Message message={message} type={error} />, options);
FwToast.info = (message, options) =>
  toast.info(<Message message={message} type={info} />, options);
FwToast.success = (message, options) =>
  toast.success(<Message message={message} type={success} />, options);
FwToast.warn = (message, options) =>
  toast.warn(<Message message={message} type={warning} />, options);
// .dismiss = toast.dismiss;
// .done = toast.done;
// .isActive = toast.isActive;
// .onChange = toast.onChange;
// .POSITION = toast.POSITION;
// .TYPE = toast.TYPE;
// .update = toast.update;

export default FwToast;
