import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';

const useContextMenu = (triggerRef) => {
  const contextRef = useRef<any>();
  const pendingClose = useRef(true);

  const [open, setOpen] = useState<boolean>();

  // when context menu is not in use (not being hovered)
  // pending close is set to true
  const setPendingClose = (allowClose) => {
    pendingClose.current = allowClose;
  };

  useEffect(() => {
    const trigger = triggerRef.current;

    // define context menu position
    const createContextFromEvent = (e) => {
      const left = e.pageX;
      const top = e.pageY;
      const right = left + 1;
      const bottom = top + 1;

      return {
        getBoundingClientRect: () => ({
          left,
          top,
          right,
          bottom,
          height: 0,
          width: 0,
        }),
      };
    };

    // close context menu
    const hide = () => {
      setOpen(false);
    };

    // if context menu is not in use, close it
    const discardContextMenu = () => {
      if (pendingClose.current) {
        hide();
        window.removeEventListener('scroll', discardContextMenu);
        window.removeEventListener('wheel', discardContextMenu);
        window.removeEventListener('mousedown', discardContextMenu);
      }
    };

    // show context menu
    const openContextMenu = (e) => {
      e.preventDefault();

      // position and show context menu
      contextRef.current = createContextFromEvent(e);
      setOpen(true);

      // subscribe to window events
      window.addEventListener('scroll', discardContextMenu);
      window.addEventListener('wheel', discardContextMenu);
      window.addEventListener('mousedown', discardContextMenu);
    };

    // affect open context menu handler to contextmenu event on trigger
    if (trigger) {
      trigger.addEventListener('contextmenu', openContextMenu);
    }

    // remove all subscriptions when unmounting
    return () => {
      if (trigger) {
        trigger.removeEventListener('contextmenu', openContextMenu);
      }

      window.removeEventListener('scroll', discardContextMenu);
      window.removeEventListener('wheel', discardContextMenu);
      window.removeEventListener('mousedown', discardContextMenu);
    };
  }, [triggerRef]);

  // todo change to an object instead of array
  return [contextRef, open, setOpen, setPendingClose] as [
    MutableRefObject<any>,
    boolean,
    Dispatch<SetStateAction<boolean>>,
    (arg0: boolean) => void
  ];
};

export default useContextMenu;
