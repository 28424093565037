import { Box, HTMLChakraProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const FwOverlayBox: FC<HTMLChakraProps<typeof Box>> = ({
  zIndex = 1000,
  w = '100%',
  pos = 'absolute',
  p = '0px',
  children,
  ...props
}) => {
  return (
    <Box zIndex={zIndex} w={w} pos={pos} p={p} {...props}>
      {children}
    </Box>
  );
};

export default FwOverlayBox;
