import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import baseApi, { CacheFileContent, validateStatusHelper } from 'api/baseApi';
import documentApi from 'api/document/documentApi';
import healthApi from 'api/health/healthApi';
import languageApi from 'api/language/languageApi';
import operationApi from 'api/operation/operationApi';
import pageContentApi from 'api/page-content/pageContentApi';
import pageApi from 'api/page/pageApi';
import tableApi from 'api/table/tableApi';
import tableDataApi from 'api/tableData/tableDataApi';
import { useApi } from 'api/useApi';
import {
  useFwSettings,
  FwSpinner,
  useFwModule,
  FwModal,
} from 'components/base';
import FwProgress from 'components/base/elements/progress/FwProgress';
import { initializeTableCriteria } from 'components/tables/helpers/tableCriteriaHelpers';
import { sendToSW, swEventActions } from 'config/serviceWorker/helpers';
import { PageContentManagement, Row, Table } from 'core/model';
import { LOCALES } from 'core/utils/constant';

import Preferences from './Preferences';
import {
  afterFetchResponse,
  cacheFileName,
  fetchCacheRoutes,
  showErrorModalState,
  swStoreHeader,
  swStoreValue,
} from './preferencesContainer.helpers';

const PreferencesContainer = () => {
  const { t } = useTranslation();

  const { dispatchAppPreference, setLocalMode } = useFwSettings();
  const { moduleRoutes } = useFwModule();

  const [options, setOptions] = useState(undefined);

  const [progress, setProgress] = useState({
    showModal: false,
    modalOptions: undefined,
    part1: undefined,
    part2: undefined,
  });

  const [getArgs] = useState([]);
  const { fetched, pending } = useApi(languageApi.getAll, getArgs);

  const getOptionByKey = (key: string) => ({
    key: key.toLowerCase(),
    value: key,
    text: ISO6391.getNativeName(key.toLowerCase()),
  });

  useEffect(() => {
    if (!pending && fetched) {
      const languageOptions = _.map(fetched?.languages, ({ key }) =>
        getOptionByKey(key)
      );

      // add default translation
      languageOptions.push(getOptionByKey(LOCALES['en']));

      // sorted alphabetically
      const sortLanguageOption = languageOptions.sort((a, b) =>
        a.key > b.key ? 1 : -1
      );

      setOptions(sortLanguageOption);
    }
  }, [fetched, pending]);

  const handleSetKey = useCallback(() => {
    // todo set in user instead or maybe refetch user from token
    // dispatchAppPreference({ appPreferenceID: key });
  }, []);

  // apply new preferences
  const handleEntityChange = useCallback(
    (data) => {
      // todo wip#585 value(s) will be overwritten by string values
      dispatchAppPreference(data);
    },
    [dispatchAppPreference]
  );

  // set state to close error modal
  const closeErrorModal = useCallback(() => {
    setProgress((prevProgress) => ({
      ...prevProgress,
      showModal: false,
      modalOptions: undefined,
    }));
  }, []);

  // set state to open error modal
  const openErrorModal = useCallback(
    (message: string) => showErrorModalState(t, message, closeErrorModal),
    [closeErrorModal, t]
  );

  // refactor to a helper file?
  const handleSetLocalMode = useCallback(
    async (localOnly: boolean) => {
      // opt out of localMode status in service worker
      setLocalMode(false, true);

      // make sure FasterWeb backend is accessible
      const pingResponse = await healthApi.get();

      if (validateStatusHelper(pingResponse?.status)) {
        // when going local only, prepare cached data for local-first behaviour
        if (localOnly) {
          let canGoLocal = true;

          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
          }));

          const cachingHeaders = { [swStoreHeader]: swStoreValue };
          const cachingParams = { headers: cachingHeaders };

          // load file containing url addresses for static data
          const response = await fetch(`/${cacheFileName}`);

          // load static data
          if (
            response.ok &&
            response.headers.get('content-type').includes('application/json')
          ) {
            try {
              // read cache file content
              const { publicRoutes, authedRoutes }: CacheFileContent =
                await response.json();

              // cache all resources
              const pubCacheSuccess = await fetchCacheRoutes(
                false,
                publicRoutes,
                cachingHeaders
              );

              const cacheSuccess = pubCacheSuccess
                ? await fetchCacheRoutes(true, authedRoutes, cachingHeaders)
                : false;

              if (!cacheSuccess) {
                // caching has failed
                canGoLocal = false;
              }
            } catch (error) {
              // cache.json file format is invalid
              canGoLocal = false;
            }
          }

          // load dynamic data
          if (canGoLocal && moduleRoutes) {
            // only work on custom modules
            const modules = moduleRoutes.filter((route) => !route.system);

            const pages = [];

            // trigger data fetches (service worker will cache all)
            for (let mIdx = 0; mIdx < modules.length; mIdx++) {
              // fetch pages
              const resM = await pageApi.getByModuleAndArea(
                modules[mIdx].key,
                null,
                cachingParams
              );

              afterFetchResponse(
                resM,
                () => pages.push(...resM?.data?.pages),
                () => (canGoLocal = false)
              );
            }

            if (pages) {
              // keep track of required docs
              const docIdArray = [];

              // keep track of cached tables
              const tableKeyArray = [];

              for (let pIdx = 0; pIdx < pages.length; pIdx++) {
                setProgress((prevProgress) => ({
                  ...prevProgress,
                  part1: (100 * (pIdx + 1)) / pages.length,
                  part2: 0,
                }));

                const pageContents = pages[pIdx].pageContent;

                for (let pcIdx = 0; pcIdx < pageContents.length; pcIdx++) {
                  // fetch page contents
                  const resPC = await pageContentApi.getById(
                    pageContents[pcIdx].pageContentID,
                    cachingParams
                  );
                  let pageContent: PageContentManagement;

                  afterFetchResponse(
                    resPC,
                    () => (pageContent = resPC?.data?.pageContent),
                    () => (canGoLocal = false)
                  );

                  if (
                    pageContent?.tableKey &&
                    !tableKeyArray.includes(pageContent.tableKey)
                  ) {
                    tableKeyArray.push(pageContent.tableKey);

                    // fetch corresponding table
                    const resT = await tableApi.getTable(
                      pageContent.tableKey,
                      cachingParams
                    );
                    let table: Table;

                    afterFetchResponse(
                      resT,
                      () => (table = resT?.data?.table),
                      () => (canGoLocal = false)
                    );

                    if (table) {
                      // fetch table data
                      const resTd = await tableDataApi.getMany(
                        tableDataApi.buildQuery(
                          table.key,
                          '',
                          initializeTableCriteria(
                            table,
                            '',
                            null,
                            [],
                            null,
                            null,
                            null,
                            null
                          )
                        ),
                        null,
                        table,
                        null,
                        null,
                        null,
                        cachingParams
                      );
                      let tableDataRows: Row[];

                      afterFetchResponse(
                        resTd,
                        () => (tableDataRows = resTd?.data?.tableData?.rows),
                        () => (canGoLocal = false)
                      );

                      if (tableDataRows) {
                        for (
                          let tdrIdx = 0;
                          tdrIdx < tableDataRows.length;
                          tdrIdx++
                        ) {
                          const docId = tableDataRows[tdrIdx].rowID;

                          if (!docIdArray.includes(docId)) {
                            docIdArray.push(docId);
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (canGoLocal) {
                for (let index = 0; index < docIdArray.length; index++) {
                  setProgress((prevProgress) => ({
                    ...prevProgress,
                    part2: (100 * (index + 1)) / docIdArray.length,
                  }));

                  let fetching = true;
                  const docId = docIdArray[index];

                  // fetch document
                  const resD = await documentApi.getByID(
                    docId,
                    '',
                    '',
                    '',
                    false,
                    cachingParams
                  );

                  afterFetchResponse(resD, null, () => (fetching = false));

                  if (fetching) {
                    // fetch operation
                    const resO = await operationApi.getManyByDocID(
                      docId,
                      cachingParams
                    );

                    afterFetchResponse(resO, null, () => (fetching = false));
                  }

                  if (!fetching) {
                    canGoLocal = false;
                    break;
                  }
                }
              }
            }
          }

          if (canGoLocal) {
            // store localMode status in settings
            setLocalMode(true);

            // close modal
            setProgress((prevProgress) => ({
              ...prevProgress,
              part1: null,
              part2: null,
              showModal: false,
              modalOptions: undefined,
            }));
          } else {
            // going local failed
            // notify service worker for a cache deletion
            sendToSW(swEventActions.clearCache);

            // and stay out of local mode
            setProgress((prevProgress) => ({
              ...prevProgress,
              part1: null,
              part2: null,
              ...openErrorModal(
                'This operation requires a stable internet connection'
              ),
            }));
          }
        } else {
          setProgress((prevProgress) => ({
            ...prevProgress,
            showModal: true,
            modalOptions: { content: t('Synchronization...') },
          }));

          // try fetch remote backup endpoint for local requests
          let backupEndpoint: CacheFileContent['backup'];
          const response = await fetch(`/${cacheFileName}`);

          if (
            response.ok &&
            response.headers.get('content-type').includes('application/json')
          ) {
            try {
              const { backup }: CacheFileContent = await response.json();
              backupEndpoint = backup;
            } catch {
              // backup is not required, keep going
            }
          }

          // going back online, synchronize (resend data to backend through network)
          baseApi.synchronize((success) => {
            if (success) {
              // opt out of localMode status in settings
              setLocalMode(false);

              // notify service worker for a cache deletion
              sendToSW(swEventActions.clearCache);

              // close modal
              setProgress((prevProgress) => ({
                ...prevProgress,
                showModal: false,
                modalOptions: undefined,
              }));
            } else {
              // re-enter localMode in service worker
              setLocalMode(true, true);

              setProgress((prevProgress) => ({
                ...prevProgress,
                ...openErrorModal(
                  'This operation requires a stable internet connection'
                ),
              }));
            }
          }, backupEndpoint);
        }
      } else {
        setProgress((prevProgress) => ({
          ...prevProgress,
          ...openErrorModal(
            'This operation requires a stable internet connection'
          ),
        }));
      }
    },
    [moduleRoutes, setLocalMode, t, openErrorModal]
  );

  const props = {
    options,
    handleSetKey,
    handleEntityChange,
    handleSetLocalMode,
  };

  return options ? (
    <>
      <Preferences {...props} />
      <FwModal
        noDimmerClick
        open={progress.showModal}
        content={
          progress.modalOptions ? undefined : (
            <>
              <>{t('common|Loading pages')}</>
              <FwProgress value={progress.part1} />
              <br />
              <>{t('common|Loading data')}</>
              <FwProgress type="success" value={progress.part2} />
            </>
          )
        }
        // state modal options must override other props
        {...progress.modalOptions}
      />
    </>
  ) : (
    <FwSpinner />
  );
};

export default PreferencesContainer;
