import _ from 'lodash';

import { FwStore } from 'components/base';
import {
  TextObject,
  TextTypes,
} from 'components/base/containers/mask/FwMask.structures';
import { executeScript } from 'components/form/components/template/helpers/executeScript';
import { Route } from 'core/model';

const getValue = (rawValue: string | TextObject, store: FwStore): string => {
  let value: string;

  if (!_.isNil(rawValue)) {
    if (_.isString(rawValue)) {
      value = rawValue;
    } else {
      switch (rawValue.type) {
        case TextTypes.static:
          value = rawValue.text;
          break;
        case TextTypes.script:
          value = executeScript(rawValue.text, { store });
          break;
      }
    }
  } else {
    value = rawValue as string;
  }

  return value;
};

const getTilesValue = (value: Route[], store: FwStore) => {
  const newValues = _.map(value, (tile: Route) => {
    return { ...tile, path: getValue(tile.path, store) };
  });

  return newValues;
};

export { getValue, getTilesValue };
