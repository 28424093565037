import {
  InferProps,
  func,
  arrayOf,
  any,
  object,
  string,
  bool,
  number,
} from 'prop-types';

import { ctor } from '../helper';

const fwCollectionPT = {
  name: string,
  subInputs: arrayOf(any),
  collectionValue: arrayOf(object),
  onBlur: func,
  onChange: func,
  invalid: bool,
  readOnly: bool,
  disabled: bool,
  invalidSubInputKey: string,
  invalidRow: number,
  loadingSubInputKeys: arrayOf(string),
  responsive: bool,
};

type FwCollectionPT = InferProps<typeof fwCollectionPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwCollectionProps extends FwCollectionPT {}
class FwCollectionProps implements FwCollectionPT {
  constructor(props?: FwCollectionPT) {
    ctor(this, props);
  }
}

export { fwCollectionPT, FwCollectionProps };
