import { useEffect, useState, ChangeEvent } from 'react';

import {
  ModalButtonProps,
  ModalMessageProps,
} from 'components/base/elements/modal/components';

export const useDrawerModal = ({
  open,
  onCancel,
  onChangeData,
  onSubmit,
}: {
  open: boolean;
  onCancel: () => void;
  onChangeData: (
    _e: ChangeEvent<HTMLElement>,
    {
      fillData,
      ...htmlData
    }: /* todo wip #672 refactor type */
    { name: string; value: string; fillData?: object }
  ) => void;
  onSubmit: () => void;
}): {
  modalButtonProps: ModalButtonProps;
  modalMesageExtendProps: ModalMessageProps;
  systemClose: () => void;
} => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (open === false) {
      setDisabled(false);
    }
  }, [open]);

  const overrideOnSubmit = () => {
    setDisabled(true);

    if (onSubmit) {
      onSubmit();
    }
  };

  const overrideOnCancel = () => {
    setDisabled(true);
    systemClose();
  };

  const overrideOnChangeData = (e, htmlData) => {
    setDisabled(false);
    onChangeData(e, htmlData);
  };

  const systemClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return {
    modalButtonProps: {
      disabled,
      overrideOnSubmit,
      overrideOnCancel,
    },
    modalMesageExtendProps: {
      onChangeDataEdit: overrideOnChangeData,
      setDisabled,
    },
    systemClose,
  };
};
