import { Box, chakra, Divider, Kbd, Stack } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, InferProps } from 'prop-types';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MapContainer,
  TileLayer,
  LayerGroup,
  Marker,
  Popup,
  Tooltip,
  GeoJSON,
} from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'lrm-graphhopper';

import { FwErrorBoundary, FwLink } from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';

import { mapViewTypes } from '../FwMask.structures';
import FwHelpBox from './FwHelpBox';
import {
  AltZoomComponent,
  DblClickComponent,
  getMarkerProp,
  maskRowToGeoObject,
  newGeoObject,
  onEachFeature,
  RightClickComponent,
  validateViewport,
} from './FwMask.Map.helpers';

const DEFAULT_VIEWPORT = {
  center: [28, 4],
  zoom: 2,
};

const ChakraMapContainer = chakra(MapContainer, {
  baseStyle: {
    h: 'full',
    w: 'full',
  },
});

const tileLayerProps = {
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
};

const FwMap: FC<FwMaskCommonProps> = ({
  maskStructure,
  maskRows,
  processes,
  handleProcessActionClick,
  handleOpen,
  zoomed,
}) => {
  const { view } = maskStructure || {};
  const { t } = useTranslation();

  // rendered geoJson count ref
  const geoJsonCountRef = useRef(0);
  const maskRowsRef = useRef({});

  const [geoObject, setGeoObject] = useState(newGeoObject);

  const [viewport /* , setViewport */] = useState(
    validateViewport(view) ? view : DEFAULT_VIEWPORT
  );

  // const onViewportChanged = (newViewport) => {
  //   setViewport(newViewport);
  // };

  useEffect(() => {
    if (
      maskStructure &&
      maskRows &&
      !_.isEqual(maskRowsRef.current, maskRows)
    ) {
      setGeoObject(maskRowToGeoObject(maskStructure, maskRows, t));

      geoJsonCountRef.current += 1;
      maskRowsRef.current = maskRows;
    }
  }, [maskStructure, maskRows, t]);

  // save in the state wether the map is maximized or not
  const [zoomState, setZoomState] = useState(zoomed);
  useEffect(() => {
    setZoomState(zoomed);
  }, [zoomed]);

  const mapProps: MapProps = {
    scrollWheelZoom: zoomed !== undefined ? zoomed : false,
    dragging: true /*false when in mobile*/,
    center: viewport.center,
    zoom: viewport.zoom,
    // onViewportChanged,
  };

  // define context menu items
  const getItemsByKey = (key) =>
    getContextMenuItems(
      t,
      () => handleOpen(key),
      () => handleOpen(key, true),
      processes,
      (processId) => handleProcessActionClick(processId, key)
    );

  const geoJsonProp = { onEachFeature };

  return (
    <Box h="100%" mt="-6px" mx="-6px">
      <ChakraMapContainer zIndex="base" {...mapProps}>
        <FwErrorBoundary>
          <GeoJSON
            key={`geoJson-${geoJsonCountRef.current}`}
            data={geoObject.geojson}
            {...geoJsonProp}
          />
        </FwErrorBoundary>
        <DblClickComponent />
        <RightClickComponent />
        {!zoomState && <AltZoomComponent />}
        <TileLayer {...tileLayerProps} />
        <LayerGroup>
          {_.map(geoObject.markers, (m, i) => (
            <FwErrorBoundary key={`marker-${i}`}>
              <Marker {...getMarkerProp(m)}>
                {m.key && (
                  <Popup>
                    <Stack>
                      {getItemsByKey(m.key).map(
                        ({ itemKey, text, ...linkProps }, index) => (
                          <Fragment key={itemKey}>
                            {index > 0 && <Divider />}
                            <FwLink {...linkProps}>{text}</FwLink>
                          </Fragment>
                        )
                      )}
                    </Stack>
                  </Popup>
                )}

                {m.tooltip && (
                  <Tooltip>
                    {_.map(m.tooltip.split('\n'), (t, k) => (
                      <div key={k}>{t}</div>
                    ))}
                  </Tooltip>
                )}
              </Marker>
            </FwErrorBoundary>
          ))}
        </LayerGroup>
        <FwHelpBox heading={t('How to use the map ?')} btnText={t('Help')}>
          {[
            [
              t('Zoom with scroll wheel : '),
              !zoomed ? <Kbd key="00">Alt</Kbd> : null,
              !zoomed ? '+' : null,
              <Kbd key="01">{t('Scroll wheel')}</Kbd>,
            ],
            [
              t('Zoom in : '),
              <Kbd key="10">{t('Double left click')}</Kbd>,
              t('or'),
              <Kbd key="11">+</Kbd>,
            ],
            [
              t('Zoom out : '),
              <Kbd key="20">{t('Right click')}</Kbd>,
              t('or'),
              <Kbd key="21">-</Kbd>,
            ],
          ]}
        </FwHelpBox>
      </ChakraMapContainer>
    </Box>
  );
};

const mapPropTypes = {
  ...mapViewTypes,
  scrollWheelZoom: bool,
  dragging: bool,
  // onViewportChanged: func,
};

type MapProps = InferProps<typeof mapPropTypes>;

FwMap.propTypes = {
  maskStructure: (prop: FwMaskCommonProps) => {
    const { maskStructure } = prop;
    const { view } = maskStructure || {};
    const { center } = view || {};
    if (
      !Array.isArray(center) ||
      center.length != 2 ||
      !center.every(Number.isFinite)
    ) {
      return new Error(`[${center}] needs to be an array of two numbers`);
    }
  },
};

export default FwMap;
