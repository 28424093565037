import { Box } from '@chakra-ui/react';
import React from 'react';

import FwButton from '../../button/FwButton';
import PagContext from '../providers/pagination-provider';
import { fetchPageNumbers } from '../services/fetch-page';

export const PaginationComp = () => {
  const pagProps = React.useContext(PagContext);
  const totalPages = Math.ceil(pagProps.total / pagProps.pageSize);

  if (!totalPages || (pagProps.hideOnSinglePage && totalPages === 1)) {
    return null;
  }

  const pages = fetchPageNumbers(pagProps);

  const changePage = (page: number) => {
    const currentPage = Math.max(1, Math.min(page, totalPages));
    pagProps.setCurrentPage(currentPage);
    pagProps.onChange(
      currentPage,
      totalPages,
      pagProps.pageSize,
      pagProps.total
    );
  };

  const fastBackward = () => {
    const page = pagProps.currentPage - pagProps.pageNeighbours * 2 - 1;
    changePage(page);
  };

  const fastForward = () => {
    const page = pagProps.currentPage + pagProps.pageNeighbours * 2 + 1;
    changePage(page);
  };

  return pagProps.total > 0 ? (
    <Box
      {...(pagProps.paginationProps || { w: 'full', justifyContent: 'center' })}
    >
      {pages.map((page, index) => {
        if (page === 'LEFT') {
          return (
            <FwButton
              small
              key={index}
              leftIcon="RiMoreLine"
              onClick={fastBackward}
            />
          );
        }

        if (page === 'RIGHT') {
          return (
            <FwButton
              small
              key={index}
              leftIcon="RiMoreLine"
              onClick={fastForward}
            />
          );
        }

        const active = page === pagProps.currentPage;

        return (
          <FwButton
            rounded
            small
            basic={active}
            disabled={pagProps.disabled}
            key={`page-${index}`}
            leftIcon={`${page}`}
            primary={active}
            onClick={() => changePage(page)}
          />
        );
      })}
    </Box>
  ) : null;
};
