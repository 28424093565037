import React, { FC } from 'react';

import { PaginationComp } from './components/pagination';
import PagContext, {
  defaultData,
  Pagination,
} from './providers/pagination-provider';
import { filterProps } from './services/filter-props';

const FwPaginator: FC<Partial<Pagination>> = (props: Partial<Pagination>) => {
  const allProps = { ...defaultData, ...props };
  const contextvalue = { ...allProps };

  const [currentPage, setCurrentPage] = React.useState(
    allProps.current || allProps.defaultCurrent
  );
  const [pageSize, setPageSize] = React.useState(
    allProps.pageSize > 0 ? allProps.pageSize : allProps.defaultPageSize
  );
  const [total, setTotal] = React.useState(allProps.total || 1);

  React.useEffect(() => {
    props.current && setCurrentPage(props.current);
    // set total to 1 to show first page even when there is no row
    setTotal(props.total || 1);
  }, [props]);

  const value = React.useMemo(() => {
    return {
      ...contextvalue,
      currentPage,
      setCurrentPage,
      pageSize,
      setPageSize,
      total,
    };
  }, [filterProps(contextvalue), currentPage, pageSize, total]);
  return (
    <PagContext.Provider value={value}>
      <PaginationComp />
    </PagContext.Provider>
  );
};

export default FwPaginator;
