import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import userFilterApi from 'api/user/userFilterApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import { getAdminInputs, UserFilter as UF, userFilterRules } from 'core/model';

const UserFilter: FC<Props> = ({ userFilter, handleSetKey }) => {
  const { t } = useTranslation();
  const castPC: UF = Object.assign(new UF(), userFilter);

  // get entityDoc for update key, operator, value and editable
  const inputs = getAdminInputs(castPC, null, t);
  const entityDoc = getEntityDoc(userFilter, inputs, userFilterRules);

  return (
    <>
      <EntityInfo
        autosave
        api={userFilterApi}
        entityDoc={entityDoc}
        keyName={'fieldID'}
        entityKey={userFilter.fieldID}
        type={'UserFilter'}
        title={'User Filter'}
        onSetKey={handleSetKey}
      />
    </>
  );
};

const propTypes = {
  userFilter: any.isRequired,
  handleSetKey: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

UserFilter.propTypes = propTypes;

export default withTranslation()(UserFilter);
