import React, { FC } from 'react';
import { Route, RouteChildrenProps, RouteProps } from 'react-router';

import { FwModuleProvider, FwTemplatesProvider } from 'components/base';
import { Module } from 'core/model';

const ShareRoute: FC<Partial<RouteChildrenProps> & RouteProps> = ({
  component: Component,
  match,
}) => {
  // todo ShareRoute is harcoded for PublicDoc at the moment
  // it could be generalized and refactored for a set of 'share/' urls
  // share pages could be any FasterWeb page that would be shared (publicly or with password protection)
  const { module } = ((match && match.params) || {}) as { module: string };
  const moduleKey = `public/${module}`;

  return (
    <Route
      render={(props) => (
        // todo maybe refactor with PrivateRoute?
        <FwTemplatesProvider lazy>
          <FwModuleProvider
            module={new Module({ key: moduleKey, position: 0 })}
          >
            <Component {...props} />
          </FwModuleProvider>
        </FwTemplatesProvider>
      )}
    />
  );
};

export default ShareRoute;
