import { Box, Th } from '@chakra-ui/react';
import _ from 'lodash';
import { InferProps, instanceOf, number, oneOf, string } from 'prop-types';
import React from 'react';

import { useFwTheme } from 'components/base';
import { dateFormats, isSameDay, isSameMonth } from 'core/utils/date';

const TimelineTh = ({ colSpan, date, now, text, type }: FwTimelineThProps) => {
  const {
    boxShadow,
    _hover: { bg },
  } = useFwTheme();

  // const [isNow /*, setIsNow*/] = useState(false);

  const hourNumber = now.getHours() + now.getMinutes() / 60;
  const hour = date.getHours() + date.getMinutes() / 60;

  const dateIsToday = isSameDay(now, date);

  const dateIsThisMonth = isSameMonth(now, date);

  const cellIsNow =
    type === dateFormats.monthYear
      ? dateIsThisMonth
      : type === dateFormats.date
      ? dateIsToday
      : dateIsToday &&
        hourNumber >= hour &&
        hourNumber < hour + 1 / /*slotMultiplier*/ 1;

  // const isCurrentMonth =
  //   now.getFullYear() === date.getFullYear() &&
  //   now.getMonth() === date.getMonth();

  // text use in all column headers to have an equivalent scaling
  const invisibleText = '-----------';

  return (
    <Th
      colSpan={colSpan}
      whiteSpace="nowrap"
      pt={'0.3em'}
      pb={'0.3em'}
      background={cellIsNow ? bg : undefined}
      boxShadow={boxShadow}
    >
      <Box visibility={'hidden'}>{invisibleText}</Box>
      <Box
        left="-10px"
        marginTop="-16px"
        position="relative"
        visibility="initial"
      >
        {text}
      </Box>
    </Th>
  );
};

const fwTimelineThPT = {
  colSpan: number,
  date: instanceOf(Date),
  now: instanceOf(Date),
  text: string,
  type: oneOf(_.values(dateFormats)),
};

type FwTimelineThProps = InferProps<typeof fwTimelineThPT>;

export { fwTimelineThPT, FwTimelineThProps };

export default React.memo(TimelineTh);
