// import { FIELD_TYPE } from 'core/utils/constant';

// const {
//   address,
//   autocomplete,
//   barcode,
//   collection,
//   date,
//   daterange,
//   datetime,
//   file,
//   idlink,
//   label,
//   link,
//   multidates,
//   multiselect,
//   photo,
//   qrcode,
//   reference,
//   searchselect,
//   select,
//   sign,
//   text,
//   textarea,
//   time,
//   timerange,
// } = FIELD_TYPE;

// const getType = (type: string, search: boolean) => {
//   let realType = type;

//   if (search) {
//     // map type to search type
//     switch (type) {
//       case address:
//       case autocomplete:
//       case link:
//       case textarea:
//         realType = text;
//         break;
//       case date:
//       case datetime:
//         realType = daterange;
//         break;
//       case select:
//       case searchselect:
//         realType = multiselect;
//         break;
//       case time:
//         realType = timerange;
//         break;
//       // todo unsupported filters
//       case barcode:
//       case collection:
//       case file:
//       case idlink:
//       case multidates:
//       case photo:
//       case qrcode:
//       case reference:
//       case sign:
//         realType = label;
//         break;
//     }
//   }

//   return realType;
// };

// todo wip#125 refactor to ui constants?
const getShadowByState = (saved: boolean, unchanged: boolean) => {
  return saved
    ? '#21ba45 30px 0px 0px -27px inset !important'
    : unchanged
    ? 'none'
    : '#fbbd08 30px 0px 0px -27px inset !important';
};

export { getShadowByState /* , getType */ };
