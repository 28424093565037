import BaseApi, { BaseApiParams } from 'api/baseApi';
import { HealthCheckReport } from 'core/model/dto/healthCheckReport';

const healthApi = {
  get: (params?: BaseApiParams) => {
    // prevent browser-level caching with unique search query
    const url = `health?ts=${Date.now()}`;

    // send request and return response
    return BaseApi.get<HealthCheckReport>(url, params);
  },
};

export default healthApi;
