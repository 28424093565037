import { InferProps, node } from 'prop-types';

import { ctor } from '../helper';

const fwErrorBoundaryPT = { children: node.isRequired, fallback: node };

type FwErrorBoundaryPT = InferProps<typeof fwErrorBoundaryPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwErrorBoundaryProps extends FwErrorBoundaryPT {}
class FwErrorBoundaryProps implements FwErrorBoundaryPT {
  constructor(props?: FwErrorBoundaryPT) {
    ctor(this, props);
  }
}

export { fwErrorBoundaryPT, FwErrorBoundaryProps };
