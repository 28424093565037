import _ from 'lodash';

interface Dto {
  dtoName?: string;
}

const ctor = <T extends PT, PT>(_this: T, props?: PT) => {
  if (_this && props) {
    _.assign(_this, props);
  }
};

const dtoCtor = <T extends Dto>(
  _this: T,
  className: string,
  props?: Omit<T, 'dtoName'>
) => {
  ctor(_this, props);
  _this.dtoName = className;
};

export { Dto, ctor, dtoCtor };
