export const enum DIRECTIONS {
  ltr = 'LTR',
  rtl = 'RTL',
}

export const enum MODES {
  dark = 'DARK',
  light = 'LIGHT',
}

interface Theme {
  mode?: MODES;
  dir?: DIRECTIONS;
  accent: string;
}

const fwAccent = 'fw-accent';

const defaultAccent = '#3182ce';

const defaultSpacing = 1.5;

const defaultTheme: Theme = {
  mode: MODES.light,
  dir: DIRECTIONS.ltr,
  accent: defaultAccent,
};

export { defaultAccent, defaultSpacing, defaultTheme, fwAccent, Theme };
