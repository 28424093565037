import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FwButton, useFwArea, useFwAuth, useFwModule } from 'components/base';
import { BUTTON_TYPE } from 'core/utils/constant';
import { getLastCustomPage } from 'core/utils/storage';
import utils from 'core/utils/utils';

const { filter, save, submit } = BUTTON_TYPE;

const DocumentButton = ({
  // props
  btnKey,
  publicly,
  name,
  effectType,
  toStatus,
  additionalData,
  onClick,
  ...props
}: any) => {
  const { t } = useTranslation();
  const { area } = useFwArea();
  const { module } = useFwModule();

  const { userFilters, setUserFilters } = useFwAuth();

  const pathname = getLastCustomPage();
  const baseUrl =
    _.includes([filter, save, submit], effectType) && !publicly
      ? utils.buildUrlNav(area, module)
      : '';

  const redirectTo =
    _.includes([filter, save, submit], effectType) && !publicly
      ? pathname && pathname.includes(baseUrl)
        ? pathname
        : baseUrl
      : undefined;

  // apply button effect
  const triggerClick = () =>
    onClick({
      t,
      btnKey,
      redirectTo,
      toStatus,
      additionalData,
      userFilters,
      setUserFilters,
    });

  return (
    <FwButton {...props} small onClick={triggerClick}>
      {t(`custom|${name}`)}
    </FwButton>
  );
};

export default DocumentButton;
