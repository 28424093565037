import _ from 'lodash';

import { useFwTheme } from 'components/base/utility';

const useFwItemStyle = (active: boolean) => {
  const { _active, _hover } = useFwTheme();

  const activeStyle = active
    ? {
        bg: _active.bg,
        boxShadow: _active.boxShadow,
      }
    : undefined;

  return {
    // active style when active equals true
    ...(activeStyle || {}),
    // hover style
    _hover:
      // (prevent hover from overriding active style, despite :hover pseudo-element)
      _.merge(_hover, { cursor: 'pointer' }, active ? _active : {}),
  };
};

export { useFwItemStyle };
