import { chakra, Divider } from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FwLink } from 'components/base';
import { fwDataPT, FwDataProps } from 'core/model';
import { toArray } from 'core/utils/logic';

import { hiddenStyle } from './FwData.helpers';
import FwDataItem from './FwData.Item';

const FwData: FC<FwDataProps> = ({ collectionLimit, type, value, wrap }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  // if value is null or undefined, toArray returns null or undefined respectively
  // otherwise returns an array
  const values = toArray(value);

  const hasManyValues = !_.isNil(values) && values.length > 1;

  const valuesAreLimited =
    hasManyValues &&
    !_.isNil(collectionLimit) &&
    values.length > collectionLimit;

  // limits the number of values in the collection displayed depending there are many values,
  // on whether collectionLimit is defined and the collection is reduced
  const valuesToRender =
    hasManyValues && !_.isNil(collectionLimit) && !expanded
      ? values.slice(0, collectionLimit)
      : values;

  return _.isNil(values) ? null : (
    <>
      {
        // prevents rendering if valuesToRender is empty (when collectionLimit is 0)
        !_.isEmpty(valuesToRender) && (
          // <Divider> and <br> (when wrapping) prevent elements in the span from being placed on one row
          <span>
            {valuesToRender.map((val, index) => (
              <Fragment key={index}>
                {
                  // to separate values in a collection, add a divider when wrapping
                  // and add a backslash when not wrapping
                  index > 0 && (wrap ? <Divider /> : <>{'\\'}</>)
                }
                {
                  // when val is undefined and it's a wrap collection value
                  // then prevent line to collapse
                  !val && hasManyValues && wrap && <br />
                }
                {
                  // when val is defined use FwDataItem
                  val && (
                    // do not wrap when there is more than one value in the collection
                    <chakra.span
                      whiteSpace={hasManyValues ? 'nowrap' : undefined}
                    >
                      <FwDataItem type={type} value={val} />
                    </chakra.span>
                  )
                }
              </Fragment>
            ))}
          </span>
        )
      }
      {
        // show a link to toggle visibility on limited values
        valuesAreLimited && (
          <>
            {/* render both 'See less' and 'See more' toggle links to preserve column width during toggle */}
            <chakra.div
              {...(expanded ? undefined : hiddenStyle)}
              whiteSpace={'nowrap'}
            >
              <FwLink onClick={toggleExpand}>{t('See less')}</FwLink>
            </chakra.div>
            <chakra.div
              {...(expanded ? hiddenStyle : undefined)}
              whiteSpace={'nowrap'}
            >
              <FwLink onClick={toggleExpand}>{t('See more')}</FwLink>
            </chakra.div>
          </>
        )
      }
    </>
  );
};

FwData.propTypes = fwDataPT;

export default FwData;
