import _ from 'lodash';

import { Area } from 'core/model';
import { IMAGE_URL } from 'core/utils/constant';

const mainArea = new Area({
  areaContent: {
    background: IMAGE_URL.background,
    logo: IMAGE_URL.logoMedium,
    logoSmall: IMAGE_URL.logoSmall,
  },
});

const areaIsMain = (area: Area) => {
  return _.isEmpty(area?.key);
};

const getAreaBasePath = (area: Area) => {
  return areaIsMain(area) ? '/' : `/${_.toLower(area.key)}/`;
};

export { mainArea, areaIsMain, getAreaBasePath };
