import _ from 'lodash';

import { Step } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import utils from 'core/utils/utils';

// find in which step an input is located from its specified key
export const getStepIDFromFieldKey = (
  steps: Step[],
  fieldKey: string
): string => {
  const step = _.find(steps, (step) =>
    _.some(step?.contents, (content) =>
      _.some(
        content?.fields,
        (field) =>
          field?.key === fieldKey ||
          (field?.type === FIELD_TYPE.collection &&
            _.some(
              field?.subInputs,
              // invalid subInput key contains index so we need to remove it
              (si) => si?.key === utils.removeIndexFromSubInputKey(fieldKey)
            ))
      )
    )
  );

  return step?.stepID;
};

const setInvalidInputKey = (
  invalidInputKey: any,
  invalidInputKeyRef,
  visibleElementsRef?
) => {
  // update
  invalidInputKeyRef.current = invalidInputKey;

  // return new state
  return {
    invalidInputKey: invalidInputKeyRef.current,
    invalidTrigger: visibleElementsRef ? { timeStamp: Date.now() } : null,
    invalidStepID: visibleElementsRef
      ? getStepIDFromFieldKey(
          visibleElementsRef.current.steps,
          invalidInputKeyRef.current
        )
      : null,
  };
};

export default setInvalidInputKey;
