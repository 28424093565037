import React from 'react';
import { useHistory } from 'react-router';

import { pushUrl } from 'scenes/dashboard/helpers/admin';

import AdminUsers from './AdminUsers';

const AdminUsersContainer = () => {
  const history = useHistory();

  return <AdminUsers handleAddRow={() => pushUrl(history, 'register')} />;
};

export default AdminUsersContainer;
