import { Context, createContext, Dispatch, SetStateAction } from 'react';

import { Area } from 'core/model';

type AreaContext = {
  area: Area;
  areas: Area[];
  areaKey: string;
  setAreaKey: Dispatch<SetStateAction<string>>;
};

const FwAreaContext: Context<AreaContext> =
  createContext<AreaContext>(undefined);

export { FwAreaContext };
