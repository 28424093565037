import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';

const colFilter = {
  key: 'FormLayoutID',
  operatorNK: OPERATOR.equals,
  position: 0 /* for filter purpose only */,
  type: FIELD_TYPE.text /* must be the type of the property */,
};

const name = {
  key: 'Name',
  name: 'Name',
  position: 1,
  type: FIELD_TYPE.text /* must be the type of the property */,
};

const key = {
  key: 'Key',
  name: 'Key',
  position: 2,
  type: FIELD_TYPE.text /* must be the type of the property */,
};

const table = {
  columns: [colFilter, name, key],
  rowCount: 10,
  sortByColumnKey: 'Name',
  ascendingSort: true,
  additionalData: {
    disableViewed: true,
    disableDoubleClick: false,
    disableContext: true,
    disableCache: true,
  },
};

export default table;
