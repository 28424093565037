import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import exportApi from 'api/export/exportApi';
import TableHub from 'api/table/tableHub';
import tableDataApi from 'api/tableData/tableDataApi';
import { useApi } from 'api/useApi';
import { useHub } from 'api/useHub';
import {
  FwMask,
  useFwArea,
  useFwModule,
  useFwSettings,
  useFwTemplates,
} from 'components/base';
import { executeScript } from 'components/form/components/template/helpers/executeScript';
import mapDocsToTableData from 'core/mapper/mapDocsToTableData';
import { Row, TableData } from 'core/model';
import { BUTTON_TYPE, CONTENT_TYPE } from 'core/utils/constant';
import { IDXDDB_STORES, getAllFromIdxdDB } from 'core/utils/idxdDB';
import {
  cacheTableFilter,
  cacheTableSearch,
  cacheTableSort,
  getTableCache,
  readSettings,
} from 'core/utils/storage';
import useMountedComponentRef from 'core/utils/useMountedComponentRef';
import utils from 'core/utils/utils';

import { filterTableColumns } from '../helpers/filterTableColumns';
import { getMenuItems } from '../helpers/getMenuItems';
import {
  initializeTableCriteria,
  setCriterion,
} from '../helpers/tableCriteriaHelpers';

const TableContainer = ({
  pageContentStore,
  pageStore,
  setPageContentStore,
  setModuleStore,
  ...props
}: any) => {
  const {
    tableKey,
    table,
    ids,
    filters,
    dataType,
    dataKey,
    documentID,
    mask,
    maskData,
    maskHandleProcess,
    newTabLink,
    processes: pcProcesses,
    style,
  } = props;

  const settings = readSettings();

  const { t } = useTranslation();
  const history = useHistory();
  const { area } = useFwArea();
  const { module } = useFwModule();
  const { processes: contextProcesses } = useFwTemplates();
  const { noCache } = useFwSettings();

  const { height } = maskData || {};
  const {
    disableCache,
    disableHub,
    hubScript,
    localScript,
    processes: tableProcessIDs,
    odsTemplates,
  } = table.additionalData || {};

  // find table processes then union with pageContent processes by id
  const tableProcesses = _.compact(
    _.map(tableProcessIDs, (pId) =>
      _.find(contextProcesses, (bt) => bt && bt.batchTemplateId === pId)
    )
  );

  const processes = _.unionBy(
    pcProcesses,
    tableProcesses,
    (p) => p.batchTemplateId
  );

  const allowCache = !noCache && !disableCache;

  const hubArgs = useMemo(() => {
    const hubChannel = hubScript
      ? /* todo pass more parameters to hubScript? */
        executeScript(hubScript, { documentID })
      : undefined;
    return { channel: hubChannel };
  }, [hubScript, documentID]);

  const [activeItem, setActiveItem] = useState<string>();

  // state
  const errorMessageRef = useRef(t(`Data could not be updated`));
  const canRefetch = useRef(false);
  const needRefetch = useRef(false);
  const isFetching = useRef(false);
  const preventSpinRef = useRef(false);
  const mountedRef = useMountedComponentRef();
  const maskMenuItemsRef = useRef([]);
  const [cache] = useState(() => ({
    // do not replace by useRef https://github.com/facebook/react/issues/14490
    current: getTableCache(tableKey),
  }));
  const [criteria] = useState(() => ({
    // do not replace by useRef https://github.com/facebook/react/issues/14490
    current: initializeTableCriteria(
      table,
      cache.current.search,
      cache.current.sort,
      [cache.current.filters, ...(filters || [])],
      mask,
      maskData,
      pageStore?.filterData,
      pageStore
    ),
  }));
  const [loading, setLoading] = useState(true);
  const [disableExportBtn, setDisableExportBtn] = useState(false);
  const [activeColumn, setActiveColumn] = useState(
    cache.current.sort && cache.current.sort.key
      ? cache.current.sort.key
      : table.sortByColumnKey
  );
  const [direction, setDirection] = useState(
    cache.current.sort && cache.current.sort.direction
      ? cache.current.sort.direction
      : table.ascendingSort
      ? 'ascending'
      : 'descending'
  );
  const [query, setQuery] = useState(
    tableDataApi.buildQuery(tableKey, table.type, criteria.current)
  );
  const [args, setArgs] = useState([
    query,
    ids,
    table,
    dataType,
    dataKey,
    documentID,
  ]);
  const [readOnlyFilterKeys, setReadOnlyFilterKeys] = useState([]);

  // hooks
  const reFetch = useCallback(
    (preventSpin?: boolean, isHubUpdate?: boolean) => {
      // prevent spin when refetch is triggered by external hub notification...
      // ...this  then requires to rebuild query from criteria...
      // ...as refetch callback has not been updated because query has not changed

      // neither fetching nor hubUpdate then trigger request (cancel current if any)
      if (!isFetching.current || !isHubUpdate) {
        preventSpinRef.current = preventSpin || false;
        setArgs(
          preventSpin
            ? [
                tableDataApi.buildQuery(tableKey, table.type, criteria.current),
                ids,
                table,
                dataType,
                dataKey,
                documentID,
              ]
            : [query, ids, table, dataType, dataKey, documentID]
        );
      } else {
        // else then mark needRefetch to query after finish current request
        needRefetch.current = true;
      }
    },
    [query, ids, tableKey, table, dataType, dataKey, documentID, criteria]
  );

  useHub(
    !dataType && !dataKey && !disableHub && !settings.localMode
      ? TableHub
      : undefined,
    hubArgs,
    {
      updatedHandler: () => reFetch(true, true),
    }
  );

  const { fetched: fetchedData, pending: pendingData } = useApi(
    tableDataApi.getMany,
    args
  );

  const [data, setData] = useState<
    TableData & { error?: { message: string; timestamp: Date } }
  >();

  useEffect(() => {
    if (canRefetch.current) {
      reFetch();
    } else {
      canRefetch.current = true;
    }
  }, [reFetch]);

  useEffect(() => {
    if (pendingData) {
      isFetching.current = true;

      if (!preventSpinRef.current) {
        setLoading(true);
      } else {
        preventSpinRef.current = false;
      }
    } else if (isFetching.current) {
      isFetching.current = false;

      if (fetchedData) {
        const tableData = fetchedData.tableData;

        if (settings.localMode) {
          // get from indexeddb
          // try get from indexedDb
          getAllFromIdxdDB(IDXDDB_STORES.document, (docs) => {
            let newTableData = _.cloneDeep(tableData);

            if (docs && docs.length) {
              if (localScript) {
                newTableData = executeScript(localScript, {
                  docs,
                  mapDocsToTableData,
                  tableData: newTableData,
                });
              } else {
                const ids = newTableData.rows.map((dRow) => dRow.rowID);

                // filter docs
                const filteredDocs = docs.filter((doc) =>
                  ids.includes(doc.documentID)
                );

                if (filteredDocs && filteredDocs.length) {
                  // map docs to table data
                  const localTableData = mapDocsToTableData(filteredDocs);
                  const localRows = localTableData.rows;

                  // update new table data
                  newTableData.rows = _.mergeWith(
                    newTableData.rows,
                    newTableData.rows.map((ntdr) =>
                      _.some(localRows, (lr) => lr.rowID === ntdr.rowID)
                        ? _.find(localRows, (lr) => lr.rowID === ntdr.rowID)
                        : undefined
                    ),
                    (objValue, srcValue) => _.merge(objValue, srcValue)
                  );
                }
              }
            }

            // set new data
            setLoading(false);
            setData({ ...newTableData, error: undefined });
          });
        } else {
          setLoading(false);
          setData({ ...tableData, error: undefined });
        }

        // update store
        if (setModuleStore) {
          setModuleStore((currentModuleStore) => ({
            ...(currentModuleStore || {}),
            tableDatas: {
              ...(currentModuleStore.tableDatas || {}),
              [tableKey]: tableData,
            },
          }));
        }
      } else {
        setLoading(false);
        setData((prevData) => ({
          ...prevData,
          error: { message: errorMessageRef.current, timestamp: new Date() },
        }));
      }

      // trigger new request after finish current
      if (needRefetch.current) {
        needRefetch.current = false;
        reFetch(true, true);
      }
    }
  }, [
    fetchedData,
    localScript,
    pendingData,
    reFetch,
    setModuleStore,
    settings.localMode,
    tableKey,
  ]);

  // trigger query
  const queryWithCriteria = useCallback(() => {
    if (mountedRef.current) {
      setActiveItem(undefined);
      setQuery(tableDataApi.buildQuery(tableKey, table.type, criteria.current));
    }
  }, [tableKey, table, criteria, setActiveItem]);

  useEffect(() => {
    if (pageStore?.filterData) {
      // set filters on table/mask to readonly
      const filterKeys = _.sortBy(_.keys(pageStore.filterData));
      if (!_.isEqual(filterKeys, readOnlyFilterKeys)) {
        setReadOnlyFilterKeys(filterKeys);
      }

      // update criteria if is not timeline or agenda
      if (![CONTENT_TYPE.timeline, CONTENT_TYPE.agenda].includes(mask)) {
        _.forOwn(pageStore.filterData, (value, key) => {
          setCriterion(criteria.current, { key, value });
        });

        // query
        queryWithCriteria();
      }
    }
  }, [criteria, mask, pageStore, queryWithCriteria, readOnlyFilterKeys]);

  useEffect(() => {
    const downloadProcesses = _.filter(processes, ({ process: p }) => {
      const unstructuredData: any = p.additionalData;

      return (
        p.type === BUTTON_TYPE.download &&
        unstructuredData &&
        unstructuredData.downloadTemplates &&
        unstructuredData.downloadTemplates.length > 0 &&
        unstructuredData.downloadFileTypes &&
        unstructuredData.downloadFileTypes.length > 0
      );
    });

    const simpleProcessDownloads = _.filter(
      downloadProcesses,
      ({ process: p }) => p.executions.length === 0
    );

    const batchProcessDownloads = _.filter(
      downloadProcesses,
      ({ process: p }) => p.executions.length > 0
    );

    // mounted then get menu items
    maskMenuItemsRef.current = getMenuItems(
      t,
      !!documentID,
      tableKey,
      newTabLink,
      odsTemplates,
      simpleProcessDownloads,
      batchProcessDownloads,
      handleExportData
    );
  }, []);

  const handleRowClick = (item: string) => {
    setActiveItem(item);
    // todo #597 for multiple rows selection
    // setPageContentStore({ ...pageContentStore, selections: [item] });
  };

  const handleOpen = (
    key /* : string */,
    inNewTab /* : boolean */,
    link /* : string */
  ) => {
    const urlNav =
      link ??
      (area || module ? utils.buildUrlNav(area, module, key) : undefined);

    if (urlNav) {
      if (inNewTab) {
        utils.openInNewTab(urlNav);
      } else {
        history.push(urlNav);
      }
    }
  };

  const handleProcessActionClick = (batchID: string, rowKey: string) => {
    const row = new Row(_.find(data.rows, { key: rowKey }));
    const batchProcess = _.find(processes, { batchTemplateId: batchID });
    const {
      process: { executions },
    } = batchProcess;

    // sort step
    const sortedSteps = utils.sortThenMapProcessSteps(executions);

    // todo #597 if multiple selected rows then get from selections in store and not update it here
    // update current selection with selected item
    if (pageContentStore) {
      setPageContentStore({ ...pageContentStore, selections: [rowKey] });

      maskHandleProcess(batchID, row, sortedSteps);
    }
  };
  const handleExportData = async (
    fileType: string,
    templateName: string,
    batchID: string,
    downloadFileName: string[]
  ) => {
    setDisableExportBtn(true);

    const res = await exportApi.exportTo(
      fileType,
      tableKey,
      templateName,
      criteria.current,
      ids,
      documentID,
      batchID,
      downloadFileName
    );

    if (res?.data?.fileUrl) {
      // open to save file
      utils.openInNewTab(res.data.fileUrl);
      setDisableExportBtn(false);
    }
  };

  // update sorting
  const handleSort = (clickedColumn) => {
    // set sort direction
    const switchToDescending =
      activeColumn === clickedColumn && direction === 'ascending';
    const newDirection = switchToDescending ? 'descending' : 'ascending';
    setDirection(newDirection);

    // set sorted column
    criteria.current.orderByField = switchToDescending
      ? undefined
      : clickedColumn;
    criteria.current.orderByFieldDesc = switchToDescending
      ? clickedColumn
      : undefined;

    // update sort cache
    if (allowCache) {
      cacheTableSort(tableKey, clickedColumn, newDirection);
    }

    // set active column
    if (activeColumn !== clickedColumn) {
      setActiveColumn(clickedColumn);
    }

    // query
    queryWithCriteria();
  };

  // page change
  const handlePageChange = (activePage) => {
    // set page
    criteria.current.indexPage = activePage;

    // query
    queryWithCriteria();
  };

  // global search change
  const handleSearchAllChange = (changedValue) => {
    const value = changedValue === '' ? undefined : changedValue;

    // reset page
    criteria.current.indexPage = 1;

    // update cache
    if (allowCache) {
      cacheTableSearch(tableKey, value);
    }

    // query
    criteria.current.searchAllByValue = value;
    queryWithCriteria();
  };

  // column filter change
  const handleSearchFieldChange = (column, preventSearch) => {
    // reset page
    criteria.current.indexPage = 1;

    // update cache
    if (allowCache) {
      cacheTableFilter(tableKey, column);
    }

    // query
    if (!preventSearch) {
      setCriterion(criteria.current, column);
      queryWithCriteria();
    }
  };

  // mask filter change
  const handleUpdateCriteriaAndQuery = useCallback(
    (criteriaObjects) => {
      // update criteria
      _.forEach(criteriaObjects, (criterion) => {
        setCriterion(criteria.current, criterion);
      });

      // query
      queryWithCriteria();
    },
    [criteria, queryWithCriteria]
  );

  const tableProp = useMemo(
    () => filterTableColumns(table, mask, maskData),
    [table, mask, maskData]
  );

  // const iconByType = {
  //   [agenda]: 'calendar',
  //   [cards]: 'align justify',
  //   [map]: 'map',
  //   [timeline]: 'clock',
  // };

  return (
    <FwMask
      {...props}
      type={mask}
      table={tableProp}
      data={data}
      activeItem={activeItem}
      activeColumn={activeColumn}
      direction={direction}
      height={height}
      loading={loading}
      processes={processes}
      style={style}
      handleSearchAllChange={handleSearchAllChange}
      handleSort={handleSort}
      handleSearchFieldChange={handleSearchFieldChange}
      handlePageChange={handlePageChange}
      handleRowClick={handleRowClick}
      disableExportBtn={disableExportBtn}
      handleProcessActionClick={handleProcessActionClick}
      handleOpen={handleOpen}
      updateCriteriaFunc={handleUpdateCriteriaAndQuery}
      menuItems={maskMenuItemsRef.current}
      readOnlyFilterKeys={readOnlyFilterKeys}
      reFetch={reFetch}
    />
  );
};

export default React.memo(TableContainer);
