import { chakra, Grid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FwCard, FwContextMenu, FwInput, FwSpinner } from 'components/base';
import {
  Card,
  RefElementProps,
  getContextMenuItems,
  useGetDrag,
  useGetDrop,
  useGetDropContent,
} from 'components/base/containers/mask/FwMask.helpers';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE } from 'core/utils/constant';
import { VIEWCONTENT_TYPES } from 'core/utils/constants';
import { readSettings } from 'core/utils/storage';

import { filterCards, formToCard, updateCards } from './FwMask.Cards.helpers';

const FwCards: FC<FwMaskCommonProps> = ({
  dragAndDrop,
  maskStructure,
  maskRows,
  loading,
  processes,
  // functions
  handleOpen,
  handleProcessActionClick,
}) => {
  const { t } = useTranslation();
  const { view, dnd } = maskStructure;
  const { onListen, drag, drop, hover, contentID } = dragAndDrop || {};
  const { active = false, script } = dnd || {};

  const cardsDropRef = useRef<HTMLDivElement>(null);

  const settings = useMemo(() => {
    return readSettings();
  }, []);

  const [cards, setCards] = useState<Card[]>(null);
  const [searchText, setSearchText] = useState('');
  const [dndLoading, setDndLoading] = useState(false);

  useEffect(() => {
    if (onListen) {
      // the onListen function retrieves the pageContentID and setCards
      // it uses setCards to update viewContent after a drop event
      onListen(contentID, setCards);
    }
  }, [contentID, onListen]);

  // updates cards according to maskStructure, maskRows and searchText
  useEffect(() => {
    if (maskStructure && maskRows) {
      setCards(
        filterCards(
          _.map(maskRows, (mr) => formToCard(maskStructure, mr, t)),
          searchText
        )
      );
    }
  }, [maskStructure, maskRows, searchText, t]);

  // function that updates cards when an item is dragged and hovered over another card
  const onHover = useCallback(
    (hoverIndex: number, newContentID: string, card: Card) => {
      setCards((prevCards) => {
        const newCards = updateCards(prevCards, hoverIndex, card);
        hover(hoverIndex, newContentID, script, setDndLoading, newCards);

        return newCards;
      });
    },
    [hover, script]
  );

  // custom hook to handle drag and drop in card
  const useDnd = (card: Card, index: number, ref: RefElementProps) => {
    const { isDragging, drag } = useGetDrag(
      active,
      VIEWCONTENT_TYPES.cards,
      card,
      index,
      contentID
    );
    const drop = useGetDrop(
      active,
      VIEWCONTENT_TYPES.cards,
      ref,
      index,
      contentID,
      onHover
    );

    drag(drop(ref));

    return isDragging;
  };

  // custom hook to handle drop content (in cards)
  const useContainerDrop = dnd ? useGetDropContent : undefined;
  const dropContent = useContainerDrop?.(
    active,
    VIEWCONTENT_TYPES.cards,
    cardsDropRef,
    contentID,
    onHover
  );

  if (dnd) {
    dropContent(cardsDropRef);
  }

  return (
    <div ref={cardsDropRef}>
      {dndLoading && (
        <chakra.div pos="absolute" top={0} left="50%" right="50%" height="40px">
          <FwSpinner />
        </chakra.div>
      )}
      {!settings.localMode && !view?.hideSearch && (
        <Grid alignItems="center" templateColumns="repeat(3, 1fr)">
          <GridItem>
            <FwInput
              search
              placeholder={t('common|Search...')}
              rightIcon={'RiSearchLine'}
              value={searchText}
              onChange={(
                _e: ChangeEvent,
                { value }: { name: string; value: string; fillData?: object }
              ) => setSearchText(value)}
              type={FIELD_TYPE.text}
              mb={2}
            />
          </GridItem>
        </Grid>
      )}
      <Grid>
        {!loading && cards ? (
          _.map(cards, (card, index) => {
            const { key, title, color, opacity } = card;
            const id = key || String(index);
            const openFunc = () => handleOpen(id);

            // define context menu items
            const contextItems = getContextMenuItems(
              t,
              openFunc,
              () => handleOpen(id, true),
              processes,
              (processId: string) => handleProcessActionClick(processId, id)
            );

            // define the values to be used for the dnd
            const dndSettings = dnd
              ? {
                  index,
                  card,
                  drag: () => drag(card, cards, contentID, index),
                  drop,
                  useDnd,
                }
              : undefined;

            return (
              <GridItem key={id} my={2}>
                <FwContextMenu
                  renderTag="div"
                  childrenRenderTag="div"
                  items={contextItems}
                >
                  <FwCard
                    key={key}
                    borderColor={color}
                    content={_.map(title.split('\n'), (s) => [s])}
                    dndSettings={dndSettings}
                    onClick={openFunc}
                    opacity={opacity}
                    searchText={searchText}
                  />
                </FwContextMenu>
              </GridItem>
            );
          })
        ) : (
          <FwSpinner />
        )}
      </Grid>
    </div>
  );
};

FwCards.propTypes = {};

export default FwCards;
