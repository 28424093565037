import { dtoCtor, Dto } from '../helper';

class Option implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Option, 'dtoName'>) {
    dtoCtor(this, 'Option', props);
  }

  key?: string;
  value?: string;
  text?: string;
  visible?: boolean;
  items?: Option[];
}

export { Option };
