import {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react';

export const getElement = <T>(child: ReactNode, props: T) => {
  const typedChild = child as ReactElement<PropsWithChildren<T>>;
  return cloneElement(typedChild, props);
};
