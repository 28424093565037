import { AppPreference, FwSettingsProviderProps } from 'core/model';

type AppPreferenceData = {
  countryCode: string;
  noCache: boolean;
};

const mapAppPreferenceToSettings = (
  appPreference: Partial<AppPreference>
): FwSettingsProviderProps => {
  const { language, theme, color, smallMenu, defaultModule, data } =
    appPreference || {};
  const apd = data as AppPreferenceData;

  return appPreference
    ? {
        accent: color,
        countryCode: apd?.countryCode,
        defaultModule,
        language,
        noCache: apd?.noCache,
        smallMenu,
        theme,
      }
    : {};
};

export { mapAppPreferenceToSettings };
