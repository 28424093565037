import { Box, chakra } from '@chakra-ui/react';
import _ from 'lodash';
import { arrayOf, func, InferProps, object, string } from 'prop-types';
import React, { FC } from 'react';

import { FwArticle, FwParagraph } from 'components/base';

import { useFwItemStyle } from '../item/FwItem.helpers';

const FwSuggestion: FC<Props> = ({ content, title, onClick }) => {
  const style = useFwItemStyle(false);
  const articleContent = [];

  const itemStyle = onClick ? style : {};

  _.map(content, (c) =>
    _.forEach(c, (value, key) => {
      articleContent.push([
        <chakra.span key={key} opacity={0.5}>{`${key} -`}</chakra.span>,
        value,
      ]);
    })
  );

  return (
    <Box {...itemStyle} onClick={onClick}>
      <FwParagraph small heading>
        {title}
      </FwParagraph>
      <FwArticle small content={articleContent} />
    </Box>
  );
};

const propTypes = {
  content: arrayOf(object),
  title: string,
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

FwSuggestion.propTypes = propTypes;

export default FwSuggestion;
