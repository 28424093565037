import { bool, InferProps, string } from 'prop-types';

import { ctor } from '../helper';

const fwCheckboxPT = {
  allowIndeterminate: bool,
  label: string,
  required: bool,
};

type FwCheckboxPT = InferProps<typeof fwCheckboxPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwCheckboxProps extends FwCheckboxPT {}
class FwCheckboxProps implements FwCheckboxPT {
  constructor(props?: FwCheckboxPT) {
    ctor(this, props);
  }
}

export { fwCheckboxPT, FwCheckboxProps };
