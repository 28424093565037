import { dtoCtor, Dto } from '../helper';
import { Design } from './design';
import { PageContent } from './pageContent';

class Page implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Page, 'dtoName'>) {
    dtoCtor(this, 'Page', props);
  }

  pageID?: string;
  fasterClientID?: string;
  key?: string;
  name?: string;
  moduleID?: string;
  module?: string;
  templateID?: string;
  template?: string;
  position: number;
  areaOnly?: boolean;
  design?: Design;
  pageContent?: PageContent[];
  areas?: string[];
}

export { Page };
