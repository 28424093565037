import {
  IDXDDB_OPERATIONS,
  IDXDDB_STORES,
  IdxdDBEntityType,
  RequestCallback,
  getEntityKey,
  performOnIdxddb,
  stores,
} from './idxdDB.helpers';

// open and create indexedDB
let db: IDBDatabase;
const dbName = 'FWLocalDB';
const dbVersion = 1;
const request = indexedDB.open(dbName, dbVersion);

// setup indexedDB schema when it opens
request.onupgradeneeded = (event) => {
  const upgradeDb = (event.target as IDBOpenDBRequest).result;

  // create indexedDB tables (stores)
  for (let i = 0; i < stores.length; i++) {
    const store = stores[i];
    upgradeDb.createObjectStore(store.name, store.options);
  }
};

// indexedDB was successfully opened
request.onsuccess = (event) => {
  db = (event.target as IDBOpenDBRequest).result;
};

// indexedDB could not be opened
request.onerror = (event) => {
  console.error('IndexedDB error:', (event.target as IDBRequest).error);
};

// fetch one entry from indexedDB store by its key (id)
const getFromIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  key: string,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.get>
) => performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.get, key, callback);

// fetch all entries from one indexedDB store
const getAllFromIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.getAll>
) => performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.getAll, null, callback);

// add an entry to a given indexdDB store
const addToIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  data: IdxdDBEntityType<T>,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.add>
) => performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.add, data, callback);

// add or replace an entry in a given indexdDB store
const addOrUpdateToIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  data: IdxdDBEntityType<T>,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.get>
) =>
  performOnIdxddb(
    db,
    storeName,
    IDXDDB_OPERATIONS.get,
    getEntityKey(storeName, data),
    callback,
    // check if data with the same 'id' already exists
    (result) => {
      if (result) {
        // data with the same 'id' exists, update it
        performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.put, data, callback);
      } else {
        // data with the same 'id' doesn't exist, add it
        performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.add, data, callback);
      }
    }
  );

// remove an entry from one indexdDB store
const deleteFromIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  key: string,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.delete>
) => performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.delete, key, callback);

// remove all entries from one indexdDB store
const deleteAllFromIdxdDB = <T extends `${IDXDDB_STORES}`>(
  storeName: T,
  callback?: RequestCallback<T, IDXDDB_OPERATIONS.clear>
) => performOnIdxddb(db, storeName, IDXDDB_OPERATIONS.clear, null, callback);

export {
  // enums
  IDXDDB_OPERATIONS,
  IDXDDB_STORES,
  // functions
  addToIdxdDB,
  addOrUpdateToIdxdDB,
  deleteAllFromIdxdDB,
  deleteFromIdxdDB,
  getAllFromIdxdDB,
  getFromIdxdDB,
};
