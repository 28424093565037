import { dtoCtor, Dto } from '../helper';
import { Design } from './design';

class Module implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Module, 'dtoName'>) {
    dtoCtor(this, 'Module', props);
  }

  fasterClientID?: string;
  moduleID?: string;
  key?: string;
  name?: string;
  position: number;
  areaOnly?: boolean;
  design?: Design;
  decorationID?: number;
  areas?: string[];
}

export { Module };
