import BaseApi from 'api/baseApi';
import { Filter } from 'core/model';
import { SYSTEM_FIELDS, OPERATOR, FIELD_TYPE } from 'core/utils/constant';
import {
  getQueryString,
  getFileNameBuildKeysQuery,
  QueryCriteria,
} from 'core/utils/tableData/getQueryString';

const exportApi = {
  exportTo: (
    fileType: string,
    tableKey: string,
    templateName: string,
    criteria: QueryCriteria,
    ids: string[],
    documentID: string,
    batchID: string,
    downloadFileName: string[]
  ) => {
    const templateQuery = templateName ? `/${templateName}` : '';
    const batchQuery = batchID ? `/batch/${batchID}` : '';
    let query = `export${batchQuery}/${fileType}/${tableKey}${templateQuery}${getQueryString(
      criteria
    )}${getFileNameBuildKeysQuery(downloadFileName)}`;

    if (ids && ids.length > 0) {
      const filter = new Filter({
        key: SYSTEM_FIELDS.formid,
        operator: OPERATOR.in,
        value: ids.join('|'),
        type: FIELD_TYPE.number,
      });

      // post id filter
      return BaseApi.post<{ filter: Filter }, { fileUrl: string }>(query, {
        filter,
      });
    } else {
      if (documentID) {
        query = `${query}&documentID=${documentID}`;
      }

      // export data
      return BaseApi.get<{ fileUrl: string }>(query);
    }
  },
};

export default exportApi;
