import { dtoCtor, Dto } from '../helper';

class PageContentManagement implements Dto {
  dtoName?: string;

  constructor(props?: Omit<PageContentManagement, 'dtoName'>) {
    dtoCtor(this, 'PageContentManagement', props);
  }

  pageID?: string;
  pageContentID?: string;
  fasterClientID?: string;
  key?: string;
  name?: string;
  type?: string;
  additionalData?: object;
  tableKey?: string;
  areaOnly?: boolean;
  visible?: boolean;
}

export { PageContentManagement };
