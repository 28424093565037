import { sortByStepRowColumnKey } from 'core/utils/sort';
import utils from 'core/utils/utils';

const getClickables = (doc: any) => {
  return sortByStepRowColumnKey(
    utils.getTemplateClickablesByStatus(doc.template, doc.status)
  );
};

export default getClickables;
