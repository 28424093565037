import { chakra } from '@chakra-ui/react';
import _ from 'lodash';
import { arrayOf, bool, InferProps, node, oneOfType } from 'prop-types';
import React, { FC } from 'react';

import { toArray } from 'core/utils/logic';

import FwParagraph from '../../elements/paragraph/FwParagraph';

const FwArticle: FC<Props> = ({
  align,
  content,
  fluid,
  heading,
  small,
  truncated,
}) => {
  const contentArray = toArray(content);

  const paragraphsProps = {
    align,
    heading,
    small,
    truncated,
  };

  return (
    <chakra.article w={fluid ? '100%' : undefined}>
      {_.map(contentArray, (item, arrayIdx) => {
        const subArray = toArray(item);

        return (
          <FwParagraph key={arrayIdx} fluid={fluid} {...paragraphsProps}>
            {subArray}
          </FwParagraph>
        );
      })}
    </chakra.article>
  );
};

const propTypes = {
  content: oneOfType([node, arrayOf(node), arrayOf(arrayOf(node))]),
  fluid: bool,
  heading: bool,
  small: bool,
  truncated: bool,
};

export interface Props extends InferProps<typeof propTypes> {
  align?: 'left' | 'center' | 'right' | 'justify';
}

FwArticle.propTypes = propTypes;

export default FwArticle;
