import {
  bool,
  elementType,
  func,
  InferProps,
  node,
  oneOf,
  string,
} from 'prop-types';

import { ctor } from '../helper';
import { stylePT } from './common';

const fwButtonPT = {
  ...stylePT,
  as: elementType,
  basic: bool,
  children: node,
  disabled: bool,
  fluid: bool,
  loading: bool,
  onClick: func,
  primary: bool,
  responsive: bool,
  rounded: bool,
  small: bool,
  tooltip: string,
  type: oneOf<'submit' | 'reset' | 'button'>(['submit', 'reset', 'button']),
};

type FwButtonPT = InferProps<typeof fwButtonPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwButtonProps extends FwButtonPT {}
class FwButtonProps implements FwButtonPT {
  constructor(props?: FwButtonPT) {
    ctor(this, props);
  }
}

export { fwButtonPT, FwButtonProps };
