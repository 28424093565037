import { useCallback, useEffect, useRef } from 'react';

import { CloseHandler, Hub, HubCallback } from './baseHub';

export function useHub(
  hub: Hub,
  params: { channel: string },
  handlers: Record<string, HubCallback>
) {
  const pending = useRef(true);
  const connector = useRef(undefined);

  const handlersRef = useRef(handlers);

  const closeHubConnection = useCallback(() => {
    if (connector.current) {
      connector.current.stopConnection();
    }
  }, []);

  useEffect(() => {
    let cancelled = false;

    const onCloseHandler: CloseHandler = async (message) => {
      // connection closes but component not unmounting
      if (cancelled === false) {
        // monitor error
        if (message) {
          console.warn(message);
        }

        // manually reconnect
        await connector.current.startConnection();
      }
    };

    const connectToHub = async (hubClass: Hub) => {
      // get hub
      const { channel } = params || {};
      const h = new hubClass(onCloseHandler, channel);

      // define handler callbacks
      h.addHandlers(handlersRef.current);

      // close previous connection if exists
      closeHubConnection();

      // register handlers
      h.subscribe();

      // start hub connection
      await h.startConnection();

      if (!cancelled) {
        connector.current = h;
      }
    };

    if (hub) {
      connectToHub(hub).then(() => {
        pending.current = false;
      });
    }

    return () => {
      cancelled = true;

      if (!pending.current) {
        closeHubConnection();
      }
    };
  }, [params, hub, closeHubConnection]);

  return [closeHubConnection];
}
