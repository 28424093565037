import { chakra, Divider, Stack } from '@chakra-ui/react';
import _ from 'lodash';
import React, { Fragment, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { FwCategory, FwItem, FwParagraph } from 'components/base/elements';
import {
  FwCategoryProps,
  FwItemProps,
  FwListProps,
  fwListPT,
} from 'core/model';

const FwList = ({
  as,
  children,
  fluid,
  items,
  small,
  testId,
  onChange,
}: FwListProps) => {
  const { t } = useTranslation();

  const categorizedItems = _.filter(
    items,
    (item) => item instanceof FwCategoryProps
  ) as FwCategoryProps[];
  const noCategoryItems = _.filter(
    items,
    (item) => item instanceof FwItemProps
  ) as FwItemProps[];

  const handleItemClick = (onClick: MouseEventHandler) =>
    onClick || onChange
      ? (e: React.MouseEvent<Element, MouseEvent>) => {
          onClick?.(e);
          onChange?.();
        }
      : undefined;

  const stackStyle = {
    spacing: 2,
    w: fluid ? '100%' : undefined,
  };

  return (
    <Stack {...stackStyle} as={as} data-testid={testId}>
      {!_.isEmpty(categorizedItems) || !_.isEmpty(noCategoryItems) ? (
        <>
          {!_.isEmpty(noCategoryItems) && (
            <div data-testid={testId ? `${testId}-items` : undefined}>
              {_.map(
                noCategoryItems as FwItemProps[] /* todo remove type */,
                ({ itemKey, onClick, ...itemProps }, index) => (
                  <FwItem
                    key={itemKey || index}
                    {...itemProps}
                    small={small}
                    onClick={handleItemClick(onClick)}
                  />
                )
              )}
            </div>
          )}
          {_.map(
            categorizedItems,
            ({ categoryKey, ...categoryProps }, index) => (
              <Fragment key={categoryKey || index}>
                {((index === 0 && !_.isEmpty(noCategoryItems)) ||
                  index > 0) && <Divider />}
                <FwCategory
                  {...categoryProps}
                  small={small}
                  onChange={onChange}
                />
              </Fragment>
            )
          )}
        </>
      ) : children ? null : (
        <FwParagraph align="center" small={small}>
          <chakra.i opacity={0.3} cursor="default">
            {t('common|No items found')}
          </chakra.i>
        </FwParagraph>
      )}
      {children && (
        <div>
          {/* wrap in div to suppress Stack spacing between children */}
          {children}
        </div>
      )}
    </Stack>
  );
};

FwList.propTypes = fwListPT;

export default FwList;
