import _ from 'lodash';

import { FIELD_TYPE } from 'core/utils/constant';
import { isSameDay, jsDateFromString, jsDateToString } from 'core/utils/date';

import {
  getDateFormat,
  getIsoDateFormat,
} from '../datetime/FwInput.Datetime.helpers';
import { addOrRemove, arrayToDisplayValue } from './defaultStringStateBuilders';
import {
  dtoSeparator,
  FwControllableStateBuilder,
} from './useFwControllableState.helper';

const { datetime } = FIELD_TYPE;

interface shouldClosePopArgs {
  multiple?: boolean;
  range?: boolean;
  type?: string;
  dtoValue: string;
  selection: Date | Date[];
}

const isIncompleteRange = (range: boolean, selection: Date | Date[]) => {
  return (
    range &&
    (!_.isArray(selection) || (_.isArray(selection) && _.isNil(selection[1])))
  );
};

const shouldClosePop = (args: shouldClosePopArgs) => {
  const { multiple, range, type, selection, dtoValue } = args;

  // check if date range selection is complete
  const incompleteRange = isIncompleteRange(range, selection);

  // leave pop open for incomplete range selections...
  //... multiple selections and for time selection in datetime
  const keepPopOpen =
    (range && incompleteRange) ||
    multiple ||
    (!multiple &&
      _.isDate(selection) &&
      type === datetime &&
      !isSameDay(dateFromDtoString(dtoValue, type), selection));

  return !keepPopOpen;
};

// const dateFromDisplayString = (dateString: string, type: string) => {
//   return jsDateFromString(dateString, getDateFormat(type));
// };

const dateFromDtoString = (dateString: string, type: string) => {
  return jsDateFromString(dateString, getIsoDateFormat(type));
};

const dateToDisplayString = (date: Date, type: string) => {
  return jsDateToString(date, getDateFormat(type));
};

const dateToDtoString = (date: Date, type: string) => {
  return jsDateToString(date, getIsoDateFormat(type));
};

// todo consider unit tests
// convert content values (dates) to display values (e.g. '01/01/2022 08:00' or '(2) 10:30 | 11:30')
const contentToDisplay = (
  contentValue: Date | Date[],
  type: string
): string => {
  const valArray: Date[] = _.isArray(contentValue)
    ? contentValue
    : [contentValue];
  const displayArray = _.map(valArray, (val) => dateToDisplayString(val, type));
  const displayValue =
    _.compact(displayArray).length > 1
      ? arrayToDisplayValue(_.compact(displayArray))
      : displayArray[0] || '';

  return displayValue;
};

// convert content values (dates) to dto values (e.g. '2022-01-01T08:00' or '10:30|11:30')
const contentToDto = (contentValue: Date | Date[], type: string) => {
  const valArray: Date[] = _.isArray(contentValue)
    ? contentValue
    : [contentValue];
  const dtoArray = _.map(valArray, (val) => dateToDtoString(val, type));

  return _.join(dtoArray, dtoSeparator) || '';
};

// convert dto values (e.g. '2022-01-01T08:00' or '10:30|11:30') to content values (dates)
const dtoToContent = (dtoValue: string, type: string) => {
  const dtoValueList = (dtoValue || '').split(dtoSeparator);
  const dateArray = _.map(dtoValueList, (val) => dateFromDtoString(val, type));
  const contentValue: Date | Date[] =
    _.compact(dateArray).length > 1 ? _.compact(dateArray) : dateArray[0];

  return contentValue;
};

// convert dto values (e.g. '2022-01-01T08:00' or '10:30|11:30') to display values (e.g. '01/01/2022 08:00' or '(2) 10:30 | 11:30')
const dtoToDisplay = (dtoValue: string, type: string) => {
  return contentToDisplay(dtoToContent(dtoValue, type), type);
};

const forceContent: FwControllableStateBuilder<
  Date | Date[],
  Date | Date[]
> = ({ value, oldState, params }) => {
  const { multiple, range, type } = params;
  const incompleteRange = isIncompleteRange(range, value);

  const newValue =
    (multiple || range) && value === undefined
      ? /* workaround to force clear action on FwInput.Datetime to have shouldNotify=true, see below */
        []
      : _.isArray(value)
      ? _.compact(value)
      : value;

  return {
    contentValue: newValue ? newValue : multiple || range ? [] : undefined,
    displayValue: contentToDisplay(newValue, type),
    dtoValue: incompleteRange ? '' : contentToDto(newValue, type),
    shouldNotify:
      value === undefined ||
      shouldClosePop({
        ...params,
        selection: newValue,
        dtoValue: oldState.dtoValue,
      }),
  };
};

const buildStateFromContent = ({ value, oldState, params }) => {
  const { multiple, type } = params;

  let newVal: Date | Date[];
  const prevVal = oldState.dtoValue;

  if (multiple) {
    const sentDtoValue = contentToDto(value, type);
    const sentDtoArray = _.split(sentDtoValue, dtoSeparator);
    const newDtoArray = _.reduce(
      sentDtoArray /* for each new dto item */,
      (currentArray, sentDtoItem) => addOrRemove(currentArray, sentDtoItem),
      _.split(prevVal, dtoSeparator) /* start with previous dto array */
    );

    newVal = dtoToContent(_.join(_.compact(newDtoArray), dtoSeparator), type);
  } else {
    newVal = value;
  }

  return forceContent({
    value: newVal,
    oldState,
    params,
  });
};

const buildStateFromDisplay = ({ oldState }) => {
  console.error('Not implemented: buildStateFromDisplay');
  return oldState;
};

const defaultBuildStateFromDto: FwControllableStateBuilder<
  Date | Date[],
  string
> = ({ value, params }) => {
  const { multiple, range, type } = params;
  const contentVal = dtoToContent(value, type);
  const incompleteRange = isIncompleteRange(range, contentVal);

  return {
    contentValue: contentVal ? contentVal : multiple || range ? [] : undefined,
    displayValue: dtoToDisplay(value, type),
    dtoValue: incompleteRange ? '' : value,
  };
};

export {
  forceContent as buildStateFromForcedContent,
  buildStateFromContent as buildStateFromContent,
  buildStateFromDisplay as buildStateFromDisplay,
  defaultBuildStateFromDto as buildStateFromDto,
};
