// file structure is based on
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts

const sizes = {
  lg: {
    addon: {
      h: 11 /* override base value of 12 */,
    },
    field: {
      h: 11 /* override base value of 12 */,
    },
  },
  md: {
    addon: {
      h: 8 /* override base value of 10 */,
    },
    field: {
      h: 8 /* override base value of 10 */,
    },
  },
};

export default { sizes };
