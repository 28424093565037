import _ from 'lodash';

import { CONTENT_TYPE } from 'core/utils/constant';

import { filterAgendaTableColumns } from '../../agenda/helpers/filterAgendaTableColumns';

const filterTableColumns = (table: any, mask: any, maskData: any) => {
  // only display columns with position > 0 (others might only be backend table filters)
  const filteredTable = {
    ...table,
    columns: _.filter(table.columns, (col) => col.position > 0),
  };

  // apply mask required column changes
  if (mask === CONTENT_TYPE.agenda) {
    filteredTable.columns = filterAgendaTableColumns(filteredTable, maskData);
  }

  return filteredTable;
};

export { filterTableColumns };
