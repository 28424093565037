import _ from 'lodash';
import React from 'react';

import { FwModal } from 'components/base';
import utils from 'core/utils/utils';

const DocModal = (props: any) => {
  const { inputs, visibleElements, buttonEffect, onSubmit, onCancel } = props;

  const stepsInputs = visibleElements
    ? utils.getVisibleInputsFromSteps(visibleElements.steps, false)
    : [];

  const modalInputs = visibleElements
    ? utils.filterExistent(visibleElements.modalInputs)
    : [];

  const visibleInputs = _.filter(
    inputs,
    (i) =>
      i && _.some(_.union(stepsInputs, modalInputs), (f) => i.key === f.key)
  );

  return (
    <FwModal
      {...props}
      inputs={visibleInputs}
      onSubmit={() =>
        onSubmit(utils.filterVisible(visibleInputs), buttonEffect)
      }
      onCancel={() => onCancel(buttonEffect ? true : false)}
    />
  );
};

export default DocModal;
