import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import pageContentApi from 'api/page-content/pageContentApi';
import { EntityInfo, Table } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import {
  getAdminInputs,
  PageContentManagement as PC,
  pageContentManagement1Rules,
} from 'core/model';
import { CONTENT_TYPE } from 'core/utils/constant';

const PageContent: FC<Props> = ({
  pageContent,
  tableStruct,
  handleAddRow,
  handleDeleteRow,
  handleSetKey,
}) => {
  const { t } = useTranslation();

  const newPageContent = {
    ...pageContent,
    additionalData: JSON.stringify(pageContent.additionalData),
  };

  const castPC: PC = Object.assign(new PC(), pageContent);

  // get entityDoc for update name, key, areaOnly and additionalData
  const inputs0 = getAdminInputs(castPC, null, t, '0');
  const entityDoc0 = getEntityDoc(newPageContent, inputs0);

  // get entityDoc for update tableKey
  const inputs1 = getAdminInputs(castPC, null, t, '1');
  const entityDoc1 = getEntityDoc(
    newPageContent,
    inputs1,
    pageContentManagement1Rules
  );

  return (
    <>
      {newPageContent && (
        <EntityInfo
          autosave
          api={pageContentApi}
          entityDoc={entityDoc0}
          keyName={'pageContentID'}
          entityKey={newPageContent.pageContentID}
          type={'PageContent'}
          title={'PageContent'}
          onSetKey={handleSetKey}
        />
      )}
      {newPageContent.pageContentID && (
        <EntityInfo
          autosave
          api={pageContentApi}
          entityDoc={entityDoc1}
          keyName={'pageContentID'}
          entityKey={newPageContent.pageContentID}
          type={'PageContent'}
          title={'Table'}
        />
      )}
      {newPageContent.type == CONTENT_TYPE.section && (
        <Table
          allowAddRow
          allowDeleteRow
          allowDoubleClick
          table={tableStruct}
          module={newPageContent}
          dataType="Field_ViewContent"
          dataKey="FieldID"
          handleAddRow={handleAddRow}
          handleDeleteRow={handleDeleteRow}
        />
      )}
    </>
  );
};

const propTypes = {
  pageContent: any.isRequired,
  tableStruct: any,
  handleAddRow: func.isRequired,
  handleDeleteRow: func.isRequired,
  handleSetKey: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

PageContent.propTypes = propTypes;

export default withTranslation()(PageContent);
