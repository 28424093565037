import { Center, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, func, InferProps, instanceOf, string } from 'prop-types';
import React, { FC, useState } from 'react';

import { FwButton } from 'components/base';

import {
  getDateTimeString,
  getLocaleTime,
  getTime,
  isSelectedDate,
  matrixDimension,
} from './TimePicker.helpers';

const TimePicker: FC<Props> = ({
  locale,
  selected,
  selectsRange,
  isDateTime,
  setIsMatrixTime,
  onChange,
}) => {
  const [hours, setHours] = useState(null);
  const [isHours, setIsHours] = useState(true);

  const onClick = (selectedDate: Date) => {
    if (isHours) {
      setHours(selectedDate);
      setIsHours(false);
    } else {
      if (selectsRange) {
        setIsHours(true);
      }

      const newSelectedDate = selected ? selected : new Date();
      selectedDate.setDate(newSelectedDate.getDate());

      onChange(selectedDate);
    }
  };

  const updateTimeDisplay = () => {
    if (isHours) {
      setIsMatrixTime(false);
    } else {
      setIsHours(true);
    }
  };

  const timePickerStyle = {
    fontSize: '0.9rem',
    variant: 'unstyled',
  };

  const buttonStyle = (isSelectedTime: boolean) => ({
    background: isSelectedTime ? '#205e9f' : undefined /*blue600*/,
    fontWeight: isSelectedTime ? 'normal' : undefined,
    color: isSelectedTime ? 'whitesmoke' : undefined,
    margin: '0 1px 0 0',
    height: 'auto',
    padding: '7px 10px',
    cursor: 'pointer',
    _hover: { bg: '#96afc6' },
  });

  return (
    <>
      {(isDateTime || (!isDateTime && !isHours)) && (
        <Center>
          <FwButton small onClick={updateTimeDisplay}>
            {getDateTimeString(isDateTime, isHours, locale, hours, selected)}
          </FwButton>
        </Center>
      )}
      <Table {...timePickerStyle}>
        <Tbody>
          {_.times(matrixDimension(isHours).height, (height) => (
            <Tr key={height}>
              {_.times(matrixDimension(isHours).width, (width) => {
                const time = getTime(width, height, hours, isHours);

                return (
                  <Td
                    {...buttonStyle(isSelectedDate(time, selected, isHours))}
                    textAlign={'center'}
                    key={width}
                    onClick={() => onClick(time)}
                  >
                    <span>{getLocaleTime(locale, time, isHours)}</span>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

const propTypes = {
  locale: string,
  selected: instanceOf(Date),
  selectsRange: bool,
  isDateTime: bool,
  setIsMatrixTime: func,
  onChange: func,
};

export type Props = InferProps<typeof propTypes>;

TimePicker.propTypes = propTypes;

export default TimePicker;
