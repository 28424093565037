import {
  chakra,
  PositionProps,
  Tag,
  useColorModeValue,
} from '@chakra-ui/react';
import _ from 'lodash';
import { bool, any, func, string, number, InferProps } from 'prop-types';
import React, { FC, forwardRef, ForwardedRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FwContextMenu,
  FwParagraph,
  FwTooltip,
  useFwCssStyle,
  useFwTheme,
} from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { useMobileDoubleClick } from 'core/utils/useMobileDoubleClick';

const EventLabel: FC<Props> = forwardRef(
  (
    {
      backgroundColor: backgroundColorProp,
      compact,
      content,
      fluid,
      index,
      endTime,
      eventKey,
      left,
      overlapDegree,
      processes,
      slots,
      startTime,
      handleOpen,
      handleProcessActionClick,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const {
      accent,
      _dark: { color: dColor },
      _light: { color: lColor },
    } = useFwTheme();
    const lblColor = useColorModeValue(dColor, lColor);
    const { backgroundColor: bg } = useFwCssStyle({
      backgroundColor: backgroundColorProp,
    });

    const onTouchStart = useMobileDoubleClick(() => handleOpen(eventKey));

    // define context menu items
    const contextItems = getContextMenuItems(
      t,
      () => handleOpen(eventKey),
      () => handleOpen(eventKey, true),
      processes,
      (processId) => handleProcessActionClick(processId, eventKey)
    );

    // defined styles
    const isEmpty = !content;
    const height = isEmpty ? '16px' : '24px';
    const halfHeight = isEmpty ? '8px' : '12px';

    const containerStyles = fluid
      ? {}
      : {
          position: 'absolute' as PositionProps['position'],
          zIndex: 4,
          left: `calc(${left} * 100%)`,
          top: overlapDegree
            ? /* all labels, empty or not must have the same positionning logic in this expanded mode */
              `calc((${index} - (${overlapDegree} / 2)) * (24px + 4px) - 12px)`
            : `calc(50% - ${halfHeight})`,
          w: `calc(${slots || 0.17} * 100%)`,
        };

    const labelStyles = {
      cursor: 'pointer',
      minW: '5px',
      minH: height,
      m: 0,
      border: '2px solid white',
      w: '100%',
      pl: '3px',
      pr: '3px',
      bg: bg || accent,
      color: lblColor,
    };

    const compactStyle = compact
      ? {
          px: 0,
        }
      : undefined;

    // render
    return (
      <FwTooltip
        placement="bottom"
        text={
          <FwParagraph small>
            {`${startTime} - ${endTime}`}
            {_.map(content.split('\n'), (line, index) => (
              <Fragment key={index}>
                <br />
                {line}
              </Fragment>
            ))}
          </FwParagraph>
        }
      >
        <chakra.div ref={ref} {...containerStyles}>
          <FwContextMenu
            renderTag="div"
            childrenRenderTag="div"
            items={contextItems}
          >
            <Tag
              onTouchStart={onTouchStart}
              {...props}
              {...labelStyles}
              {...compactStyle}
            >
              <FwParagraph small truncated>
                {content}
              </FwParagraph>
            </Tag>
          </FwContextMenu>
        </chakra.div>
      </FwTooltip>
    );
  }
);

const propTypes = {
  backgroundColor: string,
  compact: bool,
  content: string,
  fluid: bool,
  index: number,
  endTime: string,
  eventKey: string,
  left: number,
  overlapDegree: number,
  processes: any,
  slots: number,
  startTime: string,
  handleOpen: func,
  handleProcessActionClick: func,
  onDoubleClick: func,
};

export type Props = InferProps<typeof propTypes>;

EventLabel.propTypes = propTypes;
EventLabel.displayName = 'EventLabel';

export default React.memo(EventLabel);
