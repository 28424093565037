import _ from 'lodash';
import { any, func, InferProps } from 'prop-types';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';

import moduleApi from 'api/module/moduleApi';
import { EntityInfo } from 'components';
import getEntityDoc from 'components/entity-info/helpers/getEntityDoc';
import {
  Design as D,
  getAdminInputs,
  Module as M,
  moduleRules,
} from 'core/model';

const Module: FC<Props> = ({ t, module, handleSetKey }) => {
  // set new design if it doesn't exist in module
  if (module && _.isNil(module['design'])) {
    module['design'] = new D();
  }

  const castM: M = Object.assign(new M(), module);
  const moduleInputs = getAdminInputs(castM, null, t);

  const castD: D = Object.assign(new D(), module.design);
  const designInputs = getAdminInputs(castD, null, t);

  const moduleEntityDoc = getEntityDoc(module, moduleInputs, moduleRules);
  const designEntityDoc = getEntityDoc(module, designInputs);

  return (
    <>
      {module && (
        <EntityInfo
          autosave
          api={moduleApi}
          entityDoc={moduleEntityDoc}
          keyName={'key'}
          entityKey={module.key}
          type={'module'}
          title={'Module'}
          onSetKey={handleSetKey}
        />
      )}
      {module.key && (
        <EntityInfo
          autosave
          api={moduleApi}
          entityDoc={designEntityDoc}
          keyName={'key'}
          entityKey={module.key}
          type={'module'}
          title={'Design'}
        />
      )}
    </>
  );
};

const propTypes = {
  t: func.isRequired,
  module: any,
  handleSetKey: func.isRequired,
};

type Props = InferProps<typeof propTypes>;

Module.propTypes = propTypes;

export default withTranslation()(Module);
