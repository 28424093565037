import _ from 'lodash';
import {
  arrayOf,
  bool,
  func,
  InferProps,
  instanceOf,
  string,
} from 'prop-types';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { FwList, FwPop } from 'components/base';
import { mapOptionToCategory, mapOptionToItem } from 'core/mapper';
import { FwItemProps, Option } from 'core/model';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import utils from 'core/utils/utils';

import { useStringFwPop } from '../../pop/useFwPop';
import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';
import {
  getOptions,
  getSearchedOptions,
  passActiveAndOnClickProps,
  slideUp,
} from './FwInput.Select.helpers';

const FwSelect: FC<Props & CommonInputProps> = (props) => {
  const {
    // direct props
    editable = false,
    multiple = false,
    options = [],
    pattern,
    searchable = false,
    // common input props
    readOnly,
    testId,
  } = props;

  const unsupportedCase =
    // these props combinations are not yet implemented
    multiple && (editable || searchable);

  const initialOptionsRef = useRef(getOptions(options));

  const [items, setItems] = useState<Option[]>(initialOptionsRef.current);
  const [activeItemKeys, setActiveItemKeys] = useState<string[]>([]);

  const {
    displayValue,
    contentValue,
    popProps,
    baseInputProps,
    setDisplayValue,
    setContentValue,
  } = useStringFwPop(
    { ...props, options },
    { rightIcon: '', onRightIconClick: null }
  );

  // set active items when state (contentValue/displayValue) changes
  useEffect(() => {
    // turn content value into an array when not multiple
    const newActiveItemKeys = _.isArray(contentValue)
      ? contentValue
      : [contentValue];

    setActiveItemKeys(newActiveItemKeys);
  }, [contentValue, displayValue]);

  // update items when controlled options or search query change
  useEffect(() => {
    if (!_.isEmpty(options)) {
      initialOptionsRef.current = getOptions(options);

      const timeout = setTimeout(() => {
        setItems(
          searchable && displayValue
            ? getSearchedOptions(displayValue, options)
            : initialOptionsRef.current
        );
      }, 70);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [options, searchable, displayValue]);

  const inputStyle = {
    cursor: readOnly
      ? 'default'
      : open && (searchable || editable)
      ? 'text'
      : 'pointer',
  };

  const handleClickItem = (e: React.MouseEvent, data: FwItemProps) => {
    setContentValue(data.value);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    data: { name: string; value: string }
  ) => {
    if (searchable || editable) {
      let { value: targetValue } = utils.getNameValueFromEData(e, data);

      if (!_.isNil(pattern)) {
        targetValue = getValueMatch(
          pattern,
          displayValue,
          targetValue,
          (targetValue as string).length
        );
      }

      // set display value
      setDisplayValue(targetValue);
    }
  };

  // todo do not compute at every render?
  // todo this logic should be handled elsewhere? (e.g. FwComponents should only know about view models?)
  // # start of logic block
  const dropdownItems = _.compact(
    _.map(items, (dtoOption: Option) => {
      return dtoOption.items && dtoOption.items.length > 0
        ? mapOptionToCategory(
            dtoOption,
            testId ? { testId: dtoOption.key } : undefined
          )
        : mapOptionToItem(dtoOption);
    })
  );

  passActiveAndOnClickProps(dropdownItems, activeItemKeys, handleClickItem);
  // # end of logic block

  return unsupportedCase ? null : (
    <FwPop {...popProps} testId={testId}>
      <FwPop.Anchor>
        <Base
          {...baseInputProps}
          {...inputStyle}
          testId={testId ? `${testId}-input` : undefined}
          onChange={handleInputChange}
        />
      </FwPop.Anchor>
      <FwPop.Content variants={slideUp}>
        <FwList
          testId={testId ? `${testId}-list` : undefined}
          small
          items={dropdownItems}
        />
      </FwPop.Content>
    </FwPop>
  );
};

const propTypes = {
  disabled: bool,
  editable: bool,
  multiple: bool,
  options: arrayOf(instanceOf(Option)),
  pattern: string,
  placeholder: string,
  searchable: bool,
  onBlur: func,
};

export type Props = InferProps<typeof propTypes>;

FwSelect.propTypes = propTypes;

export default FwSelect;
