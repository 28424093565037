import { dtoCtor, Dto } from '../helper';

class Viewable implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Viewable, 'dtoName'>) {
    dtoCtor(this, 'Viewable', props);
  }

  lastEdit: string;
  lastView?: string;
  viewed?: boolean;
}

export { Viewable };
