import BaseApi, { BaseApiParams } from 'api/baseApi';

const aboutApi = {
  getGeneratedKey: (params: BaseApiParams) =>
    BaseApi.get<{ key: string }>('about/generatedKey', params),
  getInfo: (params: BaseApiParams) =>
    BaseApi.get<{ info: string }>('about/info', params),
};

export default aboutApi;
