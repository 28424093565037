import { any, InferProps, string } from 'prop-types';
import React, { FC, useEffect, useState } from 'react';

import operationApi from 'api/operation/operationApi';
import { useApi } from 'api/useApi';
import { FwHistory, FwSegment } from 'components/base';

const History: FC<Props> = ({ documentID, style = {} }) => {
  const [args] = useState([documentID]);
  const { fetched, pending } = useApi(operationApi.getManyByDocID, args);
  const [operations, setOperations] = useState(undefined);

  // load histories
  useEffect(() => {
    if (!pending && fetch) {
      const operations = fetched.operations;
      operations && setOperations(operations);
    }
  }, [pending, fetched]);

  return operations ? (
    <FwSegment {...style}>
      <FwHistory operations={operations} />
    </FwSegment>
  ) : (
    <></>
  );
};

const propTypes = {
  documentID: string,
  style: any,
};

export type Props = InferProps<typeof propTypes>;

History.propTypes = propTypes;

export default React.memo(History);
