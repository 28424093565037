import _ from 'lodash';

import { Input } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const optionFieldType = _.map(FIELD_TYPE, (value) => {
  return {
    key: value,
    value: value,
    text: value,
  };
});

const inputAS: AdminStructure<Input> = [
  {
    key: 'name',
    name: 'Name',
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'type',
    name: 'Type',
    required: true,
    type: FIELD_TYPE.select,
    dropdown: { dropdownID: 1, options: optionFieldType },
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'placeholder',
    name: 'Placeholder',
    type: FIELD_TYPE.text,
    row: 3,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'additionalData',
    name: 'Data',
    type: FIELD_TYPE.textarea,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
  //{
  //  key: 'pattern',
  //  name: 'Pattern',
  //  required: true,
  //  type: FIELD_TYPE.select,
  //  row: 5,
  //  column: 1,
  //  subInputs: [],
  //  visible: true,
  //},
];

//const inputRules = [
//  {
//    event: {
//      eventID: 1,
//      action: 'ONLOAD',
//      type: 'FIELD',
//      key: 'pattern',
//    },
//    script: {
//      scriptID: 1,
//      description: 'Get pattern options from tabledata api',
//      action: 'SEARCH',
//      type: 'FIELD',
//      data: `
//        api({ local: true })
//        .post('tabledata', {DataKey:'PatternNK',DataType:'Pattern',Table:{Columns:[], RowCount:100}} )
//        .then(res =>
//          res && res.data.tableData.rows && callback({
//            options:{'pattern':res.data.tableData.rows.map(row =>
//              ({ key: row.key, value: row.key, text: row.key, items: null })
//            )}
//          })
//        )
//        `,
//    },
//  },
//];

export { inputAS /*, inputRules*/ };
