import React from 'react';

import AccountPage from './components/page/AccountPage';

// const routes = [
//   {
//     path: '/account',
//     exact: true,
//     component: AccountPage,
//   },
// ];

const Account = () => {
  return <AccountPage />;
};

export default Account;
