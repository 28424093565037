import { bool, func, InferProps, string } from 'prop-types';

import { ctor } from '../helper';
import { stylePT } from './common';

const fwTilePT = {
  ...stylePT,
  active: bool,
  children: string,
  small: bool,
  to: string,
  onClick: func,
};

type FwTilePT = InferProps<typeof fwTilePT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwTileProps extends FwTilePT {}
class FwTileProps implements FwTilePT {
  constructor(props?: FwTilePT) {
    ctor(this, props);
  }
}

export { fwTilePT, FwTileProps };
