import _ from 'lodash';
import {
  arrayOf,
  any,
  func,
  InferProps,
  node,
  string,
  instanceOf,
} from 'prop-types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FwField, FwFilter, FwGrid } from 'components/base';
import { mapInputToField, mapInputToFilter } from 'core/mapper';
import { ExecutionFilter, FwFilterSharedPT, Input } from 'core/model';

const ModalMessage: FC<ModalMessageProps> = ({
  allTemplateInputs,
  content,
  data,
  disableChanges,
  filters,
  inputs,
  inputOptions,
  invalidInputKey,
  loadingInputKeys,
  message,
  onChangeData: onChangeDataFilter,
  onChangeDataEdit,
  setDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {message && t(message, { ns: ['glossary', 'common', 'custom'] })}
      {inputs && _.some(inputs) && (
        <FwGrid
          itemComponent={FwField}
          items={_.map(inputs, (i) =>
            mapInputToField(i, {
              docData: data,
              invalidInputKey,
              loadingInputKeys,
              onChange: onChangeDataEdit,
            })
          )}
        />
      )}
      {filters && _.some(filters) && (
        <FwGrid
          itemComponent={FwFilter}
          items={_.map(filters, ({ row, required }: Input, index) => {
            // get filterData and find input which match the key in filterData
            const filterData = new ExecutionFilter(data[index]);
            const input = _.find(allTemplateInputs, {
              key: filterData.key,
            });

            return mapInputToFilter(input, {
              // override prop
              row,
              required,
              invalidInputKey,
              onChange: (e, data) => {
                setDisabled(false);
                onChangeDataFilter(e, data, index);
              },
              // override value to false because input from template could be readonly
              readOnly: false,
              // override key by value of index so when we change filter key, it will re-render FwFilter instead of render like new
              key: index,
              // inject prop
              filterData,
              allTemplateInputs,
              inputOptions,
              disableChanges,
            });
          })}
        />
      )}
      {content && <FwGrid>{content}</FwGrid>}
    </>
  );
};

const sharedPT = {
  content: node,
  data: any,
  filters: arrayOf(instanceOf(Input)),
  inputs: arrayOf(instanceOf(Input)),
  invalidInputKey: string,
  loadingInputKeys: arrayOf(string),
  message: string,
  onChangeData: func,
};

export type SharedModalMessagePT = InferProps<typeof sharedPT>;

const propTypes = {
  onChangeDataEdit: func,
  setDisabled: func,
};

export type ModalMessageProps = InferProps<typeof propTypes> &
  SharedModalMessagePT &
  FwFilterSharedPT;

ModalMessage.propTypes = propTypes;

export default ModalMessage;
