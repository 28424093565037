//const urlsToCache = [homeUrl, '/index.html'];

// service worker implemented event actions
enum swEventActions {
  setLocalNavigation = 'SETLOCALNAVIGATION',
  clearCache = 'CLEARCACHE',
}

// refactored http method names for service worker
const httpMethods = { get: 'GET', post: 'POST', put: 'PUT', delete: 'DELETE' };

// mock correct-format empty response data
// allowing continuity for React app when network is not accessible
const getMockResponse = (method: string, path: string) => {
  let mockedResponse: unknown;

  switch (method) {
    case httpMethods.get:
      if (path?.startsWith('/api/document')) {
        mockedResponse = {
          document: {
            documentID: '',
            number: 0,
            key: '',
            active: false,
            status: '',
            data: '',
            metaData: {},
            template: {
              templateId: '',
            },
            appUser: null,
            extendDocuments: [],
            lastEdit: '',
            lastView: null,
            viewed: null,
          },
        };
      } else if (path?.startsWith('/api/operation')) {
        mockedResponse = {
          operations: [],
        };
      }
      break;
    case httpMethods.post:
      if (path?.startsWith('/api/document')) {
        const now = new Date().getTime();
        mockedResponse = {
          id: `${now}`,
          number: now,
        };
      }
      break;
  }

  return mockedResponse;
};

// utility to find number of identical characters between two strings
const getDistance = (string1: string, string2: string) => {
  let matchedChars = 0;

  const length = Math.min(string1.length, string2.length);

  for (let i = 0; i < length; i++) {
    if (string1[i] === string2[i]) {
      matchedChars++;
    } else {
      break;
    }
  }

  return matchedChars;
};

// find the closest cached response from given url
const findClosestUrl = (requestUrl: URL, cachedResponses: Response[]) => {
  let closestResponse = null;
  let closestDistance = -1;

  if (cachedResponses?.length) {
    cachedResponses.forEach((cachedResponse: Response) => {
      const cachedUrl = new URL(cachedResponse.url);
      const distance = getDistance(requestUrl.toString(), cachedUrl.toString());

      if (distance > closestDistance) {
        closestDistance = distance;
        closestResponse = cachedResponse;
      }
    });
  }

  return closestResponse;
};

// communication from React app (FasterWeb) to browser (service-worker)
const sendToSW = (action: `${swEventActions}`, content?: unknown) => {
  if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      action,
      content,
    });
  }
};

export {
  httpMethods,
  swEventActions,
  // functions
  findClosestUrl,
  getMockResponse,
  sendToSW,
};
