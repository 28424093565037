import { FwFilterSharedPT, FwModalSharedPT } from 'core/model';

import { ModalMessageProps, SharedModalMessagePT } from './ModalMessage';

const getModalMessageProps = ({
  allTemplateInputs,
  content,
  data,
  disableChanges,
  filters,
  inputOptions,
  inputs,
  invalidInputKey,
  loadingInputKeys,
  message,
  onChangeData,
}: FwModalSharedPT & FwFilterSharedPT): ModalMessageProps => ({
  allTemplateInputs,
  content,
  data,
  disableChanges,
  filters,
  inputOptions,
  inputs,
  invalidInputKey,
  loadingInputKeys,
  message,
  onChangeData,
});

const isRenderModalMessage = ({
  message,
  inputs,
  filters,
  content,
}: SharedModalMessagePT): boolean =>
  Boolean(message || inputs || filters || content);

export { getModalMessageProps, isRenderModalMessage };
