import {
  Popover,
  PopoverAnchor,
  PopoverCloseButton,
  PopoverContent,
  chakra,
} from '@chakra-ui/react';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FwMessage, FwParagraph } from 'components/base';
import { FwNotifierProps, fwNotifierPT } from 'core/model';

import { behaviour, notificationStyle, placement } from './FwNotifier.helpers';

const FwNotifier = ({
  anchorRef,
  button,
  children,
  message,
  timestamp,
  type,
}: FwNotifierProps) => {
  const btnOnClickRef = useRef<{
    handler: MouseEventHandler;
    event: React.MouseEvent<HTMLElement, MouseEvent>;
  }>();

  const [{ disabled, open: isOpen }, setState] = useState({
    disabled: false,
    open: false,
  });

  const onClose = useCallback(() => {
    setState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const disableButton = useCallback(
    (
      btnOnClick: MouseEventHandler,
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      btnOnClickRef.current = btnOnClick
        ? { handler: btnOnClick, event: e }
        : undefined;
      setState((prevState) => ({ ...prevState, disabled: true, open: false }));
    },
    []
  );

  useEffect(() => {
    // sync local state with prop
    setState((prevState) => ({
      ...prevState,
      disabled: false,
      open: !!timestamp,
    }));
  }, [timestamp]);

  useEffect(() => {
    // click was registered in local state, propagate click to parent
    if (disabled) {
      const { handler, event } = btnOnClickRef.current || {};
      setTimeout(() => {
        handler?.(event);
      }, 100);
    }
  }, [disabled]);

  return (
    <Popover
      autoFocus={false}
      closeOnBlur={false}
      isOpen={isOpen}
      isLazy
      modifiers={behaviour}
      placement={placement}
    >
      <PopoverAnchor>{children || <></>}</PopoverAnchor>
      <PopoverContent
        {...notificationStyle}
        maxWidth={
          anchorRef?.current ? `${anchorRef.current.clientWidth}px` : undefined
        }
      >
        <FwMessage type={type}>
          {/* content between message icon and popover close button */}
          <div>
            <chakra.div marginBottom={button ? '10px' : undefined}>
              <FwParagraph small>{message}</FwParagraph>
            </chakra.div>
            {button &&
              React.cloneElement(button, {
                disabled,
                onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                  disableButton(button?.props.onClick, e),
              })}
          </div>
          {/* ensure popover close button will not clash with other popover contents */}
          <chakra.div width="10px">
            <PopoverCloseButton onClick={onClose} />
          </chakra.div>
        </FwMessage>
      </PopoverContent>
    </Popover>
  );
};

FwNotifier.propTypes = fwNotifierPT;

export default FwNotifier;
