import BaseApi, { BaseApiParams } from 'api/baseApi';

const errorApi = {
  post: (
    body: { id: string; key: string; value: string },
    params: BaseApiParams
  ) => BaseApi.post('error', body, params),
};

export default errorApi;
