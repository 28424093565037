import { Node } from 'core/model';

const nodeWidth = 160;
const iconNodeWidth = 30;
const nodeHeight = 35;

const computeNextScale = (scale: number, ascending: boolean) => {
  return scale + (ascending ? scale / 2 : -scale / 3);
};

const getNodeWidth = (node: Node) => {
  return node?.icon && !node?.title ? iconNodeWidth : nodeWidth;
};

export { computeNextScale, getNodeWidth, iconNodeWidth, nodeHeight, nodeWidth };
