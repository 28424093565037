import React, { useState, useEffect } from 'react';

import pageApi from 'api/page/pageApi';
import { useApi } from 'api/useApi';
import { FwSpinner, useFwModule } from 'components/base';
import { Page } from 'core/model';
import routesAdmin from 'scenes/dashboard/scenes/admin/routesAdmin';

import getListFilteredLower from './helpers/getListFilteredLower';
import Pages from './Pages';

const PagesContainer = () => {
  const { moduleRoutes: modules } = useFwModule();

  const [pageRoutes, setPageRoutes] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [args] = useState([]);

  const { fetched: fetchedPages, pending: pendingPages } = useApi(
    pageApi.getAll,
    args
  );

  useEffect(() => {
    const newRoutes = [];
    const defaultRoutes = routesAdmin
      .filter((r) => r.type === 'admin/page')
      .slice();
    const { path, exact, component, buttons } = defaultRoutes[0];

    modules.map((m) =>
      newRoutes.push({
        path: path.replace(':moduleKey/:pageKey?', `${m.key}`),
        exact,
        component,
        buttons,
        type: m.name,
        name: 'Add ' + m.name.toLowerCase() + ' view',
        icon: 'RiAddLine',
      })
    );

    if (!pendingPages) {
      modules.map((m) =>
        getListFilteredLower(fetchedPages.pages, 'module', m.key).map(
          (p: Page) =>
            newRoutes.push({
              path: path.replace(':moduleKey/:pageKey?', `${m.key}/${p.key}`),
              exact,
              component,
              buttons,
              type: m.name,
              name: p.name,
              icon: 'RiLayoutColumnLine',
            })
        )
      );
    }

    setPageRoutes(newRoutes);
    setLoading(false);
  }, [fetchedPages, pendingPages, modules]);

  return loading || pendingPages ? (
    <FwSpinner />
  ) : (
    <Pages pageRoutes={pageRoutes} modules={modules} />
  );
};

export default PagesContainer;
