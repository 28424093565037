import { Menu, MenuList, MenuButton, Portal } from '@chakra-ui/react';
import {
  arrayOf,
  any,
  func,
  InferProps,
  instanceOf,
  node,
  oneOfType,
} from 'prop-types';
import React, { FC, useRef } from 'react';

import { FwList } from 'components/base';
import { defaultSpacing } from 'config/theme/constants';
import useContextMenu from 'core/hooks/useContextMenu';
import { FwCategoryProps, FwItemProps } from 'core/model';

const FwContextMenu: FC<Props> = ({
  renderTag: RenderTag,
  childrenRenderTag: ChildrenRenderTag,
  children,
  items,
  ...props
}) => {
  const triggerRef = useRef();
  const [contextRef, open, setOpen, setPendingClose] =
    useContextMenu(triggerRef);

  const handleClose = () => {
    setOpen(false);
  };

  const position =
    contextRef &&
    contextRef.current &&
    contextRef.current.getBoundingClientRect();

  return RenderTag && ChildrenRenderTag ? (
    <RenderTag {...props} ref={triggerRef}>
      {children}
      {items && position && (
        <ChildrenRenderTag display="none">
          <Portal>
            <Menu isOpen={open} onClose={handleClose} gutter={0}>
              <MenuButton
                aria-hidden
                w={0}
                h={0}
                position="absolute"
                left={position.left}
                top={position.top}
              />
              <MenuList
                zIndex={10}
                onMouseEnter={() => setPendingClose(false)}
                onMouseLeave={() => setPendingClose(true)}
                padding={defaultSpacing}
              >
                <FwList small items={items} onChange={handleClose} />
              </MenuList>
            </Menu>
          </Portal>
        </ChildrenRenderTag>
      )}
    </RenderTag>
  ) : (
    <></>
  );
};

const propTypes = {
  renderTag: any,
  childrenRenderTag: any,
  children: node,
  items: arrayOf(
    oneOfType([instanceOf(FwCategoryProps), instanceOf(FwItemProps)])
  ),
  onMouseDown: func,
  onMouseUp: func,
};

export type Props = InferProps<typeof propTypes>;

FwContextMenu.propTypes = propTypes;

export default FwContextMenu;
