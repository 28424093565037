import { dateFormats, jsDateFromString, jsDateToString } from 'core/utils/date';
import utils from 'core/utils/utils';

const getDateTimeString = (
  isDateTime: boolean,
  isHours: boolean,
  locale: string,
  hours: Date,
  date: Date
) => {
  if (isDateTime) {
    const datePart = jsDateToString(date, dateFormats.dateLiteral);
    const timePart = !isHours ? getLocaleTime(locale, hours, true) : '';
    return datePart + ' ' + timePart;
  } else {
    return getLocaleTime(locale, hours, true);
  }
};

const getLocaleTime = (locale: string, time: Date, isHour: boolean) => {
  const minutes = isHour ? '' : jsDateToString(time, dateFormats.minutes);

  let localeTime = '';

  if (locale?.startsWith('fr')) {
    const hours = jsDateToString(time, dateFormats.hours0_23);
    localeTime = `${hours}h${minutes}`;
  } else {
    // default en-CA
    const hours = jsDateToString(time, dateFormats.hours1_12);
    const dayPeriod = jsDateToString(time, dateFormats.dayPeriod);
    localeTime = `${hours}${minutes ? `:${minutes}` : ''} ${dayPeriod}`;
  }

  return localeTime;
};

const getStringTime = (time: number) => {
  return time === 0 ? '0' : utils.toHourString(time);
};

const matrixDimension = (isHour: boolean) =>
  isHour ? { width: 4, height: 6 } : { width: 3, height: 4 };

const getTime = (
  width: number,
  height: number,
  hours: Date,
  isHours: boolean
) => {
  const time = matrixDimension(isHours).width * height + width;
  const stringTime = isHours
    ? `${getStringTime(time)}:00`
    : `${getStringTime(hours.getHours())}:${getStringTime(time * 5)}`;

  return jsDateFromString(stringTime, dateFormats.isoTime);
};

const isSelectedDate = (
  newSelectedDate: Date,
  oldSelectedDate: Date,
  isHour: boolean
) =>
  oldSelectedDate &&
  newSelectedDate.getHours() === oldSelectedDate.getHours() &&
  (isHour
    ? true
    : newSelectedDate.getMinutes() === oldSelectedDate.getMinutes());

export {
  getDateTimeString,
  getLocaleTime,
  getTime,
  isSelectedDate,
  matrixDimension,
};
