import _ from 'lodash';

import { areaIsMain } from 'components/base';
import { AppUser, Area, Module, Route } from 'core/model';
import getModulesAccessible from 'core/modules/helpers/getModulesAccessible';
import { sortByStepRowColumnKey } from 'core/utils/sort';

const getModulesRoutes = (
  modules: Module[],
  filteredRoutes: Route[],
  area: Area,
  currentUser: AppUser,
  isAll: boolean
) => {
  // filter modules
  const filteredModules = sortByStepRowColumnKey(
    getModulesAccessible(modules, area, currentUser, isAll)
  );

  // build routes based on system and custom modules
  const modulesRoutes = _.concat(
    _.map(filteredModules, (m) => ({ ...m, system: false })),
    _.map(filteredRoutes, (r: Route) => ({
      key: r.key,
      name: r.name,
      design: r.design,
      position: filteredModules.length + 1,
      path: r.path,
      areaOnly: false,
      areas: !areaIsMain(area) ? [area.key] : undefined,
      system: true,
    }))
  );

  return modulesRoutes;
};

export default getModulesRoutes;
