import { Route } from 'core/model';

// import Batch from '../batch/batchContainer';
import Doc from '../doc/docContainer';

const documentRoutes: Route[] = [
  // tODO {area}/{moduleKey}/{pageKey}/d/new/{templateID}
  // tODO {area}/{moduleKey}/{pageKey}/d/{documentID}
  {
    name: 'doc-with-step',
    path: '/:id([A-Za-z0-9-]{36})/:stepId([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'doc',
    path: '/:id([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'doc-with-step',
    path: '/:id([0-9]{13})/:stepId([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'doc',
    path: '/:id([0-9]{13})',
    exact: true,
    component: Doc,
  },
  {
    name: 'new',
    path: '/new/:templateId([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    name: 'new-with-step',
    path: '/new/:templateId([A-Za-z0-9-]{36})/:stepId([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
  {
    path: '/:type/:id([A-Za-z0-9-]{36})',
    exact: true,
    component: Doc,
  },
];

export default documentRoutes;
