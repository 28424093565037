import { Module } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const moduleAS: AdminStructure<Module> = [
  {
    key: 'name',
    name: 'Name',
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'key',
    name: 'Key',
    isReadOnly: true,
    required: true,
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'position',
    name: 'Position',
    required: true,
    type: FIELD_TYPE.number,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'areaOnly',
    name: 'Only display in an area',
    type: FIELD_TYPE.checkbox,
    row: 3,
    column: 1,
    additionalData: { noLabel: true },
    subInputs: [],
    visible: true,
  },
  {
    key: 'areas',
    name: 'Areas',
    type: FIELD_TYPE.multiselect,
    row: 4,
    column: 1,
    subInputs: [],
    visible: true,
  },
];

const moduleRules = [
  {
    event: {
      eventID: 1,
      action: 'ONLOAD',
      type: 'FIELD',
      key: 'areas',
    },
    script: {
      scriptID: 1,
      description: 'Get options from area api',
      action: 'SEARCH',
      type: 'FIELD',
      data: "api({ local: true }).get(`area`).then(res => res && res.data.areas && callback({ options: {'areas': res.data.areas.map(a => ({ key: a.key, value: a.key, text: a.key, items: null })) } }))",
    },
  },
];

export { moduleAS, moduleRules };
