import React, { FC, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RouteChildrenProps, withRouter } from 'react-router-dom';

import moduleApi from 'api/module/moduleApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Module as ModuleDto } from 'core/model';

import Module from './Module';

const ModuleContainer: FC<RouteChildrenProps> = ({ match: { params } }) => {
  const { key } = params as { key: string };
  const args = useRef(key ? [key] : []);
  const { fetched, pending } = useApi(moduleApi.get, args.current);

  // set module and initialize areas to empty array
  const [module, setModule] = useState(
    key ? undefined : new ModuleDto({ position: null, areas: [] })
  );

  // receive page from backend and set in state
  useEffect(() => {
    if (!pending && fetched) {
      setModule(fetched.module);
    }
  }, [fetched, pending]);

  const handleSetKey = (key) => {
    const newModule = { ...module, key: key };
    // set in state
    setModule(newModule);
  };

  const pageProps = {
    module,
    handleSetKey,
  };

  // render
  return !pending && module ? <Module {...pageProps} /> : <FwSpinner />;
};

export default withRouter(withTranslation()(ModuleContainer));
