import _ from 'lodash';

import { Document } from 'core/model';

// props
type dataProps = Record<string, string | { inputValue: string }>;

// function
const getDocDataExtended = (document: Document, docData: dataProps) => {
  const data = document.data ? JSON.parse(document.data) : {};
  _.forEach(docData, function (value, key) {
    const inputValue = typeof value == 'object' ? value.inputValue : value;
    if (data[key] === undefined && inputValue != undefined) {
      data[key] = inputValue;
    }
  });

  return data;
};

export default getDocDataExtended;
