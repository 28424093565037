import React, { useEffect, useState } from 'react';

import { FwSpinner, useFwModule } from 'components/base';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';
import { setRoutes } from 'scenes/dashboard/helpers/admin';

const ModulesContainer = () => {
  const { allModuleRoutes } = useFwModule();

  const [modulesRoutes, setModulesRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllModules = async () => {
      const routes = setRoutes(
        allModuleRoutes.filter((m) => !m.system),
        'admin/modules',
        ':key?'
      );

      setModulesRoutes(routes);
      setLoading(false);
    };

    getAllModules();
  }, [allModuleRoutes]);

  return loading ? <FwSpinner /> : <TilesPanel routes={modulesRoutes} />;
};

export default ModulesContainer;
