import { AlertStatus } from '@chakra-ui/react';
import { InferProps, string } from 'prop-types';
import React, { FC } from 'react';

import { FwMessage } from 'components/base';

const Message: FC<Props> = ({ message, type }) => (
  <FwMessage small subtle message={message} type={type} />
);

const propTypes = {
  message: string,
};

export interface Props extends InferProps<typeof propTypes> {
  type: AlertStatus;
}

Message.propTypes = propTypes;

export default Message;
