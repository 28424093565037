import { TFunction } from 'i18next';
import _ from 'lodash';

import { FwFieldProps } from 'core/model';

import { Dto } from '../helper';
import * as structures from './structures';

import { AdminStructure } from '.';

const getAdminStructure = <T>(dtoName: string) => {
  if (!(dtoName in structures)) {
    console.error(
      `${dtoName} was not found in admin structures. Make sure parameter was passed as an instance of a class, not just as an object`
    );
  }

  // find exported input array by name
  return structures[dtoName] as AdminStructure<T>;
};

const getAdminInputs = <T extends Dto>(
  dto: T,
  commonProps?: FwFieldProps,
  t?: TFunction,
  suffix?: string
) => {
  // get appropriate input array for specified type
  const inputs = getAdminStructure<T>(
    `${_.camelCase(dto.dtoName)}${suffix ?? ''}AS`
  );

  // apply common props to inputs, translate name and return
  return _.map(inputs, (i) =>
    _.merge({ ...i, name: t(`common|${i.name}`) }, commonProps)
  );
};

export { getAdminStructure, getAdminInputs };
