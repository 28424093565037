import {
  arrayOf,
  func,
  InferProps,
  instanceOf,
  node,
  number,
  oneOf,
  string,
} from 'prop-types';

import { DataProps } from 'components/doc/function/document';
import { PLACEMENT_TYPES } from 'core/utils/constants';

import { Execution, Row } from '../dtoIndex';
import { ctor } from '../helper';

const fwProcessPT = {
  children: node,
  docId: string,
  docNumber: number,
  handleDocValidationScroll: func,
  objectData: instanceOf(Row),
  onInputChange: func,
  placement: oneOf<(typeof PLACEMENT_TYPES)[keyof typeof PLACEMENT_TYPES]>([
    PLACEMENT_TYPES.top,
    PLACEMENT_TYPES.right,
    PLACEMENT_TYPES.bottom,
    PLACEMENT_TYPES.left,
  ]),
  processID: string,
  processSteps: arrayOf(instanceOf(Execution)),
  processType: string,
};

type FwProcessPT = InferProps<typeof fwProcessPT> & { docData?: DataProps };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwProcessProps extends FwProcessPT {}
class FwProcessProps implements FwProcessPT {
  constructor(props?: FwProcessPT) {
    ctor(this, props);
  }
}

export { fwProcessPT, FwProcessProps };
