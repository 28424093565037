import React, { useRef, useState } from 'react';

import tableApi from 'api/table/tableApi';
import { useApi } from 'api/useApi';
import {
  FwMask,
  useFwArea,
  useFwModule,
  useFwPageContentStore,
  useFwPageStore,
  useFwModuleStore,
} from 'components/base';
import { MaskStructure } from 'components/base/containers/mask/FwMask.structures';
import { Module } from 'core/model';
import utils from 'core/utils/utils';

import Table from './components/tableContainer';

const TableMaskContainer = (props: any) => {
  const {
    pageContentID,
    pcTableKey,
    pcAdditionalData,
    tableKey: propTableKey,
    style,
  } = props;

  const { area } = useFwArea();
  const { module } = useFwModule();
  const { pageContentStore, setPageContentStore } =
    useFwPageContentStore() || {};
  const { pageStore } = useFwPageStore() || {};
  const { setModuleStore } = useFwModuleStore() || {};

  const moduleKey = module && module.key;
  const tableKey = propTableKey || pcTableKey;

  const newTabLink = pageContentID
    ? utils.buildUrlNav(
        area,
        new Module({
          key: `zoom/${moduleKey.replace('public/', '')}`,
          position: 0,
        }),
        `${pageContentID}`
      )
    : undefined;

  const [args] = useState([tableKey]);

  const { fetched: fetchedTable, pending: pendingTable } = useApi(
    tableApi.getTable,
    args
  );

  const maskRef = useRef(new MaskStructure(pcAdditionalData));

  return !fetchedTable || pendingTable ? (
    <FwMask loading style={style} />
  ) : (
    <Table
      {...props}
      tableKey={tableKey}
      newTabLink={newTabLink}
      maskData={maskRef.current}
      table={fetchedTable.table}
      pageContentStore={pageContentStore}
      setPageContentStore={setPageContentStore}
      pageStore={pageStore}
      setModuleStore={setModuleStore}
    />
  );
};

export default React.memo(TableMaskContainer);
