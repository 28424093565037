import _ from 'lodash';
import { any, arrayOf, bool, func, InferProps, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { FwArticle, FwHighlight, FwSegment, useFwTheme } from 'components/base';

const FwCard = ({
  borderColor,
  content,
  dndSettings,
  opacity,
  searchText,
  onClick,
}: Props) => {
  const { index, card, drag, drop, useDnd } = dndSettings || {};

  const { accent } = useFwTheme();

  const ref = useRef(null);
  const wasDraggingRef = useRef(false);

  // useDnd allows to drag and drop the card and returns whether card is being dragged
  const isDragging = useDnd?.(card, index, ref);

  // execute drag function when drag starts and drop function when card is dropped
  useEffect(() => {
    if (drag && drop) {
      if (!wasDraggingRef.current && isDragging) {
        // start of drag
        wasDraggingRef.current = true;
        drag();
      } else if (wasDraggingRef.current && !isDragging) {
        // end of drag (drop)
        wasDraggingRef.current = false;
        drop();
      }
    }
  }, [drag, drop, isDragging]);

  const highlightedContent = searchText
    ? _.map(content, (texts) =>
        _.map(texts, (text) => (
          <FwHighlight key={text} text={text} highlight={searchText} />
        ))
      )
    : content;

  return (
    <FwSegment
      ref={ref}
      opacity={isDragging || opacity ? 0.1 : undefined}
      borderColor={borderColor || accent}
      onClick={onClick}
    >
      <FwArticle small content={highlightedContent} />
    </FwSegment>
  );
};

const propTypes = {
  borderColor: string,
  content: arrayOf(arrayOf(string)),
  dndSettings: any,
  onClick: func,
  opacity: bool,
  searchText: string,
};

export type Props = InferProps<typeof propTypes>;

FwCard.propTypes = propTypes;

export default FwCard;
