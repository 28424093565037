import { dtoCtor } from '../helper';
import { Edit } from './edit';

class Filter extends Edit {
  dtoName?: string;

  constructor(props?: Omit<Filter, 'dtoName'>) {
    super(props);
    dtoCtor(this, 'Filter', props);
  }

  operator?: string;
  type?: string;
  searchType?: string;
}

export { Filter };
