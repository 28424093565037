import ISO6391 from 'iso-639-1';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import languageApi from 'api/language/languageApi';
import { useApi } from 'api/useApi';
import { FwSpinner } from 'components/base';
import { Language } from 'core/model';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';
import { setRoutes } from 'scenes/dashboard/helpers/admin';

const LangugageContainer = () => {
  const [loading, setLoading] = useState(true);
  const [areasRoutes, setAreasRoutes] = useState([]);
  const [args] = useState([]);
  const { fetched: fetchedLanguages, pending: pendingLanguages } = useApi(
    languageApi.getAll,
    args
  );
  const dtos = _.map(fetchedLanguages?.languages, (l) => {
    if (l) {
      return new Language({
        key: l.key,
        name: ISO6391.getNativeName(l.key.toLowerCase()),
      });
    }
  });

  useEffect(() => {
    const aRoutes = setRoutes(
      dtos,
      'admin/language',
      ':name?',
      true,
      'RiFontSize'
    );

    setAreasRoutes(aRoutes);
    setLoading(false);
  }, [fetchedLanguages]);

  return loading || pendingLanguages ? (
    <FwSpinner />
  ) : (
    <TilesPanel routes={areasRoutes} />
  );
};

export default LangugageContainer;
