import {
  Component,
  ComponentLifecycle,
  ErrorInfo,
  GetDerivedStateFromError,
} from 'react';

import { FwErrorBoundaryProps } from 'core/model';

type FwErrorBoundaryState = {
  error: Error;
  errorInfo: ErrorInfo;
  hasError: boolean;
};

// error boundary component was served by React until v16
// v18 docs suggest to define it as follows:
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
// it basically tries to render children first, and when it fails it renders fallback children
class FwErrorBoundary extends Component<
  FwErrorBoundaryProps,
  FwErrorBoundaryState
> {
  static getDerivedStateFromError: GetDerivedStateFromError<
    FwErrorBoundaryProps,
    FwErrorBoundaryState
  > = (error) => {
    return { hasError: true, error };
  };

  componentDidCatch: ComponentLifecycle<
    FwErrorBoundaryProps,
    FwErrorBoundaryState
  >['componentDidCatch'] = (error, errorInfo) => {
    // notify users of error
    console.log({ error, errorInfo });

    // update state to trigger render and show the fallback
    this.setState({ errorInfo });
  };

  render() {
    return this.state?.hasError
      ? /* render custom fallback */
        this.props.fallback || null
      : this.props.children;
  }
}

export { FwErrorBoundary };
