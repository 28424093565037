import { Design } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import { AdminStructure } from '..';

const designAS: AdminStructure<Design> = [
  {
    key: 'design.icon',
    name: 'Icon',
    type: FIELD_TYPE.text,
    row: 1,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'design.iconColor',
    name: 'IconColor',
    type: FIELD_TYPE.text,
    row: 1,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'design.color',
    name: 'Color',
    type: FIELD_TYPE.text,
    row: 2,
    column: 1,
    subInputs: [],
    visible: true,
  },
  {
    key: 'design.backgroundColor',
    name: 'backgroundColor',
    type: FIELD_TYPE.text,
    row: 2,
    column: 2,
    subInputs: [],
    visible: true,
  },
  {
    key: 'design.borderColor',
    name: 'borderColor',
    type: FIELD_TYPE.text,
    row: 2,
    column: 3,
    subInputs: [],
    visible: true,
  },
];

export { designAS };
