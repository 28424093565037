import { dtoCtor, Dto } from '../helper';

class AutoSaveEdit implements Dto {
  dtoName?: string;

  constructor(props?: Omit<AutoSaveEdit, 'dtoName'>) {
    dtoCtor(this, 'AutoSaveEdit', props);
  }

  name?: string;
  value?: unknown;
}

export { AutoSaveEdit };
