import { InputLeftElement, InputRightElement, Spinner } from '@chakra-ui/react';
import { bool, func, InferProps, string } from 'prop-types';
import React, { FC } from 'react';

import { FwButton, FwIcon } from 'components/base';

const BaseInputIcon: FC<Props> = ({
  disabled,
  left,
  loading,
  right,
  name,
  onClick,
  ...props
}) => {
  const displayLeft = left || !right;
  const Component = displayLeft ? InputLeftElement : InputRightElement;

  return (
    <Component pointerEvents={onClick ? undefined : 'none'} {...props}>
      {onClick ? (
        <FwButton
          small
          rounded
          disabled={disabled || loading}
          leftIcon={name}
          loading={loading}
          onClick={onClick}
        />
      ) : loading ? (
        <Spinner />
      ) : (
        <FwIcon disabled={disabled} name={name} />
      )}
    </Component>
  );
};

const propTypes = {
  disabled: bool,
  left: bool,
  loading: bool,
  right: bool,
  name: string,
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

BaseInputIcon.propTypes = propTypes;

export default BaseInputIcon;
