import _ from 'lodash';

import { FwCategoryProps, FwItemProps } from 'core/model';
import { DOWNLOAD_TYPE } from 'core/utils/constant';
import { resetTableCache } from 'core/utils/storage';
import utils from 'core/utils/utils';

const { /* csv,  */ xlsx, pdf } = DOWNLOAD_TYPE;

const handleResetTableCache = (tableKey) => () => {
  // reset cache
  resetTableCache(tableKey);

  // refresh page
  window.location.reload();

  // todo make search inputs controlled
  // and manually reset table instead of reloading whole page
  //// reset table UI
  //setActiveColumn(table.sortByColumnKey);
  //setDirection(table.ascendingSort ? 'ascending' : 'descending');

  //// reset criteria
  //criteria.current = initializeTableCriteria(
  //  table,
  //  undefined,
  //  undefined,
  //  undefined,
  //  mask
  //);

  //// todo clear value of all search inputs

  //// reset page
  //criteria.current.indexPage = 1;

  //// update state to render table
  //queryWithCriteria();
};

const getMenuItems = (
  t,
  disableOpenNewTab,
  tableKey,
  newTabLink,
  odsTemplates,
  simpleProcessDownloads,
  batchProcessDownloads,
  handleExportData
) => {
  const actionSubItems = !disableOpenNewTab
    ? [
        new FwItemProps({
          text: t('common|Open in new tab'),
          onClick: () => utils.openInNewTab(newTabLink),
        }),
      ]
    : [];

  actionSubItems.push(
    new FwItemProps({
      text: t('common|Reset filters and sort'),
      onClick: handleResetTableCache(tableKey),
    })
  );

  const dropdownItems = newTabLink
    ? [
        new FwCategoryProps({
          name: t('common|Actions'),
          leftIcon: 'RiFlashlightFill',
          items: actionSubItems,
        }),
      ]
    : [];

  if (handleExportData) {
    // templates config by odsTemplates in table.additionalData
    const tableDownloadTemplates = odsTemplates
      ? _.flatMap([xlsx, pdf], (type) =>
          _.map(
            odsTemplates,
            (tmplt) =>
              new FwItemProps({
                text: t(`custom|${tmplt}.${type.toLowerCase()}`),
                onClick: () => handleExportData(type, tmplt),
              })
          )
        )
      : [];

    // templates config by downloadTemplates and downloadFileTypes in process.additionalData
    const simpleProcessDownloadTemplates = _.map(
      simpleProcessDownloads,
      ({
        process: {
          additionalData: {
            downloadTemplates: [tmplt],
            downloadFileTypes: [type],
            downloadFileName,
          },
        },
      }) =>
        new FwItemProps({
          text: t(`custom|${tmplt}.${type.toLowerCase()}`),
          onClick: () =>
            handleExportData(type, tmplt, undefined, downloadFileName),
        })
    );

    if (
      tableDownloadTemplates.length > 0 ||
      simpleProcessDownloadTemplates.length > 0
    ) {
      dropdownItems.push(
        new FwCategoryProps({
          name: t('common|Download'),
          leftIcon: 'RiDownload2Fill',
          items: _.union(
            tableDownloadTemplates,
            simpleProcessDownloadTemplates
          ),
        })
      );
    }

    // batch templates config by downloadTemplates and downloadFileTypes in process.additionalData
    const batchProcessDownloadTemplates = _.map(
      batchProcessDownloads,
      ({
        batchTemplateId,
        process: {
          additionalData: {
            downloadTemplates: [tmplt],
            downloadFileTypes: [type],
          },
        },
      }) =>
        new FwItemProps({
          text: t(`custom|${tmplt}.${type.toLowerCase()}`),
          onClick: () => handleExportData(type, tmplt, batchTemplateId),
        })
    );

    if (batchProcessDownloadTemplates.length > 0) {
      dropdownItems.push(
        new FwCategoryProps({
          name: t('common|Batch download'),
          leftIcon: 'RiFolderDownloadFill',
          items: batchProcessDownloadTemplates,
        })
      );
    }
  }

  return dropdownItems;
};

export { getMenuItems };
