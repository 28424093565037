import _ from 'lodash';
import { bool, func, InferProps, string } from 'prop-types';
import React, { ChangeEvent, FocusEvent, forwardRef } from 'react';

import { commonPT, FwCheckboxProps } from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';

import {
  Autocomplete,
  AutocompleteProps,
  Base,
  BaseProps,
  Checkbox,
  Datetime,
  DatetimeProps,
  File,
  FileProps,
  Label,
  LabelProps,
  Link,
  LinkProps,
  Markdown,
  MarkdownProps,
  Password,
  PasswordProps,
  Select,
  SelectProps,
  Sign,
  SignProps,
} from '.';

const {
  address,
  autocomplete,
  barcode,
  checkbox,
  date,
  daterange,
  datetime,
  decimal,
  editselect,
  empty,
  file,
  icon,
  idlink,
  label,
  link,
  markdown,
  multidates,
  multiselect,
  number,
  password,
  photo,
  qrcode,
  reference,
  searcheditselect,
  searchselect,
  select,
  sign,
  text,
  textarea,
  time,
  timerange,
} = FIELD_TYPE;
const types = _.values(FIELD_TYPE);

const FwInput = forwardRef(
  ({ clearable, search, type, searchType, ...props }: Props, ref) => {
    const realType = search ? searchType : type;
    const commonProps = {
      clearable: search && _.isNil(clearable) ? true : clearable,
      ...props,
      // search checkbox
      ...(search && realType === checkbox
        ? { label: '', allowIndeterminate: true, clearable: true }
        : undefined),
    };

    return (
      <>
        {/*not implemented or empty*/}
        {_.includes([barcode, empty, icon, idlink, qrcode], realType) && <></>}
        {/*specific inputs*/}
        {_.includes([address, autocomplete, reference], realType) && (
          <Autocomplete
            {...commonProps}
            type={realType}
            custom={[address].includes(type)}
          />
        )}
        {realType === checkbox && <Checkbox {...commonProps} />}
        {_.includes(
          [date, daterange, datetime, multidates, time, timerange],
          realType
        ) && (
          <Datetime
            {...commonProps}
            multiple={realType === multidates}
            range={_.includes([daterange, timerange], realType)}
            type={
              _.includes([date, daterange, multidates], realType)
                ? date
                : _.includes([time, timerange], realType)
                ? time
                : datetime
            }
          />
        )}
        {_.includes([file, photo], realType) && (
          <File {...commonProps} type={realType} />
        )}
        {realType === label && <Label {...commonProps} />}
        {realType === link && <Link {...commonProps} />}
        {realType === markdown && <Markdown {...commonProps} />}
        {realType === password && <Password {...commonProps} />}
        {_.includes(
          [select, editselect, searchselect, searcheditselect, multiselect],
          realType
        ) && (
          <Select
            editable={_.includes([editselect, searcheditselect], realType)}
            multiple={realType === multiselect}
            searchable={_.includes([searchselect, searcheditselect], realType)}
            {...commonProps}
          />
        )}
        {realType === sign && <Sign {...commonProps} />}
        {/*base input*/}
        {(_.includes([decimal, number, text, textarea], realType) ||
          !_.includes(types, realType)) && (
          <Base ref={ref} {...commonProps} type={realType} />
        )}
      </>
    );
  }
);

const propTypes = {
  ...commonPT,
  clearable: bool,
  defaultValue: string,
  disabled: bool,
  name: string,
  readOnly: bool,
  search: bool,
  searchType: string,
  saved: bool,
  type: string,
  unchanged: bool,
  value: string,
  onBlur: func,
  onChange: func,
};

export interface CommonInputProps extends InferProps<typeof propTypes> {
  onBlur?: (e: FocusEvent) => void;
  onChange?: (
    e: ChangeEvent,
    data: { name: string; value: string | object; fillData?: object }
  ) => void;
}
export type Props = CommonInputProps &
  AutocompleteProps &
  BaseProps &
  FwCheckboxProps &
  DatetimeProps &
  FileProps &
  LabelProps &
  LinkProps &
  MarkdownProps &
  PasswordProps &
  SelectProps &
  SignProps;

FwInput.propTypes = propTypes;
FwInput.displayName = 'FwInput';

export default FwInput;
