import { dtoCtor, Dto } from '../helper';
import { AppPreference } from './appPreference';
import { UserFilter } from './userFilter';

class AppUser implements Dto {
  dtoName?: string;

  constructor(props?: Omit<AppUser, 'dtoName'>) {
    dtoCtor(this, 'AppUser', props);
  }

  id?: string;
  fasterClientID?: string;
  username?: string;
  external?: boolean;
  roles?: string[];
  appPreference?: AppPreference;
  filters?: UserFilter[];
  claims?: { type: string; value: string }[];
}

export { AppUser };
