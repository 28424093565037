import { useColorMode } from '@chakra-ui/react';

import { mapStyleToChakra } from 'core/mapper';
import { CssStylePT } from 'core/model';

const useFwCssStyle = (style: CssStylePT, withHover = false) => {
  const colorContext = useColorMode();
  return mapStyleToChakra(style, colorContext, withHover);
};

export { useFwCssStyle };
