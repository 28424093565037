import React, { FC } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import { LocationStateProps } from 'config/router';

const RedirectNotFound: FC<RouteProps> = ({ location }) => {
  const state: LocationStateProps = { is404: true };

  return <Redirect to={Object.assign({}, location, { state })} />;
};

export default RedirectNotFound;
