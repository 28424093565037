import { Link } from '@chakra-ui/react';
import { any, bool, func, InferProps, node, string } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { FwIcon, useFwTheme } from 'components/base';

const FwLink = ({
  basic,
  disabled,
  children,
  leftIcon,
  to,
  onClick,
}: Props) => {
  const { accent } = useFwTheme();

  const linkStyle = basic
    ? {
        boxShadow: 'none',
        _focus: { boxShadow: 'none' },
        _hover: { textDecoration: 'none' },
      }
    : {
        color: accent,
      };

  const disabledStyle = disabled
    ? {
        opacity: 0.4,
        _hover: { cursor: 'not-allowed' },
      }
    : undefined;

  const isLocalPath = to && to.startsWith('/');
  const isExternalPath = to && !isLocalPath;

  return (
    <Link
      {...linkStyle}
      {...disabledStyle}
      as={isLocalPath ? RouterLink : undefined}
      to={isLocalPath ? to : undefined}
      href={isExternalPath ? to : undefined}
      onClick={onClick}
    >
      {leftIcon && <FwIcon inline name={leftIcon} />}
      {children}
    </Link>
  );
};

const propTypes = {
  basic: bool,
  children: node,
  disabled: bool,
  leftIcon: string,
  to: string,
  staticContext: any,
  location: any,
  history: any,
  match: any,
  onClick: func,
};

export type Props = InferProps<typeof propTypes>;

FwLink.propTypes = propTypes;

export default FwLink;
