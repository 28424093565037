import { Box, ChakraProps, Wrap } from '@chakra-ui/react';
import { any, bool, InferProps } from 'prop-types';
import React, { FC } from 'react';

import { defaultSpacing } from 'config/theme/constants';

const FwPanel: FC<Props> = ({ center, children, scroll, small }) => {
  const panelItems = React.Children.map(children, (child) =>
    React.cloneElement(child, { small: small })
  );

  const boxStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem',
  };

  const containerStyle: ChakraProps = {
    overflowY: 'auto',
    pb: '2px',
    mb: '-2px',
  };

  const wrapStyle = {
    align: 'center',
    justify: center ? 'center' : undefined,
    // prevent wrap list sub-html node overflowing wrap container
    sx: {
      '& .chakra-wrap__list': {
        '--chakra-wrap-spacing': 'unset',
        '--wrap-spacing': 'unset',
        margin: 0,
        maxWidth: '100%',
        gap: defaultSpacing,
      },
    },
  };

  return scroll ? (
    <Box {...containerStyle}>
      <Box {...boxStyle}>{panelItems}</Box>
    </Box>
  ) : (
    <Wrap {...wrapStyle}>{panelItems}</Wrap>
  );
};

const propTypes = {
  center: bool,
  children: any,
  scroll: bool,
  small: bool,
};

export type Props = InferProps<typeof propTypes>;

FwPanel.propTypes = propTypes;

export default FwPanel;
