import {
  any,
  arrayOf,
  bool,
  elementType,
  InferProps,
  node,
  string,
} from 'prop-types';

import { ctor } from '../helper';

const fwGridPT = {
  children: node,
  items: arrayOf(any),
  itemComponent: elementType,
  responsive: bool,
  title: string,
};

type FwGridPT = InferProps<typeof fwGridPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwGridProps extends FwGridPT {}
class FwGridProps implements FwGridPT {
  constructor(props?: FwGridPT) {
    ctor(this, props);
  }
}

export { fwGridPT, FwGridProps };
