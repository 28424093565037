import { Box, HTMLChakraProps } from '@chakra-ui/react';
import React, { ElementType, ForwardedRef, forwardRef } from 'react';

import { useFwTheme, FwLabel, useFwCssStyle } from 'components/base';
import { defaultSpacing } from 'config/theme/constants';
import { FwSegmentProps, fwSegmentPT } from 'core/model';

const FwSegment = forwardRef(
  (
    {
      active,
      as,
      backgroundColor: backgroundColorProp,
      basic,
      borderColor: borderColorProp,
      center,
      children,
      color: colorProp,
      compact,
      frosted,
      leftIcon,
      leftIconColor,
      name,
      noOverflow,
      opacity,
      padded,
      rightIcon,
      rightIconColor,
      small,
      sticky,
      tile,
      onClick,
    }: FwSegmentProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const {
      accent,
      bg,
      color: defaultColor,
      _disabled: {
        _hover: { color: subtleColor },
      },
    } = useFwTheme(frosted ? 0.8 : undefined);
    const { backgroundColor, borderColor, color } = useFwCssStyle({
      backgroundColor: backgroundColorProp,
      borderColor: borderColorProp,
      color: colorProp,
    });

    const stickyStyle: HTMLChakraProps<typeof Box> = {
      position: 'sticky',
      top: 0,
      zIndex: 3,
      boxShadow: 'md',
    };

    const baseColor = color || defaultColor;

    const activeStyleSegment = {
      boxShadow: 'lg',
      borderBottom: '4px',
      borderBottomColor: accent,
      color: baseColor,
    };

    const padding = padded ? 4 : defaultSpacing;

    const segmentStyle: HTMLChakraProps<typeof Box> = {
      background: backgroundColor || bg,
      borderColor,
      borderRadius: basic ? undefined : '5px',
      borderStartWidth: borderColor ? '3px' : '0px',
      boxShadow: basic ? 'sm' : 'base',
      color: tile ? subtleColor : color || defaultColor,
      h: small ? (active ? '36px' : '33px') : undefined,
      overflow: noOverflow ? 'hidden' : undefined,
      opacity,
      p: padding,
      textAlign: center ? 'center' : undefined,
      w: compact ? '160px' : undefined,
      _hover:
        onClick || tile ? { cursor: 'pointer', color: baseColor } : undefined,
      ...(sticky && stickyStyle),
      ...(active && activeStyleSegment),
      sx: {
        '& > div:first-of-type > span:first-of-type': {
          borderLeftRadius: '0',
          ml: `-${padding}`,
          pl: padding,
        },
      },
    };

    const labelStyle = {
      color: colorProp,
      leftIcon,
      leftIconColor,
      rightIcon,
      rightIconColor,
    };

    return (
      <Box ref={ref} as={as as ElementType} {...segmentStyle} onClick={onClick}>
        {(name || labelStyle?.leftIcon || labelStyle?.rightIcon) && (
          <Box>
            <FwLabel {...labelStyle}>{name}</FwLabel>
          </Box>
        )}
        {children}
      </Box>
    );
  }
);

FwSegment.propTypes = fwSegmentPT;
FwSegment.displayName = 'FwSegment';

export default FwSegment;
