import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, PageContentManagement } from 'core/model';

const pageContentApi = {
  getById: (id: string, params: BaseApiParams) =>
    BaseApi.get<{ pageContent: PageContentManagement }>(
      `pageContent/${id}`,
      params
    ),
  post: (pageContent: PageContentManagement, params: BaseApiParams) =>
    BaseApi.post('pageContent', pageContent, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<PageContentManagement, string>,
    params: BaseApiParams
  ) => BaseApi.put('pageContent/AS', { autoSaveInfo }, params),
  delete: (PageID: string, PageContentID: string, params: BaseApiParams) =>
    BaseApi.delete(`pageContent/${PageID}/${PageContentID}`, params),
};

export default pageContentApi;
