import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { FwContextMenu } from 'components/base';
import { getContextMenuItems } from 'components/base/containers/mask/FwMask.helpers';
import { Process } from 'core/model';
import { useMobileDoubleClick } from 'core/utils/useMobileDoubleClick';

const EventWrapper = ({
  event: { key },
  children,
  processes,
  handleOpen,
  handleProcessActionClick,
  t,
}: {
  event: { key: string };
  children: ReactNode;
  processes: Process[];
  handleOpen: (key: string, newTab?: boolean) => void;
  handleProcessActionClick: (batchID: string, rowKey: string) => void;
  t: TFunction;
}) => {
  const onTouchStart = useMobileDoubleClick(() => handleOpen(key));

  // define context menu items
  const contextItems = getContextMenuItems(
    t,
    () => handleOpen(key),
    () => handleOpen(key, true),
    processes,
    (processId) => handleProcessActionClick(processId, key)
  );

  return (
    <FwContextMenu renderTag="div" childrenRenderTag="div" items={contextItems}>
      <div onTouchStart={onTouchStart}>{children}</div>
    </FwContextMenu>
  );
};

export default EventWrapper;
