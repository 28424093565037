import { TFunction } from 'i18next';

import { Option } from 'core/model';
import { FIELD_TYPE, OPERATOR } from 'core/utils/constant';

const {
  address,
  autocomplete,
  checkbox,
  date,
  datetime,
  decimal,
  editselect,
  label,
  link,
  multiselect,
  number,
  reference,
  searcheditselect,
  searchselect,
  select,
  text,
  textarea,
  time,
} = FIELD_TYPE;

const CommonTypes = [
  address,
  autocomplete,
  decimal,
  label,
  link,
  number,
  reference,
  text,
  textarea,
];
const SelectTypes = [
  editselect,
  // multiselect,
  searcheditselect,
  searchselect,
  select,
];

const Operators = [
  {
    text: 'Contains',
    value: OPERATOR.contains,
    types: [multiselect, ...CommonTypes],
  },
  {
    text: 'Does not contain',
    value: OPERATOR.notContains,
    types: [multiselect, ...CommonTypes],
  },
  {
    text: 'Equals',
    value: OPERATOR.equals,
    types: [checkbox, ...SelectTypes, ...CommonTypes],
  },
  {
    text: 'Does not equal',
    value: OPERATOR.notEquals,
    types: [checkbox, ...SelectTypes, ...CommonTypes],
  },
  {
    text: 'Between',
    value: OPERATOR.between,
    types: [date, datetime, time /* decimal, number */],
  },
  {
    text: 'Starts with',
    value: OPERATOR.startsWith,
    types: CommonTypes,
  },
  {
    text: 'Ends with',
    value: OPERATOR.endsWith,
    types: CommonTypes,
  },
  {
    text: 'In',
    value: OPERATOR.in,
    types: [select, searchselect],
  },
];

const GetOperatorOptionsByType = (t: TFunction, type: string): Option[] =>
  type && Operators.some((o) => o.types.some((ot) => ot === type))
    ? Operators.filter((o) => o.types.some((ot) => ot === type)).map(
        ({ text, value }) =>
          new Option({ key: value, text: t(`${text}`), value })
      )
    : [];

export { GetOperatorOptionsByType };
