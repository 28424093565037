import { HubConnection } from '@microsoft/signalr';

import BaseHub, { CloseHandler } from 'api/baseHub';

const events = {
  addUser: 'AddUser',
  acknowledged: 'Acknowledged',
  updated: 'Updated',
  removeConnection: 'RemoveConnection',
};

const acknowledgeUser = (
  connection: HubConnection,
  channel: string,
  connectionId: unknown
) => {
  connection
    .invoke('AcknowledgeUser', channel, connectionId)
    .catch((err: Error) => console.error(err));
};

class DocumentEditionHub extends BaseHub {
  constructor(onCloseHandler: CloseHandler, channel: string) {
    super('DocumentEditionHub', onCloseHandler, channel);

    this.addHandlers = ({
      addUserHandler,
      acknowledgedHandler,
      updatedHandler,
      removeConnectionHandler,
    }) => {
      const { addUser, acknowledged, updated, removeConnection } = events;

      this.addHandler(addUser, (connectionId, username) => {
        addUserHandler(connectionId, username);
        acknowledgeUser(this.connection, channel, connectionId);
      });
      this.addHandler(acknowledged, acknowledgedHandler);
      this.addHandler(updated, updatedHandler);
      this.addHandler(removeConnection, removeConnectionHandler);
    };
  }
}

export default DocumentEditionHub;
