import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FwTitle } from 'components/base';
import { FwGridProps, fwGridPT, FwGridItemProps } from 'core/model';
import { getRenderedItems } from 'core/utils/logic';

import { spannablesToNoSpanGrid } from './FwGrid.helpers';
import FwGridItem from './FwGrid.Item';
import NestedGrid from './FwGrid.NestedGrid';

const FwGrid: FC<FwGridProps> & { Item: FC<FwGridItemProps> } = ({
  items = [],
  title,
  ...props
}) => {
  const noSpanGrid = spannablesToNoSpanGrid(getRenderedItems(items));

  return (
    <Flex direction="column">
      <FwTitle>{title}</FwTitle>
      <NestedGrid grid={noSpanGrid} {...props} />
    </Flex>
  );
};

FwGrid.propTypes = fwGridPT;

FwGrid.Item = FwGridItem;

export default FwGrid;
