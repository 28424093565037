import { ColorModeContextType, StyleProps } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';

import { getFallbackColor, getValidColor } from 'config/theme/colors';
import { StylePT } from 'core/model';

const mapStyleToChakra = (
  style: StylePT,
  colorModeContext: ColorModeContextType | StyleFunctionProps,
  withHover = false
): {
  backgroundColor: StyleProps['backgroundColor'];
  borderColor: StyleProps['borderColor'];
  color: StyleProps['color'];
  _hover?: {
    backgroundColor: StyleProps['backgroundColor'];
  };
} => {
  const bgColor = style?.backgroundColor
    ? getValidColor(style?.backgroundColor, colorModeContext)
    : undefined;

  const chakraProps = {
    backgroundColor: bgColor,
    borderColor: getValidColor(style?.borderColor, colorModeContext),
    color: getValidColor(style?.color, colorModeContext),
  };

  return withHover
    ? { ...chakraProps, _hover: { backgroundColor: getFallbackColor(bgColor) } }
    : chakraProps;
};

const mapStyleToLeaflet = (
  style: StylePT
): {
  fillColor: StyleProps['backgroundColor'];
  color: StyleProps['borderColor'];
  labelColor: StyleProps['color'];
} => {
  // use getValidColor validation by initializing colorModeContext has undefined
  const leafletProps = {
    fillColor: getValidColor(style?.backgroundColor, undefined),
    color: getValidColor(style?.borderColor, undefined),
    labelColor: getValidColor(style?.color, undefined),
  };
  return leafletProps;
};

export { mapStyleToChakra, mapStyleToLeaflet };
