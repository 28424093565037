import { TouchEventHandler, useRef } from 'react';

// temporary fix, since React 18 is not firing double clicks within iOS
// useful resources:
// https://github.com/facebook/react/issues/25308
// https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues
// see #811
const useMobileDoubleClick = (onDoubleClick) => {
  // keep info whether target was touched in the last 500 ms
  const touchRef = useRef(false);

  const onTouchStart: TouchEventHandler<HTMLTableRowElement> = () => {
    if (touchRef.current) {
      // if target is marked as touched
      // detect a double touch and fire double click
      onDoubleClick();
    }

    // mark target as touched
    touchRef.current = true;

    // mark target as untouched after 500 ms
    setTimeout(() => {
      touchRef.current = false;
    }, 500);
  };

  return onTouchStart;
};

export { useMobileDoubleClick };
