import _ from 'lodash';
import { useState, useEffect, MutableRefObject } from 'react';

import { useInputForm } from 'components';
import { useFwStore } from 'components/base';
import { getDocData, getVisibleElements } from 'components/doc/helpers';
import { getEntityDoc } from 'components/entity-info/helpers';
import { mapInputToField } from 'core/mapper';
import { Input } from 'core/model';
import utils, { DataProps } from 'core/utils/utils';

const useInputFormContainer = (
  useTracker: boolean,
  saved: boolean,
  data: DataProps | unknown,
  inputs: Input[],
  handleChange?: (
    fieldKey: string,
    value: string | object,
    docDataRef: MutableRefObject<DataProps>
  ) => void
) => {
  const store = useFwStore();

  const entityDoc = getEntityDoc(data, inputs);
  const docData = getDocData(entityDoc, store);
  const visibleElements = getVisibleElements(entityDoc, docData, store);

  const [exists, setExists] = useState(false);
  const [prevData, setPrevData] = useState(docData);

  // receive response from api call
  useEffect(() => {
    if (useTracker) {
      setExists(true);
      setPrevData(newDocData);
    }
  }, [useTracker]);

  const {
    docData: newDocData,
    visibleElements: newVisibleElements,
    onInputFormChange,
  } = useInputForm(
    useTracker,
    exists,
    saved,
    entityDoc,
    docData,
    docData,
    visibleElements,
    undefined,
    prevData,
    handleChange
  );

  // get input for render
  const visibleInputs = utils.getVisibleInputsFromSteps(
    newVisibleElements.steps,
    true
  );

  const inputProps = {
    docData: newDocData,
    onChange: onInputFormChange,
  };

  return {
    fields: _.map(visibleInputs, (input) => mapInputToField(input, inputProps)),
  };
};

export default useInputFormContainer;
