import { dtoCtor, Dto } from '../helper';
import { Design } from './design';
import { PageContent } from './pageContent';

class Step implements Dto {
  dtoName?: string;

  constructor(props?: Omit<Step, 'dtoName'>) {
    dtoCtor(this, 'Step', props);
  }

  stepID?: string;
  position: number;
  name?: string;
  design?: Design;
  contents?: PageContent[];
}

export { Step };
