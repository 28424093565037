import { bool, InferProps, number, string } from 'prop-types';

import { ctor } from '../helper';
import { stylePT } from './common';

const fwIframePT = {
  ...stylePT,
  compact: bool,
  url: string,
  height: number,
};

type FwIframePT = InferProps<typeof fwIframePT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwIframeProps extends FwIframePT {}
class FwIframeProps implements FwIframePT {
  constructor(props?: FwIframePT) {
    ctor(this, props);
  }
}

export { fwIframePT, FwIframeProps };
