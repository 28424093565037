import { Box, Flex, SlideFade } from '@chakra-ui/react';
import { InferProps, node, string } from 'prop-types';
import React, { FC } from 'react';

import { FwMessage, FwSegment } from 'components/base';
import {
  ModalMessage,
  ModalButton,
  getModalMessageProps,
  isRenderModalMessage,
} from 'components/base/elements/modal/components';
import { FwFilterSharedPT, FwModalSharedPT } from 'core/model';
import { PLACEMENT_TYPES } from 'core/utils/constants';
import { useDrawerModal } from 'core/utils/useDrawerModal';

const directionByPlacement = {
  [PLACEMENT_TYPES.right]: 'row-reverse' as const,
  [PLACEMENT_TYPES.left]: 'row' as const,
  [PLACEMENT_TYPES.bottom]: 'column-reverse' as const,
  [PLACEMENT_TYPES.top]: 'column' as const,
};

const offsetByPlacement = {
  [PLACEMENT_TYPES.right]: { offsetX: '20px' },
  [PLACEMENT_TYPES.left]: { offsetX: '-20px' },
  [PLACEMENT_TYPES.bottom]: { offsetY: '20px' },
  [PLACEMENT_TYPES.top]: { offsetY: '-20px' },
};

const FwDrawer: FC<Props> = (props) => {
  const {
    cancelName,
    children,
    name,
    open,
    placement,
    submitName,
    onCancel,
    onChangeData,
    onSubmit,
  } = props;

  const { modalButtonProps, modalMesageExtendProps } = useDrawerModal({
    open,
    onCancel,
    onChangeData,
    onSubmit,
  });

  return (
    <Flex flexDir={directionByPlacement[placement]}>
      {open && (
        <FwSegment>
          <SlideFade in={open} {...offsetByPlacement[placement]}>
            <FwMessage subtle message={name} />
            {isRenderModalMessage(props) && (
              <ModalMessage
                {...getModalMessageProps(props)}
                {...modalMesageExtendProps}
              />
            )}
            <ModalButton
              {...modalButtonProps}
              cancelName={cancelName}
              submitName={submitName}
            />
          </SlideFade>
        </FwSegment>
      )}
      <Box
        width="100%"
        overflow={placement === PLACEMENT_TYPES.right ? 'hidden' : undefined}
      >
        {children}
      </Box>
    </Flex>
  );
};

const propTypes = {
  children: node,
  process: string,
};

type FwDrawerPT = InferProps<typeof propTypes> &
  FwModalSharedPT &
  FwFilterSharedPT;

export interface Props extends FwDrawerPT {
  placement?: (typeof PLACEMENT_TYPES)[keyof typeof PLACEMENT_TYPES];
}

FwDrawer.propTypes = propTypes;

export default FwDrawer;
