import BaseHub, { CloseHandler } from 'api/baseHub';

const events = {
  updated: 'Updated',
};

class TableHub extends BaseHub {
  constructor(onCloseHandler: CloseHandler, channel: string) {
    super('TableHub', onCloseHandler, channel);

    this.addHandlers = ({ updatedHandler }) => {
      const { updated } = events;

      this.addHandler(updated, updatedHandler);
    };
  }
}

export default TableHub;
