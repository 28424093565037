import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AppUser } from 'core/model';

const userApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ appUsers: AppUser[] }>(`appuser`, params),
  get: (email: string, params: BaseApiParams) =>
    BaseApi.get<{ appUser: AppUser }>(`appuser/${email}`, params),
  getCurrent: (params: BaseApiParams) =>
    BaseApi.get<{ appUser: AppUser }>(`appuser/self`, params),
  //create: user => ApiService.post(`user`, user),
  //put: (user, params) => BaseApi.put(`appuser`, { appUser: user }, params),
  //deleteField: (userId, fieldId) => delete(`user/${userId}/field/${fieldId}`),
};

export default userApi;
