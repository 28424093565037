import { Box, VStack } from '@chakra-ui/react';
import { bool, InferProps, node } from 'prop-types';
import React, { FC } from 'react';

import { FwSegment } from 'components/base';
import { defaultSpacing } from 'config/theme/constants';

import FwFooter from './footer/FwFooter';

const FwPancake: FC<Props> = ({ children, header, noFooter }) => {
  const containerStyle = {
    h: '100%',
    align: 'stretch',
    flex: 1,
    spacing: 0,
    overflow: 'hidden auto',
    overscrollBehavior: 'contain',
    sx: { '& header': { mb: 'auto' }, '& footer': { mt: 'auto !important' } },
  };

  const contentStyle = {
    flex: 1,
    p: defaultSpacing,
  };

  return (
    <VStack {...containerStyle}>
      {header && (
        <FwSegment as="header" basic>
          {header}
        </FwSegment>
      )}
      <Box {...contentStyle}>{children}</Box>
      {!noFooter && <FwFooter />}
    </VStack>
  );
};

const propTypes = {
  children: node,
  header: node,
  noFooter: bool,
};

export type Props = InferProps<typeof propTypes>;

FwPancake.propTypes = propTypes;

export default FwPancake;
