import { useColorMode, useTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import chroma from 'chroma-js';
import _ from 'lodash';

import { fwAccent } from 'config/theme/constants';

const useFwTheme = (bgOpacity?: number) => {
  const themeObject = useTheme();
  const colorModeContext = useColorMode();

  const colorsDict = _.merge(
    themeObject.colors,
    themeObject.semanticTokens?.colors
  );

  // get theme colors directly from Chakra UI
  const { global } = themeObject['styles'];
  const { bg: bgPropName, color: colorPropName } =
    global(colorModeContext).body;

  const bgObject = colorsDict[bgPropName];
  const colorObject = colorsDict[colorPropName];

  // refactor use of mode and transparentize utilities
  const fwMode = (light: string, dark: string) =>
    mode(light, dark)(colorModeContext);

  const bgRaw = fwMode(bgObject._light, bgObject._dark);
  const bg =
    !chroma.valid(bgRaw) && chroma.valid(_.get(colorsDict, bgRaw))
      ? _.get(colorsDict, bgRaw)
      : bgRaw;

  const colorRaw = fwMode(colorObject._light, colorObject._dark);
  const color =
    !chroma.valid(colorRaw) && chroma.valid(_.get(colorsDict, colorRaw))
      ? _.get(colorsDict, colorRaw)
      : colorRaw;

  const fwTransparentize = (color: string, opacity: number) => {
    return chroma.valid(color) ? chroma(color).alpha(opacity).css() : color;
  };

  // add opacity to background color
  const newBg: string = bgOpacity ? `${fwTransparentize(bg, bgOpacity)}` : bg;

  const boxShadowColor = fwMode(
    'var(--chakra-colors-blackAlpha-200)',
    'var(--chakra-colors-whiteAlpha-200)'
  );

  return {
    bg: newBg,
    color,
    accent: fwAccent,
    borderColor: fwMode(
      'var(--chakra-colors-gray-100)',
      'var(--chakra-colors-gray-700)'
    ),
    boxShadow: fwMode(
      'inset 1px 0 0 var(--chakra-colors-blackAlpha-400)',
      'inset 1px 0 0 var(--chakra-colors-whiteAlpha-400)'
    ),
    _dark: {
      bg: bgObject._dark,
      color: colorObject._dark,
    },
    _light: {
      bg: bgObject._light,
      color: colorObject._light,
    },
    _disabled: {
      bg: fwMode('rgba(0, 0, 0, 0.02)', 'var(--chakra-colors-whiteAlpha-50)'),
      boxShadow: `inset 1px 0 0 ${boxShadowColor}`,
      boxShadowColor,
      color: fwMode(
        'var(--chakra-colors-blackAlpha-600)',
        'var(--chakra-colors-whiteAlpha-600)'
      ),
      _hover: {
        bg: fwMode(
          'rgba(0, 0, 0, 0.05)',
          'var(--chakra-colors-whiteAlpha-200)'
        ),
        color: fwMode(
          'var(--chakra-colors-gray-600)',
          'var(--chakra-colors-gray-300)'
        ),
      },
    },
    _hover: {
      bg: fwMode(
        fwTransparentize(_.get(colorsDict, `${fwAccent}.50`), 0.9),
        fwTransparentize(_.get(colorsDict, `${fwAccent}.200`), 0.1)
      ),
    },
    // active must come after _hover to be able to override it
    _active: {
      bg: fwMode(
        fwTransparentize(_.get(colorsDict, `${fwAccent}.100`), 0.75),
        fwTransparentize(_.get(colorsDict, `${fwAccent}.200`), 0.25)
      ),
      boxShadow: `var(--chakra-colors-${fwAccent}) 0.17rem 0px 0px -0.02rem inset !important`,
    },
  };
};

export { useFwTheme };
