import { useContext } from 'react';

import { FwAreaContext } from './FwAreaContext';
import { areaIsMain, getAreaBasePath } from './helper';

const useFwArea = () => {
  const areaContext = useContext(FwAreaContext);

  return { ...areaContext, areaIsMain, getAreaBasePath };
};

export { useFwArea };
