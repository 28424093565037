import { InferProps, node } from 'prop-types';
import React, { FC, useState } from 'react';
// import utils from 'core/utils/utils';

import {
  PageContentStore,
  FwPageContentStoreContext,
} from './FwPageContentStoreContext';

const FwPageContentStoreProvider: FC<Props> = ({ children }) => {
  const [pageContentStore, setPageContentStore] = useState<PageContentStore>(
    {}
  );

  return (
    <FwPageContentStoreContext.Provider
      value={{
        pageContentStore,
        setPageContentStore,
      }}
    >
      {children}
    </FwPageContentStoreContext.Provider>
  );
};

const propTypes = { children: node };

type Props = InferProps<typeof propTypes>;

FwPageContentStoreProvider.propTypes = propTypes;

export { FwPageContentStoreProvider };
