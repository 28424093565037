import {
  any,
  arrayOf,
  bool,
  func,
  InferProps,
  instanceOf,
  node,
  oneOfType,
} from 'prop-types';

import { ctor } from '../helper';
import { commonPT, sizePT } from './common';

import { FwCategoryProps, FwItemProps } from '.';

const fwListPT = {
  ...commonPT,
  ...sizePT,
  as: any,
  children: node,
  fluid: bool,
  items: arrayOf(
    oneOfType([instanceOf(FwCategoryProps), instanceOf(FwItemProps)])
  ),
  onChange: func,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwListPT extends InferProps<typeof fwListPT> {
  // onChange?: ;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwListProps extends FwListPT {}
class FwListProps implements FwListPT {
  constructor(props?: FwListPT) {
    ctor(this, props);
  }
}

export { fwListPT, FwListProps };
