import _ from 'lodash';

import { SYSTEM_FIELDS } from 'core/utils/constant';

export const fieldKeysToDataValues = (fullData: any, headerText) => {
  const headerList = [];

  if (
    fullData[SYSTEM_FIELDS.number] &&
    fullData[SYSTEM_FIELDS.number] !== '0'
  ) {
    if (headerText) {
      _.forEach(headerText, (fieldKey) => {
        if (fullData[fieldKey]) {
          headerList.push(fullData[fieldKey]);
        }
      });
    } else {
      headerList.push(`${fullData[SYSTEM_FIELDS.number]}`);
    }
  }

  return headerList;
};
