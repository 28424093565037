import _ from 'lodash';
import React /*, { Fragment, useCallback }*/ from 'react';
import { useTranslation } from 'react-i18next';

import { FwField, FwGrid, FwSegment } from 'components/base';
import { mapInputToField } from 'core/mapper';
// import { mapInputToProps } from 'core/utils/mapper';
// import utils from 'core/utils/utils';

// import BaseGrid from '../../../grid/BaseGrid';
// import Input from '../input/Input';
// import SectionGrid from './components/SectionGrid';

const Section = ({
  section,
  docData,
  invalidInputKey,
  loadingInputKeys,
  style,
  onChangeData,
  onInputBlur,
}: any) => {
  const { t } = useTranslation();

  const { pageContentID, name, key, fields, visible } = section;
  // const sectionFields = utils.filterExistent(fields);

  // const inputKeyGetter = useCallback((input) => input.fieldID, []);

  // const inputRenderer = useCallback(
  //   (input) => (
  //     <Input
  //       {...mapInputToProps(input, docData, invalidInputKey, loadingInputKeys)}
  //       onChange={onChangeData}
  //       onInputBlur={onInputBlur}
  //     />
  //   ),
  //   [docData, invalidInputKey, loadingInputKeys, onChangeData, onInputBlur]
  // );

  const inputProps = {
    docData,
    invalidInputKey,
    loadingInputKeys,
    onBlur: onInputBlur,
    onChange: onChangeData,
  };

  return !_.isNil(visible) ? (
    <FwSegment
      {...style}
      as={FwGrid}
      basic
      key={pageContentID || key}
      name={t(`custom|${name}`)}
    >
      <FwGrid
        items={_.map(fields, (input) => mapInputToField(input, inputProps))}
        itemComponent={FwField}
      />
      {/* <BaseGrid
        grid={SectionGrid}
        items={sectionFields}
        itemKeyGetter={inputKeyGetter}
        itemRenderer={inputRenderer}
        visible={visible}
      /> */}
    </FwSegment>
  ) : null;
};

export default Section;
