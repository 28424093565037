import _ from 'lodash';
import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';

import { FwPanel, FwSegment } from 'components/base';
import { BUTTON_TYPE } from 'core/utils/constant';
import { sortByStepRowColumnKey } from 'core/utils/sort';

// import ThemedSegment from '../../theme/ThemedSegment';
import DocumentButton from './documentButtonContainer'; /* todo */

const { save, submit } = BUTTON_TYPE;

// const StyledSegment = styled(ThemedSegment).attrs({
//   textAlign: 'center',
// })`
//   &&& {
//     margin-bottom: 0.5rem;
//     ${({ frosted }) => (frosted ? `margin-top: -5px;` : '')}

//     // Fix margin issue in Semantic UI, margin is defined in em unit
//     // Thus does not apply on "display: flex" elements such as button group
//     > .ui.buttons {
//       margin-right: 3.5px;
//     }
//   }
// `;

const ClickablesSegment = ({
  autosave,
  publicly,
  actionable,
  viewersWarning,
  clickables,
  onButtonClick,
  setStickyFunc,
  showStickyFunc,
}: any) => {
  const [buttons, setButtons] = useState<any>();

  useEffect(() => {
    // build buttons
    setButtons(
      _.map(sortByStepRowColumnKey(clickables), (c) => (
        <DocumentButton
          autosave={autosave}
          key={c.clickableId}
          publicly={publicly}
          actionable={actionable}
          viewersWarning={
            _.includes([save, submit], c.type) && viewersWarning
              ? true
              : undefined
          }
          clickable={c}
          onButtonClick={onButtonClick}
        />
      ))
    );
  }, [publicly, actionable, viewersWarning, clickables, onButtonClick]);

  useEffect(() => {
    // set in sticky component
    if (buttons && setStickyFunc) {
      // setStickyFunc(() => <FwSegment frosted>{buttons}</FwSegment>);
    }
  }, [buttons, setStickyFunc]);

  useEffect(() => {
    // remove sticky component when unmount
    return () => {
      // setStickyFunc();
      // showStickyFunc(false);
    };
  }, [setStickyFunc, showStickyFunc]);

  return (
    <FwSegment frosted sticky>
      <FwPanel center>{buttons}</FwPanel>
    </FwSegment>
  );
};

export default React.memo(ClickablesSegment);
