import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, Language } from 'core/model';

const languageApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ languages: Language[] }>('language', params),
  get: (key: string, params: BaseApiParams) =>
    BaseApi.get<{ language: Language }>(`language/${key}`, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<Language, string>,
    params: BaseApiParams
  ) => BaseApi.put('language/AS', { autoSaveInfo }, params),
  post: (language: Language, params: BaseApiParams) =>
    BaseApi.post('language', language, params),
};

export default languageApi;
