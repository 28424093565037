import { InferProps, string } from 'prop-types';

import { ctor } from '../helper';

const areaRoutesPT = {
  areaKey: string,
  basePath: string,
};

type AreaRoutesPT = InferProps<typeof areaRoutesPT>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AreaRoutesProps extends AreaRoutesPT {}
class AreaRoutesProps implements AreaRoutesPT {
  constructor(props?: AreaRoutesPT) {
    ctor(this, props);
  }
}

export { areaRoutesPT, AreaRoutesProps };
