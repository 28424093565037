import { InferProps, string } from 'prop-types';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import UIRenderer from './components/UIRenderer';

const FwMarkdown: FC<Props> = ({
  // common input props
  children,
}) => {
  return (
    <ReactMarkdown components={UIRenderer()} rehypePlugins={[rehypeRaw]}>
      {children}
    </ReactMarkdown>
  );
};

const propTypes = {
  children: string,
};

export type Props = InferProps<typeof propTypes>;

FwMarkdown.propTypes = propTypes;

export default FwMarkdown;
