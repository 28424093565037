import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AutoSaveInfo, Page } from 'core/model';

const pageApi = {
  getAll: (params: BaseApiParams) =>
    BaseApi.get<{ pages: Page[] }>('page', params),
  getNew: (params: BaseApiParams) =>
    BaseApi.get<{ page: Page }>('page/new', params),
  get: (id: string, params: BaseApiParams) =>
    BaseApi.get<{ page: Page }>(`page/${id}`, params),
  getByModuleAndArea: (module: string, area: string, params: BaseApiParams) =>
    BaseApi.get<{ pages: Page[] }>(
      `page/module/${module}${area ? `/${area}` : ''}`,
      params
    ),
  post: (page: Page, params: BaseApiParams) =>
    BaseApi.post('page', page, params),
  putAS: (autoSaveInfo: AutoSaveInfo<Page, string>, params: BaseApiParams) =>
    BaseApi.put('page/AS', { autoSaveInfo }, params),
};

export default pageApi;
