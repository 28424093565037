import React, { FC } from 'react';

import { FwButton, FwParagraph, FwPop } from 'components/base/elements';

import FwArticle from '../../article/FwArticle';
import { FwHelpBoxProps, fwHelpBoxPT } from './FwHelpBox.props';
import FwOverlayBox from './FwOverlayBox';

const FwHelpBox: FC<FwHelpBoxProps> = ({ btnText, heading, children }) => {
  return (
    <FwOverlayBox p="10px" bottom="0px">
      <FwPop>
        <FwPop.Trigger>
          <FwButton leftIcon="RiQuestionLine">{btnText}</FwButton>
        </FwPop.Trigger>
        <FwPop.Content>
          <FwParagraph heading>{heading}</FwParagraph>
          <FwArticle small content={children} />
        </FwPop.Content>
      </FwPop>
    </FwOverlayBox>
  );
};

FwHelpBox.propTypes = fwHelpBoxPT;

export default FwHelpBox;
