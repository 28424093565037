import { useEffect, useState } from 'react';

import { PageContentStore, useFwPageContentStore } from '../pageContentStore';
import { PageStore, useFwPageStore } from '../pageStore';
import { ModuleStore } from './FwModuleStoreContext';
import { useFwModuleStore } from './useFwModuleStore';

type FwStore = {
  moduleStore: ModuleStore;
  pageStore: PageStore;
  pageContentStore: PageContentStore;
};

const useFwStore = (): FwStore => {
  const { moduleStore } = useFwModuleStore() || {};
  const { pageStore } = useFwPageStore() || {};
  const { pageContentStore } = useFwPageContentStore() || {};

  const [store, setStore] = useState({
    moduleStore,
    pageStore,
    pageContentStore,
  });

  useEffect(
    () => setStore((prevStore) => ({ ...prevStore, moduleStore })),
    [moduleStore]
  );

  useEffect(
    () => setStore((prevStore) => ({ ...prevStore, pageStore })),
    [pageStore]
  );

  useEffect(
    () => setStore((prevStore) => ({ ...prevStore, pageContentStore })),
    [pageContentStore]
  );

  return store;
};

export { FwStore, useFwStore };
