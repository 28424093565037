import _ from 'lodash';

interface RowColumn {
  row: number;
  column: number;
}

export interface Spannable extends RowColumn {
  rowSpan: number;
  columnSpan: number;
}

// no span grid defines a structure of nested (no span) grids
// to render exactly like a single grid with spannable items
// (spannable = defined by 4 properties: row, row span, column, column span)
// in a no span grid, items can be freely arranged by this grid css template
export type NoSpanGrid<T extends Spannable = Spannable> = {
  rows: NoSpanRow<T>[];
};

// no span row is the first element in the structure of no span grids
// and it only contains single elements: re-arranged original items
// or further nested no span grids
export type NoSpanRow<T extends Spannable = Spannable> = {
  items: NoSpanItem<T>[];
};

export type NoSpanItem<T extends Spannable = Spannable> = T | NoSpanGrid<T>;

const buildGridFromItems = <T extends Spannable>(
  itemArrays: NoSpanItem<T>[][]
): NoSpanGrid<T> => {
  const grid: NoSpanGrid<T> = {
    rows: _.map(itemArrays, (items) => ({
      items,
    })),
  };

  return grid;
};

// arrange a 1 dimension array of items into an array of groups
// each group is formed by a common set of characteristics
// for no span grids, a row subset will look like
// "all items with row + row span between 1 and 3"
const buildSubsets = <T extends Spannable>(
  characteristics: { base: string; span: string }[],
  spannables: T[]
) => {
  const subsets = [];

  // define how subsets must be built (base and span property names)
  // in this execution, the rest of characteristics will be passed
  // to a nested execution
  const { base, span } = characteristics?.shift();

  // clone collection to track which element was grouped in subset
  // by recursively removing them from the unprocessed list
  const unprocessed = _.filter(
    _.cloneDeep(spannables),
    (s) => !_.isNil(s.row) && !_.isNil(s.column)
  );

  while (_.some(unprocessed)) {
    // define characteristics of first available subset in unprocessed list
    const minNumber = _.min(_.map(unprocessed, (spannable) => spannable[base]));
    const maxSpan = _.max(
      _.map(
        _.filter(unprocessed, (spannable) => spannable[base] === minNumber),
        (spannable) => spannable[base] + (spannable[span] || 1) - 1
      )
    );

    // group unprocessed elements belonging to this subset
    const firstSubSet = _.filter(
      unprocessed,
      (spannable) => spannable[base] >= minNumber && spannable[base] <= maxSpan
    );

    // remove grouped elements in this subset from unprocessed elements tracker
    _.remove(unprocessed, (spannable) => _.includes(firstSubSet, spannable));

    // a subset is fully characterized when all its elements are arranged
    const characterizedSubset = !_.isEmpty(characteristics)
      ? /* pass remaining characteristics for further nested subsets */
        buildSubsets(_.cloneDeep(characteristics), firstSubSet)
      : /* there is no remaining characteristic, subset is fully characterized */
        firstSubSet;

    // turn nested subsets into single grids to finish processing of subset
    const processedSubset =
      characterizedSubset.length === 1 &&
      characterizedSubset[0].length === spannables.length
        ? /* spannables array was irreducible with specified set of characteristics */
          characterizedSubset[0]
        : /* further characterization was impossible, wrap all items in grid */
        !_.isEmpty(characteristics) &&
          characterizedSubset.length > 1 &&
          !_.some(characterizedSubset, (z) => _.isArray(z))
        ? [buildGridFromItems(_.map(characterizedSubset, (s) => [s]))]
        : _.map(characterizedSubset, (z) =>
            _.isArray(z)
              ? z.length > 1
                ? /* arrange nested subsets into nested grids */
                  spannablesToNoSpanGrid(z)
                : /* return only element in subset */
                  z[0]
              : /* return only element composing subset */
                z
          );

    if (
      _.isEmpty(characteristics) &&
      firstSubSet.length === spannables.length &&
      !_.some(firstSubSet, (z) => z[base] !== minNumber)
    ) {
      // characterization was impossible, prevent conversion to nested grid
      // (grid nesting would lead to infinite loop as characterization is impossible)
      subsets.push(...processedSubset);
    } else {
      // add subset to list of fully-processed subsets
      subsets.push(processedSubset);
    }
  }

  return subsets;
};

// turn 1-dimensionnal array of spannable items into no span grid
const spannablesToNoSpanGrid = <T extends Spannable>(
  spannables: T[]
): NoSpanGrid<T> => {
  // define rows as nested subsets of grid
  // and columns as nested subsets of rows
  const rowThenColumn = [
    { base: 'row', span: 'rowSpan' },
    { base: 'column', span: 'columnSpan' },
  ];

  // arrange spannables in nested grids
  const arrayOfItemsArray = buildSubsets(rowThenColumn, spannables);

  // contain rows of nested grids in a single containing grid
  return buildGridFromItems(arrayOfItemsArray);
};

export { spannablesToNoSpanGrid };
