import axios, {
  AxiosPromise,
  CancelToken,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import _ from 'lodash';

import {
  IDXDDB_STORES,
  addOrUpdateToIdxdDB,
  addToIdxdDB,
  deleteAllFromIdxdDB,
  deleteFromIdxdDB,
  getAllFromIdxdDB,
} from 'core/utils/idxdDB';
import Log from 'core/utils/log';
import { clearStorageAndRedirect, getToken } from 'core/utils/storage';

import externalApi from './external/externalApi';

enum HTTP_METHODS {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export type RouteCacheMap = {
  origin: string;
  target: string;
  params: RequestInit;
};

export type CacheRoute = string | RouteCacheMap;

export type BackupTarget = {
  url: string;
  proxy: boolean;
};

export type BackupEndpoint = BackupTarget | string;

export type CacheFileContent = {
  publicRoutes: CacheRoute[];
  authedRoutes: CacheRoute[];
  backup?: BackupEndpoint;
};

const getUrl = window.location;
const baseUrl = getUrl.protocol + '//' + getUrl.host + '/api/';

export type BaseApiParams = {
  cancelToken?: CancelToken;
  validErrors?: number[];
  localMode?: boolean;
} & AxiosRequestConfig;

export const showApiError = async (error: Partial<AxiosError>) => {
  const details = (error?.response?.data as { details: string })?.details;

  if (details) {
    console.error(details);
  }
};

export const validateStatusHelper = (
  status: number | null | undefined,
  validErrors?: number[]
) =>
  !_.isNil(status) &&
  ((200 <= status && status < 300) ||
    (validErrors && validErrors.includes(status)));

const processLocalModeParam = <D>(
  params: BaseApiParams,
  httpMethod: `${HTTP_METHODS}`,
  url: string,
  data: D
) => {
  if (params.localMode) {
    delete params.localMode;

    // save in indexedDB for later synchronization
    addToIdxdDB(IDXDDB_STORES.request, {
      method: httpMethod,
      url,
      data: _.cloneDeep(data),
    });
  }
};

class BaseApi {
  external: boolean;
  instance = axios.create();

  constructor(external = false) {
    this.external = external;

    if (!this.external) {
      this.instance.defaults.baseURL = baseUrl;
    }

    this.instance.interceptors.request.use(
      (config) => {
        if (!this.external) {
          const token = getToken();

          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } else {
          // todo investigate config for CORS
          //config.headers.common['Access-Control-Allow-Origin'] = '*';
          //config.headers.common['Access-Control-Allow-Methods'] =
          //  'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        showApiError(error);

        if (!this.external && error.response?.status === 401) {
          // log out unauthorized user
          clearStorageAndRedirect('');
        }

        return Promise.reject(error);
      }
    );
  }

  base =
    (func: string) =>
    <D>(url: string, params: BaseApiParams, data?: D) => {
      const extra = {
        ...params,
        validateStatus: (status: number) =>
          validateStatusHelper(status, params.validErrors),
      };

      return this.instance[func](..._.compact([url, data, extra])).catch(
        this.handleError
      );
    };

  get = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('get')(url, params);

  post = <D, R>(
    url: string,
    data: D,
    params = {} as BaseApiParams
  ): AxiosPromise<R> => {
    processLocalModeParam(params, HTTP_METHODS.post, url, data);
    return this.base('post')(url, params, data);
  };

  put = <D, R>(
    url: string,
    data: D,
    params = {} as BaseApiParams
  ): AxiosPromise<R> => {
    processLocalModeParam(params, HTTP_METHODS.put, url, data);
    return this.base('put')(url, params, data);
  };

  delete = <R>(url: string, params = {}): AxiosPromise<R> =>
    this.base('delete')(url, params);

  synchronize = (
    callback?: (success: boolean) => void,
    backupEndpoint?: BackupEndpoint
  ) => {
    // asynchronously get all pending requests from indexedDB
    getAllFromIdxdDB(
      IDXDDB_STORES.request,
      this.handleGetAllRequests(callback, backupEndpoint)
    );
  };

  handleGetAllRequests =
    (callback?: (success: boolean) => void, backupEndpoint?: BackupEndpoint) =>
    async (requests = []) => {
      // resend requests then execute provided callback
      const syncSuccess =
        requests?.length > 0
          ? await this.sendRequests(requests, backupEndpoint)
          : true;
      callback?.(syncSuccess);
    };

  // submit all local requests to backend server through network
  sendRequests = async (requests = [], backupEndpoint?: BackupEndpoint) => {
    let success: boolean = null;

    // store all identifiers created by first document creation POST requests
    const postIdList = [];
    const postIdMappings: Record<string, string> = {};
    const syncedRequests: { request: Request; response: AxiosResponse }[] = [];

    const noProxyBackup =
      typeof backupEndpoint === 'string' || !backupEndpoint.proxy;

    const backupResult = backupEndpoint
      ? noProxyBackup
        ? await fetch(
            typeof backupEndpoint === 'string'
              ? backupEndpoint
              : backupEndpoint.url,
            {
              method: HTTP_METHODS.post,
              body: JSON.stringify({ requests }),
            }
          )
        : await externalApi.post({
            method: HTTP_METHODS.post,
            url: backupEndpoint.url,
            body: JSON.stringify({ requests }),
          })
      : undefined;

    if (!backupEndpoint || validateStatusHelper(backupResult?.status)) {
      // resend pending queued requests
      while (requests?.length && success !== false) {
        const request = requests.shift();

        if (request.method === HTTP_METHODS.post) {
          const postIsNewDoc = !request.data?.document;

          if (!postIsNewDoc) {
            // get one id from new doc id list
            const newDocId = postIdList.shift();
            postIdMappings[request.data.document.documentID] = newDocId;

            // set id in request
            request.data.document.documentID = newDocId;
            request.data.document.number = 0;
            // need to update metadata?
          }

          const response = await this.post(request.url, request.data);

          if (validateStatusHelper(response?.status)) {
            syncedRequests.push({ request, response });

            if (postIsNewDoc) {
              // store as new doc id
              postIdList.push((response?.data as { id: string })?.id);
            }

            deleteFromIdxdDB(IDXDDB_STORES.request, request.id);
          } else {
            success = false;

            if (!postIsNewDoc) {
              const prevLinkedRequest = syncedRequests.find(
                (req) =>
                  (req.response?.data as { id: string })?.id ===
                  request.data.document.documentID
              ).request;

              if (prevLinkedRequest) {
                // add new doc request to IdxdDB
                addToIdxdDB(IDXDDB_STORES.request, prevLinkedRequest);
              }
            }
          }
        } else if (request.method === HTTP_METHODS.put) {
          const foundId = postIdMappings[request.data.document.documentID];

          // when document was created under local mode...
          if (foundId) {
            // ...replace local id by remote (correct) id...
            // ...and remove unnecessary number (set to 0) to avoid errors
            request.data.document.documentID = foundId;
            request.data.document.number = 0;
            request.data.document.metaData = {
              ...request.data.document.metaData,
              FORMID: foundId,
              NUMBER: 0,
            };
          }

          const response = await this.put(request.url, request.data);

          if (validateStatusHelper(response?.status)) {
            syncedRequests.push({ request, response });
            deleteFromIdxdDB(IDXDDB_STORES.request, request.id);
          } else {
            success = false;

            if (foundId) {
              // update request in IdxdDB with correct id
              addOrUpdateToIdxdDB(IDXDDB_STORES.request, request);
            }
          }
        }
      }

      if (success !== false) {
        deleteAllFromIdxdDB(IDXDDB_STORES.document);
        success = true;
      }
    }

    return success;
  };

  handleError = (error: { message: string }) => {
    if (axios.isCancel(error)) {
      console.error('Request canceled', error.message);
      Log.error('Request canceled', error.message);
    }
  };
}

export const BaseExternalApi = new BaseApi(true);

export default new BaseApi(false);
