import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Operation } from 'core/model';

const operationApi = {
  getManyByDocID: (id: string, params: BaseApiParams) => {
    const urlArray = ['operation'];

    if (id) {
      urlArray.push(id);
    }

    return BaseApi.get<{ operations: Operation[] }>(urlArray.join('/'), params);
  },
};

export default operationApi;
