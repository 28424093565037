import { AlertStatus } from '@chakra-ui/react';
import { InferProps, number, string } from 'prop-types';

import { ctor } from '../helper';

const fwProgressPT = { value: number, type: string };

interface FwProgressPT extends InferProps<typeof fwProgressPT> {
  type?: Exclude<AlertStatus, 'loading'>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FwProgressProps extends FwProgressPT {}
class FwProgressProps implements FwProgressPT {
  constructor(props?: FwProgressPT) {
    ctor(this, props);
  }
}

export { fwProgressPT, FwProgressProps };
