import BaseApi, { BaseApiParams } from 'api/baseApi';
import { AppPreference, AutoSaveInfo } from 'core/model';

const endpoint = 'apppreference';

const appPreferenceApi = {
  get: (params: BaseApiParams) =>
    BaseApi.get<{ appPreference: AppPreference }>(endpoint, params),
  post: (appPreference: AppPreference, params: BaseApiParams) =>
    BaseApi.post(endpoint, appPreference, params),
  putAS: (
    autoSaveInfo: AutoSaveInfo<AppPreference, number>,
    params: BaseApiParams
  ) => BaseApi.put(endpoint + '/AS', { autoSaveInfo }, params),
};

export default appPreferenceApi;
