import BaseApi, { BaseApiParams } from 'api/baseApi';
import { Filter, UserFilter } from 'core/model';

const userFilterApi = {
  get: (fieldId: string, userId: string, params: BaseApiParams) =>
    BaseApi.get<{ userFilter: UserFilter }>(
      `userfilter/${fieldId}/${userId}`,
      params
    ),
  post: (userFilter: UserFilter, params: BaseApiParams) =>
    BaseApi.post('userfilter', userFilter, params),
  delete: (FieldId: string, UserId: string, params: BaseApiParams) =>
    BaseApi.delete(`userfilter/${FieldId}/${UserId}`, params),
  putMany: (filters: Filter[], params?: BaseApiParams) =>
    BaseApi.put(`userfilter`, { filters }, params),
};

export default userFilterApi;
