import _ from 'lodash';
import {
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import { useFwStore } from 'components/base';
import {
  DocState,
  isSaveAndChange,
  ModalDocState,
  reducer,
  renderModal,
  SafeDispatch,
  TrackerProps,
  updateFields,
  VisibleElements,
} from 'components/doc/function/document';
import { Suggestions, updateState } from 'components/doc/helpers';
import { Document } from 'core/model';
import utils, { DataProps } from 'core/utils/utils';
import { defaultModal } from 'core/utils/utilsModal';

const useInputForm = (
  useTracker: boolean,
  exists: boolean,
  saved: boolean,
  entityDoc: Document,
  entityDocData: DataProps,
  entityDocDataExtended: DataProps,
  entityVisibleElements: VisibleElements,
  entityInvalidInputKey: string,
  entityPrevDocData: DataProps,
  onChange?: (
    fieldKey: string,
    value: string | object,
    docDataRef: MutableRefObject<DataProps>
  ) => void
) => {
  // ref
  const unmountingRef = useRef(false);
  const entityDocRef = useRef(entityDoc);
  const entityDocDataExtendedRef = useRef(_.cloneDeep(entityDocDataExtended));
  const entityDocDataRef = useRef(_.cloneDeep(entityDocData));
  const visibleElementsRef = useRef(_.cloneDeep(entityVisibleElements));
  const invalidInputKeyRef = useRef(entityInvalidInputKey);

  const store = useFwStore();

  // useState
  const [{ docData, invalidInputKey, modal, visibleElements }, dispatch] =
    useReducer(reducer, {
      docData: entityDocDataRef.current,
      visibleElements: visibleElementsRef.current,
      modal: defaultModal,
      invalidInputKey: invalidInputKeyRef.current,
    });

  // unmount management
  useEffect(() => {
    return () => {
      unmountingRef.current = true;
    };
  }, []);

  const safeDispatch: SafeDispatch = (newState) => {
    if (!unmountingRef.current) {
      dispatch(newState);
    }
  };

  // trigger on load search functions
  useEffect(() => {
    Suggestions.getSuggestions(
      dispatch,
      entityDocRef.current,
      entityDocDataRef.current,
      undefined,
      visibleElementsRef.current,
      store
    );
  }, [entityDocRef, entityDocDataRef, store, visibleElementsRef]);

  // on input focus change
  const onInputFormChange = (
    e: ChangeEvent<HTMLInputElement>,
    data: DataProps
  ) => {
    const { field, value } = utils.getFieldValueFromEData(e, data);

    updateState(
      { [field]: value },
      entityDocRef,
      entityDocDataRef,
      visibleElementsRef,
      invalidInputKeyRef,
      safeDispatch,
      store,
      data?.name
    );

    onChange?.(field, value, entityDocDataRef);
  };

  // on input focus out
  const onInputFormBlur = (e: FocusEvent<HTMLInputElement>) => {
    renderModal(e, entityDocRef, entityDocDataRef, safeDispatch, store);
  };

  // reset modal
  const dispatchAndHideModal = (
    defaultModal: ModalDocState,
    newState?: DocState
  ) => {
    safeDispatch({ ...(newState || {}), modal: { ...defaultModal } });
  };

  const hideModal = () => {
    dispatchAndHideModal(defaultModal);
  };

  // visual autosave indicator
  let tracker: TrackerProps[];

  if (useTracker) {
    tracker = isSaveAndChange(
      exists,
      saved,
      entityDocDataExtendedRef.current,
      entityPrevDocData,
      docData
    );
  }

  _.forEach(visibleElements.steps, (steps) => {
    _.forEach(steps.contents, (content) => {
      const fields = content.fields;
      fields.length && updateFields(exists, fields, tracker, docData);
    });
  });

  return {
    docData,
    invalidInputKey,
    modal,
    visibleElements,
    hideModal,
    onInputFormChange,
    onInputFormBlur,
  };
};

export default useInputForm;
