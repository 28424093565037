import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { FocusEvent, MutableRefObject } from 'react';

import { FwStore } from 'components/base';
import { showModal } from 'components/doc//helpers';
import {
  AutoSaveEdit,
  AutoSaveInfo,
  Clickable,
  Document,
  ExtendDocument,
  FwFieldProps,
  Input,
  Step,
} from 'core/model';
import { FIELD_TYPE } from 'core/utils/constant';
import { MODAL_TYPES } from 'core/utils/constants';
import utils from 'core/utils/utils';

// props
export type DataProps = Record<string, string | object | object[]>;
type RefInputAdditionalDataProps = {
  filters: Record<string, string>;
  linkFields: string[];
  referenceFields: string[];
  referenceSearchField: string;
};
type RefInputValueProps = {
  refDocID: string;
  linkDocID: string;
  inputValue: string;
};
export type TrackerProps = { key: string; saved: boolean; unchanged: boolean };
type TriggerApiCallProps = (
  formSubmit:
    | DataProps
    | Document /* doc seems to send Document, entityInfoContainer seems to send DataProps */,
  fields: string[]
) => void;
export type VisibleElements = { steps: Step[]; modalInputs: Input[] };
export type ModalDocState = {
  isOpen: boolean;
  modalType: `${MODAL_TYPES}`;
  options: object;
};
export type DocState = {
  actionable: boolean;
  clickables: Clickable[];
  docData: DataProps;
  exists: boolean;
  invalidInputKey: string;
  invalidStepID: string;
  invalidTrigger: { timeStamp: number };
  loading: boolean /* only used in EntityInfoContainer */;
  loadingInputKey: { key: string; loading: boolean };
  loadingInputKeys: string[];
  modal: ModalDocState;
  prevDocData: DataProps /* only used in EntityInfoContainer */;
  prevDocument: Document /* only used in EntityInfoContainer */;
  redirect: { external: boolean; location: string };
  requiredAreFill: boolean /* only used in EntityInfoContainer */;
  visibleElements: VisibleElements;
};
export type SafeDispatch = (arg0: Partial<DocState>) => void;

// function
export const fieldTimeout = (
  previousTimeouts: Record<string, NodeJS.Timeout>,
  timeoutField: string,
  submitFields: string[],
  formSubmit:
    | DataProps
    | Document /* doc seems to send Document, entityInfoContainer seems to send DataProps */,
  triggerApiCall: TriggerApiCallProps
) => {
  let newTimeout: NodeJS.Timeout;

  // cancel previous timeout for this field
  if (previousTimeouts[timeoutField]) {
    clearTimeout(previousTimeouts[timeoutField]);
  }

  if (submitFields.length > 0) {
    // build new timeout for this field
    newTimeout = setTimeout(() => {
      // send timeout notification
      triggerApiCall(formSubmit, submitFields);
    }, 1000);

    // store timeout for the field
    previousTimeouts[timeoutField] = newTimeout;
  } else {
    clearTimeout(previousTimeouts[timeoutField]);
  }
};

export const isSaveAndChange = (
  exists: boolean,
  saved: boolean,
  refData: DataProps,
  prevData: DataProps,
  currentData: DataProps
) => {
  const dataProps = _.keys(currentData);

  // initialize unchanged and saved state by field
  const tracker: TrackerProps[] = exists ? [] : undefined;

  if (exists && !_.isEmpty(dataProps)) {
    _.forEach(dataProps, (key) => {
      if (_.isObject(refData[key])) {
        const secondDataProps = _.keys(currentData[key]);

        _.forEach(secondDataProps, (sKey) => {
          const unchanged =
            `${prevData[key][sKey]}` === `${currentData[key][sKey]}`;

          tracker.push({
            key: key + '.' + sKey,
            saved:
              saved &&
              `${refData[key][sKey]}` !== `${prevData[key][sKey]}` &&
              unchanged,
            unchanged: unchanged,
          });
        });
      } else {
        const unchanged = `${prevData[key]}` === `${currentData[key]}`;

        tracker.push({
          key,
          saved: saved && `${refData[key]}` !== `${prevData[key]}` && unchanged,
          unchanged: unchanged,
        });
      }
    });
  }

  return tracker;
};

export const isValidResponse = (response: Partial<AxiosResponse>) => {
  return response && (response.status === 204 || response.status === 201);
};

export const isSaved = (response: Partial<AxiosResponse>, pending: boolean) => {
  return (
    (!pending && isValidResponse(response)) ||
    _.isNil(isValidResponse(response))
  );
};

export const updateFields = (
  exists: boolean,
  fields: FwFieldProps[],
  tracker: TrackerProps[],
  currentData: DataProps
) => {
  _.forEach(fields, (field) => {
    const tracking = _.find(tracker, { key: field.key });
    const disabledAndReadOnly = field.isReadOnly && exists;
    const value = currentData[field.key];

    field.value = _.isNil(value) ? undefined : `${value}`;
    field.saved = tracking ? tracking.saved : false;
    field.unchanged = tracking ? tracking.unchanged : true;
    field.disabled = disabledAndReadOnly;
    field.readOnly = disabledAndReadOnly;
  });
};

export const getAutoSaveInfo = (
  form: DataProps,
  fields: string[],
  keyName: string
) => {
  const autoSaveEdits = [];
  _.forEach(fields, function (field) {
    autoSaveEdits.push(
      new AutoSaveEdit({
        name: utils.toPascalCase(field),
        value: form[field],
      })
    );
  });

  const autoSaveInfo = new AutoSaveInfo({
    id: form[keyName],
    autoSaveEdits: autoSaveEdits,
  });

  return autoSaveInfo;
};

export const getExtendDocs = (
  dataSubmit: DataProps,
  document: Document,
  linkTemplateID: string
) => {
  const allInputs = utils.getDistinctFieldsFromTemplates([document.template]);

  // process reference link form
  // this should be call before updateCollectionData
  // for case has reference field inside collection field
  const extendDocs = buildExtendedDocs(
    allInputs,
    dataSubmit,
    document.extendDocuments
  );

  // process collection data
  updateCollectionData(
    allInputs,
    dataSubmit,
    extendDocs,
    document.extendDocuments
  );

  // stringify linkFormData and assign linkDocTemplateID
  extendDocs.forEach((ed) => {
    if (ed.linkDocument && typeof ed.linkDocument.data !== 'string') {
      ed.linkDocument.data = JSON.stringify(ed.linkDocument.data);
    }

    if (ed.linkDocument && !ed.linkDocument.template) {
      // link with temlate type LINK
      ed.linkDocument.template = { templateId: linkTemplateID };
    }
  });

  return extendDocs;
};

const initLinkDoc = (extendDocuments: ExtendDocument[], linkDocID: string) => {
  // get link doc from doc which is link with this ref field
  const linkDoc = (
    linkDocID
      ? extendDocuments.find(
          (ed) => ed.linkDocument && ed.linkDocument.documentID === linkDocID
        ).linkDocument
      : {}
  ) as Document;

  return linkDoc;
};

const updateLinkDocData = (
  linkDoc: Document,
  key: string,
  linkFields: string[],
  docData: DataProps,
  idx: number
) => {
  const linkDocData =
    linkDoc.data && typeof linkDoc.data === 'string'
      ? JSON.parse(linkDoc.data)
      : linkDoc.data ?? {};
  const endKey = idx ? `|${idx}` : '';

  linkFields.forEach((lf) => {
    const combineKey = `${key}|${lf}`;

    if (!_.isNil(docData[combineKey])) {
      linkDocData[`${combineKey}${endKey}`] = docData[combineKey];
    }
  });

  linkDoc.data = linkDocData;
};

export const buildExtendedDoc = (
  extendDocs: ExtendDocument[],
  docExtendDocs: ExtendDocument[],
  docData: DataProps,
  refInputKey: string,
  refInputValue: RefInputValueProps,
  refInputAdditionalData: RefInputAdditionalDataProps,
  idx: number
) => {
  const { refDocID, linkDocID } = refInputValue;
  const { linkFields } = refInputAdditionalData;

  if (refDocID) {
    // ref field has value then create link to reference doc
    const refDoc = extendDocs.find(
      (d) => d.referenceDocument.documentID === refDocID
    );

    if (refDoc) {
      // already exist then update linkForm data if any
      if (linkFields && linkFields.length > 0) {
        // get link doc if already has or else init new
        if (!refDoc.linkDocument) {
          refDoc.linkDocument = initLinkDoc(docExtendDocs, linkDocID);
        }
        // update linkFormData
        updateLinkDocData(
          refDoc.linkDocument,
          refInputKey,
          linkFields,
          docData,
          idx
        );
      }
    } else {
      // add new ref doc
      const newRefDoc = {
        referenceDocument: {
          documentID: refDocID,
          number: undefined,
          super: undefined,
          lastEdit: undefined,
        },
        linkDocument: undefined,
      };

      // add linkForm data if any
      if (linkFields && linkFields.length > 0) {
        const linkDoc = initLinkDoc(docExtendDocs, linkDocID);

        // update linkFormData
        updateLinkDocData(linkDoc, refInputKey, linkFields, docData, idx);

        // assign to refDoc
        newRefDoc.linkDocument = linkDoc;
      }

      extendDocs.push(newRefDoc);
    }
  }
};

const buildExtendedDocs = (
  allInputs: Input[],
  dataSubmit: DataProps,
  docExtendDocs: ExtendDocument[]
) => {
  const extendDocs = [];

  const referenceInputs = allInputs.filter(
    (f) => f.type === FIELD_TYPE.reference
  );

  referenceInputs.forEach(({ key, additionalData }) => {
    const value = { ...(dataSubmit[key] as RefInputValueProps) };
    const { inputValue } = value;

    // keep value of input
    dataSubmit[key] = inputValue;

    // build extendDoc by ref input
    buildExtendedDoc(
      extendDocs,
      docExtendDocs,
      dataSubmit,
      key,
      value,
      additionalData as RefInputAdditionalDataProps,
      undefined
    );
  });

  return extendDocs;
};

const updateCollectionData = (
  allInputs: Input[],
  dataSubmit: DataProps,
  extendDocs: ExtendDocument[],
  docExtendDocs: ExtendDocument[]
) => {
  _.forEach(allInputs, function ({ key, type, subInputs }) {
    if (type === FIELD_TYPE.collection) {
      // remove empty rows from collection
      const rowsArray = utils.removeEmptyRowCollection(
        dataSubmit[key] as object[]
      );

      // keep info of length of collection (number of its rows)
      dataSubmit[key] = `${rowsArray.length}`;

      if (subInputs.some((sf) => sf.type === FIELD_TYPE.reference)) {
        // has reference fields inside collection field
        const refSubInputs = subInputs.filter(
          (f) => f.type === FIELD_TYPE.reference
        );

        // get all ref related inputs
        const allRefRelatedInputs = refSubInputs.map((i) => i.key);
        const extendRefInputs = subInputs
          .filter((f) =>
            refSubInputs.some((i) => f.key.startsWith(`${i.key}|`))
          )
          .map((i) => i.key);

        allRefRelatedInputs.push(...extendRefInputs);

        rowsArray.forEach((row, idx) => {
          // process all data relate to reference inputs
          refSubInputs.forEach(({ key, additionalData }) => {
            const value = row[key] || {};
            const { inputValue } = value;

            // keep value of input
            dataSubmit[`${key}|${idx + 1}`] = inputValue;

            // build extend doc by ref input
            buildExtendedDoc(
              extendDocs,
              docExtendDocs,
              row,
              key,
              value,
              additionalData as RefInputAdditionalDataProps,
              idx + 1
            );
          });

          // process others inputs
          // save otherInputs as normal key|index: value
          const otherInputs = _.pickBy(
            row,
            (_v, k) => !allRefRelatedInputs.includes(k)
          );

          _.forOwn(otherInputs, (val, key) => {
            if (!_.isNil(val)) {
              dataSubmit[`${key}|${idx + 1}`] = val;
            }
          });
        });
      } else {
        utils.getCollectionDataToFieldValueIndex(dataSubmit, rowsArray);
      }
    }
  });
};

export const reducer = (
  prevState: Partial<DocState>,
  newState: Partial<DocState>
) => {
  if (newState && newState.loadingInputKey) {
    const { key: inputKey, loading } = newState.loadingInputKey;

    if (loading) {
      prevState.loadingInputKeys = _.merge(prevState.loadingInputKeys || [], [
        inputKey,
      ]);
    } else {
      prevState.loadingInputKeys = _.remove(
        prevState.loadingInputKeys,
        inputKey
      );
    }

    newState.loadingInputKey = undefined;
  }

  return {
    ...prevState,
    ..._.pickBy(newState, (value) => value !== undefined),
  };
};

export const getSubmitData = (prevData: DataProps, currentData: DataProps) => {
  const submitData = {};

  _.forEach(currentData, function (value, key) {
    if (value != prevData[key] || _.isUndefined(prevData[key])) {
      submitData[key] = value;
    }
  });

  return submitData;
};

export const renderModal = (
  e: FocusEvent<HTMLInputElement>,
  documentRef: MutableRefObject<Document>,
  docDataRef: MutableRefObject<DataProps>,
  safeDispatch: SafeDispatch,
  store: FwStore
) => {
  const inputKey = e?.target?.name ?? '';

  // show modal
  const modal = showModal(
    documentRef.current,
    docDataRef.current,
    [inputKey],
    true,
    store
  );

  // trigger render
  if (modal) {
    safeDispatch({ modal });
  }
};
