import _ from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router';

import { Background } from 'components';
import { FwSpinner, FwToast, useFwArea } from 'components/base';

import AreaRoutes from './AreaRoutes';

const App = () => {
  const { areas, getAreaBasePath } = useFwArea();

  return _.isEmpty(areas) ? (
    <FwSpinner />
  ) : (
    <>
      <FwToast />
      <Background />
      <Switch>
        {_.map(areas, (area, index) => {
          const basePath = getAreaBasePath(area);

          return (
            <Route
              key={index}
              path={basePath}
              render={() => (
                <AreaRoutes
                  key={index}
                  areaKey={area.key}
                  basePath={basePath}
                />
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default App;
