import { Grid, GridItem, TableContainer } from '@chakra-ui/react';
import _ from 'lodash';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { FwInput, useFwStore } from 'components/base';
import { getViewDate } from 'components/base/containers/mask/FwMask.helpers';
import { getDateRange } from 'components/tables/helpers/tableCriteriaHelpers';
import { Option } from 'core/model';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE } from 'core/utils/constant';
import {
  dateFormats,
  getDateOrDefault,
  jsDateFromString,
  jsDateToString,
} from 'core/utils/date';
import utils from 'core/utils/utils';

import { DateViewType } from '../FwMask.structures';
import {
  dateRangeToString,
  getHourEndNumber,
  getHourStartNumber,
} from './FwMask.Timeline.helpers';
import TimelineTable from './FwMask.Timeline.Table';

const FwTimeline: FC<FwMaskCommonProps> = ({
  maskStructure,
  readOnlyFilterKeys,
  updateCriteriaFunc,
  ...props
}) => {
  // todo wip#585 use compact to make table scroll vertical
  const { view, document /*, compact*/ } = maskStructure || {};
  const {
    date,
    end,
    hideTypeSelect,
    hideDateSelect,
    slotMultiplier = 1,
    start,
    type = DateViewType.day,
  } = view || {};
  const timelineFieldKeyFrom = document?.start?.[0];

  const { t } = useTranslation();
  const { moduleStore, pageStore, pageContentStore } = useFwStore();

  const [startVal, endVal] = getDateRange(date, {
    moduleStore,
    pageStore,
    pageContentStore,
  });

  const [selectedDate, setSelectedDate] = useState({
    startDate: getViewDate(startVal, {
      moduleStore,
      pageStore,
      pageContentStore,
    }),
    endDate: getViewDate(endVal, { moduleStore, pageStore, pageContentStore }),
  });

  const [selectedView, setSelectedView] = useState(
    _.includes(_.values(DateViewType), type) ? type : DateViewType.day
  );
  const [startView, setStartView] = useState(
    getHourStartNumber(selectedView, start)
  );
  const [endView, setEndView] = useState(getHourEndNumber(selectedView, end));

  const handleDateChange = useCallback(
    (newValue: string, forView?: `${DateViewType}`) => {
      const arrayValue = _.includes(newValue, '|')
        ? newValue.split('|')
        : [newValue, newValue];
      const startDateString = arrayValue[0];
      const endDateString =
        forView === DateViewType.day ? arrayValue[0] : arrayValue[1];

      const startDate = jsDateFromString(startDateString, dateFormats.isoDate);
      const endDate = jsDateFromString(endDateString, dateFormats.isoDate);

      setSelectedDate({
        startDate: getDateOrDefault(startDate),
        endDate: getDateOrDefault(endDate),
      });

      const { start, end } = document;

      // if date has changed, update query to refetch data
      if (start || end) {
        const startField = start?.[0];
        const endField = end?.[0];

        const dateCriteria =
          !startField || !endField || startField === endField
            ? [
                {
                  key: startField || endField,
                  value: `${startDateString}|${endDateString}`,
                },
              ]
            : [
                { key: startField, value: `|${endDateString}` },
                { key: endField, value: `${startDateString}|` },
              ];

        updateCriteriaFunc?.(dateCriteria);
      }
    },
    [document, updateCriteriaFunc]
  );

  useEffect(() => {
    if (pageStore?.filterData) {
      _.forOwn(pageStore?.filterData, (value, key) => {
        if (key === timelineFieldKeyFrom) {
          handleDateChange(value, selectedView);
        } else {
          // filter with key value
          updateCriteriaFunc([{ key, value }]);
        }
      });
    }
  }, [
    handleDateChange,
    pageStore,
    selectedView,
    timelineFieldKeyFrom,
    updateCriteriaFunc,
  ]);

  const handleChangeView = (view: `${DateViewType}`) => {
    setStartView(getHourStartNumber(view, start));
    setEndView(getHourEndNumber(view, end));
    setSelectedView(view);

    if (!pageStore?.filterData) {
      const { startDate, endDate } = selectedDate;

      handleDateChange(
        `${jsDateToString(startDate, dateFormats.isoDate)}|${jsDateToString(
          endDate,
          dateFormats.isoDate
        )}`,
        view
      );
    }
  };

  // todo #585 refactor styling?
  const tableContainerStyle = {
    mb: 2,
  };

  return (
    <>
      {(!hideTypeSelect || !hideDateSelect) && (
        <Grid alignItems="center" templateColumns="repeat(3, 1fr)" mb={2}>
          <GridItem>
            {!hideTypeSelect && (
              <FwInput
                type={FIELD_TYPE.select}
                value={selectedView}
                options={_.map(
                  _.values(DateViewType),
                  (v) =>
                    new Option({
                      key: v,
                      text: t(`calendar|${utils.toSentenceCase(v)}`),
                      value: v,
                    })
                )}
                onChange={(_e: ChangeEvent, { value }) =>
                  handleChangeView(value)
                }
              />
            )}
          </GridItem>
          <GridItem />
          <GridItem>
            {!hideDateSelect && (
              <FwInput
                readOnly={_.includes(readOnlyFilterKeys, timelineFieldKeyFrom)}
                type={
                  selectedView === DateViewType.day
                    ? FIELD_TYPE.date
                    : FIELD_TYPE.daterange
                }
                value={
                  selectedView === DateViewType.day
                    ? jsDateToString(
                        selectedDate.startDate,
                        dateFormats.isoDate
                      )
                    : dateRangeToString(
                        selectedDate.startDate,
                        selectedDate.endDate
                      )
                }
                onChange={(_e: ChangeEvent, { value }) =>
                  handleDateChange(value, selectedView)
                }
              />
            )}
          </GridItem>
        </Grid>
      )}
      <TableContainer {...tableContainerStyle}>
        <TimelineTable
          {...props}
          endView={endView}
          maskStructure={maskStructure}
          selectedDate={selectedDate}
          selectedView={selectedView}
          slotMultiplier={slotMultiplier}
          startView={startView}
        />
      </TableContainer>
    </>
  );
};

FwTimeline.propTypes = {};

export default FwTimeline;
