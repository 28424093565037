const mathSign =
  Math.sign ||
  function mathSign(x) {
    const val = +x;

    if (val === 0 || Number.isNaN(val)) {
      return val;
    }
    return val > 0 ? 1 : -1;
  };

const scrollToAnchor = (
  target?: string,
  lastOffsetY?: number,
  lastAcceleration = 0.1,
  movementDirection = undefined,
  scrollable = null
) => {
  const anchorName = target || window.location.hash;
  const anchor = anchorName && document.querySelector(anchorName);
  const scrollParent = scrollable || getScrollableParent(anchor);
  const offsetY = scrollParent
    ? scrollParent.scrollTop
    : window.scrollY || window.pageYOffset;

  if (!anchor) {
    // no target to scroll to
    return;
  } else {
    // scroll window to anchor
    const elementTop = Math.round(anchor.getBoundingClientRect().top - 150);
    const direction = mathSign(elementTop);
    const scrollStep = Math.ceil(Math.abs(elementTop / 8)) * direction;
    const acceleration = Math.min(1, (lastAcceleration * 100) ** 1.1 / 100);

    // scroll is effective (it did not hit target and did not change direction)
    if (
      lastOffsetY !== offsetY &&
      (movementDirection === undefined || movementDirection === direction)
    ) {
      // keep scrolling
      (scrollParent || window).scrollBy(0, scrollStep * acceleration);
      requestAnimationFrame(() =>
        scrollToAnchor(target, offsetY, acceleration, direction, scrollParent)
      );
    }
  }
};

const getScrollableParent = (element: Element) => {
  let returnVal = null;

  if (element) {
    if (element.scrollHeight > element.clientHeight) {
      returnVal = element;
    } else {
      returnVal = getScrollableParent(element.parentNode as Element);
    }
  }

  return returnVal;
};

export default scrollToAnchor;
