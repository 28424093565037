import { Grid as ChakraGrid, GridItem } from '@chakra-ui/react';
import _ from 'lodash';
import { bool, elementType, InferProps, node } from 'prop-types';
import React, { FC } from 'react';

import { defaultSpacing } from 'config/theme/constants';

import { NoSpanGrid } from './FwGrid.helpers';
import NestedItem from './FwGrid.NestedItem';

const getGridStyle = (responsive) => ({
  gap: defaultSpacing,
  templateColumns: responsive
    ? 'repeat(auto-fit, minmax(200px, 1fr))'
    : 'minmax(50px, auto)',
});

const NestedGrid: FC<Props> = ({
  children,
  grid,
  responsive,
  itemComponent,
  ...props
}) => {
  return (
    <ChakraGrid data-role="rowgrid" {...getGridStyle(responsive)} {...props}>
      {_.map(grid.rows, (row, index) => {
        return (
          <GridItem data-role="row" key={index} rowStart={index + 1}>
            <ChakraGrid
              data-role="columngrid"
              {...getGridStyle(row.items?.length > 1)}
              {...props}
            >
              {_.map(row.items, (item, itemIndex) => {
                return (
                  <NestedItem
                    key={itemIndex}
                    item={item}
                    itemComponent={itemComponent}
                    responsive={responsive}
                  />
                );
              })}
            </ChakraGrid>
          </GridItem>
        );
      })}
      {children}
    </ChakraGrid>
  );
};

const propTypes = {
  children: node,
  itemComponent: elementType,
  responsive: bool,
};

interface Props extends InferProps<typeof propTypes> {
  grid: NoSpanGrid;
}

NestedGrid.propTypes = propTypes;

export default NestedGrid;
